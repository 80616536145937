import React, { useEffect, useRef } from "react";
import {
  TableContainer,
  TableWrapper,
  TableMain,
  TableHead,
  TableBody,
  TableBodyCell,
  TableHeadRow,
  TableBodyRow,
  TableEmpty,
  TableBodyRow2,
} from "./styles";
import { TTableProps, TTableHeadItem } from "./types";
import getObjectDynamicPath from "../../utils/extended-proto";
import TableFilter from "./tableFilter";
import { useAuth } from "../../context/AppContext";

export type Tsorter = keyof any;

const Table = ({
  head = [],
  items = [],
  sorter,
  setSorter,
  setSortOrder,
  renderItem = (_b) => {},
  ...props
}: TTableProps) => {
  const visibleItems = head.filter((x) => x.visible);
  const { setDetailMap, setSelectedCar } = useAuth();
  
  const divRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      //@ts-ignore
      if (divRef.current && !divRef.current.contains(event.target)) {
        const targetTagName = (event.target as HTMLElement).tagName.toLowerCase();
        const targetClassName = (event.target as HTMLElement).className;

        if (targetTagName === 'div' || targetTagName === 'span' || targetTagName === 'button') {
          if (!targetClassName.includes('leaflet-map')) {
            setSelectedCar(null);
            setDetailMap(false);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    //eslint-disable-next-line
  }, [divRef]);

  return (
    <TableContainer {...props} className="table-container"  >
      <TableWrapper className="table-wrapper">
        <TableMain className="table-main">
          <TableHead className="table-head">
            <TableHeadRow className="table-head">
              {visibleItems.map((x: any, index: any) => (
                <TableFilter
                  key={index}
                  name={x.reference}
                  label={x.label}
                  active={sorter}
                  onClickfilter={(e) => {
                    setSorter(x.reference);
                    setSortOrder(e);
                  }}
                />
              ))}
            </TableHeadRow>
          </TableHead>
          {!!items.length && (
            <TableBody className="table-body" ref={divRef}>
              {items.map((rowData: any, rowIndex: number) => {
                if (rowIndex % 2 === 0) {
                  return (
                    <TableBodyRow2
                      key={Math.random() * 10000}
                      className="table-body-row"
                    >
                      {visibleItems.map((a: TTableHeadItem, b: number) => (
                        <TableBodyCell
                          key={Math.random() * 10000}
                          className="table-body-cell"
                        >
                          {renderItem({
                            headItem: a,
                            cell: {
                              index: b,
                              data: getObjectDynamicPath(rowData, a.reference),
                            },
                            row: {
                              index: rowIndex,
                              data: rowData,
                            },
                            table: items,
                          })}
                        </TableBodyCell>
                      ))}
                    </TableBodyRow2>
                  );
                } else {
                  return (
                    <TableBodyRow
                      key={Math.random() * 10000}
                      className="table-body-row"
                    >
                      {visibleItems.map((a: TTableHeadItem, b: number) => (
                        <TableBodyCell
                          key={Math.random() * 10000}
                          className="table-body-cell"
                        >
                          {renderItem({
                            headItem: a,
                            cell: {
                              index: b,
                              data: getObjectDynamicPath(rowData, a.reference),
                            },
                            row: {
                              index: rowIndex,
                              data: rowData,
                            },
                            table: items,
                          })}
                        </TableBodyCell>
                      ))}
                    </TableBodyRow>
                  );
                }
              })}
            </TableBody>
          )}
        </TableMain>
        {!items.length && <TableEmpty>No Datas</TableEmpty>}
      </TableWrapper>
    </TableContainer>
  );
};

export default Table;
