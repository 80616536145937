import React from "react";
import {
  TermsContainer,
  TermsContainerBody,
  TermsContainerBodyInner,
  TermsHeader,
  TermsHeaderUpdated,
  TermsHeaderWrapper,
  TermsItem,
  TermsItemContent,
  TermsItemTitle,
} from "./styles";
import { termsData } from "./terms";

const Terms = () => {

  return (
    <TermsContainer className="TermsContainer">
      <TermsHeaderWrapper className="TermsHeaderWrapper">
        <TermsHeader className="TermsHeader">
          Terms of Service for useTurboFox.com
        </TermsHeader>
        <TermsHeaderUpdated className="TermsHeaderUpdated">
          Last Updated: March 27, 2024
        </TermsHeaderUpdated>
      </TermsHeaderWrapper>
      <TermsContainerBody className="TermsContainerBody">
        <TermsContainerBodyInner className="TersContainerBodyInner">
          {
            termsData.map((item, index) => (
              <TermsItem className="TermsItem" key={index}>
                <TermsItemTitle className="TermsItemTitle">{index + 1}. {item.title}</TermsItemTitle>
                <TermsItemContent className="TermsItemContent">{item.description}</TermsItemContent>
              </TermsItem>
            ))
          }
        </TermsContainerBodyInner>
      </TermsContainerBody>
    </TermsContainer>
  );
};

export default Terms;
