import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, SIZES } from "../../constants";

export const TurboMainContainerBody = styled.div<{ theme?: Theme, location: any}>`
  ${({ theme, location }) => `
      width: 100%;
      height: 100%;
      min-height: ${location ? 'calc(100dvh - 70px)' : ''} ;
      display: flex;
      flex-direction: column;
      flex:1;
      font-size: ${SIZES.body1}px;
      font-family: ${FONT_FAMILY.regular};
      position:relative;
  `}
`;
export const DashboardReportConent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex:1;
      position: relative;
      ${theme.breakpoints.down("sm")} {
        overflow-x:hidden;
        overflow-y:auto;
      }
    `}
`;
export const DashboardLeftSdie = styled.div<{
  theme?: Theme;
  openBigMap: boolean;
  isMobile: boolean;
}>`
  ${({ theme, openBigMap, isMobile }) => `
    width: ${openBigMap && !isMobile ? 0 : "70%"};
    display: ${openBigMap && isMobile ? 'none' : ''};
    min-width: ${openBigMap ? 0 : "830px"};
    height: ${!isMobile ? "calc(100dvh - 135px)": "100%"};
    // height: calc(100dvh - 135px);
    padding:  ${openBigMap ? "0" : "23px 30px"};
    overflow: auto;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    ${theme.breakpoints.down("lg")} {
      width: 100%;
      min-width:initial;
    }
    ${theme.breakpoints.down("sm")} {
      padding:${openBigMap ? "0" : "15px"};
      height:initial;
    }
  `}
`;
export const DashboardRightSdie = styled.div<{
  theme?: Theme;
  openBigMap: boolean;
}>`
  ${({ theme, openBigMap }) => `
        width: ${openBigMap ? "100%" : "30%"};
        height:100%;
        position:sticky;
        top:0;
        background-color:${COLORS.primary};
        position: relative;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        transition: all .3s;
        ${theme.breakpoints.down("lg")} {
          width:${openBigMap ? "initial" : 0};
          height:calc(100dvh - 135px);
        }
        ${theme.breakpoints.down("md")} {
          width:${openBigMap ? "initial" : 0};
          height:calc(100dvh - 137px);
        }
        ${theme.breakpoints.down("sm")} {
          width:${openBigMap ? "initial" : 0};
          height:calc(100dvh - 127px);
        }
    `}
`;

export const MapOpenButton = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 13px;
      height: 45px;
      background: ${COLORS.dark};
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      position: absolute;
      left: 0;
      top: 50%;
      z-index: 500;
      transform: translateY(-50%);
      cursor: pointer;
      align-items: center;
      display: flex;
      justify-content: center;

      ${theme.breakpoints.down("lg")} {
        display:none;
      }
  `}
`;
