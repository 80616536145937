import React, { useState } from "react";
import {
  ExtraOfferContent,
  ExtraOfferContentContainer,
  ExtraOfferContentHeader,
  ExtraOfferContentPrice,
  ExtraOfferContents,
  ExtraOfferHeader,
  ExtraOfferPrice,
  ExtraOfferSubTitle,
  ExtraOfferTitle,
  ExtraOffers,
  ExtraOffersContainer,
  ExtraOffersLockTitle,
  OfferPriceSpan,
} from "../../styles";
import {
  LockContainer,
  LockContainerButton,
  LockContainerButtonWrapper,
  LockContainerHeader,
  LockWrapper,
} from "./lockStyle";
import { Modal } from "antd";
import SubScription from "../../../../components/custom/subScription";

const Lock = () => {
  const [showSubScription, setShowSubScription] = useState<boolean>(false);

  const clickHandle = () => {
    setShowSubScription(true);
  };
  const CancelHandle = () => {
    setShowSubScription(false);
  };
  return (
    <ExtraOffersContainer className="ExtraOffersContainer">
      {" "}
      <ExtraOffersLockTitle className="ExtraOffersLockTitle">What extras are people offering in your area?</ExtraOffersLockTitle>
      <ExtraOffers className="ExtraOffer">
        <ExtraOfferHeader>
          <ExtraOfferTitle className="ExtraOfferTitle">
            What extras are people offering in your area?
          </ExtraOfferTitle>
          <ExtraOfferSubTitle>Beach Chairs</ExtraOfferSubTitle>
          <ExtraOfferPrice>
            $20/day <OfferPriceSpan>3 available</OfferPriceSpan>
          </ExtraOfferPrice>
        </ExtraOfferHeader>

        <ExtraOfferContent>
          <ExtraOfferContentHeader>Prepaid refuel</ExtraOfferContentHeader>
          <ExtraOfferContentContainer>
            Save time, make drop-off a breeze, and avoid additional fees by
            adding this Extra, which allows you to return my car at any fuel
            level. Price includes up to a full tank of gas.
          </ExtraOfferContentContainer>
          <ExtraOfferContentPrice>$80/trip</ExtraOfferContentPrice>
        </ExtraOfferContent>

        <ExtraOfferContents>
          <ExtraOfferContentHeader>Child safety seat</ExtraOfferContentHeader>
          <ExtraOfferContentContainer>
            Save time, make drop-off a breeze, and avoid additional fees by
            adding this Extra, which allows you to return my car at any fuel
            level. Price includes up to a full tank of gas.
          </ExtraOfferContentContainer>
          <ExtraOfferPrice>
            $150/trip <OfferPriceSpan>4 available</OfferPriceSpan>
          </ExtraOfferPrice>
        </ExtraOfferContents>

        <LockWrapper className="LockWrapper">
          <LockContainer className="LockContainer">
            <LockContainerHeader className="LockContainerHeader">
              Want to unlock even more insights?
            </LockContainerHeader>
            <LockContainerButtonWrapper className="LockContainerButtonWrapper">
              <LockContainerButton
                className="LockContainerButton"
                onClick={clickHandle}
              >
                Upgrade your plan
              </LockContainerButton>
            </LockContainerButtonWrapper>
          </LockContainer>
        </LockWrapper>

        <Modal
          open={showSubScription}
          onCancel={CancelHandle}
          width={1200}
          centered
          style={{ overflow: "auto" }}
          footer=""
        >
          <SubScription />
        </Modal>
      </ExtraOffers>
    </ExtraOffersContainer>
  );
};

export default Lock;
