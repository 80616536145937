import React, { useEffect, useRef } from "react";
import L from "leaflet";
import { Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import {
    PopupContainer,
    PopupContainerBadge,
    PopupContainerBody,
    PopupContainerBodyItem,
    PopupContainerBodyItemName,
    PopupContainerBodyItemValue,
    PopupContainerBottomBadge,
    PopupContainerDescription,
    PopupContainerDescriptionHeader,
    PopupContainerImageSection,
    PopupContainerTitle,
    PopupImg,
    PopupSeeButton,
    PopupSeeButtonWrapper,
} from "./styles";
import { ICONS } from "../../../constants";
import { nFormatter } from "../../../utils/helper";
import './index.css'

const CustomMarker = (props: any) => {
    const { car, id } = props;
    const markerRef = useRef(null);
    const Icon = L.divIcon({
        className: "green-icon",
        iconSize: [35, 42],
        iconAnchor: [17.5, 42],
        popupAnchor: [0, -40],
        shadowSize: [68, 95],
        shadowAnchor: [20, 92],
        html: `
            <div class="icon-text">
                <div class="icon-img">
                  <img src='${car?.image_link}' alt="" style="width:28px; height: 28px; border-radius:50px; object-fit: cover;" />
                </div>
                <div class="icon-number">${car.no}</div>
                <img src='${ICONS.gooogleMapIcon_white}' style="width:35px" />
            </div>`

    });

    const handleSeeOnTuro = (url: string) => {
        window.open(url, "_blank")
    }


    const openPopup = () => {
        if (markerRef) {
            //@ts-ignore
            markerRef.current?.openPopup();
        }
    }

    useEffect(() => {
        if (id === car?.id) {
            openPopup();
        }
        //eslint-disable-next-line
    }, [id])

    return (
        <Marker position={[car.latitude, car.longitude]} icon={Icon} ref={markerRef}>
            <Popup minWidth={330}>
                <PopupContainer className="PopupContainer">
                    <PopupContainerImageSection>
                        <PopupImg src={car?.image_link}></PopupImg>
                    </PopupContainerImageSection>

                    <PopupContainerDescription className="PopupContainerDescription">
                        <PopupContainerDescriptionHeader className="PopupContainerDescriptionHeader">
                            <PopupContainerTitle className="PopupContainerTitle">
                                {car.make} {car.model} {car.year}
                            </PopupContainerTitle>
                            {
                                car?.placement &&
                                <>
                                    {
                                        car?.placement === 'top' ?
                                            <PopupContainerBadge className="PopupContainerBadge">
                                                {car?.placement}
                                            </PopupContainerBadge>
                                            :
                                            <PopupContainerBottomBadge className="PopupContainerBottomBadge" >
                                                {car.placement}
                                            </PopupContainerBottomBadge>
                                    }
                                </>
                            }
                        </PopupContainerDescriptionHeader>

                        <PopupContainerBody className="PopupContainerBody">
                            <PopupContainerBodyItem className="PopupContainerBodyItem">
                                <PopupContainerBodyItemName className="PopupContainerBodyItemName">
                                    Monthly ROI
                                </PopupContainerBodyItemName>
                                <PopupContainerBodyItemValue className="PopupContainerBodyItemValue">
                                    {car.monthly_roi}%
                                </PopupContainerBodyItemValue>
                            </PopupContainerBodyItem>
                            <PopupContainerBodyItem className="PopupContainerBodyItem">
                                <PopupContainerBodyItemName className="PopupContainerBodyItemName">
                                    Monthly Revenue
                                </PopupContainerBodyItemName>
                                <PopupContainerBodyItemValue className="PopupContainerBodyItemValue">
                                    ${car.expec_month_rev < 1000 ? nFormatter(car.expec_month_rev, 0) : nFormatter(car.expec_month_rev, 1)}
                                </PopupContainerBodyItemValue>
                            </PopupContainerBodyItem>
                            <PopupContainerBodyItem className="PopupContainerBodyItem">
                                <PopupContainerBodyItemName className="PopupContainerBodyItemName">
                                    MSRP
                                </PopupContainerBodyItemName>
                                <PopupContainerBodyItemValue className="PopupContainerBodyItemValue">
                                    ${nFormatter(car.msrp, 1)}
                                </PopupContainerBodyItemValue>
                            </PopupContainerBodyItem>
                        </PopupContainerBody>

                        <PopupSeeButtonWrapper className="PopupSeeButtonWrapper">
                            <PopupSeeButton className="PopupSeeButton" onClick={() => handleSeeOnTuro(car.turo_link)}>
                                See It On Turo
                            </PopupSeeButton>
                        </PopupSeeButtonWrapper>
                    </PopupContainerDescription>
                </PopupContainer>
            </Popup>
        </Marker>
    )
};
export default CustomMarker;
