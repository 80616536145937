export const FV = (
  rate: number,
  nper: number,
  pmt: number,
  pv: number,
  type: number
) => {
  let pow = Math.pow(1 + rate, nper),
    fv;
  if (rate) {
    fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow;
  } else {
    fv = -1 * (pv + pmt * nper);
  }
  return fv?.toFixed(2);
};
