import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, SIZES } from "../../../../constants";

export const DashboardContentBottom = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display:none;
    ${theme.breakpoints.down("md")} {
        display:flex;
        width:100%;
        flex-direction:column;
        align-items:end;
        border-top:1px solid #dbdbdb;
        position:sticky;
        bottom:0;
    }
    ${theme.breakpoints.down("sm")} {

    }

      
    `}
`;

export const ImageLink = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display: flex;
        flex-direction: row-reverse;

        ${theme.breakpoints.down("lg")} {
            display: flex;
            padding: 24px 32px;
            -webkit-box-pack: end;
            justify-content: end;
            gap: 32px;
            width: 100%;
            bottom: 0px;
            background-color: rgb(255, 255, 255);
            flex-direction:row;
       
            
        }
        ${theme.breakpoints.down("sm")} {
            
        }
    `}
`;

export const MobileBackBtn = styled.button<{ theme?: Theme}>`
  ${({ theme }) => `
        padding:12px 39px;
        background-color: ${COLORS.gray};
        border-radius: 33px;
        display:flex;
        justify-content:center;
        align-items:center;
        color: ${ COLORS.dark};
        font-size:${SIZES.body1}px;
        font-family:${FONT_FAMILY.semibold};
        border: 1px solid ${COLORS.gray};
        margin-right:30px;

        ${theme.breakpoints.down("lg")} {
            width:100%;
            margin-right:0;
            padding:8px 20px;
            font-size:16px;
        } 
    `}
`;

export const BackBtnInner = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        width:20px;
        margin-right: 10px;
        ${theme.breakpoints.down("md")} {
            width:15px;
        }
        ${theme.breakpoints.down("sm")} {
            width:15px;
        }
    `}
`;

export const NextBtn = styled.button<{ theme?: Theme, disabled: boolean }>`
  ${({ theme, disabled }) => `
        padding:12px 39px;
        background-color: ${disabled ? COLORS.gray : COLORS.dark};
        border-radius: 33px;
        display:flex;
        justify-content:center;
        align-items:center;
        color:white;
        font-size:${SIZES.body1}px;
        font-family:${FONT_FAMILY.semibold};
        border:1px solid ${disabled ? COLORS.gray : COLORS.dark};;

        ${theme.breakpoints.down("lg")} {
       
            width:100%;
            margin-right:0;
            padding:8px 20px;
            font-size:16px;
        }  
    `}
`;

export const NextBtnInner = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        width:20px;
        margin-left: 10px;
        ${theme.breakpoints.down("md")} {
            width:17px;
        }
        ${theme.breakpoints.down("sm")} {
            width:16px;
        }
    `}
`;

export const BackBtnWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        position: absolute;
        left: 55px;
        top: 65px;
    `}
`;

export const BackBtn = styled.button<{ theme?: Theme, disabled: boolean }>`
  ${({ theme, disabled }) => `
        padding:12px 39px;
        background-color:${COLORS.white_smoke};
        border-radius: 33px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid ${COLORS.white_smoke};
        &:hover {
            background-color: ${disabled ? '' : 'transparent'};
            color:${COLORS.white_smoke};
            border:1px solid ${COLORS.white_smoke};
        }
        ${theme.breakpoints.down("md")} {
            display:none;
          }
    `}
`;
