import { message } from "antd";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SubscriptionAPI from "../../../api/subscription";
import { useAuth } from "../../../context/AppContext";
import { disableSplashScreen2, enableSplashScreen2, getCheckoutOrigin } from "../../../utils/helper";

const CheckoutCallback = () => {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const session_id = query.get("session_id");
    const navigation = useNavigate();
    const { handleGetKeyOfMine } = useAuth();

    const handleCallBack = async () => {
        if (session_id) {
            try {
                enableSplashScreen2();
                await SubscriptionAPI.retrieveCheckoutSession(session_id);
                await handleGetKeyOfMine();
                message.success(
                    `Your Plan Changed Succesfully!`
                );
                const origin = getCheckoutOrigin();
                if (origin) {
                    navigation(`${origin}`)
                } else {
                    navigation('/')
                }
            } catch (error) {
                navigation('/')
            } finally {
                disableSplashScreen2();
            }
        } else {
            disableSplashScreen2();
        }
    };

    useEffect(() => {
        handleCallBack();
        //eslint-disable-next-line
    }, [session_id])
    return (
        <></>
    )
}

export default CheckoutCallback;