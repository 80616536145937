import React from "react";
import { BackBtn, BackBtnInner, BackBtnWrapper } from "./styles";
import { ICONS } from "../../../../constants";

const BackBtnModule = (props: any) => {
  const { handleStepInfo, currentstep } = props;

  const handleBack = () => {
    
    let nextStep = currentstep - 1;
    if (nextStep < 0) {
      nextStep = 0;
    };
    handleStepInfo(nextStep);
  };

  return (
    <BackBtnWrapper className="BackBtnWrapper">
      <BackBtn className="BackBtn" onClick={handleBack} disabled={currentstep === 0}>
        <BackBtnInner
          className="BackBtnInner"
          src={ICONS.back_arrow}
        />
      </BackBtn>
    </BackBtnWrapper>
  );
};

export default BackBtnModule;
