// import "animate.css/animate.min.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useAuth } from "../context/AppContext";
import { DashboardLayout } from "../layout";
import LandingPage from "../pages/landing";
import SignUp from "../pages/auth/signUp";
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import MarketOverView from "../pages/marketOverView";
import EarningPotential from "../pages/earningPotential";
import PersonOwnCarInfo from "../pages/marketOverView/elements/personOwnCarInfo";
import PersonalInfo from "../pages/personalInfo";
import LocationPage from "../pages/locationPage";
import GoogleCallback from "../pages/auth/googleCallback";
import CheckoutCallback from "../pages/auth/checkoutCallback";
import Terms from '../pages/terms';
import Policy from '../pages/policy';

function Routers() {
  const { currentUser } = useAuth();

  return (
    <Router>
      {currentUser ? (
        <>
          {currentUser.authstep > 3 ? (
            <DashboardLayout>
              <Routes>
                <Route path="/report/:id" element={<EarningPotential />} />
                <Route
                  path="/marketoverview/:id"
                  element={<MarketOverView />}
                />
                <Route path="/account" element={<PersonalInfo />} />
                <Route path="/locationPage" element={<LocationPage />} />
                <Route
                  path="/marketoverview/:report_id/:host_id"
                  element={<PersonOwnCarInfo />}
                />
                <Route path="/checkout/success" element={<CheckoutCallback />} />
                <Route path="*" element={<Navigate to="/report/0" />} />
              </Routes>
            </DashboardLayout>
          ) : (
            <Routes>
              <Route path="/home" element={<LandingPage />} />
              <Route path="/data-register" element={<Register />} />
              <Route path="*" element={<Navigate to="/data-register" />} />
            </Routes>
          )}
        </>
      ) : (
        <Routes>
          <Route path="/home" element={<LandingPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<Login />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Policy />} />
          <Route path="/connect/:provider" element={<GoogleCallback />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      )}
    </Router>
  );
}

export default Routers;
