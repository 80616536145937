import React from "react";
import Chart from "react-apexcharts";

const ApexChartsColumn = (props: any) => {
  const { projectedRevenue, totalRevenue } = props;

  const Fakedata: any = {
    series: [
      {
        name: "demand",
        data: [
          {
            x: "Jan",
            y: 400,
          },
          {
            x: "Feb",
            y: 430,
          },
          {
            x: "Mar",
            y: 448,
          },
          {
            x: "Apr",
            y: 470,
          },
          {
            x: "May",
            y: 540,
          },
          {
            x: "Jun",
            y: 580,
          },
          {
            x: "Jul",
            y: 690,
          },
          {
            x: "Aug",
            y: 340,
          },
          {
            x: "Sep",
            y: 550,
          },
          {
            x: "Oct",
            y: 800,
          },
          {
            x: "Nov",
            y: 650,
          },
          {
            x: "Dec",
            y: 600,
          },
        ],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        type: "bar",
        height: 230,
        background: "white",
      },
      stroke: {
        show: true,
        curve: "straight",
        lineCap: "butt",
        colors: "red",
        width: 2,
        dashArray: 20,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 'bold',
          colors: undefined
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      yaxis: {
        type: "category",
        labels: {
          formatter: function (value: any) {
            return "$" + value;
          },
        },
      },
      xaxis: {
        type: "category",
        crosshairs: {
          show: true,
          width: 1,
          position: "back",
          opacity: 1,
          stroke: {
            color: "#b6b6b6",
            width: 0,
            dashArray: 0,
          },
          fill: {
            type: "solid",
            color: "#B1B9C4",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 1,
            opacity: 1,
          },
        },
      },
      title: {
        text: "Annual earnings with seasonality ",
        align: "left",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "16.5px",
          fontFamily: "NunitoSans-Regular",
          color: "black",
        },
      },
      subtitle: {
        // text: `$${totalRevenue ? totalRevenue?.toFixed(0): ''}`,
        text: `$${totalRevenue ? totalRevenue.toFixed(0) : '?'}`,
        align: "left",
        margin: 10,
        offsetX: 0,
        offsetY: 30,
        floating: false,
        style: {
          fontSize: "30px",
          fontWeight: "bold",
          fontFamily: "Poppins-Bold",
          color: "black",
        },
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            subtitle: {
              style: {
                fontSize: "24px",
                fontWeight: "bold",
                fontFamily: "Poppins-Bold",
                color: "black",
              },
            },
            legend: {
              position: "bottom",
            },
            dataLabels: {
              style: {
                fontSize: "12px",
              }
            },
          }
        }
      ],
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          return (
            '<div class="toolTipContainer">' +
            "<span>" +
            "$" +
            series[seriesIndex][dataPointIndex]?.toFixed(0) +
            "</span>" +
            "</div>"
          );
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 7,
          width: 10,
          columnWidth: "60%",
        },
      },
    },
  };

  let series = [
    {
      name: "demand",
      data: projectedRevenue
    },
  ]

  return (
    <Chart
      options={Fakedata.options}
      series={projectedRevenue ? series : Fakedata.series}
      type="bar"
      height={350}
    />
  );
};

export default ApexChartsColumn;
