import React from 'react';

const VerticalDotsIcon = ({ ...props }) => (
  <svg width="27" height="26" viewBox="0 0 27 26" fill="none" {...props}>
    <path
      d="M18.5 0H8.5C4.72876 0 2.84315 0 1.67157 1.17157C0.5 2.34315 0.5 4.22876 0.5 8V18C0.5 21.7712 0.5 23.6569 1.67157 24.8284C2.84315 26 4.72876 26 8.5 26H18.5C22.2712 26 24.1569 26 25.3284 24.8284C26.5 23.6569 26.5 21.7712 26.5 18V8C26.5 4.22876 26.5 2.34315 25.3284 1.17157C24.1569 0 22.2712 0 18.5 0Z"
      fill="transparent"
    />
    <path
      d="M13.0807 15.097C14.0072 15.097 14.7582 14.346 14.7582 13.4196C14.7582 12.4932 14.0072 11.7422 13.0807 11.7422C12.1543 11.7422 11.4033 12.4932 11.4033 13.4196C11.4033 14.346 12.1543 15.097 13.0807 15.097Z"
      fill="currentColor"
    />
    <path
      d="M13.0807 9.22593C14.0072 9.22593 14.7582 8.47493 14.7582 7.54851C14.7582 6.6221 14.0072 5.87109 13.0807 5.87109C12.1543 5.87109 11.4033 6.6221 11.4033 7.54851C11.4033 8.47493 12.1543 9.22593 13.0807 9.22593Z"
      fill="currentColor"
    />
    <path
      d="M13.0807 20.9676C14.0072 20.9676 14.7582 20.2166 14.7582 19.2902C14.7582 18.3638 14.0072 17.6128 13.0807 17.6128C12.1543 17.6128 11.4033 18.3638 11.4033 19.2902C11.4033 20.2166 12.1543 20.9676 13.0807 20.9676Z"
      fill="currentColor"
    />
  </svg>
);

export default VerticalDotsIcon;
