import React, { useMemo, useState } from "react";
//eslint-disable-next-line
import { DTempCars, DTopTenHead } from "./data";
import { usePagination } from "../../../../../hooks";
import { TTableRenderItemObject } from "../../../../../components/table/types";
import { TSingleCar } from "../../../../../api/report/types";
import {
  TopBottomTableContainer,
  TopBottomTableLockContainer,
  TopBottomTableLockTitle,
} from "./styles";
import { COLORS } from "../../../../../constants";
import LockTable from "../../../../../components/table/lockIndex";
import { Tsorter } from "../../../../../components/table";

const Lock = () => {
  //eslint-disable-next-line
  const [totalCar, setTotalCar] = useState<any[]>(DTempCars);
  //eslint-disable-next-line
  const [sorter, setSorter] = useState<Tsorter>("no");
  //eslint-disable-next-line
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);
  const PageSize = 10;

  //eslint-disable-next-line
  const { pagesCount, page, setTotalResults, handlePageChange } = usePagination(
    {
      limit: PageSize,
      page: 1,
      onChange: async (params, setPage) => {
        setPage(params.page);
        setTotalResults(totalCar.length);
      },
    }
  );

  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return totalCar?.slice(firstPageIndex, lastPageIndex);
  }, [page, totalCar, PageSize]);

  const renderItem = ({ headItem, row }: TTableRenderItemObject) => {
    const car: any = row.data as TSingleCar;

    if (headItem.reference === "vehicle") {
      return car.vehicle;
    }

    if (headItem.reference === "placement") {
      return (
        <div
          style={{
            padding: 2,
            width: "fit-content",
            minWidth: "60px",
            borderRadius: "5px",
            textAlign: "center",
            background: `${
              car.placement === "top" ? COLORS.light_green : COLORS.light_blue
            }`,
            color: `${car.placement === "top" ? COLORS.green : COLORS.blue}`,
          }}
        >
          {car.placement}
        </div>
      );
    }

    return car[headItem.reference];
  };

  return (
    <TopBottomTableContainer>
      <TopBottomTableLockContainer className="TopBottomTableLockContainer">
        <TopBottomTableLockTitle className="TopBottomTableLockTitle">
          Top 10 And Bottom 10
        </TopBottomTableLockTitle>
        <LockTable
          head={DTopTenHead}
          items={currentTableData}
          renderItem={renderItem}
          sorter={sorter}
          setSorter={setSorter}
          setSortOrder={setSortOrder}
        />
      </TopBottomTableLockContainer>
    </TopBottomTableContainer>
  );
};

export default Lock;
