import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, MSIZES, SIZES } from "../../constants";
import { Button } from "antd";

export const DahsboardHeader = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      justify-content:space-between;
      padding: 10PX 30px 10px 30px;
      border-bottom:1px solid ${COLORS.gray};
      positon:absolute;
      background-color:white;
      left:0;
      top:0;
      width:100%;

      ${theme.breakpoints.down("lg")} {
        padding: 11px 18px;
        position:sticky;
        top:65px;
        z-index:999;
      }
      ${theme.breakpoints.down("sm")} {
        padding: 11px 18px;
      }
    `}
`;
export const MarketOverViewContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width:100%;
      height:100%;
      display:flex;
      flex-direction:column;
      gap:10px;
    `}
`;
export const TopBottomTenTableBody = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

    `}
`;
export const TopBottomTenTableTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body1}px;
        font-family:${FONT_FAMILY.medium};
    `}
`;

export const DashboardHeaderBackButton = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      align-items:center;
    `}
`;
export const BackButtonIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
      width:24px;
      height:24px;
    `}
`;
export const BackButtonName = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    `}
`;

export const DashboardHeaderTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.h6}px;
      font-family:${FONT_FAMILY.semibold};
      text-align: center;
      padding: 6px;
      width: 100%;
      ${theme.breakpoints.down("sm")} {
        font-size:${MSIZES.h3}px;
        display: flex;
        justify-content: center;
      }
    `}
`;
export const DashboardHeaderSetting = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        gap:15px;
    `}
`;
export const SettingBtnGroup = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      align-items:center;
    `}
`;
export const DashboardHeaderLabel = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.semibold};
      display:flex;
      align-items:center;
      white-space: pre;
      ${theme.breakpoints.down("sm")} {
        font-size:${SIZES.body4}px;
        display:none;
      }
    `}
`;
export const DashboardHeaderLabelPlus = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.semibold};
      display:flex;
      align-items:center;
      white-space: pre;
      display:none;
      ${theme.breakpoints.down("sm")} {
        font-size:${SIZES.body4}px;
        display:flex;
      }
    `}
`;
export const NewReportSpace = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
      ${theme.breakpoints.down("sm")} {
  
      }
    `}
`;
export const HeaderButton = styled.div<{ theme?: Theme }>`
  font-size: ${SIZES.body1}px;
  gap: 5px;
  font-family: ${FONT_FAMILY.medium};
  display: flex !important;
  align-items: center !important;
  background-color: transparent !important;
  color: black !important;
  border: none !important;
  box-shadow: none !important;
  &:hover {
    cursor:pointer;
    background-color: transparent !important;
    color: #4096ff !important;
  }
  ${({ theme }) => `
      
      `}
`;
export const SettingOption = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      align-items:center;
    `}
`;
export const HeaderBackLabel = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        ${theme.breakpoints.down("sm")} {
          display:none;
        }
    `}
`;
export const UserLocation = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        align-items:center;
        font-family:${FONT_FAMILY.semibold};
        align-items:center;
        gap:5px;
    `}
`;

export const OptionIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
      width:20px;
      height:20px;
      margin-right:11px;
    `}
`;
export const OptionTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body1}px;
    font-family:${FONT_FAMILY.medium};
    `}
`;
export const DashboardLeftSdieInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;
      width:100%;
      gap:20px;
      font-size:${SIZES.body1}px;
      font-family:${FONT_FAMILY.regular};

      ${theme.breakpoints.down("sm")} {
        font-size:${MSIZES.body2}px;
      }
    `}
`;
export const LeftSideDescriptions = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;

    `}
`;
export const LeftSideTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.h6}px;
        font-family:${FONT_FAMILY.semibold};
        
        ${theme.breakpoints.down("sm")} {
            font-size:${MSIZES.h4}px;
        }
    `}
`;
export const LeftSideTitleSmall = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.h9}px;
      font-family:${FONT_FAMILY.medium};
    `}
`;

export const MarketInfos = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.regular};
      gap:18px;
      ${theme.breakpoints.down("sm")} {
        gap:14px;
        flex-direction:column;
      }
    `}
`;
export const Market = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.medium};
      display:flex;
      align-items:center;
      gap:3px;
    `}
`;
export const MarketSpan = styled.span<{ theme?: Theme }>`
  ${({ theme }) => `
        color:#818181;
        font-family:${FONT_FAMILY.regular};
        border:1px solid white;
        cursor: pointer;
        &:hover {
          color: ${COLORS.primary};
        }
    `}
`;
export const MarketSpans = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        color:#818181;
        font-family:${FONT_FAMILY.regular};

        border:1px solid white;
        display:flex;

    `}
`;
export const MarketScore = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
 
    `}
`;
export const MarketType = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        font-size:${SIZES.body4}px;
        font-family:${FONT_FAMILY.medium};
        display:flex;
        align-items:center;
        gap:3px;
    `}
`;
export const SelectOptionss = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        gap:10px;
    `}
`;

export const SelectOption = styled.select<{ theme?: Theme }>`
  ${({ theme }) => `
      padding: 9px;
      border-radius: 31px;
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.medium};
      outline:0;
      border:1px solid ${COLORS.gray}; 
    `}
`;
export const ButtonUpdate = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
      color:white;
      padding:9px 13px;
      background-color:#4318ff;
      outline:0;
      border-radius:30px;     
    `}
`;

export const DashboardLeftSideInnerWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        position:relative;
        ${theme.breakpoints.down("sm")} {
        }
    `}
`;
export const MoneyInfos = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        width:100%;
        gap:10px;
        ${theme.breakpoints.down("sm")} {
          flex-direction:column;
        }
    `}
`;
export const MoneyInfoss = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        width:100%;
        gap:10px;
        flex-direction:column;
    `}
`;

export const MoneyInfo = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        margin-bottom:12px;
    `}
`;

export const InfoGraph = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      justify-content:space-between;
      align-items:center;
    `}
`;
export const InfoGraphReveueIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
      width:100%;
      height:100%;
    `}
`;
export const InfoGraphReveueGraph = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    width:185px;
    height:127px;
    `}
`;
export const InfoGraphReveueGraphIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
      width:60px;
      height:30px;
    `}
`;

export const InfoShow = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

    `}
`;
export const MoneyInfoTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      color:#8F8F8F;
      font-size:${SIZES.body2}px;
      font-family:${FONT_FAMILY.medium};
      ${theme.breakpoints.down("sm")} {
      }
    `}
`;
export const LockMoneyInfoTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      color:#8F8F8F;
      font-size:${SIZES.body2}px;
      font-family:${FONT_FAMILY.medium};
      ${theme.breakpoints.down("sm")} {
        font-family:${FONT_FAMILY.regular}

      }
    `}
`;
export const MoneyItemOneLockIconWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      height:100%;
      display:flex;
      align-items:center;
      ${theme.breakpoints.down("sm")} {
          justify-content:center;
      }
    `}
`;
export const MoneyItemOneLockIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
       height:66px;
  ${theme.breakpoints.down("sm")} {
        height:30px;
      }
    `}
`;
export const MoneyInfoPrice = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.h5}px;
      font-family:${FONT_FAMILY.semibold};
      ${theme.breakpoints.down("sm")} {
        font-size:${SIZES.body1}px;
      }
    `}
`;
export const LockMoneyInfoPrice = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.h5}px;
      font-family:${FONT_FAMILY.semibold};
      ${theme.breakpoints.down("sm")} {
        font-size:${SIZES.body1}px;
      }
    `}
`;
export const MoneyItemDiv = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display:flex;
        gap:10px;
        ${theme.breakpoints.down("sm")} {
          
        }
    `}
`;
export const MoneyInfoItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        position:relative;
      display:flex;
      flex-direction:column;
      width:100%;
      border:0.77px solid ${COLORS.gray};
      border-radius:14px;
      padding:5px;
      ${theme.breakpoints.down("sm")} {
      }
    `}
`;


export const LockMoneyInfoItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;
      width:100%;
      border:0.77px solid ${COLORS.gray};
      border-radius:14px;
      padding: 0px 0 21px 0px;
      gap: 26px;
      position:relative;
      ${theme.breakpoints.down("sm")} {
        gap:0;
        padding:0 0 9px 0;
        justify-content:space-between;
        gap:17px;
      }
    `}
`;
export const MoneyItemOne = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        gap: 2px;
        padding: 21px 21px 0 21px;
        ${theme.breakpoints.down("sm")} {
          padding:11px 11px 0 11px; 
          flex-direction:initial;
          align-items:center;
        }
    `}
`;
export const LockMoneyItemOne = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        gap: 2px;
        padding: 21px 21px 0 21px;
        justify-content:space-between;
        align-items:center;  
        ${theme.breakpoints.down("sm")} {
          padding:11px 11px 0 11px; 
          flex-direction:column;
          align-items:initial;
          gap:24px;
        }
    `}
`;
export const LockMoneyItemOneInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        
        ${theme.breakpoints.down("sm")} {
          display:flex;
          align-items:center;
        }
    `}
`;
export const MoneyInfoItemLockButtonWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        justify-content:center;
        flex: 1;
        align-items: center;
        ${theme.breakpoints.down("sm")} {
          flex:initial;
        }
    `}
`;
export const LockButton = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body4}px;
        background-color: ${COLORS.white};
        color:${COLORS.black};
        padding: 9px 7px;
        border-radius: 20px;
        border:1px solid ${COLORS.black};
        &:hover {
          color:${COLORS.primary};
          border:1px solid ${COLORS.primary};
        }
        ${theme.breakpoints.down("sm")} {
          padding:5px 7px;
        }
    `}
`;
export const FinancialCalculators = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        height:310px;
        padding:32px 25px 32px 38px;
        background-color:#F9F9F9;
        display:flex;
        border-radius:10px;
        
      ${theme.breakpoints.down("sm")} {
        flex-direction:column;
        height:initial;
        justify-content:center;
        align-items:center;
        text-align:center;
        flex-diretion:column-reverse;
      }
    `}
`;
export const FinancialCalculatorLeft = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width:100%;
      display:flex;
      flex-direction:column;
      padding: 5px 0;
    `}
`;
export const FinancialCalculatorLeftTittle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:28px;
      font-family:${FONT_FAMILY.bold};
      margin-bottom:33px;
      ${theme.breakpoints.down("sm")} {
        font-size:24px;
      }
    `}
`;
export const TitleSpan = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
     
    `}
`;
export const FinancialCalculatorLeftDescription = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.body1}px;
      font-family:${FONT_FAMILY.regular};
      margin-bottom:30px;
      color: ${COLORS.dark}80;

      ${theme.breakpoints.down("sm")} {
        font-size:${MSIZES.body1}px;
      }
    `}
`;
export const FinancialCalculatorLeftButtonDiv = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        ${theme.breakpoints.down("sm")} {
          display:flex;
          justify-content:center;
          margin-bottom:8px;
        }

    `}
`;
export const FinancialCalculatorLeftButton = styled(Button) <{ theme?: Theme }>`
  ${({ theme }) => `
        color:white;
        padding:18px 12px;
        background-color:${COLORS.primary};
        border-radius:30px;
        display:flex;
        align-items:center;
        font-size: 15px;
        ${theme.breakpoints.down("sm")} {
          font-size:${SIZES.body4}px;
          padding:14px 12px;
          height: 35px;
        }
    `}
`;
export const FinancialCalculatorRight = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width:100%;
      display: flex;
      flex-direction: row-reverse;
      ${theme.breakpoints.down("sm")} {
          justify-content:center;
          margin-bottom:10px;
      }
    `}
`;
export const InfoGraphReveue = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 50px;
      height: 50px;
      background-color: #f4f7fe;
      border-radius: 50%;
      padding:14px;
      display:flex;
      justify-content:center;
      align-items:center;
      text-align:center;

      ${theme.breakpoints.down("sm")} {
        width:40px;
        height:40px;
    }
    `}
`;
export const LockInfoGraphReveue = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 50px;
      height: 50px;
      background-color: #f4f7fe;
      border-radius: 50%;
      padding:14px;
      display:flex;
      justify-content:center;
      align-items:center;
      text-align:center;

      ${theme.breakpoints.down("sm")} {
        width:40px;
        height:40px;
    }
    `}
`;

export const MoneyInfoDesWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;
      width:100%;
      ${theme.breakpoints.down("sm")} {
          text-align:end;
          padding:0 4px;
      }

    `}
`;
export const LockMoneyInfoDesWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;
      width:100%;
      ${theme.breakpoints.down("sm")} {
          text-align:end;
          padding:0 4px;
      }

    `}
`;
export const FinancialCalculatorRightImage = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        height:100%;
        ${theme.breakpoints.down("sm")} {
          height:initial;
          width:53%;
        }
    `}
`;
export const MonthlyReveue = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        height:379px;
        display:flex;
        padding:10px;
        flex-direction:column;
        border:1px solid ${COLORS.gray};
        border-radius:14px;
        position:relative;
        ${theme.breakpoints.down("sm")} {
          height:initial;
          padding:4px;
        }
    `}
`;

export const MonthlyReveueContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

    `}
`;
export const MonthlyLockTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-family:${FONT_FAMILY.bold};
        padding:10px;
    `}
`;
export const MonthlyReveueHeader = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
    `}
`;

export const MonthlyReveueTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        color:#A3AED0;
        font-size:20px;
        font-family:${FONT_FAMILY.dm_medium};
    `}
`;
export const MonthlyReveueButton = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `

    `}
`;
export const MonthlyReveueValue = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:30px;
       font-family:${FONT_FAMILY.bold};
        margin-bottom:20px;
    `}
`;
export const MonthlyReveueGraph = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        flex:1;
    `}
`;
export const AnnualRevenues = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      padding-top: 10px;
      width:100%;
      height:392px;
      border:1px solid ${COLORS.gray};
      border-radius:14px;
      position:relative;
      ${theme.breakpoints.down("sm")} {
        height:initial;
        padding:9px 0 0 0;
      }
  `}
`;
export const ClipLoaderWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        position:absolute;
        top:0;
        left:0;
        background-color:#0000000a;
        width:100%;
        height:100%;
        border-radius:4px;
        backdrop-filter:blur(5px);
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius:14px;
        z-index:0;
    `}
`;

export const ClipLoaderWrapperBig = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        position:absolute;
        top:0;
        left:0;
        // height: calc(100dvh - 135px);
        background-color:#0000000a;
        width:100%;
        height:100%;
        border-radius:4px;
        backdrop-filter:blur(5px);
        display:flex;
        align-items:center;
        justify-content:center;
        z-index:1000;
        ${theme.breakpoints.down("sm")} {
          // height: calc(100dvh - 120px);
        }
    `}
`;

export const ClipLoaderWrapperBig2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        position:absolute;
        top:0;
        left:0;
        // height: calc(100dvh - 65px);
        background-color:#0000000a;
        width:100%;
        height:100%;
        border-radius:4px;
        backdrop-filter:blur(5px);
        display:flex;
        align-items:center;
        justify-content:center;
        z-index:1000;
        // ${theme.breakpoints.down("sm")} {
        //   height: calc(100dvh - 65px);
        // }
    `}
`;

export const NoneReportsWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        position:absolute;
        top:0;
        left:0;
        background-color: ${COLORS.light_gray};
        width:100%;
        height:100%;
        display:flex;
        align-items:center;
        justify-content:center;
        z-index:1000;
    `}
`;

export const AnnualRevenuesContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

      ${theme.breakpoints.down("sm")} {

      }
  `}
`;
export const AnnualRevenuesLockTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-family:${FONT_FAMILY.bold};
        padding:10px;
      ${theme.breakpoints.down("sm")} {

      }
  `}
`;
export const AnnualRevenuesHeaderTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

      width:100%;
      padding-left:15px;        
      font-size: ${SIZES.body1}px;
      font-family: ${FONT_FAMILY.medium};
      
      ${theme.breakpoints.down("sm")} {
       font-size: ${SIZES.body4}px;
       padding-left:12px;
      }
  `}
`;
export const ExtraOffersContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
   
  `}
`;
export const ExtraOffersLockTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
           font-family:${FONT_FAMILY.bold};
        padding:10px;
  `}
`;
export const ExtraOffers = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    position:relative;
    width:100%;
    display:flex;
    flex-direction:column;
    padding:27px 14px 15px 15px;
    border:1px solid ${COLORS.gray};
    border-radius:14px;
  `}
`;
export const ExtraOfferTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.h9}px;
      font-family:${FONT_FAMILY.semibold};
      margin-bottom:20px;
      ${theme.breakpoints.down("sm")} {
        font-size:${SIZES.body4}px;
      }
  `}
`;
export const ExtraOfferHeader = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
         border-bottom:1px solid ${COLORS.gray};
        margin-bottom:20px;
  `}
`;
export const ExtraOfferSubTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body4}px;
        font-family:${FONT_FAMILY.medium};
        margin-bottom:9px;
        margin-top:20px;
  `}
`;
export const ExtraOfferPrice = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.medium};
      display:flex;
      gap:10px;
      padding-bottom:14px;
  `}
`;
export const ExtraOfferContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      margin:13px 0;
      border-bottom:1px solid ${COLORS.gray};
      
  `}
`;
export const ExtraOfferContents = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      margin:13px 0;
      
  `}
`;
export const ExtraOfferContentHeader = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.semibold};
      margin-bottom:10px;
        ${theme.breakpoints.down("sm")} {
         font-size:${SIZES.body4}px;
    }
  `}
`;
export const ExtraOfferContentContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body4}px;
    font-family:${FONT_FAMILY.regular};
    color:#818181;
    margin-bottom:10px;
    ${theme.breakpoints.down("sm")} {
      font-size:${SIZES.body2}px;
    }
  `}
`;
export const ExtraOfferContentPrice = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
     font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.semibold};
      display:flex;
      gap:10px;
      padding-bottom:14px;
      ${theme.breakpoints.down("sm")} {
      font-size:${SIZES.body4}px;
    }

  `}
`;
export const OfferPriceSpan = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        color:#838383;
  `}
`;
export const ExtraOfferList = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      width:100%;
      flex-direction:column;
  `}
`;
export const ExtraItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      justify-content:space-between;
  `}
`;
export const ItemInfo = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display:flex;
  `}
`;
export const ItemInfoIconWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
  `}
`;
export const ItemInfoIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        height:20px;
  `}
`;
export const ItemInfoTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      ${theme.breakpoints.down("sm")} {
        font-size:${SIZES.body2}px;
        font-family:${FONT_FAMILY.regular};
      }
  `}
`;
export const ItemInfoDescription = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

  `}
`;
export const Competitorss = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      position:relative;
      border:1px solid ${COLORS.gray};
      border-radius:14px;
      width:100%;
      padding:30px 0 10px 0;
      
      ${theme.breakpoints.down("md")} {
      }

  `}
`;
export const CompetitorsContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

      ${theme.breakpoints.down("md")} {
      }

  `}
`;
export const CompetitorsLockTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-family:${FONT_FAMILY.bold};
      padding:10px;
      ${theme.breakpoints.down("md")} {
      }

  `}
`;
export const CompetitorsHeader = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      justify-content:space-between;
      margin-bottom:22px;
      padding: 0 20px;
      ${theme.breakpoints.down("sm")} {
        margin-bottom:initial;
      }
  `}
`;
export const CompetitorsHeaderTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.body1}px;
      font-family:${FONT_FAMILY.medium}
  `}
`;
export const CompetitorsHeaderViewAll = styled(Button) <{ theme?: Theme }>`
  ${({ theme }) => `
      background-color: white;
      padding: 2.5px 12px;
      color: black;
      outline: 0;
      border: 1px solid white;
      border-radius: 20px;
      font-size: 16px;
      display: flex;
      align-items: center;
      box-shadow:none;
      justify-content: center;
      font-family:${FONT_FAMILY.regular};

      &:hover {
        background-color:${COLORS.primary};
        color:${COLORS.primary};
      }
  `}
`;
export const SpanViewAllIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        width:18px;
        height:12px;
  `}
`;
export const CompetitorsItemList = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      cursor: pointer;
      flex-direction:column;
      ${theme.breakpoints.down("lg")} {
        flex-direction:row;
        align-items:center;
        justify-content:center;
      }
  `}
`;
export const PaginationCompetitors = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display:flex;
        justify-content:end;
        margin-top:14px;
      ${theme.breakpoints.down("sm")} {
        justify-content:center;
        margin-top:0;
      }
  `}
`;
export const CompetitorsItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      border:1px solid #dedede;
      padding:6px 0 6px 5px;
      display:flex;
      align-items:center;
      justify-content:space-between;
  `}
`;
export const CompetitorItemImage = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 238px;
      height: 237px;
      border-radius: 20px;
  `}
`;
export const CompetitorItemImageInner = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        height:100%;
        border-radius:5px;
        object-fit:cover;
  `}
`;
export const CompetitorItemDescription = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width:73%;
      height:100%;
      display:flex;
      flex-direction:column;
      padding: 10px 10px 10px 15px;
      gap:10px;
  `}
`;
export const DescriptionTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      justify-content:space-between;
      align-items:center;
  `}
`;
export const CompertitorPrice = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:14px;
      font-family:${FONT_FAMILY.medium}
  `}
`;
export const CompertitorTime = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      color:#6d6d6d;
      font-size:${SIZES.body1}px;
  `}
`;
export const CompertitorFunction = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      gap:20px;
  `}
`;
export const CompertitorTips = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      align-items:center;
  `}
`;
export const DescriptionTitleGroup = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
          display:flex;
          align-items:center;
          gap:15px;
    `}
`;
export const DescriptionTitleInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.semibold};
      color:${COLORS.primary};
    `}
`;
export const DescriptionTitleStarAndButton = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      align-items:center;
      gap:15px;
  `}
`;
export const TitleStars = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:15px;
  `}
`;
export const ViewAllButton = styled(Button) <{ theme?: Theme }>`
  ${({ theme }) => `
      background-color: ${COLORS.primary};
      padding: 2.5px 12px;
      color: white;
      outline: 0;
      border: 1px solid ${COLORS.primary};
      border-radius: 20px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family:${FONT_FAMILY.regular};
      &:hover {
        background-color:white;
        color:${COLORS.primary};
      }
  `}
`;
export const CompetitorRevenue = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        width:30%;
  `}
`;

export const CompetitorRevenueGraph = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 100%;
  `}
`;
export const CompetitorRevenueDetail = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      margin-bottom:10px;
      justify-content: space-between;
      align-items: center;
  `}
`;
export const CompetitorRevenueGraphItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-family:${FONT_FAMILY.bold};
      font-size:${SIZES.body1}px;
      display:flex;
      flex-direction:column;
  `}
`;
export const GraphItemIcon = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 30px;
      height: 30px;
      background-color: #f4f7fe;
      border-radius: 50%;
      padding:5px;
      display:flex;
      justify-content:center;
      align-items:center;
      text-align:center;
  `}
`;
export const CompetitorRevenueGraphIcon = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      margin-bottom:20px;
  `}
`;

export const CompetitorRevenueShow = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        color:#8A8A8A;
        font-family:${FONT_FAMILY.regular};
        font-size:${SIZES.body4}px;
  `}
`;
export const TipItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display:flex;
    width:25%;
    gap:10px;
    align-items:center;
  `}
`;
export const TipItemInner = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
  `}
`;
export const TipItemIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
      width:19px;
  `}
`;
export const TipItemName = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.regular};
      color:#8A8A8A;
      height: 15px;
  `}
`;
