import React from 'react';

const InfoIcon = ({ ...props }) => (
  <svg viewBox="0 0 14 13" {...props}>
    <path
      d="M7 0.8125C3.85918 0.8125 1.3125 3.35918 1.3125 6.5C1.3125 9.64082 3.85918 12.1875 7 12.1875C10.1408 12.1875 12.6875 9.64082 12.6875 6.5C12.6875 3.35918 10.1408 0.8125 7 0.8125ZM7 11.2227C4.39238 11.2227 2.27734 9.10762 2.27734 6.5C2.27734 3.89238 4.39238 1.77734 7 1.77734C9.60762 1.77734 11.7227 3.89238 11.7227 6.5C11.7227 9.10762 9.60762 11.2227 7 11.2227Z"
      fill="currentColor"
    />
    <path
      d="M6.39062 4.26562C6.39063 4.42724 6.45483 4.58224 6.56911 4.69652C6.68339 4.8108 6.83838 4.875 7 4.875C7.16162 4.875 7.31661 4.8108 7.43089 4.69652C7.54517 4.58224 7.60938 4.42724 7.60938 4.26562C7.60938 4.10401 7.54517 3.94901 7.43089 3.83473C7.31661 3.72045 7.16162 3.65625 7 3.65625C6.83838 3.65625 6.68339 3.72045 6.56911 3.83473C6.45483 3.94901 6.39063 4.10401 6.39062 4.26562ZM7.30469 5.6875H6.69531C6.63945 5.6875 6.59375 5.7332 6.59375 5.78906V9.24219C6.59375 9.29805 6.63945 9.34375 6.69531 9.34375H7.30469C7.36055 9.34375 7.40625 9.29805 7.40625 9.24219V5.78906C7.40625 5.7332 7.36055 5.6875 7.30469 5.6875Z"
      fill="currentColor"
    />
  </svg>
);

export default InfoIcon;
