import React from "react";
import { AuthTimeLineContainer, ContainerSpan, SpanInner } from "./styles";

const AuthTimeLine = (props: any) => {
  const { authStep } = props;
  return (
    <>
      <AuthTimeLineContainer className="AuthTimeLine css-1ekn273">
        <ContainerSpan className="ContainerSpan css-a62hyl" role="progressbar">
          <SpanInner
            className="SpanInner css-4fl2zx"
            style={{ transform: `translateX(${-100 + (authStep + 1) * 20}%)` }}
          ></SpanInner>
        </ContainerSpan>
      </AuthTimeLineContainer>
    </>
  );
};

export default AuthTimeLine;
