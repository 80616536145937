import styled from "@emotion/styled";
import { Theme } from "@mui/material";

export const LayoutMain = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
   margin:0;
   width:100%;
   height:100dvh;
   display:flex;
   flex-direction:column;
  `}
`;
export const LayoutContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
   margin:0;
   width:100%;
   display:flex;
   flex-direction:column;
   flex:1;
   position:relative;
   align-items:center;
   justify-content:center;
   min-height: 800px;
   overflow:auto;
    margin-top:65px;
   ${theme.breakpoints.down("md")} {
    justify-content:initial;
  }
   ${theme.breakpoints.down("md")} {
    min-height: initial;

  }


  `}
`;
