import React from "react";
import { GoogleMapButton, GoogleMapIcon, GoogleMapLabel } from "./styles";
import { ICONS } from "../../../../constants";

const GoogleMap = (props: any) => {
  const { handleChange } = props;

  const handleClick = () => {
    localStorage.setItem("scrollPosition", window.pageYOffset.toString())
    handleChange(true);
  }

  return (
    <GoogleMapButton
      className="GoogleMapButton"
      onClick={handleClick}
    >
      <GoogleMapLabel className="GoogleMapLabel">Map</GoogleMapLabel>{" "}
      <GoogleMapIcon src={ICONS.googleMap}></GoogleMapIcon>
    </GoogleMapButton>
  );
};

export default GoogleMap;
