import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { Avatar, Search } from "../../ui";
import Menu from "../menu";
import MenuNoPortal from "../menu-no-portal";
import { COLORS, FONT_FAMILY, SIZES } from "../../../constants";
import { Link } from "react-router-dom";

export const NavigationMain = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
        z-index: 900;
        width: 100%;
        padding: ${theme.spacing(5)} ${theme.spacing(10)};
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${theme.spacing(10)};
        height: 65px;
        ${theme.breakpoints.down("lg")} {
          padding: ${theme.spacing(5)};
          border-bottom:0.77px solid #E3E3E3;
          position:sticky;
          top:0;
        }

        ${theme.breakpoints.down("sm")} {
          padding: ${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(2.5)};
          gap: 0;
        }
    `}
`;

export const NavigationRouteName = styled(Link)<{ theme?: Theme }>`
  ${({ theme }: any) => `
        color: #e6e6e6;
        font-size: 18px;
        font-weight: 500;
        line-height: 1;
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        gap: ${theme.spacing(2)};

        ${theme.breakpoints.down("sm")} {
            font-size: 14px;
            justify-content: center;
        }
    `}
`;

export const NavigationSpan = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
    text-transform: uppercase;
    font-size: 10px;
    color: #e6e6e6;    
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};

    svg {
      width: 15px;
      height: 15px;
    }
  `}
`;

export const NavigationItems = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
        display: flex;
        gap: ${theme.spacing(2)};
        align-items: center;
        position: relative;

        ${theme.breakpoints.down("lg")} {
          gap: ${theme.spacing(2.5)};
        }
    `}
`;

export const NavigationTooltipContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
        background: white;
        color: #6D728E;
        padding: ${theme.spacing(5)} ${theme.spacing(5)};
        font-size: 14px;
        margin: -10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;

        span {
          text-decoration: underline;
          color: #6D728E;
        }
    `}
`;

export const NavigationBalance = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
        color: #e6e6e6;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        background-color: #e6e6e610;
        padding: ${theme.spacing(2.5)} ${theme.spacing(5)};
        border-radius: 100vw;

        ${theme.breakpoints.down("sm")} {
          font-size: 10px;
        }
    `}
`;

export const NavigationNotification = styled.img<{ theme?: Theme }>`
  ${({ theme }: any) => `
      cursor: pointer;
      display: grid;
      width: 40px;
      height: 40px;
      place-items: center;
      background-color: ${COLORS.gray};
      border-radius: 100%;
      padding: ${theme.spacing(0.8)};
  `}
`;
export const NavigationItemAvatar = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
        height: 35px;
        width: 35px;
        color: white;
        text-transform: uppercase;
        font-family: ${FONT_FAMILY.medium};
        font-size: 15px;
        border-radius: 100px;
        background: ${COLORS.blue};
        text-align: center;
        display:flex;
        justify-content: center;
        align-items: center;
  `}
`;

export const NavigationProfileOuter = styled.div<{ theme?: Theme }>`
  position: relative;
  user-select: none;
`;

export const NavigationProfile = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
        display: flex;
        gap: ${theme.spacing(3)};
        align-items: center;
        cursor: pointer;
    `}
`;

export const NavigationProfileName = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
        color: ${COLORS.dark};
        font-size: 14px;
        font-weight: 500;
        line-height: 1;

        ${theme.breakpoints.down("lg")} {
          display: none;
        }
    `}
`;

export const NavigationProfileImage = styled(Avatar)<{ theme?: Theme }>`
  ${({ theme }: any) => `
        background-color: #e6e6e620;
        color: ${COLORS.dark};
    `}
`;

export const NavigationSearch = styled(Search)<{ theme?: Theme }>``;

export const NavigationProfileDropdown = styled(Menu)<{
  theme?: Theme;
  position?: { right: number; top: number };
}>`
  ${({ theme, position }) => `
  position: fixed;
  z-index: 1000;
  width: 120px;
  right: 30px;
  top: 68px;
  background-color: ${COLORS.white};
  `}
`;

export const NavigationProvileIcon = styled.div<{
  theme?: Theme;
  expanded: boolean;
}>`
  ${({ theme, expanded }) => `
    width: 14px;
    height: 14px;
    transform: scaleY(${expanded ? -1 : 1});
      transform 500ms ease-in-out;
    color: ${COLORS.dark};
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
    `}
`;

export const BalanceIcon = styled.div<{
  theme?: Theme;
  expanded: boolean;
}>`
  ${({ theme, expanded }) => `
    width: 14px;
    height: 14px;
    transform: scaleY(${expanded ? -1 : 1});
      transform 500ms ease-in-out;
    color: #e6e6e6;
    svg {
      display: block;
      width: 100%;
      height: 100%;
      fill: #a7a9b6;
    }
    `}
`;

export const NavigationMenu = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
        display: flex;
        align-items: center;
        gap: ${theme.spacing(5)};

        ${theme.breakpoints.down("sm")} {
          gap: 0;
        }
    `}
`;

export const NavigationMenuButton = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
        display: none;
        place-items: center;
        padding: ${theme.spacing(2.5)};
        cursor: pointer;

        ${theme.breakpoints.down("lg")} {
          display: grid;
        }
    `}
`;

export const NavigationBalanceDropdown = styled(MenuNoPortal)<{
  theme?: Theme;
  position: { right: number; top: number };
}>`
  ${({ theme, position }) => `
  position: absolute;
  z-index: 200;
  width: 120px;
  // right: ${position?.right}px;
  // top: ${position?.top}px;
  `}
`;

export const NavigationCurrency = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
      display: flex;
      align-items: center;
      white-space: pre;
      font-family: ${FONT_FAMILY.medium};
      color: ${COLORS.dark};
      border: 1px solid ${COLORS.dark};
      padding: ${theme.spacing(2.5)} ${theme.spacing(5)};
      border-radius: 100vw;
      font-size: 14px;
      position: relative;
      cursor: pointer;
      user-select: none;
  `}
`;
export const CarReporCalculator = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
      display:flex;
      gap:8px;
      border:1px solid #a0bfd56b;
      padding:6px 14px;
      border-radius:20px;
      &:hover {
        cursor:pointer;
      }
      ${theme.breakpoints.down("sm")} {
        flex-direction:column;
        gap:0;
      }
  `}
`;
export const CarReportCount = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
      display:flex;
      align-items:center;
      gap:8px;
  `}
`;
export const CarReportCountIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }: any) => `
      height:15px;
      ${theme.breakpoints.down("sm")} {
        height:10px;
      }
  `}
`;
export const MarketReportCountIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }: any) => `
      height:20px;
      ${theme.breakpoints.down("sm")} {
        height:15px;
      }
  `}
`;
export const CarReportCountNum = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
      ${theme.breakpoints.down("sm")} {
        font-size:${SIZES.body4}px;
      }
  `}
`;
export const MarketReportCount = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `

  `}
`;
