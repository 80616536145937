import React from "react";
import Chart from "react-apexcharts";

const StatsWithAreaChart = (props: any) => {
  // ** Props
  const {
    series,
    options,
    type,
    height,
    color,
  } = props;
  return (
    <Chart
      options={options}
      series={series}
      color={color}
      type={type}
      height={height ? height : 50}
    />
  );
};

export default StatsWithAreaChart;
