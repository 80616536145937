import React, { useState } from "react";
import { ForgotLabel1, ForgotLabel2, ForgotModal, InputForm, InputLName, ModalIcon, ModalIconWrapper, RequiredValidate, SendBtn } from "../login/styles";
import { InputNameInfo } from "../signUp/styles";
import { COLORS, IMAGES } from "../../../constants";
import AuthorizationAPI from "../../../api/authorization";
import ClipLoader from "react-spinners/ClipLoader";
import { Alert, message } from "antd";
import { validateEmailAddress } from "../../../utils/authValidation";

const ForgotPassword = () => {

    const [userinfo, setUserInfo] = useState<any>({
        email: "",
    })
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [errors, setErrors] = useState<any>({
        email: "",
    })

    const handleSendEmail = async () => {
        setErrorMsg("");

        if (!userinfo.email) {
            setErrors((info: any) => ({ ...info, email: "Email is required" }))
        }

        if (!validateEmailAddress(userinfo.email)) {
            return;
        }

        setLoading(true);
        const values = {
            email: userinfo.email,
        };

        AuthorizationAPI.forgotPassword(values)
            .then(data => {
                message.success(`Please check your email to reset your password.!`);
            })
            .catch(error => {
                setErrorMsg(error?.response?.data?.error?.message ?? "Something went wrong!");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChange = (event: any) => {
        const field = event.target.name;
        const value = event.target.value;
        setUserInfo((info: any) => ({ ...info, [field]: value }))
        switch (field) {
            case 'email':
                if (!validateEmailAddress(value)) {
                    setErrors((info: any) => ({ ...info, email: "Email is invalid" }))
                } else {
                    setErrors((info: any) => ({ ...info, email: "" }))
                }
                break;

            default:
                break;
        }
    }

    return (
        <ForgotModal className="ForgotModal">
            <ModalIconWrapper className="ModalIconWrapper">
                <ModalIcon
                    className="ModalIcon"
                    src={`${IMAGES.forgot_modal}`}
                />
            </ModalIconWrapper>
            <ForgotLabel1 className="ForgotLabel1">
                Forgot Your Password?
            </ForgotLabel1>
            <ForgotLabel2 className="ForgotLabel2">
                To reset your password, enter the email address you use to
                login to TurboFox.
            </ForgotLabel2>
            {errorMsg ? (
                <Alert
                    className="alert_error"
                    message={errorMsg}
                    type="error"
                    closable
                    afterClose={() => setErrorMsg("")}
                />
            ) : null}
            <InputForm className="InputForm">
                <InputNameInfo className="input-name-info">
                    <InputLName
                        className="InputEmail"
                        type="email"
                        name="email"
                        placeholder="Email"
                        disabled={loading}
                        value={userinfo.email}
                        onChange={handleChange}
                    />

                    {errors.email && (
                        <RequiredValidate className="RequiredValidate">
                            {errors.email}
                        </RequiredValidate>
                    )}
                </InputNameInfo>
                <SendBtn
                    className="SendBtn"
                    onClick={handleSendEmail}
                    disabled={loading}
                >
                    {
                        loading ?
                            <ClipLoader color={COLORS.blue} loading={loading} size={30} />
                            :
                            "Send Password Reset Email"
                    }
                </SendBtn>
            </InputForm>
        </ForgotModal>
    )
}

export default ForgotPassword;