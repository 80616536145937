import React from 'react';
import {
  AvatarMain,
  AvatarText,
  // AvatarImage,
} from './styles';
import { TAvatarProps } from './types';

const Avatar = ({ children, image, ...props }: TAvatarProps) => (
  <AvatarMain {...props}>
    <AvatarText>{children}</AvatarText>
    {/* <AvatarImage src={image} /> */}
  </AvatarMain>
);

export default Avatar;
