import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthorizationAPI from "../../../api/authorization";
import { disableSplashScreen, enableSplashScreen, setToken, setUser } from "../../../utils/helper";
import { useAuth } from "../../../context/AppContext";
import { message } from "antd";
import { clarity } from "react-microsoft-clarity";

const GoogleCallback = () => {
    const navigation = useNavigate();
    const location = useLocation();
    const { setCurrentUser } = useAuth();

    const handleCallBack = async () => {
        try {
            const { search } = location;
            const data = await AuthorizationAPI.googleSignInCallBack(search);
            if (!data?.user.blocked) {
                setToken(data.jwt);
                setCurrentUser(data.user);
                setUser(data.user);
                
                if (clarity.hasStarted()) {
                    clarity.identify('USER_ID', { userProperty: data.user.username });
                }
                
                message.success(
                    `Welcome to TurboFox ${data.user.firstname + " " + data.user.lastname
                    }!`
                );
                disableSplashScreen();
            } else {
                navigation("/login");
                message.error(
                    `Your account has blocked!`
                );
                disableSplashScreen();
            }
        } catch (error) {
            navigation("/login");
            disableSplashScreen();
        }
    };

    useEffect(() => {
        enableSplashScreen();
        handleCallBack();

        return () => {
            disableSplashScreen();
        }
        //eslint-disable-next-line
    }, [location])
    return (
        <></>
    )
}

export default GoogleCallback;