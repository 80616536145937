import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS } from "../../../../constants";

export const GoogleMapContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display:flex;
        justify-content:center;
        position:absolute;
        bottom:24px;
        display:none;
        z-index:99999;
        ${theme.breakpoints.down("lg")} {
          display:flex;
        }
        ${theme.breakpoints.down("sm")} {
          justify-content:end;
        }
    `}
`;
export const GoogleMapButton = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
      position:absolute;
      bottom:24px;
      right:calc(50% - 50px);
      display:none;
      width:100px;
      background-color:${COLORS.black};
      padding: 15px;
      border-radius:30px;
      color:white;
      gap:5px;
      align-items:center;
      justify-content:center;
      margin-right:30px;
      padding:15px;
      z-index:99999999999999999;
      ${theme.breakpoints.down("lg")} {
        display:flex;
      }
      ${theme.breakpoints.down("sm")} {
        display:flex;
        width:45px;
        bottom: 45px;
        right:0;
      }
    `}
`;

export const GoogleMapButtonList = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
      position:absolute;
      bottom:24px;
      right:calc(50% - 50px);
      display:none;
      width:100px;
      background-color:${COLORS.black};
      padding: 15px;
      border-radius:30px;
      color:white;
      gap:5px;
      align-items:center;
      justify-content:center;
      margin-right:30px;
      padding:15px;
      z-index:99999999999999999;
      ${theme.breakpoints.down("lg")} {
        display:flex;
      }
      ${theme.breakpoints.down("sm")} {
        display:flex;
        width:45px;
        bottom: 25px;
        right:0;
      }
    `}
`;
export const GoogleMapLabel = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
          display:flex;
        ${theme.breakpoints.down("sm")} {
            display:none;
        }
    `}
`;
export const GoogleMapIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        height:17px;
        ${theme.breakpoints.down("sm")} {

          }
    `}
`;
