export const termsData = [
    {
        title: "Registration",
        description:
            "useTurboFox.com invites individuals and/or companies interested in exploring Turo market performance and understanding vehicle earning potential to register for access to our analytics software. Registration requires accurate contact information; anonymous trials or temporary email addresses are not permitted. We reserve the right to suspend any account not adhering to these terms.",
    },
    {
        title: "Subscription and Data License Grant",
        description:
            "(a) Upon payment of all applicable fees and adherence to these Terms and Conditions, useTurboFox.com grants the Customer a limited, non-exclusive license to access and use our software and data via the internet, as specified in the relevant subscription terms. This license is non-transferable without express written consent from useTurboFox.com.",
    },
    {
        title: "Ownership",
        description:
            "All software, data, methodologies, and services provided by useTurboFox.com are licensed, not sold, to the Customer. useTurboFox.com retains exclusive ownership of all rights, including intellectual property rights. The Customer gains no ownership rights by using our service, only a limited license as outlined in these terms.",
    },
    {
        title: "Payment Terms",
        description:
            `Custom payment terms may be available for high-volume customers. Otherwise, fees are due net thirty (30) days from the start of the subscription. Late payments may result in suspension of service.
            Waiver of Right to Cancel.By placing your order, you acknowledge and agree that you are waiving your right to cancel and return your order within 14 days as provided by the EU rules on online and distance selling.You understand and accept that this waiver is a condition of your purchase and that you will not be entitled to a full refund if you change your mind or are dissatisfied with the product or service you ordered.You further agree that this waiver is irrevocable and binding upon you and your successors and assigns.
            For self- serve subscriptions, the Customer may cancel their subscription at any time by contacting support@useturbofox.com with the email address used to sign up to the Subscription.For Self - Serve subscriptions to useturbofox.com specifically, the Customer can unsubscribe directly online by logging in to the TurboFox tool.All cancellation requests must be received two working days prior to the renewal date.For monthly subscriptions, cancellation requests received after the monthly anniversary of the month shall take effect the following month.For annual subscriptions, cancellation requests received after the annual anniversary date shall take effect the following year.If you cancel your subscription, you will enjoy your subscription benefits until the end of the then - current subscription term, and your subscription benefits will expire at the end of the then - current subscription term for which you have paid.You will not be eligible for a prorated refund of any portion of the subscription fees paid for any unused days of the then - current subscription term.
            `,
    },
    {
        title: "Term and Renewal",
        description:
            "Subscriptions are available on a monthly or annual basis, automatically renewed unless written cancellation is provided at least two days before the renewal date.",
    },
    {
        title: "Termination",
        description:
            "useTurboFox.com reserves the right to terminate any subscription if misuse or unauthorized use is detected.",
    },
    {
        title: "Privacy",
        description:
            "Your privacy is important to us. We commit to not collecting any information that can identify specific search trends that may compromise privacy. Our Privacy Policy is incorporated by reference into these Terms and Conditions.",
    },
    {
        title: "Confidential Information",
        description:
            "The Customer agrees to protect any confidential information received in connection with the services and to use it only for purposes of this agreement.",
    },
    {
        title: "Disclaimers and Limitations of Liability",
        description:
            `useTurboFox.com provides its website, data, software, and services "as is," without warranty of any kind. We disclaim all warranties, express or implied, including but not limited to, merchantability and fitness for a particular purpose. Our liability for any claims related to these terms or the use of our services is limited to the amount you have paid us in the 12 months preceding the claim.`,
    },
    {
        title: "Misuse and Abuse of Data",
        description:
            `(a) Unauthorized access, scraping, or any form of data extraction from useTurboFox.com without explicit written permission is strictly prohibited. Data may not be copied, disassembled, resold, or redistributed in any manner. Violation of these terms may result in legal action and banning from the site.
             (b) Without explicit prior written consent, the following actions are prohibited:
            Copying, harvesting, indexing, scraping, or any form of data extraction for any purpose.
            Utilizing useTurboFox.com data for AI / Machine Learning/ Generative AI tools training.
            Using data in a manner that violates intellectual property rights or is illegal, harmful, offensive, or interferes with the service's operation.
                (c) We reserve the right to ban users abusing our data or services without notice.
            (d) Users must comply with all applicable laws and regulations and must not use our services to facilitate illegal activities.
            `,
    },
    {
        title: "Non-Solicitation",
        description:
            "Without prior written consent, neither party may solicit or engage in any employment, consulting, or other arrangements with any employee or consultant of the other party during the term of this agreement and for six months thereafter.",
    },
    {
        title: " Notices",
        description:
            "Notices should be in writing and may be delivered by various means, including email, to the addresses provided by each party. Changes to contact information should be communicated in accordance with this section.",
    },
    {
        title: 'Governing Law; Arbitration/Litigation',
        description: 'This Agreement is governed by the laws of California. Disputes under $10,000 USD are subject to binding arbitration in California per the American Arbitration Association rules. Claims exceeding $10,000 USD or not subject to arbitration will be adjudicated in California courts. This section also outlines the waiver of class action rights and the process for judicial actions outside of arbitration.',
    },
    {
        title: 'Venue',
        description: `Legal actions related to this Agreement shall be filed in California, with jurisdiction details provided based on the party's geographic location.`,
    },
    {
        title: 'Injunction',
        description: 'In the event of a breach of confidentiality or other key provisions, the aggrieved party is entitled to seek an injunction, alongside other legal remedies.',
    },
    {
        title: 'Export Compliance',
        description: 'Users must comply with all applicable export laws and regulations, ensuring that the software, content, and data are not used for prohibited purposes.',
    },
    {
        title: ' Official Language',
        description: 'The official language of this Agreement is English, and all communications and reports between the parties shall be in English.',
    },
    {
        title: ' Entire Agreement; Amendments',
        description: 'This Agreement represents the entire understanding between the parties, superseding all prior agreements and communications. Amendments must be in writing and agreed upon by both parties.',
    },
    {
        title: 'Waiver',
        description: 'Waivers must be expressly granted in writing by the authorized representatives of the waiving party.',
    },
    {
        title: 'Severability',
        description: 'Should any provision of this Agreement be found invalid or unenforceable, the remainder of the Agreement shall remain in effect, with adjustments made to preserve the original intent.',
    },
    {
        title: 'Assignment',
        description: 'This Agreement is personal to the user and may not be assigned or transferred without prior written consent, subject to specific conditions outlined herein.',
    },
    {
        title: 'Contact Us',
        description: 'For questions or concerns regarding these Terms of Service, please contact us at support@useturbofox.com.',
    },
];