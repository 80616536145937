import React, { useEffect, useState } from "react";

import { SettingOutlined } from "@ant-design/icons";
import {
  GoSeeBtnWrapper,
  GoSeeBtnWrapperw,
  GoSeeInfoButton,
  GoSeeInfoButtonw,
  HostDescriptionContainer,
  HostDescriptionContainerw,
  HostDescriptionHeader,
  HostDescriptionHeaderLeft,
  HostDescriptionHeaderLeftw,
  HostDescriptionHeaderw,
  HostExperience,
  HostExperiencew,
  HostImage,
  HostImageWrapper,
  HostImageWrapperw,
  HostInfoItem,
  HostInfoItemIcon,
  HostInfoItemIconw,
  HostInfoItemTitle,
  HostInfoItemTitlew,
  HostInfoItemValue,
  HostInfoItemValuew,
  HostInfoItemw,
  HostInformations,
  HostInformationsw,
  HostName,
  HostNamew,
  HostNickName,
  HostNickNamew,
  NickNameIcon,
  NickNameIconw,
  TopTenHostDivBodyFirstw,
  TopTenHostdivBody,
  TopTenHostdivBodyInner,
  TopTenHostdivBodyInnerw,
  TopTenHostdivBodyw,
  TopTenHostdiveBodyInnerWrapperw,
  TopTenHostsContainerCardList,
  TopTenHostsContainerw,
} from "./styles";
import { ICONS, IMAGES } from "../../../../constants";
import "./index.css";
import { Link } from "react-router-dom";
import {
  PersonOwnCarFilterOptions,
  PersonOwnCarInfoItem,
  PersonOwnCarInfoItemsWrapper,
  PersonOwnCarInfoLabel,
  SelectOptionButton,
  SelectOptionButtonWrapper,
} from "../personOwnCarInfo/styles";
import { Modal, Select } from "antd";
import {
  LockContainer,
  LockContainerButton,
  LockContainerButtonWrapper,
  LockContainerHeader,
  LockWrapper,
} from "./lockStyle";
import SubScription from "../../../../components/custom/subScription";

const Lock = () => {
  const [responsiveShow, setResponsiveShow] = useState<boolean>(false);
  const [selectInfos, setSelectInfos] = useState<any>({
    noofcar: [],
    allstarstatus: [],
    nooftrip: [],
    monthlyrevenue: [],
    projecteddroi: [],
  });
  const [showSubScription, setShowSubScription] = useState<boolean>(false);

  const clickHandle = () => {
    setShowSubScription(true);
  };

  const CancelHandle = () => {
    setShowSubScription(false);
  };

  const gridStyle: any = {
    width: "100%",
    display: "flex",
    borderRadius: "10px",
  };

  const NoOfCars = [
    {
      type: "noofcar",
      label: "10",
      value: "33742794",
    },
    {
      type: "noofcar",
      label: "6",
      value: "32949123",
    },
    {
      type: "noofcar",
      label: "7",
      value: "37476765",
    },
  ];

  const AllStarStatus = [
    {
      type: "allstarstatus",
      label: "10",
      value: "33742794",
    },
    {
      type: "allstarstatus",
      label: "6",
      value: "32949123",
    },
    {
      type: "allstarstatus",
      label: "7",
      value: "37476765",
    },
  ];
  const NoOfTrips = [
    {
      type: "nooftrip",
      label: "10",
      value: "33742794",
    },
    {
      type: "nooftrip",
      label: "6",
      value: "32949123",
    },
    {
      type: "nooftrip",
      label: "7",
      value: "37476765",
    },
  ];
  const MonthlyReveue = [
    {
      type: "monthlyrevenue",
      label: "10",
      value: "33742794",
    },
    {
      type: "monthlyrevenue",
      label: "6",
      value: "32949123",
    },
    {
      type: "monthlyrevenue",
      label: "7",
      value: "37476765",
    },
  ];
  const ProjectedROI = [
    {
      type: "projecteddroi",
      label: "10",
      value: "33742794",
    },
    {
      type: "projecteddroi",
      label: "6",
      value: "32949123",
    },
    {
      type: "projecteddroi",
      label: "7",
      value: "37476765",
    },
  ];

  const handleChange = (val: any, option: any) => {
    const key = option.type;
    const value = option.value;
    setSelectInfos((selectinfo: any) => ({
      ...selectinfo,
      [key]: [...(selectinfo[key] || []), value],
    }));
  };

  const clearHandle = () => {
    setSelectInfos({
      noofcar: [],
      allstarstatus: [],
      nooftrip: [],
      monthlyrevenue: [],
      projecteddroi: [],
    });
  };

  useEffect(() => {
    if (window.innerWidth < 685) {
      setResponsiveShow(true);
    } else {
      setResponsiveShow(false);
    }
    //eslint-disable-next-line
  }, [window.innerWidth]);
  return (
    <TopTenHostsContainerw className="TopTenHostsContainer">
      <PersonOwnCarFilterOptions className="PersonOwnCarFilterOptions">
        <PersonOwnCarInfoItemsWrapper className="PersonOwnCarInfoItemsWrapper">
          <PersonOwnCarInfoItem className="PersonOwnCarInfoItem">
            <PersonOwnCarInfoLabel className="PersonOwnCarInfoLabel">
              No. Of Cars
            </PersonOwnCarInfoLabel>
            <Select
              className=""
              mode="multiple"
              allowClear
              style={{
                height: 39,
              }}
              value={selectInfos.noofcar}
              placeholder="Please select"
              onSelect={handleChange}
              options={NoOfCars}
            />
          </PersonOwnCarInfoItem>
          <PersonOwnCarInfoItem className="PersonOwnCarInfoItem">
            <PersonOwnCarInfoLabel className="PersonOwnCarInfoLabel">
              All Star Status
            </PersonOwnCarInfoLabel>
            <Select
              className=""
              mode="multiple"
              allowClear
              style={{
                height: 39,
              }}
              value={selectInfos.allstarstatus}
              placeholder="Please select"
              onSelect={handleChange}
              options={AllStarStatus}
            />
          </PersonOwnCarInfoItem>
          <PersonOwnCarInfoItem className="PersonOwnCarInfoItem">
            <PersonOwnCarInfoLabel className="PersonOwnCarInfoLabel">
              No. Of Trips
            </PersonOwnCarInfoLabel>
            <Select
              className=""
              mode="multiple"
              allowClear
              style={{
                height: 39,
              }}
              value={selectInfos.nooftrip}
              placeholder="Please select"
              onSelect={handleChange}
              options={NoOfTrips}
            />
          </PersonOwnCarInfoItem>
          <PersonOwnCarInfoItem className="PersonOwnCarInfoItem">
            <PersonOwnCarInfoLabel className="PersonOwnCarInfoLabel">
              Monthly Revenue
            </PersonOwnCarInfoLabel>
            <Select
              className=""
              mode="multiple"
              allowClear
              style={{
                height: 39,
              }}
              placeholder="Please select"
              value={selectInfos.monthlyrevenue}
              onSelect={handleChange}
              options={MonthlyReveue}
            />
          </PersonOwnCarInfoItem>
          <PersonOwnCarInfoItem className="PersonOwnCarInfoItem">
            <PersonOwnCarInfoLabel className="PersonOwnCarInfoLabel">
              Projected ROI
            </PersonOwnCarInfoLabel>
            <Select
              className=""
              mode="multiple"
              allowClear
              style={{
                height: 39,
              }}
              placeholder="Please select"
              value={selectInfos.projecteddroi}
              onSelect={handleChange}
              options={ProjectedROI}
            />
          </PersonOwnCarInfoItem>
        </PersonOwnCarInfoItemsWrapper>

        <SelectOptionButtonWrapper className="SelectOptionButtonWrapper">
          <SelectOptionButton className="selectOptionUpdate">
            Apply
          </SelectOptionButton>
          <SelectOptionButton
            className="selectOptionUpdate"
            onClick={clearHandle}
          >
            Clear
          </SelectOptionButton>
        </SelectOptionButtonWrapper>
      </PersonOwnCarFilterOptions>

      <TopTenHostsContainerCardList className="TopTenHostsContainerCardList">
        {responsiveShow ? (
          <TopTenHostdivBodyw className="TopTenHostdivBodyw" title="">
            <TopTenHostdivBodyInnerw
              className="TopTenHostdivBodyInner"
              style={gridStyle}
            >
              <TopTenHostdiveBodyInnerWrapperw className="TopTenHostdiveBodyInnerWrapper">
                <TopTenHostDivBodyFirstw className="TopTenHostDivBodyFirst">
                  <HostImageWrapperw className="HostImageWrapper">
                    <HostImage src={IMAGES.person1}></HostImage>
                  </HostImageWrapperw>
                </TopTenHostDivBodyFirstw>

                <HostDescriptionContainerw className="HostDescriptionContainerw">
                  <HostDescriptionHeaderw className="HostDescriptionHeader">
                    <HostDescriptionHeaderLeftw className="HostDescriptionHeaderLeft">
                      <HostNamew className="HostName">Mario julio C.</HostNamew>
                      <HostNickNamew className="HostNickName">
                        <NickNameIconw
                          className="NickNameIcon"
                          src={ICONS.unknown}
                        ></NickNameIconw>{" "}
                        Miami, Fl
                      </HostNickNamew>
                    </HostDescriptionHeaderLeftw>
                    <GoSeeBtnWrapperw className="GoSeeBtnWrapper">
                      <Link
                        to={"/marketoverview/topten/Bear89393sdfsafd99asdf"}
                      >
                        <GoSeeInfoButtonw className="GoSeeInfoButton">
                          See More
                        </GoSeeInfoButtonw>
                      </Link>
                    </GoSeeBtnWrapperw>
                  </HostDescriptionHeaderw>

                  <HostExperiencew className="HostExperiencew">
                    <SettingOutlined /> All-Star Hosts like Mario julio are the
                    top-rated and most experienced hosts on Turo.
                  </HostExperiencew>
                </HostDescriptionContainerw>
              </TopTenHostdiveBodyInnerWrapperw>

              <HostInformationsw className="HostInformations">
                <HostInfoItemw className="HostInfoItem">
                  <HostInfoItemIconw
                    className="HostInfoItemIconw"
                    src={ICONS.carNo}
                  ></HostInfoItemIconw>
                  <HostInfoItemTitlew className="HostInfoItemTitle">
                    No. Of Cars
                  </HostInfoItemTitlew>
                  <HostInfoItemValuew className="HostInfoItemValue">
                    10
                  </HostInfoItemValuew>
                </HostInfoItemw>
                <HostInfoItemw className="HostInfoItem">
                  <HostInfoItemIconw
                    className="HostInfoItemIconw"
                    src={ICONS.estimatedROI}
                  ></HostInfoItemIconw>
                  <HostInfoItemTitlew className="HostInfoItemTitle">
                    Estimated ROI
                  </HostInfoItemTitlew>
                  <HostInfoItemValuew className="HostInfoItemValue">
                    $39.2K
                  </HostInfoItemValuew>
                </HostInfoItemw>
                <HostInfoItemw className="HostInfoItem">
                  <HostInfoItemIconw
                    className="HostInfoItemIconw"
                    src={ICONS.estimatedMon}
                  ></HostInfoItemIconw>
                  <HostInfoItemTitlew className="HostInfoItemTitle">
                    Estimated Mon. ROI
                  </HostInfoItemTitlew>
                  <HostInfoItemValuew className="HostInfoItemValue">
                    $200K
                  </HostInfoItemValuew>
                </HostInfoItemw>
                <HostInfoItemw className="HostInfoItem">
                  <HostInfoItemIconw
                    className="HostInfoItemIconw"
                    src={ICONS.rank}
                  ></HostInfoItemIconw>
                  <HostInfoItemTitlew className="HostInfoItemTitle">
                    Rank
                  </HostInfoItemTitlew>
                  <HostInfoItemValuew className="HostInfoItemValue">
                    1st
                  </HostInfoItemValuew>
                </HostInfoItemw>
              </HostInformationsw>
            </TopTenHostdivBodyInnerw>
          </TopTenHostdivBodyw>
        ) : (
          <TopTenHostdivBody className="TopTenHostdivBody" title="">
            <TopTenHostdivBodyInner
              className="TopTenHostdivBodyInner"
              style={gridStyle}
            >
              <HostImageWrapper>
                <HostImage src={IMAGES.person1}></HostImage>
              </HostImageWrapper>

              <HostDescriptionContainer>
                <HostDescriptionHeader className="HostDescriptionHeader">
                  <HostDescriptionHeaderLeft className="HostDescriptionHeaderLeft">
                    <HostName className="HostName">Mario julio C.</HostName>
                    <HostNickName className="HostNickName">
                      <NickNameIcon
                        className="NickNameIcon"
                        src={ICONS.unknown}
                      ></NickNameIcon>{" "}
                      Miami, Fl
                    </HostNickName>
                  </HostDescriptionHeaderLeft>
                  <GoSeeBtnWrapper className="GoSeeBtnWrapper">
                    <Link to={"/marketoverview/topten/Bear89393sdfsafd99asdf"}>
                      <GoSeeInfoButton className="GoSeeInfoButton">
                        See More
                      </GoSeeInfoButton>
                    </Link>
                  </GoSeeBtnWrapper>
                </HostDescriptionHeader>

                <HostExperience>
                  <SettingOutlined /> All-Star Hosts like Mario julio are the
                  top-rated and most experienced hosts on Turo.
                </HostExperience>

                <HostInformations className="HostInformations">
                  <HostInfoItem className="HostInfoItem">
                    <HostInfoItemIcon
                      className="HostInfoItemIcon"
                      src={ICONS.carNo}
                    ></HostInfoItemIcon>
                    <HostInfoItemTitle className="HostInfoItemTitle">
                      No. Of Cars
                    </HostInfoItemTitle>
                    <HostInfoItemValue className="HostInfoItemValue">
                      10
                    </HostInfoItemValue>
                  </HostInfoItem>
                  <HostInfoItem className="HostInfoItem">
                    <HostInfoItemIcon
                      className="HostInfoItemIcon"
                      src={ICONS.estimatedROI}
                    ></HostInfoItemIcon>
                    <HostInfoItemTitle className="HostInfoItemTitle">
                      Estimated ROI
                    </HostInfoItemTitle>
                    <HostInfoItemValue className="HostInfoItemValue">
                      $39.2K
                    </HostInfoItemValue>
                  </HostInfoItem>
                  <HostInfoItem className="HostInfoItem">
                    <HostInfoItemIcon
                      className="HostInfoItemIcon"
                      src={ICONS.estimatedMon}
                    ></HostInfoItemIcon>
                    <HostInfoItemTitle className="HostInfoItemTitle">
                      Estimated Mon. ROI
                    </HostInfoItemTitle>
                    <HostInfoItemValue className="HostInfoItemValue">
                      $200K
                    </HostInfoItemValue>
                  </HostInfoItem>
                  <HostInfoItem className="HostInfoItem">
                    <HostInfoItemIcon
                      className="HostInfoItemIcon"
                      src={ICONS.rank}
                    ></HostInfoItemIcon>
                    <HostInfoItemTitle className="HostInfoItemTitle">
                      Rank
                    </HostInfoItemTitle>
                    <HostInfoItemValue className="HostInfoItemValue">
                      1st
                    </HostInfoItemValue>
                  </HostInfoItem>
                </HostInformations>
              </HostDescriptionContainer>
            </TopTenHostdivBodyInner>
          </TopTenHostdivBody>
        )}
        {responsiveShow ? (
          <TopTenHostdivBodyw className="TopTenHostdivBodyw" title="">
            <TopTenHostdivBodyInnerw
              className="TopTenHostdivBodyInner"
              style={gridStyle}
            >
              <TopTenHostdiveBodyInnerWrapperw className="TopTenHostdiveBodyInnerWrapper">
                <TopTenHostDivBodyFirstw className="TopTenHostDivBodyFirst">
                  <HostImageWrapperw className="HostImageWrapper">
                    <HostImage src={IMAGES.person2}></HostImage>
                  </HostImageWrapperw>
                </TopTenHostDivBodyFirstw>

                <HostDescriptionContainerw className="HostDescriptionContainerw">
                  <HostDescriptionHeaderw className="HostDescriptionHeader">
                    <HostDescriptionHeaderLeftw className="HostDescriptionHeaderLeft">
                      <HostNamew className="HostName">Rodriguez</HostNamew>
                      <HostNickNamew className="HostNickName">
                        <NickNameIconw
                          className="NickNameIcon"
                          src={ICONS.unknown}
                        ></NickNameIconw>{" "}
                        Miami, Fl
                      </HostNickNamew>
                    </HostDescriptionHeaderLeftw>
                    <GoSeeBtnWrapperw className="GoSeeBtnWrapper">
                      <Link
                        to={"/marketoverview/topten/Bear89393sdfsafd99asdf"}
                      >
                        <GoSeeInfoButtonw className="GoSeeInfoButton">
                          See More
                        </GoSeeInfoButtonw>
                      </Link>
                    </GoSeeBtnWrapperw>
                  </HostDescriptionHeaderw>

                  <HostExperiencew className="HostExperiencew">
                    <SettingOutlined /> All-Star Hosts like Mario julio are the
                    top-rated and most experienced hosts on Turo.
                  </HostExperiencew>
                </HostDescriptionContainerw>
              </TopTenHostdiveBodyInnerWrapperw>

              <HostInformationsw className="HostInformations">
                <HostInfoItemw className="HostInfoItem">
                  <HostInfoItemIconw
                    className="HostInfoItemIconw"
                    src={ICONS.carNo}
                  ></HostInfoItemIconw>
                  <HostInfoItemTitlew className="HostInfoItemTitle">
                    No. Of Cars
                  </HostInfoItemTitlew>
                  <HostInfoItemValuew className="HostInfoItemValue">
                    10
                  </HostInfoItemValuew>
                </HostInfoItemw>
                <HostInfoItemw className="HostInfoItem">
                  <HostInfoItemIconw
                    className="HostInfoItemIconw"
                    src={ICONS.estimatedROI}
                  ></HostInfoItemIconw>
                  <HostInfoItemTitlew className="HostInfoItemTitle">
                    Estimated ROI
                  </HostInfoItemTitlew>
                  <HostInfoItemValuew className="HostInfoItemValue">
                    $39.2K
                  </HostInfoItemValuew>
                </HostInfoItemw>
                <HostInfoItemw className="HostInfoItem">
                  <HostInfoItemIconw
                    className="HostInfoItemIconw"
                    src={ICONS.estimatedMon}
                  ></HostInfoItemIconw>
                  <HostInfoItemTitlew className="HostInfoItemTitle">
                    Estimated Mon. ROI
                  </HostInfoItemTitlew>
                  <HostInfoItemValuew className="HostInfoItemValue">
                    $200K
                  </HostInfoItemValuew>
                </HostInfoItemw>
                <HostInfoItemw className="HostInfoItem">
                  <HostInfoItemIconw
                    className="HostInfoItemIconw"
                    src={ICONS.rank}
                  ></HostInfoItemIconw>
                  <HostInfoItemTitlew className="HostInfoItemTitle">
                    Rank
                  </HostInfoItemTitlew>
                  <HostInfoItemValuew className="HostInfoItemValue">
                    1st
                  </HostInfoItemValuew>
                </HostInfoItemw>
              </HostInformationsw>
            </TopTenHostdivBodyInnerw>
          </TopTenHostdivBodyw>
        ) : (
          <TopTenHostdivBody className="TopTenHostdivBody" title="">
            <TopTenHostdivBodyInner
              className="TopTenHostdivBodyInner"
              style={gridStyle}
            >
              <HostImageWrapper>
                <HostImage src={IMAGES.person2}></HostImage>
              </HostImageWrapper>

              <HostDescriptionContainer>
                <HostDescriptionHeader className="HostDescriptionHeader">
                  <HostDescriptionHeaderLeft className="HostDescriptionHeaderLeft">
                    <HostName className="HostName">Rodriguez</HostName>
                    <HostNickName className="HostNickName">
                      <NickNameIcon
                        className="NickNameIcon"
                        src={ICONS.unknown}
                      ></NickNameIcon>{" "}
                      Miami, Fl
                    </HostNickName>
                  </HostDescriptionHeaderLeft>
                  <GoSeeBtnWrapper className="GoSeeBtnWrapper">
                    <Link to={"/marketoverview/topten/Bear89393sdfsafd99asdf"}>
                      <GoSeeInfoButton className="GoSeeInfoButton">
                        See More
                      </GoSeeInfoButton>
                    </Link>
                  </GoSeeBtnWrapper>
                </HostDescriptionHeader>

                <HostExperience>
                  <SettingOutlined /> All-Star Hosts like Mario julio are the
                  top-rated and most experienced hosts on Turo.
                </HostExperience>

                <HostInformations className="HostInformations">
                  <HostInfoItem className="HostInfoItem">
                    <HostInfoItemIcon
                      className="HostInfoItemIcon"
                      src={ICONS.carNo}
                    ></HostInfoItemIcon>
                    <HostInfoItemTitle className="HostInfoItemTitle">
                      No. Of Cars
                    </HostInfoItemTitle>
                    <HostInfoItemValue className="HostInfoItemValue">
                      10
                    </HostInfoItemValue>
                  </HostInfoItem>
                  <HostInfoItem className="HostInfoItem">
                    <HostInfoItemIcon
                      className="HostInfoItemIcon"
                      src={ICONS.estimatedROI}
                    ></HostInfoItemIcon>
                    <HostInfoItemTitle className="HostInfoItemTitle">
                      Estimated ROI
                    </HostInfoItemTitle>
                    <HostInfoItemValue className="HostInfoItemValue">
                      $39.2K
                    </HostInfoItemValue>
                  </HostInfoItem>
                  <HostInfoItem className="HostInfoItem">
                    <HostInfoItemIcon
                      className="HostInfoItemIcon"
                      src={ICONS.estimatedMon}
                    ></HostInfoItemIcon>
                    <HostInfoItemTitle className="HostInfoItemTitle">
                      Estimated Mon. ROI
                    </HostInfoItemTitle>
                    <HostInfoItemValue className="HostInfoItemValue">
                      $200K
                    </HostInfoItemValue>
                  </HostInfoItem>
                  <HostInfoItem className="HostInfoItem">
                    <HostInfoItemIcon
                      className="HostInfoItemIcon"
                      src={ICONS.rank}
                    ></HostInfoItemIcon>
                    <HostInfoItemTitle className="HostInfoItemTitle">
                      Rank
                    </HostInfoItemTitle>
                    <HostInfoItemValue className="HostInfoItemValue">
                      1st
                    </HostInfoItemValue>
                  </HostInfoItem>
                </HostInformations>
              </HostDescriptionContainer>
            </TopTenHostdivBodyInner>
          </TopTenHostdivBody>
        )}
        {responsiveShow ? (
          <TopTenHostdivBodyw className="TopTenHostdivBodyw" title="">
            <TopTenHostdivBodyInnerw
              className="TopTenHostdivBodyInner"
              style={gridStyle}
            >
              <TopTenHostdiveBodyInnerWrapperw className="TopTenHostdiveBodyInnerWrapper">
                <TopTenHostDivBodyFirstw className="TopTenHostDivBodyFirst">
                  <HostImageWrapperw className="HostImageWrapper">
                    <HostImage src={IMAGES.person3}></HostImage>
                  </HostImageWrapperw>
                </TopTenHostDivBodyFirstw>

                <HostDescriptionContainerw className="HostDescriptionContainerw">
                  <HostDescriptionHeaderw className="HostDescriptionHeader">
                    <HostDescriptionHeaderLeftw className="HostDescriptionHeaderLeft">
                      <HostNamew className="HostName">Donald</HostNamew>
                      <HostNickNamew className="HostNickName">
                        <NickNameIconw
                          className="NickNameIcon"
                          src={ICONS.unknown}
                        ></NickNameIconw>{" "}
                        Miami, Fl
                      </HostNickNamew>
                    </HostDescriptionHeaderLeftw>
                    <GoSeeBtnWrapperw className="GoSeeBtnWrapper">
                      <Link
                        to={"/marketoverview/topten/Bear89393sdfsafd99asdf"}
                      >
                        <GoSeeInfoButtonw className="GoSeeInfoButton">
                          See More
                        </GoSeeInfoButtonw>
                      </Link>
                    </GoSeeBtnWrapperw>
                  </HostDescriptionHeaderw>

                  <HostExperiencew className="HostExperiencew">
                    <SettingOutlined /> All-Star Hosts like Mario julio are the
                    top-rated and most experienced hosts on Turo.
                  </HostExperiencew>
                </HostDescriptionContainerw>
              </TopTenHostdiveBodyInnerWrapperw>

              <HostInformationsw className="HostInformations">
                <HostInfoItemw className="HostInfoItem">
                  <HostInfoItemIconw
                    className="HostInfoItemIconw"
                    src={ICONS.carNo}
                  ></HostInfoItemIconw>
                  <HostInfoItemTitlew className="HostInfoItemTitle">
                    No. Of Cars
                  </HostInfoItemTitlew>
                  <HostInfoItemValuew className="HostInfoItemValue">
                    10
                  </HostInfoItemValuew>
                </HostInfoItemw>
                <HostInfoItemw className="HostInfoItem">
                  <HostInfoItemIconw
                    className="HostInfoItemIconw"
                    src={ICONS.estimatedROI}
                  ></HostInfoItemIconw>
                  <HostInfoItemTitlew className="HostInfoItemTitle">
                    Estimated ROI
                  </HostInfoItemTitlew>
                  <HostInfoItemValuew className="HostInfoItemValue">
                    $39.2K
                  </HostInfoItemValuew>
                </HostInfoItemw>
                <HostInfoItemw className="HostInfoItem">
                  <HostInfoItemIconw
                    className="HostInfoItemIconw"
                    src={ICONS.estimatedMon}
                  ></HostInfoItemIconw>
                  <HostInfoItemTitlew className="HostInfoItemTitle">
                    Estimated Mon. ROI
                  </HostInfoItemTitlew>
                  <HostInfoItemValuew className="HostInfoItemValue">
                    $200K
                  </HostInfoItemValuew>
                </HostInfoItemw>
                <HostInfoItemw className="HostInfoItem">
                  <HostInfoItemIconw
                    className="HostInfoItemIconw"
                    src={ICONS.rank}
                  ></HostInfoItemIconw>
                  <HostInfoItemTitlew className="HostInfoItemTitle">
                    Rank
                  </HostInfoItemTitlew>
                  <HostInfoItemValuew className="HostInfoItemValue">
                    1st
                  </HostInfoItemValuew>
                </HostInfoItemw>
              </HostInformationsw>
            </TopTenHostdivBodyInnerw>
          </TopTenHostdivBodyw>
        ) : (
          <TopTenHostdivBody className="TopTenHostdivBody" title="">
            <TopTenHostdivBodyInner
              className="TopTenHostdivBodyInner"
              style={gridStyle}
            >
              <HostImageWrapper>
                <HostImage src={IMAGES.person3}></HostImage>
              </HostImageWrapper>

              <HostDescriptionContainer>
                <HostDescriptionHeader className="HostDescriptionHeader">
                  <HostDescriptionHeaderLeft className="HostDescriptionHeaderLeft">
                    <HostName className="HostName">Donald</HostName>
                    <HostNickName className="HostNickName">
                      <NickNameIcon
                        className="NickNameIcon"
                        src={ICONS.unknown}
                      ></NickNameIcon>{" "}
                      Miami, Fl
                    </HostNickName>
                  </HostDescriptionHeaderLeft>
                  <GoSeeBtnWrapper className="GoSeeBtnWrapper">
                    <Link to={"/marketoverview/topten/Bear89393sdfsafd99asdf"}>
                      <GoSeeInfoButton className="GoSeeInfoButton">
                        See More
                      </GoSeeInfoButton>
                    </Link>
                  </GoSeeBtnWrapper>
                </HostDescriptionHeader>

                <HostExperience>
                  <SettingOutlined /> All-Star Hosts like Mario julio are the
                  top-rated and most experienced hosts on Turo.
                </HostExperience>

                <HostInformations className="HostInformations">
                  <HostInfoItem className="HostInfoItem">
                    <HostInfoItemIcon
                      className="HostInfoItemIcon"
                      src={ICONS.carNo}
                    ></HostInfoItemIcon>
                    <HostInfoItemTitle className="HostInfoItemTitle">
                      No. Of Cars
                    </HostInfoItemTitle>
                    <HostInfoItemValue className="HostInfoItemValue">
                      10
                    </HostInfoItemValue>
                  </HostInfoItem>
                  <HostInfoItem className="HostInfoItem">
                    <HostInfoItemIcon
                      className="HostInfoItemIcon"
                      src={ICONS.estimatedROI}
                    ></HostInfoItemIcon>
                    <HostInfoItemTitle className="HostInfoItemTitle">
                      Estimated ROI
                    </HostInfoItemTitle>
                    <HostInfoItemValue className="HostInfoItemValue">
                      $39.2K
                    </HostInfoItemValue>
                  </HostInfoItem>
                  <HostInfoItem className="HostInfoItem">
                    <HostInfoItemIcon
                      className="HostInfoItemIcon"
                      src={ICONS.estimatedMon}
                    ></HostInfoItemIcon>
                    <HostInfoItemTitle className="HostInfoItemTitle">
                      Estimated Mon. ROI
                    </HostInfoItemTitle>
                    <HostInfoItemValue className="HostInfoItemValue">
                      $200K
                    </HostInfoItemValue>
                  </HostInfoItem>
                  <HostInfoItem className="HostInfoItem">
                    <HostInfoItemIcon
                      className="HostInfoItemIcon"
                      src={ICONS.rank}
                    ></HostInfoItemIcon>
                    <HostInfoItemTitle className="HostInfoItemTitle">
                      Rank
                    </HostInfoItemTitle>
                    <HostInfoItemValue className="HostInfoItemValue">
                      1st
                    </HostInfoItemValue>
                  </HostInfoItem>
                </HostInformations>
              </HostDescriptionContainer>
            </TopTenHostdivBodyInner>
          </TopTenHostdivBody>
        )}
      </TopTenHostsContainerCardList>

      <LockWrapper className="LockWrapper">
        <LockContainer className="LockContainer">
          <LockContainerHeader className="LockContainerHeader">
            Want to unlock even more insights?
          </LockContainerHeader>
          <LockContainerButtonWrapper className="LockContainerButtonWrapper">
            <LockContainerButton
              className="LockContainerButton"
              onClick={clickHandle}
            >
              Upgrade your plan
            </LockContainerButton>
          </LockContainerButtonWrapper>
        </LockContainer>
      </LockWrapper>

      <Modal
        open={showSubScription}
        onCancel={CancelHandle}
        width={1200}
        centered
        style={{ overflow: "auto" }}
        footer=""
      >
        <SubScription />
      </Modal>
    </TopTenHostsContainerw>
  );
};

export default Lock;
