import React, { useState } from "react";
import {
  ContactModalButton,
  ContactModalButtonWrapper,
  ContactModalContainer,
  ContactModalFullNameWrapper,
  ContactModalInput,
  ContactModalInputWrapper,
  ContactModalTextAreaWrapper,
  RequiredValidate,
} from "./styles";
import TextArea from "antd/es/input/TextArea";
import {
  validateEmailAddress,
  validateName,
  validatePhoneNumber,
} from "../../../../utils/authValidation";
import { useAuth } from "../../../../context/AppContext";
import { message } from "antd";

const ContactModal = (props: any) => {
  const { contactLoading, showModal } = props;

  const { handleSendMail } = useAuth();

  const [userinfo, setUserinfo] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    phoneNumber: "",
    whatLooking: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    phoneNumber: "",
    whatLooking: "",
  });

  const handleChange = (event: any) => {
    const field = event.target.name;
    const value = event.target.value;
    setUserinfo((info) => ({ ...info, [field]: value }));
    switch (field) {
      case "firstName":
        if (!validateName(value)) {
          setErrors((info) => ({
            ...info,
            firstName: "First Name is required",
          }));
        } else {
          setErrors((info) => ({ ...info, firstName: "" }));
        }
        break;
      case "lastName":
        if (!validateName(value)) {
          setErrors((info) => ({ ...info, lastName: "Last Name is required" }));
        } else {
          setErrors((info) => ({ ...info, lastName: "" }));
        }
        break;
      case "email":
        if (!validateEmailAddress(value)) {
          setErrors((info) => ({ ...info, email: "Email is invalid" }));
        } else {
          setErrors((info) => ({ ...info, email: "" }));
        }
        break;
      case "phoneNumber":
        if (!validatePhoneNumber(value)) {
          setErrors((info: any) => ({
            ...info,
            phoneNumber: "phoneNumber is invalid",
          }));
        } else {
          setErrors((info: any) => ({ ...info, phoneNumber: "" }));
        }
        break;
      case "company":
        if (!value) {
          setErrors((info: any) => ({
            ...info,
            company: "company is required",
          }));
        } else {
          setErrors((info: any) => ({ ...info, company: "" }));
        }
        break;
      case "whatLooking":
        if (!value) {
          setErrors((info: any) => ({
            ...info,
            whatLooking: "whatLooking is required",
          }));
        } else {
          setErrors((info: any) => ({ ...info, whatLooking: "" }));
        }
        break;
      default:
        break;
    }
  };

  const handleClick = async () => {
    if (!userinfo.firstName) {
      setErrors((info: any) => ({
        ...info,
        firstName: "FirstName is required",
      }));
    }
    if (!userinfo.lastName) {
      setErrors((info: any) => ({ ...info, lastName: "LastName is required" }));
    }

    if (!userinfo.email) {
      setErrors((info: any) => ({ ...info, email: "Email is required" }));
    }
    if (!userinfo.company) {
      setErrors((info: any) => ({ ...info, company: "Company is required" }));
    }
    if (!userinfo.whatLooking) {
      setErrors((info: any) => ({ ...info, whatLooking: "Comment is required" }));
    }
    if (!(userinfo.phoneNumber)) {
      setErrors((info: any) => ({ ...info, phoneNumber: "Phone Number is required" }));
    }

    if (!validateName(userinfo.firstName)) {
      return;
    }
    if (!validateName(userinfo.lastName)) {
      return;
    }
    if (!validateEmailAddress(userinfo.email)) {
      return;
    }
    if (!validatePhoneNumber(userinfo.phoneNumber)) {
      return;
    }
    if (!(userinfo.whatLooking)) {
      return;
    }
    contactLoading(true);
    showModal(false);
    try {
      const msg = await handleSendMail(userinfo);
      if (msg) {
        message.success(msg);
      }
    } catch (error) {
      message.error("Some thing went wrong");
    } finally {
      contactLoading(false);
    }

  }

  return (
    <ContactModalContainer className="ContactModalContainer">
      <ContactModalFullNameWrapper className="ContactModalFullNameWra">
        <ContactModalInputWrapper className="ContactModalInputWrapper">
          <ContactModalInput
            className="ContactModalInput"
            name="firstName"
            onChange={handleChange}
            placeholder="First name*"
            value={userinfo.firstName}
          ></ContactModalInput>
          {errors.firstName && (
            <RequiredValidate className="RequiredValidate">
              {errors.firstName}
            </RequiredValidate>
          )}
        </ContactModalInputWrapper>

        <ContactModalInputWrapper className="ContactModalInputWrapper">
          <ContactModalInput
            className="ContactModalInput"
            name="lastName"
            onChange={handleChange}
            placeholder="Last name*"
            value={userinfo.lastName}
          ></ContactModalInput>
          {errors.lastName && (
            <RequiredValidate className="RequiredValidate">
              {errors.lastName}
            </RequiredValidate>
          )}
        </ContactModalInputWrapper>
      </ContactModalFullNameWrapper>

      <ContactModalInputWrapper className="ContactModalInputWrapper">
        <ContactModalInput
          className="ContactModalInput"
          name="company"
          placeholder="Company*"
          onChange={handleChange}
          value={userinfo.company}
        ></ContactModalInput>
        {errors.company && (
          <RequiredValidate className="RequiredValidate">
            {errors.company}
          </RequiredValidate>
        )}
      </ContactModalInputWrapper>

      <ContactModalFullNameWrapper className="ContactModalFullNameWra">
        <ContactModalInputWrapper className="ContactModalInputWrapper">
          <ContactModalInput
            className="ContactModalInput"
            name="email"
            onChange={handleChange}
            placeholder="Email*"
            value={userinfo.email}
          ></ContactModalInput>
          {errors.email && (
            <RequiredValidate className="RequiredValidate">
              {errors.email}
            </RequiredValidate>
          )}
        </ContactModalInputWrapper>
        <ContactModalInputWrapper className="ContactModalInputWrapper">
          <ContactModalInput
            className="ContactModalInput"
            name="phoneNumber"
            onChange={handleChange}
            placeholder="Phone Number*"
            value={userinfo.phoneNumber}
          ></ContactModalInput>
          {errors.phoneNumber && (
            <RequiredValidate className="RequiredValidate">
              {errors.phoneNumber}
            </RequiredValidate>
          )}
        </ContactModalInputWrapper>
      </ContactModalFullNameWrapper>
      <ContactModalTextAreaWrapper className="ContactModalTextAreaWrapper">
        <ContactModalInputWrapper className="ContactModalInputWrapper">
          <TextArea
            value={userinfo.whatLooking}
            onChange={handleChange}
            name="whatLooking"
            placeholder="What are you looking to a accomplish?*"
            autoSize={{
              minRows: 3,
              maxRows: 5,
            }}
          />
          {errors.whatLooking && (
            <RequiredValidate className="RequiredValidate">
              {errors.whatLooking}
            </RequiredValidate>
          )}
        </ContactModalInputWrapper>
      </ContactModalTextAreaWrapper>

      <ContactModalButtonWrapper className="ContactModalButtonWrapper">
        <ContactModalButton className="ContactModalButton" onClick={handleClick}>
          Contact sales
        </ContactModalButton>
      </ContactModalButtonWrapper>
    </ContactModalContainer>
  );
};

export default ContactModal;
