import React from 'react';
import { HeaderContainer, HeaderLoginBtn, HeaderLogo, HeaderPtag, HeaderRightSide } from './styles';
import { Link, useLocation } from 'react-router-dom';
import { IMAGES, } from '../../../constants';

const AuthHeader = ({ ...props }) => {

  const path = useLocation();
  const url = path ? (path.pathname).slice(1) : '';
  const label = url === 'login' ? '  Sign Up' : url === 'signup' && '  Login';
  const linkTo = url === 'login' ? 'signup' : url === 'signup' && 'login';
  
  return (
    <HeaderContainer className='HeaderContainer'>
      <Link to={'/home'}>
        <HeaderLogo className='HeaderLogo' src={`${IMAGES.logo}`} />
      </Link>
      <HeaderRightSide className='HeaderRightSide'>
        <HeaderPtag className='HeaderPtag'>
          {url === 'login' ? 'Already have an account?  ' : url === 'signup' ? 'Don’t have an account?  ' : ''}
        </HeaderPtag>
        <Link to={`/${linkTo}`}>
          <HeaderLoginBtn className='HeaderLoginBtn'>{label}</HeaderLoginBtn>
        </Link>
      </HeaderRightSide>
    </HeaderContainer >
  );
};

export default AuthHeader;
