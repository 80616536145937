import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { TCheckboxColor, TCheckboxSize } from './types';
import { CheckIcon } from '../../svg';
import { Label } from '../../ui';

const sizes: any = {
  small: 14,
  medium: 18,
  large: 24,
};

export const CheckboxMain = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: ${theme.spacing(2)},
    `}
`;

export const CheckboxDisplayIcon = styled(CheckIcon) <{theme?: Theme, checked?: boolean }>`
${({ checked, theme }: any) => `
  width: 100%;
  height: 100%;
  display: block;
  transform: scale(${checked ? 1: 0 });
    transform 150ms ease-in-out, color 150ms ease-in-out;
  color: #fdfeff;
  `}
`;

export const CheckboxDisplay = styled.div<{
  theme?: Theme;
  size: TCheckboxSize;
  color: TCheckboxColor;
  checked: any;
}>`
  ${({ theme, size, color, checked }: any) => `
    width: ${sizes[size]}px;
    height: ${sizes[size]}px;
    padding: ${theme.spacing(0.25)};
    border-width: 2px;
    border-style: solid;
    border-color: ${checked ? '#5983c5' : '#2f3d53'};
    border-radius: 4px;
    user-select: none;
      border-color 150ms ease-in-out;
    cursor: pointer;
    ${CheckboxDisplayIcon} {
      color: ${theme.palette[color].main}20;
    }
  `}
`;

export const CheckboxInput = styled.input<{
  theme?: Theme;
  color: TCheckboxColor;
}>`
  ${({ theme, color }: any) => `
    &:checked ~ ${CheckboxDisplay} {
      border-color: ${theme.palette[color].main};
      ${CheckboxDisplayIcon} {
        color: ${theme.palette[color].main};
        transform: scale(1);
      }
    }
  `}
`;

export const CheckboxLabel = styled(Label) <{ theme?: Theme }>`
  ${({ theme }: any) => `
      display: block;
      font-size: 14px;
      color: #7E839F;
      cursor: pointer;
      pointer-events: none;
      margin-left: 5px;
      a {
        pointer-events: all !important;
      }
    `}
`;
