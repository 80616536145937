import React, { useState } from "react";
import {
  FinancialCalculatorLeft,
  FinancialCalculatorLeftButton,
  FinancialCalculatorLeftButtonDiv,
  FinancialCalculatorLeftDescription,
  FinancialCalculatorLeftTittle,
  FinancialCalculatorRight,
  FinancialCalculatorRightImage,
  FinancialCalculators,
} from "../../styles";
import { ICONS } from "../../../../constants";
import { Modal } from "antd";
import AdjustEstimateModal from "../../../../components/custom/adjustEstimateModal";

const FinancialCalculator = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <FinancialCalculators className="FinancialCalculator">
        <FinancialCalculatorLeft className="FinancialCalculatorLeft">
          <FinancialCalculatorLeftTittle className="FinancialCalculatorLeftTittle">
            Customize your estimate
          </FinancialCalculatorLeftTittle>
          <FinancialCalculatorLeftDescription className="FinancialCalculatorLeftDescription">
            Find Out the Best Times to Rent Your Car: Learn when you can make
            the most money by renting out your car.
          </FinancialCalculatorLeftDescription>
          <FinancialCalculatorLeftButtonDiv>
            <FinancialCalculatorLeftButton
              className="FinancialCalculatorLeftButton"
              onClick={handleClick}
            >
              Customize Estimate
            </FinancialCalculatorLeftButton>
          </FinancialCalculatorLeftButtonDiv>
        </FinancialCalculatorLeft>
        <FinancialCalculatorRight className="FinancialCalculatorRight">
          <FinancialCalculatorRightImage
            src={ICONS.financial_calculator_model}
            className="FinancialCalculatorRightImage"
          ></FinancialCalculatorRightImage>
        </FinancialCalculatorRight>
        <Modal
          title="Adjust Your Estimate"
          open={isModalOpen}
          onCancel={handleCancel}
          width={1200}
          centered
          style={{ overflow: "auto" }}
          footer=""
        >
          <AdjustEstimateModal modalopen={setIsModalOpen}></AdjustEstimateModal>
        </Modal>
      </FinancialCalculators>
    </>
  );
};

export default FinancialCalculator;
