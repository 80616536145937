import React, { useEffect, useState, FC } from 'react';
import { Tsorter } from '.';
import {
    TableHeadCell, TableLableContainer,
} from './styles';
import { ArrowDownIcon } from '../svg';
import { NavigationProvileIcon } from '../custom/navigation/styles';

interface TableFilterProps {
    label: string,
    name: string,
    onClickfilter: (order: 'asc' | 'desc') => void,
    active?: Tsorter,
}

const TableFilter: FC<TableFilterProps> = ({label, name, onClickfilter, active }) => {
    const [order, setOrder] = useState<'asc' | 'desc' | null>(null)
    useEffect(() => {
        active === name && setOrder('asc');
    }, [active, name])
    const handleClick = () => {
        setOrder(e => e === 'asc' ? 'desc' : 'asc')
        onClickfilter(order === 'asc' ? 'desc' : 'asc' ?? 'asc')
    }
    return (
        <TableHeadCell onClick={handleClick} className='table-header-cell'>
            <TableLableContainer className='table-lable-container'>
                {label}
                <div className='ml-4'>
                    <NavigationProvileIcon expanded={order === 'asc'} className='navigation-provile-icon'>
                        <ArrowDownIcon />
                    </NavigationProvileIcon>
                </div>
            </TableLableContainer>
        </TableHeadCell>
    )
}

export default TableFilter