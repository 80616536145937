import React from 'react';

import { TPageLayoutProps } from './type';
import {
  PageLayoutMain,
  PageLayoutContent
} from './styles';
import { Header } from '../../components/custom';

const PageLayout = ({ children, ...props }: TPageLayoutProps) => (
  <PageLayoutMain {...props} className='page-layout-main'>
    <Header />
    <PageLayoutContent className='page-layout-content'>
      {children}
    </PageLayoutContent>
  </PageLayoutMain>
);

export default PageLayout;
