import React, { forwardRef } from 'react';

import {
  MenuMain,
  MenuItem,
  MenuItemIcon,
  MenuItemLabel,
} from './styles';
import { TMenuProps, TMenuRef } from './types';
import { createPortal } from 'react-dom';

const Menu = forwardRef<TMenuRef, TMenuProps>(({ items, ...props }, ref) =>
  typeof window !== 'undefined' ? (
    createPortal(
      <MenuMain ref={ref} {...props}>
        {items.map((x) => (
          <MenuItem
            key={x.label}
            icon={x.icon ? 'icon' : ''}
            onClick={x.action}
          >
            {x.icon && <MenuItemIcon> {x.icon}</MenuItemIcon>}
            <MenuItemLabel>{x.label}</MenuItemLabel>
          </MenuItem>
        ))}
      </MenuMain>,
      document.querySelector('#root') as Element
    )
  ) : (
    <div ref={ref} />
  )
);

export default Menu;
