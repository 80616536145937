import React, { forwardRef } from 'react';

import {
  MenuMain,
  MenuItem,
  MenuItemIcon,
  MenuItemLabel,
} from './styles';
import { TMenuProps, TMenuRef } from './types';

const MenuNoPortal = forwardRef<TMenuRef, TMenuProps>(
  ({ items, ...props }, ref) =>
    typeof window !== 'undefined' ? (
      <MenuMain ref={ref} {...props}>
        {items.map((x, index) => (
          <MenuItem onClick={x.action} key={index}>
            <MenuItemIcon> {x.icon}</MenuItemIcon>
            <MenuItemLabel>{x.label}</MenuItemLabel>
          </MenuItem>
        ))}
      </MenuMain>
    ) : (
      <div ref={ref} />
    )
);

export default MenuNoPortal;
