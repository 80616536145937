import React from 'react';
import {
  SidebarMain,
} from './styles';
import { TSidebarProps } from '../sidebar/types';
import { HeaderLoginButton, HeaderTryButton } from '../header/styles';

const Sidebar = ({ ...props }: TSidebarProps) => {

  return (
    <SidebarMain {...props}>
      {/* <HeaderNavLink to='/'>
        Solutions
      </HeaderNavLink>
      <HeaderNavLink to='/'>
        Resources
      </HeaderNavLink>
      <HeaderNavLink to='/'>
        Contact Us
      </HeaderNavLink> */}
      <div style={{height: '10px'}}></div>
      <HeaderTryButton to={"/signup"}>
        Try for free
      </HeaderTryButton>
      <HeaderLoginButton to='/login'>
        Login
      </HeaderLoginButton>
    </SidebarMain>
  );
};

export default Sidebar;
