import React, { useMemo, useState } from "react";
import { DTempTotalCars, DTopTenHead } from "../marketOverViewItem/topBottomTen/data";
import LockTable, { Tsorter } from "../../../../components/table/lockIndex";
import { usePagination } from "../../../../hooks";
import { TTableRenderItemObject } from "../../../../components/table/types";
import { TSingleCar } from "../../../../api/report/types";
import { TopBottomTableContainer, TopBottomTableLockContainer } from "../marketOverViewItem/topBottomTen/styles";
//eslint-disable-next-line


const Lock = () => {
  //eslint-disable-next-line
  const [totalCar, setTotalCar] = useState<any[]>(DTempTotalCars);
  //eslint-disable-next-line
  const [sorter, setSorter] = useState<Tsorter>("no");
  //eslint-disable-next-line
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);
  const PageSize = 20;

  //eslint-disable-next-line
  const { pagesCount, page, setTotalResults, handlePageChange } = usePagination(
    {
      limit: PageSize,
      page: 1,
      onChange: async (params, setPage) => {
        setPage(params.page);
        setTotalResults(totalCar.length);
      },
    }
  );

  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return totalCar?.slice(firstPageIndex, lastPageIndex);
  }, [page, totalCar, PageSize]);

  const renderItem = ({ headItem, row }: TTableRenderItemObject) => {
    const car: any = row.data as TSingleCar;

    if (headItem.reference === "vehicle") {
      return car.vehicle;
    }

    return car[headItem.reference];
  };

  return (
    <TopBottomTableContainer>
      <TopBottomTableLockContainer className="TopBottomTableLockContainer">
        <LockTable
          head={DTopTenHead}
          items={currentTableData}
          renderItem={renderItem}
          sorter={sorter}
          setSorter={setSorter}
          setSortOrder={setSortOrder}
        />
      </TopBottomTableLockContainer>
    </TopBottomTableContainer>
  );
};

export default Lock;
