import { ICONS } from "../../../../constants";

export const WHATLOOKINGDATAS = [
    {
      name: "investment",
      icon: ICONS.investment,
      title: "A best investment for me",
      hover: ICONS.b_investment,
    },
    {
      name: "potential",
      icon: ICONS.potential,
      title: "A car’s earning potential",
      hover: ICONS.b_potential,
    },
    {
      name: "market_performance",
      icon: ICONS.market_performance,
      title: "A specific market’s performance",
      hover: ICONS.b_market_performance,
    },
    {
      name: "competitor",
      icon: ICONS.competitor,
      title: "How I compare to my competitors",
      hover: ICONS.b_competitor,
    },
    {
      name: "price_advice",
      icon: ICONS.price_advice,
      title: "Price advice for my listing",
      hover: ICONS.b_price_advice,
    },
    {
      name: "other",
      icon: ICONS.other,
      title: "other",
      hover: ICONS.b_other,
    },
  ];