import React, { useState } from "react";
import {
  AnnualRevenues,
  AnnualRevenuesContainer,
  AnnualRevenuesHeaderTitle,
  AnnualRevenuesLockTitle,
} from "../../styles";
import ApexChartsColumnRevenues from "../../../../components/custom/apexChartsColumnRevenues";
import {
  LockContainer,
  LockContainerButton,
  LockContainerButtonWrapper,
  LockContainerHeader,
  LockWrapper,
} from "./lockStyle";
import { Modal } from "antd";
import SubScription from "../../../../components/custom/subScription";

const Lock = () => {
  const [showSubScription, setShowSubScription] = useState<boolean>(false);

  const clickHandle = () => {
    setShowSubScription(true);
  };
  const CancelHandle = () => {
    setShowSubScription(false);
  };
  return (
    <AnnualRevenuesContainer className="AnnualRevenuesContainer">
      <AnnualRevenuesLockTitle className="AnnualRevenuesLockTitle">
        How has the annual estimated demand changed over time?
      </AnnualRevenuesLockTitle>
      <AnnualRevenues className="AnnualRevenue">
        <AnnualRevenuesHeaderTitle className="How has the annual estimated demand changed over time?">
          How has the annual estimated demand changed over time?
        </AnnualRevenuesHeaderTitle>
        <ApexChartsColumnRevenues></ApexChartsColumnRevenues>

        <LockWrapper className="LockWrapper">
          <LockContainer className="LockContainer">
            <LockContainerHeader className="LockContainerHeader">
              Want to unlock even more insights?
            </LockContainerHeader>
            <LockContainerButtonWrapper className="LockContainerButtonWrapper">
              <LockContainerButton
                className="LockContainerButton"
                onClick={clickHandle}
              >
                Upgrade your plan
              </LockContainerButton>
            </LockContainerButtonWrapper>
          </LockContainer>
        </LockWrapper>

        <Modal
          open={showSubScription}
          onCancel={CancelHandle}
          width={1200}
          centered
          style={{ overflow: "auto" }}
          footer=""
        >
          <SubScription />
        </Modal>
      </AnnualRevenues>
    </AnnualRevenuesContainer>
  );
};

export default Lock;
