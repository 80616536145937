import React, { useEffect, useState } from "react";
import { ItemContent, ItemName, RectangleItem } from "./styles";
import { COLORS } from "../../../../constants";

const ItemRectangle = (props: any) => {
  const { data, handleChange, selectedItem } = props;

  const [bool, setBool] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);

  const onClick = () => {
    setBool((state) => !state);
    handleChange(data);
  };
  const onMouseOver = () => {
    setBool(true);
  };
  const onMouseLeave = () => {
    setBool(false);
  };
  useEffect(() => {
    if (selectedItem === data) {
      setClicked(true);
    } else {
      setClicked(false);
    }
  }, [selectedItem, data]);

  return (
    <RectangleItem
      className="RectangleItem"
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      style={{ borderColor: bool || clicked ? `${COLORS.primary}` : "black" }}
    >
      <ItemContent className="ItemContent">
        <ItemName
          className="ItemName"
          style={{
            color: clicked ? `${COLORS.primary}` : "black",
          }}
        >
          {data}
        </ItemName>
      </ItemContent>
    </RectangleItem>
  );
};

export default ItemRectangle;
