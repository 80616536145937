import styled from "@emotion/styled";
import { Theme } from "@mui/material";

export const ClipLoaderWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        position:absolute;
        background-color:#0000000a;
        top:0;
        left:0;
        width:100%;
        height:100%;
        border-radius:10px;
        backdrop-filter:blur(5px);
        display:flex;
        align-items:center;
        justify-content:center;
        ${theme.breakpoints.down("sm")} {
          
        }
    `}
`;
