import React, { useState } from "react";
import {
  BackBtnInner,
  WhatLookingContainer,
  WhatLookingContent,
  WhatLookingContentRight,
  WhatLookingRightSection,
  WhatLookingTiTle,
  RectangleItemGroup,
  ManyCarBackground,
  ManyCarImg,
} from "./styles";
import { ICONS, IMAGES } from "../../../../constants";
import ItemRectangle from "./itemRectangle";
import BottomButton from "../../components/bottomButton";
import { NextBtnContainer, NextBtns } from "../describes/styles";
import BackBtnModule from "../../components/bottomButton/backBtnModule";
import { MANYCARS } from "./data";

const ManyCar = (props: any) => {
  const { handleStepInfo, handleUpdateUser } = props;
  const [bool, setBool] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>("");

  const onMouseOver = () => {
    setBool(true);
  };

  const onMouseLeave = () => {
    setBool(false);
  };

  const handleChange = (data: string) => {
    setSelectedItem(data)
  };

  const handelNext = (nextstep: number) => {
    handleStepInfo(nextstep)
    const userinfo = {
      carCount: selectedItem
    }
    handleUpdateUser(userinfo);
  }

  const disabled = !selectedItem

  return (
    <>
      <WhatLookingContainer className="WhatLookingContainer">
        <BackBtnModule handleStepInfo={handleStepInfo} currentstep={1} />

        <WhatLookingContent className="WhatLookingContent">
          <WhatLookingTiTle className="WhatLookingTiTle">
            How Many Cars Do You Own?
          </WhatLookingTiTle>
          <WhatLookingRightSection className="WhatLookingRightSection">
            <WhatLookingContentRight className="WhatLookingContentRight">
              <RectangleItemGroup className="RectangleItemGroup">
                {MANYCARS.map((car, index) => (
                  <ItemRectangle
                    key={index}
                    data={car}
                    handleChange={handleChange}
                    selectedItem={selectedItem}
                  />
                ))}
              </RectangleItemGroup>
            </WhatLookingContentRight>
          </WhatLookingRightSection>
          <ManyCarBackground className="ManyCarBackground">
            <ManyCarImg src={IMAGES.manycars_bg}></ManyCarImg>
          </ManyCarBackground>
        </WhatLookingContent>
        <NextBtnContainer className="NextBtnContainer">
          <NextBtns
            className="NextBtns"
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onClick={() => handelNext(2)}
            disabled={disabled}
          >
            Next&nbsp;&nbsp;
            <BackBtnInner
              className="BackBtnInner"
              src={
                bool && !disabled ? ICONS.new_next_arrow_blue : ICONS.new_next_arrow_black
              }
            ></BackBtnInner>
          </NextBtns>
        </NextBtnContainer>
      </WhatLookingContainer>
      <BottomButton
        handleStepInfo={handelNext}
        currentstep={1}
        disabled={disabled}
      />
    </>
  );
};

export default ManyCar;
