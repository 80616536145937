import React, { useEffect, useState } from "react";
import { ImgContainer, ItemImage, ItemName, RectangleItem } from "./styles";
import { COLORS } from "../../../../constants";

const ItemRectangle = (props: any) => {
  const { data, selectedItem, handleSelect } = props;

  const [clicked, setClicked] = useState<boolean>(false);
  const [bool, setBool] = useState<boolean>(false);

  const handleClick = () => {
    handleSelect(data.name, data.title);
    setClicked(!clicked);
  };

  const onMouseOver = () => {
    setBool(true);
  };
  const onMouseLeave = () => {
    setBool(false);
  };

  useEffect(() => {
    if (selectedItem === data.name) {
      setClicked(true);
    } else {
      setClicked(false);
    }
  }, [selectedItem, data]);

  return (
    <RectangleItem
      className="RectangleItem"
      onClick={handleClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      style={{ borderColor: bool || clicked ? `${COLORS.primary}` : "black" }}
    >
      <ImgContainer className="ImgContainer">
        <ItemImage
          className={`ItemImage`}
          src={clicked ? data.hover : data.icon}
          style={{ height: data.height ? data.height : "40px" }}
        />
      </ImgContainer>
      <ItemName
        className="ItemName"
        style={{ color: clicked ? `${COLORS.primary}` : "black" }}
      >
        {data.title}
      </ItemName>
      <span className="css-w0pj6f nospan"></span>
    </RectangleItem>
  );
};

export default ItemRectangle;
