import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, SIZES } from "../../../../constants";

export const UpgradePlanContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        align-items:center;
        gap:10px;
        ${theme.breakpoints.down("sm")} {
          }
    `}
`;
export const UpgradePlanContainerContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        text-align:center;
        font-size:${SIZES.h8}px;
        font-family:${FONT_FAMILY.semibold};
        padding:14px 0 20px 0;
        ${theme.breakpoints.down("sm")} {
        }
    `}
`;
export const UpgradePlanContainerSubContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        text-align:center;
        font-size:${SIZES.body4}px;
        font-family:${FONT_FAMILY.regular};
        ${theme.breakpoints.down("sm")} {
        }
    `}
`;
export const UpgradePlanContainerIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 110px;
        ${theme.breakpoints.down("sm")} {
        }
    `}
`;
export const UpgradePlanButtonWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
          display:flex;
          justify-content:center;
    `}
`;
export const UpgradePlanButton = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:8px 12px;
        margin-top: 20px;
        background-color:${COLORS.black};
        border:1px solid ${COLORS.black};
        color:white;
          border-radius:20px;
        &:hover {
            background-color:transparent;
            color:${COLORS.black};
            }
    `}
`;
export const InformModalContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

    `}
`;
export const InformModalContainerInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

    `}
`;
export const InformModalContainerInnerIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 150px;
          
    `}
`;
export const InformModalContainerInnerBody = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

    `}
`;
export const InformModalContainerDescription = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

    `}
`;
export const InformModalContainerButtonWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      justify-content:end;
      gap:7px;
      width:100%;
      margin-top: 28px;
    `}
`;
export const InformModalContainerButton = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
      background-color:${COLORS.black};
      border:1px solid ${COLORS.black};
      color:white;
      border-radius:20px;
      width: 30%;
      font-size:14px;
      padding: 5px 1px;
      &:hover {
          background-color:transparent;
          color:${COLORS.black};
          }
      ${theme.breakpoints.down("sm")} {
        width:36%;
      }
    `}
`;
