import React, { useEffect, useState } from "react";
import {
  CalculateButton,
  CalculateInfoInput,
  InfoInput,
  InputContainer,
  InputIcon,
  WindowTitle,
} from "../../../../pages/landing/styles";
import { COLORS, ICONS } from "../../../../constants";
import { CalculatorContainer } from "./styles";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { useAuth } from "../../../../context/AppContext";
import { Input, MapInput } from "../../../ui";
import ClipLoader from "react-spinners/ClipLoader";
import { OpeenStreetMap } from "../../../../api/report/types";
import { DMake } from "../../../../pages/landing/data";
import ReportAPI from "../../../../api/report";
import { useDebounce } from "../../../../hooks";

const EarningCalulator = (props: any) => {
  const { closeNModal, isCarReport } = props;
  const { handleCreateNewReport, handleCreateNewMarketReport, setLoadingDashboardLayout } = useAuth();
  const navigate = useNavigate();
  const [makeOptions, setMakeOptions] = useState<any[]>([]);
  const [modelOptions, setModelOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [location, setLocation] = useState<any>(null)
  const [allModelOptions, setAllModelOptions] = useState<any>([])
  const [makeModelData, setMakeModelData] = useState<any>(null)
  const [yearOptions, setYearOptions] = useState<any[]>([])
  const [mapOptions, setMapOptions] = useState<any[]>([]);

  const [inputInfo, setInputInfo] = useState({
    make: DMake[0],
    model: DMake[0],
    year: null,
  });

  const [errors, setErrors] = useState<any>({
    year: "",
  });

  const handleChangeMake = (make: any) => {
    if (!make) {
      setInputInfo((data: any) => ({
        ...data,
        make: make,
        mode: make
      }))


    } else {
      setInputInfo((data: any) => ({
        ...data,
        make: make
      }))
    }
  }

  const handleChangeModel = (event: any) => {
    let makeselect: any = null;

    if (makeModelData && event) {
      for (let make in makeModelData) {
        for (let model in makeModelData[make]) {
          if (model === event?.value) {
            makeselect = {
              label: make,
              value: make
            };
          }
        }
      }
    }

    if (event) {
      setInputInfo((data: any) => ({
        ...data,
        model: event,
        make: makeselect
      }))
    } else {
      setInputInfo((data: any) => ({
        ...data,
        model: event,
      }))
    }
  }

  useEffect(() => {
    let inputField = document.querySelector('div.autocomplete-input-wrapper>input');
    inputField?.addEventListener('keydown', (e: any) => {
      if (e.key === 'Enter' && e.keyCode === 13 && e.code === 'Enter') {
        e.stopPropagation();
        e.preventDefault();
      } else {
      }
    })
  }, []);

  const getCarOptions = async (location: any) => {
    setLoading(true);
    try {
      const body = {
        lat: location.lat,
        lng: location.lng
      }
      const res = await ReportAPI.getMakeModelYearList(body);
      const makeoptions = res?.make?.map((make: any) => {
        return {
          label: make,
          value: make,
        }
      })

      const modeloptions = res?.model?.map((model: any) => {
        return {
          label: model,
          value: model,
        }
      })

      setMakeOptions(makeoptions);
      setModelOptions(modeloptions);
      setAllModelOptions(modeloptions);

      setMakeModelData(res.data);

    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location?.value?.lat?.toString())
      getCarOptions(location.value)
  }, [location])

  useEffect(() => {
    const selectMake = inputInfo?.make?.value
    let matcedData = [];
    if (makeModelData) {
      if (!selectMake) {
        setModelOptions([...allModelOptions]);
      } else {
        for (let dataKey in makeModelData) {
          if (selectMake === dataKey) {
            matcedData.push(makeModelData[dataKey])
          }
        }

        let modeloptions: any[] = [];
        matcedData.forEach((model) => {
          for (let key in model) {
            modeloptions = [...modeloptions, {
              label: key,
              value: key,
            }]
          }
        })

        setModelOptions(modeloptions);

        let yearArray: any = []
        matcedData?.forEach((item: any) => {
          for (let key in item) {
            if (typeof item[key] === 'object') {
              yearArray.push(...item[key])
            } 
          }
        })

        //@ts-ignore
        const uniqueArray = [...new Set(yearArray)]

        let sortedArray = uniqueArray.sort((a: any, b: any) => a - b)
        const yearoptions = sortedArray?.map((year: any) => {
          return {
            label: year?.toString(),
            value: year?.toString(),
          }
        })

        setYearOptions(yearoptions);
      }
    }

    //eslint-disable-next-line
  }, [inputInfo.make])

  const getGeocoding = (address = '') => {
    if (!address) {
      setMapOptions([])
      return;
    };

    const openStreetMapUrl = 'https://nominatim.openstreetmap.org'
    const url = `${openStreetMapUrl}/search?format=json&q=${address}`;

    fetch(url)
      .then((response) => response.json())
      .then((data: OpeenStreetMap[]) => {
        let options = data.filter((location, index, array) => array.findIndex((innerLocation) => innerLocation.display_name === location.display_name) === index);
        options = options.filter((item: any) => item.display_name.includes("United States"));
        if (options.length > 0) {
          const moptions = options.map((item: any) => {
            return {
              label: item.display_name,
              // value: item.display_name,
              value: {
                lat: item.lat,
                lng: item.lon,
                city: item.name
              }
            }
          })
          setMapOptions(moptions)
        }
      })
      .catch(() => setMapOptions([]));
  }

  const getLocationOptions = async (searchTerm: string = '') => {
    getGeocoding(searchTerm);
  };

  const debouncedJobs = useDebounce(getLocationOptions, 500);

  const [errorss, setErrorss] = useState([false, false, false]);

  const handleErrors = (index: number) => (value: boolean) => {
    setErrorss((x) => x.map((a, b) => (b === index ? value : a)));
  };

  const handleGenerate = async () => {
    closeNModal();
    setLoadingDashboardLayout(true);
    if (!location?.value?.city) {
      setErrors((info: any) => ({ ...info, city: "City is required" }));
    }

    //@ts-ignore
    let min = inputInfo.year ? new Date(inputInfo.year?.value).getFullYear() - 1 : null;
    //@ts-ignore
    let max = inputInfo.year ? new Date(inputInfo.year?.value).getFullYear() + 1 : null;

    const newReport = {
      city: location?.value?.city,
      make: inputInfo?.make?.value,
      model: inputInfo?.model?.value,
      year: [min, max],
      lat: location?.value?.lat,
      lng: location?.value?.lng,
    };

    const newMarketReport = {
      city: location?.value?.city,
      lat: location?.value?.lat,
      lng: location?.value?.lng,
    };

    try {
      const report = isCarReport
        ? await handleCreateNewReport(newReport)
        : await handleCreateNewMarketReport(newMarketReport);
      if (report) {
        navigate(`/${report.type}/${report.report_id}`, { replace: true });
      }
      setLoadingDashboardLayout(false);
    } catch (error: any) {
      setLoadingDashboardLayout(false);
    }
  };

  const isDisabledCar =
    !location?.value?.city ||
    !inputInfo?.make?.value ||
    !inputInfo?.model?.value ||
    errors?.year ||
    !!errorss.find((x) => x === true)

  const isDisabledMarket =
    !location?.value?.city

  return (
    <CalculatorContainer className="CalculatorContainer">
      <WindowTitle className="window-title">
        {isCarReport ? "Car Report" : "Market Report"}
      </WindowTitle>
      <InfoInput className="infoInputAuto">
        <MapInput
          type="select"
          label=""
          onSearch={debouncedJobs}
          placeholder="Select Location"
          options={mapOptions}
          value={location}
          errorCallback={handleErrors(1)}
          onValue={(location) => setLocation(location)}
          validators={[
            {
              message: 'Location is required',
              validator: (location: any) => {
                if (location) return true;
                return false;
              },
            },
          ]}
        />
      </InfoInput>
      {isCarReport && (
        <>
          <InfoInput className="info-input">
            <InputContainer className="input-container">
              <InputIcon className="input-icon">
                {loading ? (
                  <ClipLoader color={COLORS.blue} loading={loading} size={20} />
                ) : (
                  <img src={ICONS.maker} alt="" />
                )}
              </InputIcon>
              <Input
                type="select"
                label=""
                placeholder="Make"
                options={makeOptions}
                value={inputInfo.make}
                onValue={(make) => handleChangeMake(make)}
                validators={[
                  {
                    message: 'Make is required',
                    validator: (make: any) => {
                      if (make) return true;
                      return false;
                    },
                  },
                ]}
              />
            </InputContainer>
          </InfoInput>
          <InfoInput>
            <InputContainer>
              <InputIcon>
                {loading ? (
                  <ClipLoader color={COLORS.blue} loading={loading} size={20} />
                ) : (
                  <img src={ICONS.model} alt="" />
                )}
              </InputIcon>
              <Input
                type="select"
                label=""
                placeholder="Model"
                options={modelOptions}
                value={inputInfo.model}
                onValue={(model) => handleChangeModel(model)}
                validators={[
                  {
                    message: 'Model is required',
                    validator: (model: any) => {
                      if (model) return true;
                      return false;
                    },
                  },
                ]}
              />
            </InputContainer>
          </InfoInput>
          <InfoInput>
            <InputContainer>
              <InputIcon>
                {loading ? (
                  <ClipLoader color={COLORS.blue} loading={loading} size={20} />
                ) : (
                  <img src={ICONS.year} alt="" />
                )}
              </InputIcon>
              <Input
                type="select"
                label=""
                placeholder="Year"
                options={yearOptions}
                value={inputInfo.year}
                onValue={(year) => setInputInfo((data: any) => ({ ...data, year }))}
              />
            </InputContainer>
          </InfoInput>
        </>
      )}
      <CalculateInfoInput className="calculateinfo-input">
        <CalculateButton
          className="calculate-button"
          onClick={handleGenerate}
          disabled={isCarReport ? isDisabledCar : isDisabledMarket}
        >
          Generate Report
        </CalculateButton>
      </CalculateInfoInput>
    </CalculatorContainer>
  );
};

export default EarningCalulator;
