import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, SIZES } from "../../../constants";
import { Popup } from "react-leaflet";

export const PopupContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        height:100%;
        display:flex;
        font-size:${SIZES.body4}px;
        font-family:${FONT_FAMILY.regular};

        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const PopupContainerImageSection = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100px;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const CustomPopup = styled(Popup)<{ theme?: Theme }>`
  ${({ theme }) => `
        width:320px !important;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;

export const PopupImg = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        height:100%;
        background-size:cover;
        background-repeat:no-repeat;
        background-position:center;
        object-fit: cover;
         border-radius: 6px;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const PopupContainerDescription = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex:1;
        padding:0;
        margin-left: 10px;
        gap:10px;
        flex-direction:column;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const PopupContainerDescriptionHeader = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        justify-content:space-between;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const PopupContainerTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      
        font-size:${SIZES.body4}px;
        font-family:${FONT_FAMILY.medium};

        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const PopupContainerBadge = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size: 12px;
        color: ${COLORS.black};
        border-radius: 5px;
        padding: 1px 4px;
        min-width:45px;
        max-height:19px;
        text-align:center;
        background-color: #20e589;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const PopupContainerBottomBadge = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size: 12px;
        color: ${COLORS.black};
        border-radius: 5px;
        padding: 1px 4px;
        min-width:45px;
        max-height:19px;
        text-align:center;
        background-color: #9ccaeb;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const PopupContainerBody = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        justify-content:space-between;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const PopupContainerBodyItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        gap:3px;
        justify-content:space-between;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const PopupContainerBodyItemName = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body2}px;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const PopupContainerBodyItemValue = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-family:${FONT_FAMILY.medium};
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const PopupSeeButtonWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

        width:100%;
        display:flex;
        justify-content:flex-end;

        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const PopupSeeButton = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `

        background-color:${COLORS.primary};
        color:white;
        border:1px solid ${COLORS.primary};
        outline:0;
        border-radius:30px;
        font-size:${SIZES.body2}px;
        padding:3px 10px;
        &:hover {
          background-color:white;
          color:${COLORS.primary};
        }
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
