import React, { useEffect, useMemo, useState } from "react";
import {
  ClipLoaderWrapper,
  ExtraOfferContent,
  ExtraOfferContentContainer,
  ExtraOfferContentHeader,
  ExtraOfferContentPrice,
  ExtraOfferContents,
  ExtraOfferHeader,
  ExtraOfferPrice,
  ExtraOfferTitle,
  ExtraOffers,
  OfferPriceSpan,
} from "../../styles";
import Lock from "./lock";
import { useAuth } from "../../../../context/AppContext";
import { COLORS } from "../../../../constants";
import ClipLoader from "react-spinners/ClipLoader";
import { Pagination } from "../../../../components/ui";
import { usePagination } from "../../../../hooks";
import ReportAPI from "../../../../api/report";
import { groupdAndSort } from "../../../../utils/helper";
import { TExtra } from "../../../../api/report/types";
import { TableEmpty } from "../../../../components/table/styles";

const ExtraOffer = (props: any) => {
  const { id } = props;
  const { apiKey, currentReport } = useAuth();
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [totalData, setTotalData] = useState<TExtra[]>([])

  const PageSize = 3;
  const { pagesCount, page, setTotalResults, handlePageChange } = usePagination(
    {
      limit: PageSize,
      page: 1,
      onChange: async (params, setPage) => {
        setPage(params.page);
        setTotalResults(totalData?.length);
      },
    }
  );

  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return totalData?.slice(firstPageIndex, lastPageIndex);
  }, [page, totalData, PageSize]);

  const handleGetReportData = async () => {
    setLoadingReport(true);
    try {
      const res = await ReportAPI.getExtraInfos(id);
      if (res) {
        const result: any = groupdAndSort(res, "extraPricingType", "amount");
        if (result) {
          setTotalData(result);
          setTotalResults(result?.length);
        }
      }
    } catch (error) {
    } finally {
      setLoadingReport(false);
    }
  };

  useEffect(() => {
    if (currentReport) {
      handleGetReportData();
    }
    // eslint-disable-next-line
  }, [currentReport]);

  return (
    <>
      {!apiKey?.plan_type ? (
        <Lock />
      ) : (
        <ExtraOffers className="ExtraOffer">
          <ExtraOfferHeader className="ExtraOfferHeader">
            <ExtraOfferTitle className="ExtraOfferTitle">
              What extras are people offering in your area?
            </ExtraOfferTitle>
          </ExtraOfferHeader>
          <>
            {
              loadingReport ?
                <>
                  <ExtraOfferContent>
                    <ExtraOfferContentHeader>Prepaid refuel</ExtraOfferContentHeader>
                    <ExtraOfferContentContainer>
                      Save time, make drop-off a breeze, and avoid additional fees by
                      adding this Extra, which allows you to return my car at any fuel
                      level. Price includes up to a full tank of gas.
                    </ExtraOfferContentContainer>
                    <ExtraOfferContentPrice>$80/trip</ExtraOfferContentPrice>
                  </ExtraOfferContent>

                  <ExtraOfferContents>
                    <ExtraOfferContentHeader>Child safety seat</ExtraOfferContentHeader>
                    <ExtraOfferContentContainer>
                      Save time, make drop-off a breeze, and avoid additional fees by
                      adding this Extra, which allows you to return my car at any fuel
                      level. Price includes up to a full tank of gas.
                    </ExtraOfferContentContainer>
                    <ExtraOfferPrice>
                      $150/trip <OfferPriceSpan>4 available</OfferPriceSpan>
                    </ExtraOfferPrice>
                  </ExtraOfferContents>
                </>
                :
                <>
                  {
                    currentTableData.length > 0 ?
                      <>
                        {
                          currentTableData?.map((item, index: any) => {
                            return (
                              <ExtraOfferContent className="ExtraOfferContent" key={index}>
                                <ExtraOfferContentHeader className="ExtraOfferContentHeader">
                                  {item?.label}
                                </ExtraOfferContentHeader>
                                <ExtraOfferContentContainer>
                                  {item?.description}
                                </ExtraOfferContentContainer>
                                <ExtraOfferPrice className="ExtraOfferPrice">
                                  ${item?.amount}/{item?.extraPricingType === "PER_TRIP" ? "trip" : "day"}
                                  {/* <OfferPriceSpan className="OfferPriceSpan">
                              4 available
                            </OfferPriceSpan> */}
                                </ExtraOfferPrice>
                              </ExtraOfferContent>
                            )
                          })
                        }
                      </>
                      :
                      <TableEmpty>No Extras</TableEmpty>
                  }
                </>
            }
          </>
          {
            currentTableData.length > 0 &&
            <Pagination
              onChange={(_e, x) => handlePageChange(x)}
              page={page}
              count={pagesCount}
            />
          }
          {loadingReport && (
            <ClipLoaderWrapper className="ClipLoaderWrapper">
              <ClipLoader
                color={COLORS.black}
                loading={loadingReport}
                size={50}
              />
            </ClipLoaderWrapper>
          )}
        </ExtraOffers>
      )}
    </>
  );
};

export default ExtraOffer;
