import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DahsboardHeader,
  DashboardHeaderBackButton,
  DashboardHeaderLabel,
  DashboardHeaderLabelPlus,
  DashboardHeaderSetting,
  DashboardHeaderTitle,
  HeaderBackLabel,
  HeaderButton,
  NewReportSpace,
  SettingBtnGroup,
} from "../../../pages/earningPotential/styles";
import { ArrowLeftOutlined, CarFilled, CompassFilled } from "@ant-design/icons";
import { Input } from "../../ui";
import { IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAuth } from "../../../context/AppContext";
import ReportMenuActions from "./optionMenu";
import ReportAPI from "../../../api/report";
import { TitleEditcontainer, Titlecontainer } from "./styles";
import { AddIcon } from "../../svg";
import EarningCalulator from "./earningCalulator";
import Modal from "../modal";
import { useModal } from "../../../hooks";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Dropdown, Space } from 'antd';
import SubscriptionAPI from "../../../api/subscription";
import { COLORS } from "../../../constants";

const SubHeader = (props: any) => {
  const {
    currentReport,
    setCurrentReport,
    allReports,
    setAllReports,
    handleDeleteReport,
    apiKey,
    allProducts,
    currentUser
  } = useAuth();

  const { handleOpenMap, openBigMap } = props;

  const [title, setTitle] = useState<string>("");
  const [nModal, openNModal, closeNModal] = useModal(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [isCarReport, setIsCarReport] = useState<boolean>(true);

  const navigate = useNavigate();

  const location = useLocation();
  const pathArray = location.pathname.split('/');
  let istopten = pathArray.length === 4 ? true : false;
  const report_id = pathArray[2];

  useEffect(() => {
    if (currentReport) {
      setTitle(currentReport?.title);
    }
  }, [currentReport]);

  const handleBack = () => {
    if (window.innerWidth < 1200) {
      openBigMap && handleOpenMap();
    }
    if (istopten) {
      navigate(`/marketoverview/${report_id}`)
    } else {
      allReports.forEach((item: any, index: number) => {
        if (item.report_id === report_id) {
          if (index !== 0) {
            navigate(`/${allReports[index - 1].type === 'marketoverview' ? 'marketoverview' : 'report'}/${allReports[index - 1].report_id}`);
          }
        }
      })
    }
  };

  const handleSaveName = async () => {
    setEdit(false);
    const newReport = {
      title: title,
    };

    const body1 = { newReport };

    try {
      await ReportAPI.updateReport(body1, currentReport.id);
      setCurrentReport((data: any) => ({
        ...data,
        title: title,
      }));

      const reports = allReports.map((item: any, index: number) => {
        if (allReports[index - 1].id === currentReport.id) {
          return {
            ...item,
            title: title,
          };
        } else {
          return item;
        }
      });

      setAllReports([...reports]);
    } catch (error) {

    }
  };

  const handleCancelName = () => {
    setTitle(currentReport?.title);
    setEdit(false);
  };

  const handleDelete = async () => {
    try {
      await handleDeleteReport(currentReport.id);
      if (allReports) {
        if (allReports.length > 0) {
          const firstReport = allReports[0];
          navigate(`/${firstReport.type}/${firstReport.report_id}`, { replace: true });
        }
      }
    } catch (error) {

    }
  };

  const handlePayment = async (plantype: string) => {
    const selectedProduct = allProducts.find(
      (item: any) => item.title === plantype
    );
    if (selectedProduct) {
      try {
        const res = await SubscriptionAPI.makePaymentRequest(
          selectedProduct.id,
          currentUser.email,
        );
        if (res.url) {
          window.location.replace(res.url);
        }
      } catch (err) {
      } finally {
      }
    }
  }

  const CarReportLink = () => (
    <div>
      You don't have enough car report credits &nbsp;
      <span
        style={{
          color: `${COLORS.primary}`, borderRadius: '3px', padding: '3px', textDecorationLine: 'underline',
          textUnderlineOffset: '5px', cursor: 'pointer'
        }}

        onClick={() => handlePayment("One Car Report")}
      >Buy more credits</span>
    </div>
  );
  const MarketReportLink = () => (
    <div>
      You don't have enough market report credits &nbsp;
      <span
        style={{
          color: `${COLORS.primary}`, borderRadius: '3px', padding: '3px', textDecorationLine: 'underline',
          textUnderlineOffset: '5px', cursor: 'pointer'
        }}
        onClick={() => handlePayment('One Market Report')}
      >Buy more credits</span>
    </div>
  );

  const handleClick = (val: any) => {
    if (apiKey) {
      if (val === 'car') {
        setIsCarReport(true)
        if (apiKey.total_car_reports > 0) {
          val === 'car' ? setIsCarReport(true) : setIsCarReport(false)
          openNModal();
        } else {
          toast.error(CarReportLink, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        if (apiKey.total_market_reports > 0) {
          val === 'car' ? setIsCarReport(true) : setIsCarReport(false)
          openNModal();
        } else {
          toast.error(MarketReportLink, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } else {
      debugger
    }
  }

  const items = [
    {
      key: '1',
      icon: <CarFilled />,
      label: (
        <div onClick={() => handleClick('car')}>
          Car Report
        </div>
      ),
    },
    {
      key: '2',
      icon: <CompassFilled />,
      label: (
        <div onClick={() => handleClick('market')}>
          Market Report
        </div>
      ),

    },
  ];

  return (
    <DahsboardHeader className="DahsboardHeader">
      <DashboardHeaderBackButton className="DashboardHeaderBackButton">
        <HeaderButton onClick={handleBack}>
          <ArrowLeftOutlined />{" "}
          <HeaderBackLabel className="HeaderBackLabel">
            Back
          </HeaderBackLabel>
        </HeaderButton>
      </DashboardHeaderBackButton>
      <DashboardHeaderTitle className="DashboardHeaderTitle">
        {edit ? (
          <TitleEditcontainer>
            <Input
              type="text"
              style={{ maxWidth: "500px" }}
              label=""
              placeholder="Please Enter"
              value={title}
              onValue={(title: any) => setTitle(title)}
            />
            <IconButton
              className=" btn-active-light-primary"
              color="secondary"
              disabled={title.length === 0}
              onClick={handleSaveName}
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              className=" btn-active-light-danger"
              color="error"
              onClick={handleCancelName}
            >
              <CancelIcon />
            </IconButton>
          </TitleEditcontainer>
        ) : (
          <Titlecontainer>{currentReport?.title}</Titlecontainer>
        )}
      </DashboardHeaderTitle>
      <DashboardHeaderSetting className="DashboardHeaderSetting">
        <ReportMenuActions setEdit={setEdit} handleDelete={handleDelete} />
        <SettingBtnGroup className="SettingBtnGroup">
          <HeaderButton className="HeaderButton" >
            <DashboardHeaderLabel className="DashboardHeaderLabel">
              <Dropdown
                menu={{
                  items,
                }}
                trigger={['click']}
              >
                <NewReportSpace className="NewReportSpace">
                  <AddIcon />
                  <Space>
                    New Report
                  </Space>
                </NewReportSpace>
              </Dropdown>
            </DashboardHeaderLabel>
            <DashboardHeaderLabelPlus className="DashboardHeaderLabelPlus">
              <Dropdown
                menu={{
                  items,
                }}
                trigger={['click']}
              >
                <NewReportSpace className="NewReportSpace">
                  <AddIcon />
                </NewReportSpace>
              </Dropdown>
            </DashboardHeaderLabelPlus>
          </HeaderButton>
        </SettingBtnGroup>
      </DashboardHeaderSetting>
      {nModal && (
        <Modal size="small" onClose={closeNModal}>
          <EarningCalulator closeNModal={closeNModal} isCarReport={isCarReport} load />
        </Modal>
      )}
    </DahsboardHeader>
  );
};

export default SubHeader;
