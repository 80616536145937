import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, SIZES } from "../../../../constants";

export const LockContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      position:absolute;
      top: 200px;
      left:50%;
      transform: translate(-50%, -50%);
      text-align:center;
      padding:24px 16px;
      background-color:white;
      width:400px;
      display:flex;
      flex-direction:column;
      gap:20px;
      border-radius:15px;

      ${theme.breakpoints.down('sm')} {
        width:318px;
      }

  `}
`;
export const LockContainerHeader = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body1}px;
        font-family:${FONT_FAMILY.regular};
  `}
`;
export const LockContainerButton = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body4}px;
        background-color: ${COLORS.white};
        color:${COLORS.black};
        padding: 9px 7px;
        border-radius: 20px;
        border:1px solid ${COLORS.black};
        &:hover {
          color:${COLORS.primary};
          border:1px solid ${COLORS.primary};
        }
        ${theme.breakpoints.down("sm")} {
          padding:5px 7px;
        }
  `}
`;
export const LockWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        position:absolute;
        left:0;
        top:0;
        border-radius: 10px;
        width:100%;
        height:100%;
        background-color:#00000021;
        backdrop-filter: blur(2px);

    `}
`;
export const LockContainerButtonWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
    `}
`;
