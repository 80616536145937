import React, { useEffect, useState } from "react";
import {
  AnnualRevenues,
  AnnualRevenuesHeaderTitle,
  ClipLoaderWrapper,
} from "../../styles";
import ApexChartsColumnRevenues from "../../../../components/custom/apexChartsColumnRevenues";
import Lock from "./lock";
import { useAuth } from "../../../../context/AppContext";
import ClipLoader from "react-spinners/ClipLoader";
import { COLORS } from "../../../../constants";
import ReportAPI from "../../../../api/report";
import { DataParsing } from "../../../../utils/fetch_data";

const AnnualRevenue = (props: any) => {
  const { id } = props;
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [demand, setDemand] = useState<any>(null);
  const { currentReport } = useAuth();
  const { apiKey } = useAuth();
  const [categories, setCategories] = useState<any[]>([]);
  // const {loadingReport} = props;
  const handleGetReportData = async () => {
    setLoadingReport(true);
    try {
      const res = await ReportAPI.getRDAOreport(id);
      if (res) {
        setDemand(DataParsing(res?.time_series_by_month, "portion_rented"));
        const cate = res?.time_series_by_month?.slice(-12).map((item: any) => item.date)
        setCategories(cate)
      }
      setLoadingReport(false);
    } catch (error) {
    }
  };

  useEffect(() => {
    if (currentReport) {
      handleGetReportData();
    }
    // eslint-disable-next-line
  }, [currentReport]);

  return (
    <>
      {!apiKey?.plan_type ? (
        <Lock />
      ) : (
        <AnnualRevenues className="AnnualRevenue">
          <AnnualRevenuesHeaderTitle className="How has the annual estimated demand changed over time?">
            How has the annual estimated demand changed over time?
          </AnnualRevenuesHeaderTitle>
          <ApexChartsColumnRevenues demand={demand} categories={categories}/>
          {loadingReport && (
            <ClipLoaderWrapper className="ClipLoaderWrapper">
              <ClipLoader
                color={COLORS.black}
                loading={loadingReport}
                size={50}
              />
            </ClipLoaderWrapper>
          )}
        </AnnualRevenues>
      )}
    </>
  );
};

export default AnnualRevenue;
