import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import {
  ClipLoaderWrapper,
  InfoGraphReveue,
  LockButton,
  LockInfoGraphReveue,
  LockMoneyInfoDesWrapper,
  LockMoneyInfoItem,
  LockMoneyInfoPrice,
  LockMoneyInfoTitle,
  LockMoneyItemOne,
  LockMoneyItemOneInner,
  MoneyInfoDesWrapper,
  MoneyInfoItem,
  MoneyInfoItemLockButtonWrapper,
  MoneyInfoPrice,
  MoneyInfoTitle,
  MoneyInfos,
  MoneyItemOne,
  MoneyItemOneLockIcon,
  MoneyItemOneLockIconWrapper,
} from "../../../../earningPotential/styles";
import { Award, BarChart2, CreditCard } from "react-feather";
import MiniLineChart from "../../../../../components/custom/miniLineChart";
import {
  ChartDemandLongData,
  ChartRateLongData,
  ChartRevenueLongData,
} from "../../../../earningPotential/data";
import ClipLoader from "react-spinners/ClipLoader";
import { COLORS, ICONS } from "../../../../../constants";
import { useAuth } from "../../../../../context/AppContext";
import ReportAPI from "../../../../../api/report";
import { DataParsing } from "../../../../../utils/fetch_data";
import { useParams } from "react-router-dom";
import SubScription from "../../../../../components/custom/subScription";

const MoneyInfoPage = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { currentReport, apiKey } = useAuth();
  const [currentStatus, setCurrentStatus] = useState<any>(null);
  const [projectedRevenue, setProjectedRevenue] = useState<any>(null);
  const [demand, setDemand] = useState<any>(null);
  const [averageDailyRate, setAverageDailyRate] = useState<any>(null);
  const [showSubScription, setShowSubScription] = useState<boolean>(false);
  const { id } = useParams()

  const handleGetMarketOverViewData = async () => {
    setLoading(true);
    try {
      const res = await ReportAPI.getRDAMarketreport(id);
      setCurrentStatus(res?.total_means);
      if (res) {
        setProjectedRevenue(DataParsing(res.time_series_by_month, "expec_month_rev"));
        setDemand(DataParsing(res.time_series_by_month, "portion_rented"));
        setAverageDailyRate(DataParsing(res.time_series_by_month, "avg_daily_price_amount"));
        setLoading(false);
      }
    } catch (error) {
    }
  };

  const clickHandle = () => {
    setShowSubScription(true);
  };

  const CancelHandle = () => {
    setShowSubScription(false);
  };

  useEffect(() => {
    if (currentReport) {
      handleGetMarketOverViewData();
    }
    //eslint-disable-next-line
  }, [currentReport]);

  return (
    <>
      {!apiKey?.plan_type ? (
        <MoneyInfos className="MoneyInfos">
          <MoneyInfoItem className="MoneyInfoItem">
            <MoneyItemOne className="moneyItemOne">
              <InfoGraphReveue
                className="InfoGraphReveue"
                style={{ backgroundColor: "#ffc10747" }}
              >
                <CreditCard color="#ffc107" />
              </InfoGraphReveue>
              <MoneyInfoDesWrapper className="MoneyInfoDesWrapper">
                <MoneyInfoPrice className="MoneyInfoPrice">
                  ${currentStatus?.expec_month_rev ? Math.round(currentStatus?.expec_month_rev) : "?"}
                </MoneyInfoPrice>
                <MoneyInfoTitle className="MoneyInfoTitle">
                  Projected Revenue
                </MoneyInfoTitle>
              </MoneyInfoDesWrapper>
            </MoneyItemOne>
            <MiniLineChart
              data={projectedRevenue ?? ChartRevenueLongData}
              status="revenue"
              height={100}
              color="#ffc107"
            />
            {loading && (
              <ClipLoaderWrapper className="ClipLoaderWrapper">
                <ClipLoader
                  color={COLORS.black}
                  loading={loading}
                  size={50}
                />
              </ClipLoaderWrapper>
            )}
          </MoneyInfoItem>
          <LockMoneyInfoItem className="LockMoneyInfoItem">
            <LockMoneyItemOne className="LockMoneyItemOne">
              <LockMoneyItemOneInner className="LockMoneyItemOneInner">
                <LockInfoGraphReveue
                  className="LockInfoGraphReveue"
                  style={{ backgroundColor: "#ff9f4359" }}
                >
                  <CreditCard color="#ff9f43" />
                </LockInfoGraphReveue>
                <LockMoneyInfoDesWrapper className="LockMoneyInfoDesWrapper">
                  <LockMoneyInfoPrice className="LockMoneyInfoPrice">
                    ?%
                  </LockMoneyInfoPrice>
                  <LockMoneyInfoTitle className="LockMoneyInfoTitle">
                    Demand
                  </LockMoneyInfoTitle>
                </LockMoneyInfoDesWrapper>
              </LockMoneyItemOneInner>
              <MoneyItemOneLockIconWrapper className="MoneyItemOneLockIconWrapper">
                <MoneyItemOneLockIcon
                  src={ICONS.lock}
                  className="MoneyItemOneLockIcons"
                ></MoneyItemOneLockIcon>
              </MoneyItemOneLockIconWrapper>
            </LockMoneyItemOne>
            <MoneyInfoItemLockButtonWrapper className="MoneyInfoItemLockButtonWrapper">
              <LockButton className="LockButton" onClick={clickHandle}>
                Upgrade your plan
              </LockButton>
            </MoneyInfoItemLockButtonWrapper>
          </LockMoneyInfoItem>

          <LockMoneyInfoItem className="LockMoneyInfoItem">
            <LockMoneyItemOne className="LockMoneyItemOne">
              <LockMoneyItemOneInner className="LockMoneyItemOneInner">
                <LockInfoGraphReveue
                  className="LockInfoGraphReveue"
                  style={{ backgroundColor: "#00cfe85c" }}
                >
                  <BarChart2 color="#00cfe8" />
                </LockInfoGraphReveue>
                <LockMoneyInfoDesWrapper className="LockMoneyInfoDesWrapper">
                  <LockMoneyInfoPrice className="LockMoneyInfoPrice">
                    $?
                  </LockMoneyInfoPrice>
                  <LockMoneyInfoTitle className="LockMoneyInfoTitle">
                    Average Daily Rate
                  </LockMoneyInfoTitle>
                </LockMoneyInfoDesWrapper>
              </LockMoneyItemOneInner>
              <MoneyItemOneLockIconWrapper className="MoneyItemOneLockIconWrapper">
                <MoneyItemOneLockIcon
                  src={ICONS.lock}
                  className="MoneyItemOneLockIcons"
                ></MoneyItemOneLockIcon>
              </MoneyItemOneLockIconWrapper>
            </LockMoneyItemOne>
            <MoneyInfoItemLockButtonWrapper className="MoneyInfoItemLockButtonWrapper">
              <LockButton className="LockButton" onClick={clickHandle}>
                Upgrade your plan
              </LockButton>
            </MoneyInfoItemLockButtonWrapper>
          </LockMoneyInfoItem>
          <Modal
            open={showSubScription}
            onCancel={CancelHandle}
            width={1200}
            centered
            style={{ overflow: "auto" }}
            footer=""
          >
            <SubScription />
          </Modal>
        </MoneyInfos>
      ) : (
        <MoneyInfos className="MoneyInfos">
          <MoneyInfoItem className="MoneyInfoItem">
            <MoneyItemOne className="moneyItemOne">
              <InfoGraphReveue
                className="InfoGraphReveue"
                style={{ backgroundColor: "#ffc10747" }}
              >
                <CreditCard color="#ffc107" />
              </InfoGraphReveue>
              <MoneyInfoDesWrapper className="MoneyInfoDesWrapper">
                <MoneyInfoPrice className="MoneyInfoPrice">
                  ${currentStatus?.expec_month_rev ? Math.round(currentStatus?.expec_month_rev) : "?"}
                </MoneyInfoPrice>
                <MoneyInfoTitle className="MoneyInfoTitle">
                  Projected Revenue
                </MoneyInfoTitle>
              </MoneyInfoDesWrapper>
            </MoneyItemOne>
            <MiniLineChart
              data={projectedRevenue ?? ChartRevenueLongData}
              status="revenue"
              height={100}
              color="#ffc107"
            />
            {loading && (
              <ClipLoaderWrapper className="ClipLoaderWrapper">
                <ClipLoader
                  color={COLORS.black}
                  loading={loading}
                  size={50}
                />
              </ClipLoaderWrapper>
            )}
          </MoneyInfoItem>
          <MoneyInfoItem className="MoneyInfoItem">
            <MoneyItemOne className="moneyItemOne">
              <InfoGraphReveue
                className="InfoGraphReveue"
                style={{ backgroundColor: "#ff9f4359" }}
              >
                <Award color="#ff9f43" />
              </InfoGraphReveue>
              <MoneyInfoDesWrapper className="MoneyInfoDesWrapper">
                <MoneyInfoPrice className="MoneyInfoPrice">
                  {currentStatus?.portion_rented ? (currentStatus?.portion_rented * 100).toFixed(2) : ''}%
                </MoneyInfoPrice>
                <MoneyInfoTitle className="MoneyInfoTitle">Demand</MoneyInfoTitle>
              </MoneyInfoDesWrapper>
            </MoneyItemOne>
            <MiniLineChart
              data={demand ?? ChartDemandLongData}
              status="demand"
              height={100}
              color="#ff9f43"
            />

            {loading && (
              <ClipLoaderWrapper className="ClipLoaderWrapper">
                <ClipLoader
                  color={COLORS.black}
                  loading={loading}
                  size={50}
                />
              </ClipLoaderWrapper>
            )}
          </MoneyInfoItem>
          <MoneyInfoItem className="MoneyInfoItem">
            <MoneyItemOne className="moneyItemOne">
              <InfoGraphReveue
                className="InfoGraphReveue"
                style={{ backgroundColor: "#00cfe85c" }}
              >
                <BarChart2 color="#00cfe8" />
              </InfoGraphReveue>
              <MoneyInfoDesWrapper className="MoneyInfoDesWrapper">
                <MoneyInfoPrice className="MoneyInfoPrice">
                  ${currentStatus?.avg_daily_price_amount ? Math.round(currentStatus?.avg_daily_price_amount) : ''}
                </MoneyInfoPrice>
                <MoneyInfoTitle className="MoneyInfoTitle">
                  Average Daily Rate
                </MoneyInfoTitle>
              </MoneyInfoDesWrapper>
            </MoneyItemOne>
            <MiniLineChart
              data={averageDailyRate ?? ChartRateLongData}
              status="average"
              height={100}
              color="#00cfe8"
            />

            {loading && (
              <ClipLoaderWrapper className="ClipLoaderWrapper">
                <ClipLoader
                  color={COLORS.black}
                  loading={loading}
                  size={50}
                />
              </ClipLoaderWrapper>
            )}
          </MoneyInfoItem>
        </MoneyInfos>
      )}
    </>
  );
};

export default MoneyInfoPage;
