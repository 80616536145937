import React, { useEffect, useState } from "react";
import {
  ClipLoaderWrapper,
  MonthlyReveue,
  MonthlyReveueButton,
  MonthlyReveueGraph,
  MonthlyReveueHeader,
} from "../../styles";
import ApexChartsColumn from "../../../../components/custom/apexChartsColumn";
import Lock from "./lock";
import { useAuth } from "../../../../context/AppContext";
import ClipLoader from "react-spinners/ClipLoader";
import { COLORS } from "../../../../constants";
import ReportAPI from "../../../../api/report";
import { MonthlyReveueDataParsing } from "../../../../utils/fetch_data";

const MontlyReveue = (props: any) => {
  const { apiKey } = useAuth();
  const { currentReport } = useAuth();
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const { id } = props;
  const [projectedRevenue, setProjectedRevenue] = useState<any>(null);
  const [totalRevenue, setTotalRevenue] = useState<string>("");

  const handleGetReportData = async () => {
    setLoadingReport(true);
    try {
      const res = await ReportAPI.getRDAOreport(id);
      if (res) {
        console.log(MonthlyReveueDataParsing(res?.time_series_by_month, "expec_month_rev"))
        setProjectedRevenue(MonthlyReveueDataParsing(res?.time_series_by_month, "expec_month_rev"));
        setTotalRevenue(
          res?.time_series_by_month
            .slice(-12)
            .map((item: any) => item.expec_month_rev)
            .reduce((sum: number, val: number) => sum + val, 0)
        );
        setLoadingReport(false);
      }
    } catch (error) {
      console.log("error", error)
    } 
  };

  useEffect(() => {
    if (currentReport) {
      handleGetReportData();
    }
    // eslint-disable-next-line
  }, [currentReport]);

  return (
    <>
      {!apiKey?.plan_type ? (
        <Lock />
      ) : (
        <MonthlyReveue className="MonthlyReveue">
          <MonthlyReveueHeader className="MonthlyReveueHeader">
            <MonthlyReveueButton className="MonthlyReveueButton"></MonthlyReveueButton>
          </MonthlyReveueHeader>

          <MonthlyReveueGraph className="MonthlyReveueGraph">
            <ApexChartsColumn projectedRevenue={projectedRevenue} totalRevenue={totalRevenue} />
          </MonthlyReveueGraph>

          {loadingReport && (
            <ClipLoaderWrapper className="ClipLoaderWrapper">
              <ClipLoader
                color={COLORS.black}
                loading={loadingReport}
                size={50}
              />
            </ClipLoaderWrapper>
          )}
        </MonthlyReveue>
      )}
    </>
  );
};

export default MontlyReveue;
