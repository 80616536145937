import React from "react";
import { MarketOverViewContainer } from "../../../earningPotential/styles";
import { COLORS } from "../../../../constants";

import { useRTL } from "../../../../utils/useRTL";
import RevenueChart from "../revenueChart";
import DemandChart from "../demandChart";
import CurrentStatusGraph from "../currentStatusGraph";

import TopBottomTenTable from "./topBottomTen";
import MoneyInfoPage from "./moneyInfoPage";

const MarketOverViewItem = (props: any) => {
  const {currentPage} = props;
  //eslint-disable-next-line
  const [isRtl, setIsRtl] = useRTL();

  return (
    <MarketOverViewContainer className="MarketOverViewContainer">
      <MoneyInfoPage />

      <TopBottomTenTable currentPage={currentPage}/>

      <RevenueChart
        direction={isRtl ? "rtl" : "ltr"}
        primary={COLORS.primary}
        success={COLORS.secondary}
        warning={COLORS.red}
      />

      <DemandChart
        direction={isRtl ? "rtl" : "ltr"}
        primary={COLORS.primary}
        success={COLORS.secondary}
        warning={COLORS.red}
      />

      <CurrentStatusGraph />
    </MarketOverViewContainer>
  );
};

export default MarketOverViewItem;
