import React, { useEffect, useState } from "react";
import {
  InfoGraphReveue,
  LockButton,
  LockInfoGraphReveue,
  LockMoneyInfoDesWrapper,
  LockMoneyInfoItem,
  LockMoneyInfoPrice,
  LockMoneyInfoTitle,
  LockMoneyItemOne,
  LockMoneyItemOneInner,
  MoneyInfoDesWrapper,
  MoneyInfoItem,
  MoneyInfoItemLockButtonWrapper,
  MoneyInfoPrice,
  MoneyInfoTitle,
  MoneyInfoss,
  MoneyItemDiv,
  MoneyItemOne,
  MoneyItemOneLockIcon,
  MoneyItemOneLockIconWrapper,
} from "../../styles";
import MiniLineChart from "../../../../components/custom/miniLineChart";

import { Award, BarChart, BarChart2, CreditCard } from "react-feather";

import { DataParsing } from "../../../../utils/fetch_data";
import {
  ChartAnnualROILongData,
  ChartDemandLongData,
  ChartRateLongData,
  ChartRevenueLongData,
} from "../../data";
import { useAuth } from "../../../../context/AppContext";
import { COLORS, ICONS } from "../../../../constants";
import { Modal } from "antd";
import SubScription from "../../../../components/custom/subScription";
import ClipLoader from "react-spinners/ClipLoader";
import { ClipLoaderWrapper } from "./styles";
import ReportAPI from "../../../../api/report";

const MoneyInfos = (props: any) => {
  const { id } = props;
  const { apiKey, currentReport, setCarDemand, setCarRevenue, setCarPurchasePrice } = useAuth();
  const [loading, setLoadingReport] = useState<boolean>(false);
  const [currentStatus, setCurrentStatus] = useState<any>(null);
  const [projectedRevenue, setProjectedRevenue] = useState<any>(null);
  const [demand, setDemand] = useState<any>(null);
  const [averageDailyRate, setAverageDailyRate] = useState<any>(null);
  const [annualROI, setAnnualROI] = useState<any>(null);

  const [showSubScription, setShowSubScription] = useState<boolean>(false);

  const clickHandle = () => {
    setShowSubScription(true);
  };

  const CancelHandle = () => {
    setShowSubScription(false);
  };

  const handleGetReportData = async () => {
    setLoadingReport(true);
    try {
      const res = await ReportAPI.getRDAOreport(id);
      if (res) {
        setCurrentStatus(res?.total_means);
        setCarDemand(res.total_means.portion_rented * 100);
        setCarRevenue(res.total_means.expec_month_rev);
        setCarPurchasePrice(res.total_means.price_base_msrp);
        setProjectedRevenue(DataParsing(res?.time_series, "expec_month_rev"));
        setDemand(DataParsing(res?.time_series, "portion_rented"));
        setAverageDailyRate(DataParsing(res?.time_series, "avg_daily_price_amount"));
        setAnnualROI(DataParsing(res?.time_series, "annual_roi"));
        setLoadingReport(false);
      }
    } catch (error: any) {
    }
  };

  useEffect(() => {
    if (currentReport) {
      handleGetReportData();
    }
    // eslint-disable-next-line
  }, [currentReport]);


  return (
    <>
      {!apiKey?.plan_type ? (
        <MoneyInfoss className="MoneyInfos">
          <MoneyItemDiv className="MoneyItemDiv">
            <MoneyInfoItem className="MoneyInfoItem">
              <MoneyItemOne className="moneyItemOne">
                <InfoGraphReveue
                  className="InfoGraphReveue"
                  style={{ backgroundColor: "#ffc10747" }}
                >
                  <CreditCard color="#ffc107" />
                </InfoGraphReveue>
                <MoneyInfoDesWrapper className="MoneyInfoDesWrapper">
                  <MoneyInfoPrice className="MoneyInfoPrice">
                    ${currentStatus?.expec_month_rev && currentStatus?.expec_month_rev !== 'unknown' ? currentStatus?.expec_month_rev?.toFixed(0) : "?"}
                  </MoneyInfoPrice>
                  <MoneyInfoTitle className="MoneyInfoTitle">
                    Projected Revenue
                  </MoneyInfoTitle>
                </MoneyInfoDesWrapper>
              </MoneyItemOne>
              <MiniLineChart
                data={projectedRevenue ?? ChartRevenueLongData}
                status="revenue"
                height={100}
                color="#ffc107"
              />
              {loading && (
                <ClipLoaderWrapper className="ClipLoaderWrapper">
                  <ClipLoader
                    color={COLORS.black}
                    loading={loading}
                    size={50}
                  />
                </ClipLoaderWrapper>
              )}
            </MoneyInfoItem>

            <LockMoneyInfoItem className="LockMoneyInfoItem">
              <LockMoneyItemOne className="LockMoneyItemOne">
                <LockMoneyItemOneInner className="LockMoneyItemOneInner">
                  <LockInfoGraphReveue
                    className="LockInfoGraphReveue"
                    style={{ backgroundColor: "#ff9f4359" }}
                  >
                    <CreditCard color="#ff9f43" />
                  </LockInfoGraphReveue>
                  <LockMoneyInfoDesWrapper className="LockMoneyInfoDesWrapper">
                    <LockMoneyInfoPrice className="LockMoneyInfoPrice">
                      ?%
                    </LockMoneyInfoPrice>
                    <LockMoneyInfoTitle className="LockMoneyInfoTitle">
                      Demand
                    </LockMoneyInfoTitle>
                  </LockMoneyInfoDesWrapper>
                </LockMoneyItemOneInner>
                <MoneyItemOneLockIconWrapper className="MoneyItemOneLockIconWrapper">
                  <MoneyItemOneLockIcon
                    src={ICONS.lock}
                    className="MoneyItemOneLockIcons"
                  ></MoneyItemOneLockIcon>
                </MoneyItemOneLockIconWrapper>
              </LockMoneyItemOne>
              <MoneyInfoItemLockButtonWrapper className="MoneyInfoItemLockButtonWrapper">
                <LockButton className="LockButton" onClick={clickHandle}>
                  Upgrade your plan
                </LockButton>
              </MoneyInfoItemLockButtonWrapper>
            </LockMoneyInfoItem>
          </MoneyItemDiv>

          <MoneyItemDiv className="MoneyItemDiv">
            <LockMoneyInfoItem className="LockMoneyInfoItem">
              <LockMoneyItemOne className="LockMoneyItemOne">
                <LockMoneyItemOneInner className="LockMoneyItemOneInner">
                  <LockInfoGraphReveue
                    className="LockInfoGraphReveue"
                    style={{ backgroundColor: "#00cfe85c" }}
                  >
                    <BarChart2 color="#00cfe8" />
                  </LockInfoGraphReveue>
                  <LockMoneyInfoDesWrapper className="LockMoneyInfoDesWrapper">
                    <LockMoneyInfoPrice className="LockMoneyInfoPrice">
                      $?
                    </LockMoneyInfoPrice>
                    <LockMoneyInfoTitle className="LockMoneyInfoTitle">
                      Average Daily Rate
                    </LockMoneyInfoTitle>
                  </LockMoneyInfoDesWrapper>
                </LockMoneyItemOneInner>
                <MoneyItemOneLockIconWrapper className="MoneyItemOneLockIconWrapper">
                  <MoneyItemOneLockIcon
                    src={ICONS.lock}
                    className="MoneyItemOneLockIcons"
                  ></MoneyItemOneLockIcon>
                </MoneyItemOneLockIconWrapper>
              </LockMoneyItemOne>
              <MoneyInfoItemLockButtonWrapper className="MoneyInfoItemLockButtonWrapper">
                <LockButton className="LockButton" onClick={clickHandle}>
                  Upgrade your plan
                </LockButton>
              </MoneyInfoItemLockButtonWrapper>
            </LockMoneyInfoItem>

            <LockMoneyInfoItem className="LockMoneyInfoItem">
              <LockMoneyItemOne className="LockMoneyItemOne">
                <LockMoneyItemOneInner className="LockMoneyItemOneInner">
                  <LockInfoGraphReveue
                    className="LockInfoGraphReveue"
                    style={{ backgroundColor: "#ff9f4359" }}
                  >
                    <BarChart color="#ea5455" />
                  </LockInfoGraphReveue>
                  <LockMoneyInfoDesWrapper className="LockMoneyInfoDesWrapper">
                    <LockMoneyInfoPrice className="LockMoneyInfoPrice">
                      ?%
                    </LockMoneyInfoPrice>
                    <LockMoneyInfoTitle className="LockMoneyInfoTitle">
                      Projected annual ROI
                    </LockMoneyInfoTitle>
                  </LockMoneyInfoDesWrapper>
                </LockMoneyItemOneInner>
                <MoneyItemOneLockIconWrapper className="MoneyItemOneLockIconWrapper">
                  <MoneyItemOneLockIcon
                    src={ICONS.lock}
                    className="MoneyItemOneLockIcons"
                  ></MoneyItemOneLockIcon>
                </MoneyItemOneLockIconWrapper>
              </LockMoneyItemOne>
              <MoneyInfoItemLockButtonWrapper className="MoneyInfoItemLockButtonWrapper">
                <LockButton className="LockButton" onClick={clickHandle}>
                  Upgrade your plan
                </LockButton>
              </MoneyInfoItemLockButtonWrapper>
            </LockMoneyInfoItem>
          </MoneyItemDiv>

          <Modal
            open={showSubScription}
            onCancel={CancelHandle}
            width={1200}
            centered
            style={{ overflow: "auto" }}
            footer=""
          >
            <SubScription />
          </Modal>
        </MoneyInfoss>
      ) : (
        <MoneyInfoss className="MoneyInfos">
          <MoneyItemDiv className="MoneyItemDiv">
            <MoneyInfoItem className="MoneyInfoItem">
              <MoneyItemOne className="moneyItemOne">
                <InfoGraphReveue
                  className="InfoGraphReveue"
                  style={{ backgroundColor: "#ffc10747" }}
                >
                  <CreditCard color="#ffc107" />
                </InfoGraphReveue>
                <MoneyInfoDesWrapper className="MoneyInfoDesWrapper">
                  <MoneyInfoPrice className="MoneyInfoPrice">
                    ${currentStatus?.expec_month_rev && currentStatus?.expec_month_rev !== 'unknown' ? currentStatus?.expec_month_rev.toFixed(0) : "?"}
                  </MoneyInfoPrice>
                  <MoneyInfoTitle className="MoneyInfoTitle">
                    Projected Revenue
                  </MoneyInfoTitle>
                </MoneyInfoDesWrapper>
              </MoneyItemOne>
              <MiniLineChart
                data={projectedRevenue ?? ChartRevenueLongData}
                status="revenue"
                height={100}
                color="#ffc107"
              />
              {loading && (
                <ClipLoaderWrapper className="ClipLoaderWrapper">
                  <ClipLoader
                    color={COLORS.black}
                    loading={loading}
                    size={50}
                  />
                </ClipLoaderWrapper>
              )}
            </MoneyInfoItem>

            <MoneyInfoItem className="MoneyInfoItem">
              <MoneyItemOne className="moneyItemOne">
                <InfoGraphReveue
                  className="InfoGraphReveue"
                  style={{ backgroundColor: "#ff9f4359" }}
                >
                  <Award color="#ff9f43" />
                </InfoGraphReveue>
                <MoneyInfoDesWrapper className="MoneyInfoDesWrapper">
                  <MoneyInfoPrice className="MoneyInfoPrice">
                    {((currentStatus?.portion_rented) * 100) ? Math.round((currentStatus?.portion_rented) * 100) : "?"}%
                  </MoneyInfoPrice>
                  <MoneyInfoTitle className="MoneyInfoTitle">
                    Demand
                  </MoneyInfoTitle>
                </MoneyInfoDesWrapper>
              </MoneyItemOne>
              <MiniLineChart
                data={demand ?? ChartDemandLongData}
                status="demand"
                height={100}
                color="#ff9f43"
              />
              {loading && (
                <ClipLoaderWrapper className="ClipLoaderWrapper">
                  <ClipLoader
                    color={COLORS.black}
                    loading={loading}
                    size={50}
                  />
                </ClipLoaderWrapper>
              )}
            </MoneyInfoItem>
          </MoneyItemDiv>

          <MoneyItemDiv>
            <MoneyInfoItem className="MoneyInfoItem">
              <MoneyItemOne className="moneyItemOne">
                <InfoGraphReveue
                  className="InfoGraphReveue"
                  style={{ backgroundColor: "#00cfe85c" }}
                >
                  <BarChart2 color="#00cfe8" />
                </InfoGraphReveue>
                <MoneyInfoDesWrapper className="MoneyInfoDesWrapper">
                  <MoneyInfoPrice className="MoneyInfoPrice">
                    ${currentStatus?.avg_daily_price_amount ? currentStatus?.avg_daily_price_amount.toFixed(0) : "?"}
                  </MoneyInfoPrice>
                  <MoneyInfoTitle className="MoneyInfoTitle">
                    Average Daily Rate
                  </MoneyInfoTitle>
                </MoneyInfoDesWrapper>
              </MoneyItemOne>
              <MiniLineChart
                data={averageDailyRate ?? ChartRateLongData}
                status="average"
                height={100}
                color="#00cfe8"
              />
              {loading && (
                <ClipLoaderWrapper className="ClipLoaderWrapper">
                  <ClipLoader
                    color={COLORS.black}
                    loading={loading}
                    size={50}
                  />
                </ClipLoaderWrapper>
              )}
            </MoneyInfoItem>
            <MoneyInfoItem className="MoneyInfoItem">
              <MoneyItemOne className="moneyItemOne">
                <InfoGraphReveue
                  className="InfoGraphReveue"
                  style={{ backgroundColor: "#ea545529" }}
                >
                  <BarChart color="#ea5455" />
                </InfoGraphReveue>
                <MoneyInfoDesWrapper className="MoneyInfoDesWrapper">
                  <MoneyInfoPrice className="MoneyInfoPrice">
                    {currentStatus?.annual_roi ? (currentStatus?.annual_roi * 100).toFixed(0) : "?"}%
                  </MoneyInfoPrice>
                  <MoneyInfoTitle className="MoneyInfoTitle">
                    Projected annual ROI
                  </MoneyInfoTitle>
                </MoneyInfoDesWrapper>
              </MoneyItemOne>
              <MiniLineChart
                data={annualROI ?? ChartAnnualROILongData}
                status="annualROI"
                height={100}
                color="#ea5455"
              />
              {loading && (
                <ClipLoaderWrapper className="ClipLoaderWrapper">
                  <ClipLoader
                    color={COLORS.black}
                    loading={loading}
                    size={50}
                  />
                </ClipLoaderWrapper>
              )}
            </MoneyInfoItem>
          </MoneyItemDiv>
        </MoneyInfoss>
      )}
    </>
  );
};

export default MoneyInfos;
