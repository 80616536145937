import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import MarketOverViewItem from "./elements/marketOverViewItem";
import TopTenHosts from "./elements/topTenHosts";
import CarData from "./elements/carData";
import "./index.css";
import TurboMainContainer from "../../layout/turboMainContainer";
import { useAuth } from "../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";

const MarketOverView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { allReports, setCurrentReport, setMapCars, openBigMapStatus, setDetailMap } = useAuth();
  const [loadingDashboardLayout, setLoadingDashboardLayout] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<string>("1");

  const onChange = (key: any) => {
    setMapCars([]);
    setCurrentPage(key);
    setDetailMap(false);
  };

  useEffect(() => {
    const currentPage = localStorage.getItem("currentPage");
    if (currentPage) {
      setCurrentPage(currentPage);
      localStorage.removeItem('currentPage');
    } else {
      setCurrentPage("1");
    }
  }, [id]);

  const handleFirstPage = () => {
    let firstReport = allReports.find((item: any) => item.report_id === id);
    if (!firstReport) {
      firstReport = allReports[0];
      navigate(`/${firstReport.type}/${firstReport.report_id}`, {
        replace: true,
      });
      setCurrentReport(firstReport);
    } else {
      setCurrentReport(firstReport);
    }
    setLoadingDashboardLayout(false);
  };

  useEffect(() => {
    if (allReports && allReports !== undefined) {
      if (allReports.length > 0) {
        handleFirstPage();
      }
    } 
    // eslint-disable-next-line
  }, [id, allReports]);

  useEffect(() => {
    if (!openBigMapStatus) {
      const scrollPosition = localStorage.getItem('scrollPosition');
      if (scrollPosition) {
        window.scrollTo({ top: parseFloat(scrollPosition) });
        localStorage.removeItem('scrollPosition');
      }
    }
  }, [openBigMapStatus])


  const items = [
    {
      key: "1",
      label: "Overview",
      children: <MarketOverViewItem currentPage={currentPage}/>,
    },
    {
      key: "2",
      label: "Top 10 Hosts",
      children: <TopTenHosts currentPage={currentPage}/>,
    },
    {
      key: "3",
      label: "Car Data",
      children: <CarData currentPage={currentPage}/>,
    },
  ];

  return (
    <TurboMainContainer
      className="TurboMainContainer"
      loadingDashboardLayout={loadingDashboardLayout}
    >
      <Tabs
        className="tabs-container"
        activeKey={currentPage}
        items={items}
        onChange={onChange}
      />
    </TurboMainContainer>
  );
};

export default MarketOverView;
