import React, { useState } from "react";
import {
  BackBtnInner,
  DashboardBackground,
  DashboardContainer,
  DashboardContent,
  DashboardContentRight,
  DashboardImg,
  DashboardRightSection,
  DashboardTiTle,
  InputSpecify,
  NextBtnContainer,
  NextBtns,
  NumberStatus,
  RectangleItemGroup,
} from "./styles";
import { ICONS, IMAGES } from "../../../../constants";
import ItemRectangle from "./itemRectangle";
import BottomButton from "../../components/bottomButton";
import BackBtnModule from "../../components/bottomButton/backBtnModule";
import { DASHBOARDDATAS } from "./data";

const Dashboard = (props: any) => {
  const { handleStepInfo, handleUpdateUser } = props;

  const [bool, setBool] = useState<boolean>(false);
  const [shosInputSpecify, setShowInputSpecify] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [customdesc, setCustomDesc] = useState<string>("");
  const [describes, setDescribes] = useState<string>("");

  const onMouseOver = () => {
    setBool(true);
  };
  const onMouseLeave = () => {
    setBool(false);
  };

  const handleSelect = (name: string, title: string) => {
    setSelectedItem(name);
    setDescribes(title);
    if (name === "tellus") {
      setShowInputSpecify(true);
    } else {
      setShowInputSpecify(false);
    }
  };

  const onChange = (value: any) => {
    setCustomDesc(value);
  };

  const handelNext = (nextstep: number) => {
    handleStepInfo(nextstep)
    if (selectedItem === 'tellus') {
      const userinfo = {
        describes: customdesc
      }
      handleUpdateUser(userinfo);
    } else {
      const userinfo = {
        describes: describes
      }
      handleUpdateUser(userinfo);
    }
  }

    //eslint-disable-next-line
  const disabled = (!selectedItem || selectedItem === 'tellus') && customdesc.length === 0;

  return (
    <>
      <DashboardContainer className="DashboardContainer">
        <BackBtnModule handleStepInfo={handleStepInfo} currentstep={0} />
        <DashboardContent className="DashboardContent">
          <DashboardTiTle className="DashboardTiTle">
            Which best describes you?
          </DashboardTiTle>
          <DashboardRightSection className="DashboardRightSection">
            <DashboardContentRight className="DashboardContentRight">
              <RectangleItemGroup className="RectangleItemGroup">
                {DASHBOARDDATAS.map((item, index) => (
                  <ItemRectangle
                    data={item}
                    selectedItem={selectedItem}
                    key={index}
                    handleSelect={handleSelect}
                  />
                ))}
              </RectangleItemGroup>
            </DashboardContentRight>
            {shosInputSpecify && (
              <>
                <InputSpecify
                  className="InputSpecify"
                  maxLength={150}
                  value={customdesc}
                  placeholder="Please specify..."
                  onChange={(e) => onChange(e.target.value)}
                />
                <NumberStatus className="NumberStatus">
                  {customdesc.length}/150
                </NumberStatus>
              </>
            )}
          </DashboardRightSection>
          <DashboardBackground className="DashboardBackground">
            <DashboardImg src={IMAGES.dashboard_bg}></DashboardImg>
          </DashboardBackground>
        </DashboardContent>
      </DashboardContainer>
      <NextBtnContainer className="NextBtnContainer">
        <NextBtns
          className="NextBtns"
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          disabled={disabled}
          onClick={() => handelNext(1)}
        >
          Next&nbsp;
          <BackBtnInner
            src={
              bool && !disabled ? ICONS.new_next_arrow_blue : ICONS.new_next_arrow_black
            }
          ></BackBtnInner>
        </NextBtns>
      </NextBtnContainer>
      <BottomButton
        handleStepInfo={handelNext}
        currentstep={0}
        disabled={disabled}
      />
    </>
  );
};

export default Dashboard;
