import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, SIZES } from "../../../../constants";

export const TopTenHostsContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
    `}
`;
export const CardMeta = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        gap:10px;
        border-bottom:1px solid ${COLORS.gray};
    `}
`;
export const CardMetaRow1 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        justify-content:space-between;
        padding:4px 10px;
    `}
`;
export const CardMetaRow2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        justify-content:space-between;
        padding:4px 10px;
    `}
`;
export const CardMetaTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `  
        font-size:${SIZES.body1}px;
        color:${COLORS.primary};
        font-weight:bold;
    `}
`;
export const CardMetaStars = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        align-items:center;
    `}
`;
export const CardMetaHeader = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        color:#838383;
        font-weight:bold;
    `}
`;
export const CardMetaSetting = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
         color:#838383;
        font-weight:bold;

    `}
`;
export const HostPrice = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
         font-weight:bold;
         font-size:${SIZES.body1}px;
         text-align: right;
         padding: 5px;
    `}
`;
export const HostImageWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width:148px;
      border-radius: 20px;
      ${theme.breakpoints.down("sm")} {
        width:75px;
        height:95px;
      }
  `}
`;
export const HostImage = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        height:100%;
        border-radius:5px;
        object-fit:cover;
    `}
`;
export const HostDescriptionContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;
      padding-left:27px;
      flex: 1;
      padding: 10px 0 10px 37px;
      gap:20px;
      justify-content:space-between;
      ${theme.breakpoints.down("sm")} {
        padding: 0 5px 0 12px;
        gap:10px;
      }
    }
    `}
`;
export const HostDescriptionHeader = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        align-items:center;
        justify-content:space-between;
    `}
`;
export const HostDescriptionHeaderLeft = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        gap:10px;
    `}
`;
export const HostName = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.h9}px;
    font-family:${FONT_FAMILY.semibold};

    ${theme.breakpoints.down("sm")} {
      font-size:${SIZES.body4}px;
    }
  `}
`;
export const NickNameIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `

    width:23px;
  `}
`;
export const HostNickName = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.medium};
      color:#838383;
      gap:10px;
  `}
`;
export const HostExperience = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body1}px;
    font-family:${FONT_FAMILY.regular};
    color:#838383;
  `}
`;
export const HostInformations = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      align-items:center;
      justify-content:space-between;
  `}
`;
export const HostInfoItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        gap:2px;
  `}
`;
export const HostInfoItemIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
      width:20px;
  `}
`;
export const HostInfoItemIconRank = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
      width:20px;
      height:22px;
  `}
`;
export const HostInfoItemTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.medium};
      color:#8F8F8F;
      ${theme.breakpoints.down("sm")} {
       font-size:${SIZES.body3}px;
    }
  `}
`;
export const HostInfoItemValue = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.h9}px;
    font-family:${FONT_FAMILY.semibold};
    ${theme.breakpoints.down("sm")} {
      font-size:${SIZES.body2}px;
   }
  `}
`;

export const GoSeeInfoButton = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
      padding:8px 27px;
      font-size:${SIZES.body4}px;
       font-family:${FONT_FAMILY.medium};
      background-color:${COLORS.primary};
      border:1px solid ${COLORS.primary};
      color:white;
      border-radius:30px;
      transition:all .3s;
      &:hover {
        background-color:transparent;
        color:${COLORS.primary};
      }
      ${theme.breakpoints.down("sm")} {
        padding:4px 8px;
        font-size:10px;
     }
  `}
`;
export const GoSeeBtnWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  `}
`;
export const TopTenHostsContainerCardList = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;
      gap:10px;
      position:relative;
      min-height: 400px;
  `}
`;
export const TopTenHostdivBody = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      padding:15px;
      cursor: pointer;
      border:1px solid ${COLORS.gray};
         ${theme.breakpoints.down("sm")} {
          padding:10px;
     }
  `}
`;
export const TopTenHostdivBodyInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      align-items:center;
  `}
`;
export const TopTenHostDivBodyFirst = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
 
  `}
`;
export const TopBodyFirstTwoSection = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
 
  `}
`;
export const TopTenHostdiveBodyInnerWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
  `}
`;




/////////////////////////responsive design///////////////////////



export const TopTenHostsContainerw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        position:relative;
    `}
`;
export const CardMetaw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        gap:10px;
        border-bottom:1px solid ${COLORS.gray};
    `}
`;
export const CardMetaRow1w = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        justify-content:space-between;
        padding:4px 10px;
    `}
`;
export const CardMetaRow2w = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        justify-content:space-between;
        padding:4px 10px;
    `}
`;
export const CardMetaTitlew = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `  
        font-size:${SIZES.body1}px;
        color:${COLORS.primary};
        font-weight:bold;
    `}
`;
export const CardMetaStarsw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        align-items:center;
    `}
`;
export const CardMetaHeaderw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        color:#838383;
        font-weight:bold;
    `}
`;
export const CardMetaSettingw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
         color:#838383;
        font-weight:bold;

    `}
`;
export const HostPricew = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
         font-weight:bold;
         font-size:${SIZES.body1}px;
         text-align: right;
         padding: 5px;
    `}
`;
export const HostImageWrapperw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width:97px;
      height:100%;
  `}
`;
export const HostImagew = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
    
  
  `}
`;
export const HostDescriptionContainerw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;
      gap:16px;
      flex:1;
      justify-content:space-between;
      padding:10px;
    `}
`;
export const HostDescriptionHeaderw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        justify-content:space-between;
        flex-direction:column;
        flex:1;
    `}
`;
export const HostDescriptionHeaderLeftw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        gap:10px;
    `}
`;
export const HostNamew = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.h9}px;
    font-family:${FONT_FAMILY.semibold};
  `}
`;
export const NickNameIconw = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `

    width:23px;
  `}
`;
export const HostNickNamew = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.medium};
      color:#838383;
      gap:10px;
  `}
`;
export const HostExperiencew = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body4}px;
    font-family:${FONT_FAMILY.regular};
    color:#838383;
  `}
`;
export const HostInformationsw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      align-items:center;
      justify-content:space-between;
  `}
`;
export const HostInfoItemw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        gap:2px;
  `}
`;
export const HostInfoItemIconw = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
      width:16px;
  `}
`;
export const HostInfoItemTitlew = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:11px;
      font-family:${FONT_FAMILY.medium};
      color:#8F8F8F;
  `}
`;
export const HostInfoItemValuew = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body1}px;
    font-family:${FONT_FAMILY.semibold};
  `}
`;

export const GoSeeInfoButtonw = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
      padding:4px 8px;
      font-size:${SIZES.body2}px;
       font-family:${FONT_FAMILY.regular};
      background-color:${COLORS.primary};
      border:1px solid ${COLORS.primary};
      color:white;
      border-radius:30px;
      transition:all .3s;
      &:hover {
        background-color:transparent;
        color:${COLORS.primary};
      }
      ${theme.breakpoints.down("sm")} {
        padding:5px 12px;
        font-size:10px;
     }
  `}
`;
export const GoSeeBtnWrapperw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      text-align:end;
  `}
`;
export const TopTenHostsContainerCardListw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;
      gap:10px;
  `}
`;
export const TopTenHostdivBodyw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      padding:10px;
      border:1px solid ${COLORS.gray};
  `}
`;
export const TopTenHostdivBodyInnerw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;
      gap:10px;
  `}
`;
export const TopTenHostDivBodyFirstw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
 
  `}
`;
export const TopBodyFirstTwoSectionw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
 
  `}
`;
export const TopTenHostdiveBodyInnerWrapperw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      gap:7px;
  `}  
`;
