import React from "react";
import { LeftSideDescriptions, LeftSideTitle } from "../../styles";

const LeftSideDescription = () => {

  return (
    <>
      <LeftSideDescriptions className="LeftSideDescription">
        <LeftSideTitle className="LeftSideTitle">
          Car Earning Potential
        </LeftSideTitle>

      </LeftSideDescriptions>
    </>
  );
};

export default LeftSideDescription;
