import React, { useState } from "react";
import { ForgotLabel1, ForgotModal, InputForm, InputLName, ModalIcon, ModalIconWrapper, RequiredValidate, SendBtn } from "../login/styles";
import { InputNameInfo } from "../signUp/styles";
import { COLORS, IMAGES } from "../../../constants";
import AuthorizationAPI from "../../../api/authorization";
import ClipLoader from "react-spinners/ClipLoader";
import { Alert, message } from "antd";
import { validateEightNumber } from "../../../utils/authValidation";
import { useLocation, useNavigate } from "react-router";

const ResetPassword = () => {

    const navigate = useNavigate();

    const [errorMsg, setErrorMsg] = useState("");

    const [userinfo, setUserinfo] = useState({
        password: '', confirmPassword: ''
    })

    const [errors, setErrors] = useState({
        password: '', confirmPassword: ''
    })

    const [loading, setLoading] = useState<boolean>(false);

    const useQuery = () => new URLSearchParams(useLocation().search);

    const query = useQuery();

    const code = query.get('code');

    const handleResetPassword = async () => {
        if (!userinfo.password) {
            setErrors((info: any) => ({ ...info, password: "Password is required" }))
        }

        if (!validateEightNumber(userinfo.password)) {
            return;
        }

        if (userinfo.password !== userinfo.confirmPassword) {
            return;
        }

        if (!code)
            return;

        const values = {
            password: userinfo.password,
            passwordConfirmation: userinfo.confirmPassword,
            code
        };

        setLoading(true);

        setErrorMsg("");
        AuthorizationAPI.confirmResetPassword(values)
            .then(data => {
                message.success(`Your password has been resetted. In a few second you'll be redirected to login page.`);

                setTimeout(() => { navigate("/login", { replace: true }); }, 5000);
            })
            .catch(error => {
                setErrorMsg(error?.response?.data?.error?.message ?? "Something went wrong!");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleChange = (event: any) => {
        const field = event.target.name;
        const value = event.target.value;
        setUserinfo(info => ({ ...info, [field]: value }))
        switch (field) {
            case 'password':
                if (!validateEightNumber(value)) {
                    setErrors(info => ({ ...info, password: "Password must be at least eight characters" }))
                } else {
                    setErrors(info => ({ ...info, password: "" }))
                }
                break;
            case 'confirmPassword':
                if (userinfo.password !== value) {
                    setErrors(info => ({ ...info, confirmPassword: "Passwords must match" }))
                } else {
                    setErrors(info => ({ ...info, confirmPassword: "" }))
                }
                break;

            default:
                break;
        }

    }

    return (
        <ForgotModal className="ForgotModal">
            <ModalIconWrapper className="ModalIconWrapper">
                <ModalIcon
                    className="ModalIcon"
                    src={`${IMAGES.resetpassword}`}
                />
            </ModalIconWrapper>
            <ForgotLabel1 className="ForgotLabel1">
                Reset Password
            </ForgotLabel1>

            {errorMsg ? (
                <Alert
                    className="alert_error"
                    message={errorMsg}
                    type="error"
                    closable
                    afterClose={() => setErrorMsg("")}
                />
            ) : null}
            <InputForm className="InputForm">
                <InputNameInfo className="input-name-info">
                    <InputLName
                        type="password"
                        name="password"
                        className="InputPassword"
                        placeholder="Password"
                        disabled={loading}
                        value={userinfo.password}
                        onChange={handleChange}
                    ></InputLName>
                    {errors.password && (
                        <RequiredValidate className="RequiredValidate">
                            {errors.password}
                        </RequiredValidate>
                    )}
                </InputNameInfo>
                <InputNameInfo className="input-name-info">
                    <InputLName
                        type="password"
                        name="confirmPassword"
                        className="input-confirm-password"
                        placeholder="Confirm Password"
                        value={userinfo.confirmPassword}
                        disabled={loading}
                        onChange={handleChange}
                    ></InputLName>
                    {errors.confirmPassword && (
                        <RequiredValidate className="RequiredValidate">
                            {errors.confirmPassword}
                        </RequiredValidate>
                    )}
                </InputNameInfo>
                <SendBtn
                    className="SendBtn"
                    onClick={handleResetPassword}
                    disabled={loading}
                >
                    {
                        loading ?
                            <ClipLoader color={COLORS.blue} loading={loading} size={30} />
                            :
                            "Send Password Reset Email"
                    }
                </SendBtn>
            </InputForm>
        </ForgotModal>
    )
}

export default ResetPassword;