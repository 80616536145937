import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { COLORS } from '../../../../constants';

export const CalculatorContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  width: 100%;
  background-color: ${COLORS.dark};
  height:fit-content;
  border-radius: 12px;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  ${theme.breakpoints.down("md")}{
      width:100%;
      padding:20px;
      height:fit-content;
    }
    `}
`;