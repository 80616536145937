import React, { useEffect, useState } from "react";
import { SidebarMain, SidebarItems, SidebarCancel } from "./styles";
import { SidebarItem, SidebarItemNested } from "./elements";
import { DNSidebarItems, DSidebarItems } from "./data";
import { TSidebarProps } from "./types";
import { CancelIcon } from "../../svg";
import { useAuth } from "../../../context/AppContext";

const Sidebar = ({ ...props }: TSidebarProps) => {
  const { handleMobileMenu, showMobileMenu, allReports } = useAuth();
  const [reports, setReports] = useState<any[]>([]);
  const [sidebarItems, setSidebarItems] = useState<any[]>(DNSidebarItems);

  useEffect(() => {
    if (allReports && allReports !== undefined) {
      const reports = allReports?.map((item: any, index: any) => {
        return {
          id: index,
          label:
            item.title.length > 18
              ? item.title.slice(0, 16) + "..."
              : item.title,
          location: `${item.type}/${item.report_id}`,
        };
      });
      setReports(reports);
      if (allReports.length > 0) {
        setSidebarItems(DSidebarItems);
      } else {
        setSidebarItems(DNSidebarItems)
      }
    }
  }, [allReports]);

  const handleSidebar = () => {
    if (window.innerWidth < 1200) {
      handleMobileMenu(!showMobileMenu);
    }
  };

  const nestedArray = [
    {
      id: 0,
      state: false,
    },
  ];

  return (
    <SidebarMain className="SidebarMain"  {...props}>
      <SidebarCancel onClick={handleSidebar} className="SidebarCancel">
        <CancelIcon />
      </SidebarCancel>
      <SidebarItems className="SidebarItems">
        {sidebarItems.map((x, index) =>
          x.type === "nested" ? (
            <SidebarItemNested
              className="SidebarItemNested"
              label={x.label}
              icon={x.icon}
              items={reports}
              key={x.id}
              item={nestedArray[index]}
            />
          ) : (
            <SidebarItem
              className="SidebarItem"
              label={x.label}
              icon={x.icon}
              location={x.location}
              key={x.id}
              onClick={() => {
                handleSidebar();
              }}
            />
          )
        )}
      </SidebarItems>
    </SidebarMain>
  );
};

export default Sidebar;
