import React, { useState } from "react";
import {
  CurrentStatusGraphBody,
  CurrentStatusGraphContainer,
  CurrentStatusGraphTitle,
  ScatterChartContainerLockBody,
  ScatterChartContainerLockTitle,
} from "./styles";

import Chart from "react-apexcharts";
import {
  LockContainer,
  LockContainerButton,
  LockContainerButtonWrapper,
  LockContainerHeader,
  LockWrapper,
} from "./lockStyle";
import { Modal } from "antd";
import SubScription from "../../../../components/custom/subScription";

const Lock = () => {
  const [showSubScription, setShowSubScription] = useState<boolean>(false);

  const clickHandle = () => {
    setShowSubScription(true);
  };
  const CancelHandle = () => {
    setShowSubScription(false);
  };
  const series = [
    {
      name: "Weekly Average",
      data: [35, 39, 43, 48, 53, 59, 64, 68, 74, 79, 83, 89],
    },
    {
      name: "Trend Line",
      data: [25, 41, 62, 42, 78, 61, 68, 61, 43, 84, 85, 88],
    },
  ];
  const options: any = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    legend: {
      tooltipHoverFormatter: function (val: any, opts: any) {
        return (
          val +
          " - <strong>" +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          "</strong>"
        );
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: [
        "01 Jan",
        "02 Jan",
        "03 Jan",
        "04 Jan",
        "05 Jan",
        "06 Jan",
        "07 Jan",
        "08 Jan",
        "09 Jan",
        "10 Jan",
        "11 Jan",
        "12 Jan",
        "13 Jan",
        "14 Jan",
        "15 Jan",
        "16 Jan",
        "17 Jan",
        "18 Jan",
        "19 Jan",
        "20 Jan",
        "21 Jan",
        "22 Jan",
        "23 Jan",
        "24 Jan",
        "25 Jan",
        "26 Jan",
        "27 Jan",
        "28 Jan",
        "29 Jan",
        "30 Jan",
        "31 Jan",
      ],
    },
    yaxis: {
      title: {
        text: "Expected Revenue Over Last Twelve Months",
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "black",
          fontSize: "12px",
          fontFamily: "NunitoSans-Regular",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
      labels: {
        formatter: function (value: any) {
          return "$" + value;
        },
      },
    },

    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <ScatterChartContainerLockBody className="ScatterChartContainerLockBody">
      <ScatterChartContainerLockTitle className="ScatterChartContainerLockTitle">
        Expected Revenue Over Last Twelve Months
      </ScatterChartContainerLockTitle>
      <CurrentStatusGraphContainer className="CurrentStatusGraphContainer">
        <CurrentStatusGraphTitle>
          Expected Revenue Over Last Twelve Months
        </CurrentStatusGraphTitle>
        <CurrentStatusGraphBody className="CurrentStatusGraphBody">
          <Chart options={options} series={series} type="line" height={400} />
        </CurrentStatusGraphBody>

        <LockWrapper className="LockWrapper">
          <LockContainer className="LockContainer">
            <LockContainerHeader className="LockContainerHeader">
              Want to unlock even more insights?
            </LockContainerHeader>
            <LockContainerButtonWrapper className="LockContainerButtonWrapper">
              <LockContainerButton
                className="LockContainerButton"
                onClick={clickHandle}
              >
                Upgrade your plan
              </LockContainerButton>
            </LockContainerButtonWrapper>
          </LockContainer>
          <Modal
            open={showSubScription}
            onCancel={CancelHandle}
            width={1200}
            centered
            style={{ overflow: "auto" }}
            footer=""
          >
            <SubScription />
          </Modal>
        </LockWrapper>
      </CurrentStatusGraphContainer>
    </ScatterChartContainerLockBody>
  );
};

export default Lock;
