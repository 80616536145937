import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, SIZES } from "../../constants";
import { Button, Input } from "antd";

export const PersonalInfoContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.body1}px;
      font-family:${FONT_FAMILY.regular};
      padding:30px 135px;
      width:100%;
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;

      ${theme.breakpoints.down("sm")} {
        padding:30px;
      }
    `}
`;
export const PersonalInfoContainerInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display:flex;
        flex-direction:column;
        gap:14px;
        max-width:900px;
    `}
`;
export const ProfileInfo = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        border:1px solid ${COLORS.gray};
        padding:15px 35px;
    `}
`;
export const PersonalInfoTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.h5}px;
        font-family:${FONT_FAMILY.medium};
        ${theme.breakpoints.down("sm")} {
         font-size:${SIZES.h9}px;
        }
    `}
`;
export const PersonalInfoBody = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        gap:10px;
    `}
`;
export const PersonalInfoDiv = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        width:100%;
        gap:10px;
        ${theme.breakpoints.down("sm")} {
          flex-direction:column;
        }
    `}
`;
export const ChangePasswordBody = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
       width:100%;
       display:flex;
       justify-content:end;
    `}
`;
export const ChangePasswordBtn = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body4}px;
        background-color:white;
        color:black;
        &:hover {
          color:${COLORS.primary};
          cursor:pointer;
        }

    `}
`;
export const PersonInfoDivItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
          display:flex;
          flex-direction:column;
          width:100%;
          gap:10px;
    `}
`;
export const PersonInfoDivItemTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    ${theme.breakpoints.down("sm")} {
      font-size:${SIZES.body4}px;
    }

  `}
`;
export const PersonInfoInput = styled(Input) <{ theme?: Theme }>`
  ${({ theme }) => `
        padding:10px 20px;
        outline:0;
        line-height:2;
        border:1px solid ${COLORS.gray};
        width:100%;
    `}
`;
export const PersonInfPersonInfoInputPasswordInput = styled(Input.Password) <{
  theme?: Theme;
}>`
  ${({ theme }) => `
        padding:10px 20px;
        outline:0;
        line-height:2;
        border:1px solid ${COLORS.gray};
        width:100%;
    `}
`;
export const EditPasswordContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;
      gap:10px;
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.regular};
  `}
`;
export const EditPasswordInputItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

  `}
`;
export const EditPasswordInputLabel = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

  `}
`;
export const EditPasswordInput = styled(Input.Password) <{ theme?: Theme }>`
  ${({ theme }) => `
      padding:10px 20px;
      outline:0;
      line-height:2;
      border:1px solid ${COLORS.gray};
      width:100%;
  `}
`;

export const ChangeSubScription = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      width:100%;
      padding:24px 40px;
      border:1px solid ${COLORS.gray};
      justify-content:space-between;

      ${theme.breakpoints.down("sm")} {
        padding:24px 19px;
        flex-direction:column;
        gap: 21px;
        justify-content: center;
        text-align: center;
        align-items: center;
      }
  `}
`;
export const ChangeSubScriptionLeftSection = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;
      gap:17px;
      ${theme.breakpoints.down("sm")} {
        gap:12px;

      }
  `}
`;
export const ChangeSubScriptionRightSection = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      align-items:center;
      flex-direction: column;
      justify-content: center;
  `}
`;
export const ChangeSubScriptionLabel = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.h6}px;
    font-family:${FONT_FAMILY.medium};
    ${theme.breakpoints.down("sm")} {
      font-size:${SIZES.body1}px;
     }
  `}
`;
export const ChangeSubScriptionDescription1 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.h5}px;
        font-family:${FONT_FAMILY.medium};
        color:${COLORS.primary};
        ${theme.breakpoints.down("sm")} {
          font-size:${SIZES.h9}px;
         }
  `}
`;
export const ChangeSubScriptionDescription2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    ${theme.breakpoints.down("sm")} {
      font-size:${SIZES.body4}px;
    }
  `}
`;
export const ChangeSubScriptionButton = styled(Button) <{ theme?: Theme }>`
  ${({ theme }) => `
      padding:20px 30px;
      border-radius:20px;
      line-height:2;
      display:flex;
      align-items:center;
      justify-content:center;
      background-color: ${COLORS.black};
      color: ${COLORS.white};
      margin-bottom:3px;
      
        ${theme.breakpoints.down("sm")} {
          padding:15px 25px !important;
          line-height:initial;
        }
  `}
`;
export const CancelSubScriptionButton = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      padding:20px 30px;
      border-radius:20px;
      line-height:2;
      display:flex;
      align-items:center;
      justify-content:center;
      text-decoration-line: underline;
      text-underline-offset: 5px;
      cursor: pointer;

      &:hover {
        color:${COLORS.primary};
      }
      
        ${theme.breakpoints.down("sm")} {
          padding:15px 25px !important;
          line-height:initial;
        }
  `}
`;

export const OKButtonWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width:100%;
      display:flex;
      justify-content:flex-end;
      margin-top:20px;
  `}
`;
export const SaveButton = styled(Button) <{ theme?: Theme }>`
  ${({ theme }) => `
        padding:20px 30px;
        border-radius:20px;
        line-height:2;
        display:flex;
        align-items:center;
        justify-content:center;
  `}
`;
export const EditModalButtonGroup = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      justify-content:end;
      gap:10px;
      margin-top:20px;
  `}
`;
export const EditModalEditBtn = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:4px 15px;
        background-color:white;
        color:black;
        border:1px solid ${COLORS.gray};
        transition:.5s;
        border-radius:5px;
        &:hover {
          color:${COLORS.primary};
          border-color:${COLORS.primary};
        }
  `}
`;
export const EditModalUpdateBtn = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
      padding:4px 15px;
      background-color:${COLORS.primary};
      color:white;
      border:1px solid ${COLORS.primary};
      transition:.5s;
      border-radius:5px;
      &:hover {
        color:${COLORS.primary};
        background-color:white;
      }
  `}
`;
export const RequiredValidates = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    color:${COLORS.red};
    font-size:13px;
   ${theme.breakpoints.down("md")} {
  }

  `}
`;
