import React, { useEffect, useState } from "react";
import {
  CurrentStatusGraphBody,
  CurrentStatusGraphContainer,
  CurrentStatusGraphSubTitle,
  CurrentStatusGraphTitle,
} from "./styles";

import Chart from "react-apexcharts";
import Lock from "./Lock";
import { useAuth } from "../../../../context/AppContext";
import { ClipLoaderWrapper } from "../../../earningPotential/styles";
import ClipLoader from "react-spinners/ClipLoader";
import { COLORS } from "../../../../constants";
import ReportAPI from "../../../../api/report";
import { useParams } from "react-router-dom";
import { expectedRevenueLastTwelveDataParsing } from "../../../../utils/fetch_data";

const CurrentStatusGraph = () => {
  const { apiKey, currentReport } = useAuth();
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [series, setSeries] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);

  const options: any = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    legend: {
      tooltipHoverFormatter: function (val: any, opts: any) {
        return (
          val +
          " - <strong>" +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          "</strong>"
        );
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: "Expected Revenue Over Last Twelve Months",
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "black",
          fontSize: "12px",
          fontFamily: "NunitoSans-Regular",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
      labels: {
        formatter: function (value: any) {
          return "$" + value;
        },
      },
    },

    grid: {
      borderColor: "#f1f1f1",
    },
  };

  const handleGetReportData = async () => {
    setLoading(true);
    try {
      const res = await ReportAPI.getExpectedRevenueLastTwelve(id);
      if (res) {
        let categos = res?.slice(-12).map((item: any) => item.yyyy_mm);
        setSeries(expectedRevenueLastTwelveDataParsing(res?.slice(-12)));
        setLoading(false)
        setCategories(categos);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    if (currentReport) {
      handleGetReportData();
    }
    //eslint-disable-next-line
  }, [currentReport]);

  return (
    <>
      {!apiKey?.plan_type ? (
        <Lock />
      ) : (
        <CurrentStatusGraphContainer className="CurrentStatusGraphContainer">
          <CurrentStatusGraphTitle>
            Expected Revenue Over Last Twelve Months
          </CurrentStatusGraphTitle>
          <CurrentStatusGraphSubTitle>
            City: {currentReport?.city}
          </CurrentStatusGraphSubTitle>
          <CurrentStatusGraphBody className="CurrentStatusGraphBody">
            <Chart options={options} series={series} type="line" height={400} />
          </CurrentStatusGraphBody>

          {loading && (
            <ClipLoaderWrapper className="ClipLoaderWrapper">
              <ClipLoader
                color={COLORS.black}
                loading={loading}
                size={50}
              />
            </ClipLoaderWrapper>
          )}
        </CurrentStatusGraphContainer>
      )}
    </>
  );
};

export default CurrentStatusGraph;
