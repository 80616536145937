import React, { useState } from "react";
import {
  MonthlyLockTitle,
  MonthlyReveue,
  MonthlyReveueButton,
  MonthlyReveueContainer,
  MonthlyReveueGraph,
  MonthlyReveueHeader,
} from "../../styles";
import ApexChartsColumn from "../../../../components/custom/apexChartsColumn";
import {
  LockContainer,
  LockContainerButton,
  LockContainerButtonWrapper,
  LockContainerHeader,
  LockWrapper,
} from "./lockStyle";
import { Modal } from "antd";
import SubScription from "../../../../components/custom/subScription";

const Lock = () => {
  const [showSubScription, setShowSubScription] = useState<boolean>(false);

  const clickHandle = () => {
    setShowSubScription(true);
  };
  const CancelHandle = () => {
    setShowSubScription(false);
  };
  return (
    <MonthlyReveueContainer className="MonthlyReveueContainer">
      {" "}
      <MonthlyLockTitle className="MonthlyLockTitle">
        Annual earnings with seasonality
      </MonthlyLockTitle>
      <MonthlyReveue className="MonthlyReveue">
        <MonthlyReveueHeader className="MonthlyReveueHeader">
          <MonthlyReveueButton className="MonthlyReveueButton"></MonthlyReveueButton>
        </MonthlyReveueHeader>

        <MonthlyReveueGraph className="MonthlyReveueGraph">
          <ApexChartsColumn projectedRevenue={null} totalRevenue={null}></ApexChartsColumn>
        </MonthlyReveueGraph>

        <LockWrapper className="LockWrapper">
          <LockContainer className="LockContainer">
            <LockContainerHeader className="LockContainerHeader">
              Want to unlock even more insights?
            </LockContainerHeader>
            <LockContainerButtonWrapper className="LockContainerButtonWrapper">
              <LockContainerButton
                className="LockContainerButton"
                onClick={clickHandle}
              >
                Upgrade your plan
              </LockContainerButton>
            </LockContainerButtonWrapper>
          </LockContainer>
          <Modal
            open={showSubScription}
            onCancel={CancelHandle}
            width={1200}
            centered
            style={{ overflow: "auto" }}
            footer=""
          >
            <SubScription />
          </Modal>
        </LockWrapper>
      </MonthlyReveue>
    </MonthlyReveueContainer>
  );
};

export default Lock;
