import React, { useEffect, useRef, useState } from "react";
import {
  SidebarItemNestedMain,
  SidebarItemNestedOuter,
  SidebarItemNestedIcon,
  SidebarItemNestedLabel,
  SidebarItemNestedDropDown,
  SidebarItemNestedDropDownSubItem,
  SidebarItemNestedDropDownSubItemLabel,
  SidebarItemNestedExpandIcon,
  SidebarItemNestedContainer,
} from "./styles";
import { TSidebarItemNestedProps } from "./types";
import { ArrowDownIcon } from "../../../../svg";
import { useLocation } from "react-router-dom";
import { TSidebarSubItem } from "../../types";
import { ReportSearchWrapper, SidebarItemNestedWrapper } from "../../styles";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const SidebarItemNested = ({
  icon,
  label,
  items,
  item,
  ...props
}: TSidebarItemNestedProps) => {
  const router = useLocation();
  const prefix = <SearchOutlined style={{}} />;
  const [totalData, setTotalData] = useState<any>([]);
  const [searchedData, setSearchedData] = useState<any>([]);
  const myRef = useRef<null | HTMLDivElement>(null);
  const [showSidebarItemNestedDropDown, setShowSidebarItemNestedDropDown] = useState<boolean>(true);

  const onChange = (val: any) => {
    if (val) {
      let searchedItems = totalData?.filter((ele: any) => ele.label.toLowerCase().includes(val.toLowerCase()));
      setSearchedData(searchedItems);
    } else {
      setSearchedData(totalData);
    }
  };

  useEffect(() => {
    if (items) {
      setTotalData(items);
      setSearchedData(items);
    }
  }, [items])

  const clickHandle = () => {
    setShowSidebarItemNestedDropDown(!showSidebarItemNestedDropDown)
  }

  const active = items.map((x) => x.location).includes("report") || items.map((x) => x.location).includes("marketoverview");
  return (
    <SidebarItemNestedOuter  active={active}  {...props} className="SidebarItemNestedOuter">
      <SidebarItemNestedMain className="sidebar-item-nested-main" onClick={clickHandle}>
        <SidebarItemNestedContainer className="SidebarItemNestedContainer">
          <SidebarItemNestedIcon
            className="sidebar-item-nested-icon"
            src={icon}
          ></SidebarItemNestedIcon>
          <SidebarItemNestedLabel className="sidebar-item-nested-label">
            {label}
          </SidebarItemNestedLabel>
        </SidebarItemNestedContainer>
        <SidebarItemNestedExpandIcon
          expanded={!showSidebarItemNestedDropDown}
          className="sidebar-item-nested-expand-icon"
        >
          <ArrowDownIcon />
        </SidebarItemNestedExpandIcon>
      </SidebarItemNestedMain>

      <SidebarItemNestedDropDown
        expanded={showSidebarItemNestedDropDown}
        className="SidebarItemNestedDropDown"
        ref={myRef}
      >
        <ReportSearchWrapper className="ReportSearchWrapper">
          <Input
            suffix={prefix}
            placeholder="Search..."
            style={{
              border: "none",
              width: "88%",
              borderRadius: 0,
              borderBottom: "1px solid #e7e7e7",
              boxShadow: "none",
              marginBottom: "10px",
            }}
            onChange={(e) => onChange(e.target.value)}
          />
        </ReportSearchWrapper>
        <SidebarItemNestedWrapper className="SidebarItemNestedWrapper">
          {searchedData.map((x: TSidebarSubItem) => (
            <SidebarItemNestedDropDownSubItem
              className="SidebarItemNestedDropDownSubItem"
              to={x.location}
              key={x.id}
            >
              <SidebarItemNestedDropDownSubItemLabel
                className="SidebarItemNestedDropDownSubItemLabel"
                active={router.pathname.includes(x.location)}
              >
                <div className="d-flex justify-content-between">{x.label}</div>
              </SidebarItemNestedDropDownSubItemLabel>
            </SidebarItemNestedDropDownSubItem>
          ))}
        </SidebarItemNestedWrapper>
      </SidebarItemNestedDropDown>
    </SidebarItemNestedOuter>
  );
};

export default SidebarItemNested;
