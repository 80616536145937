import React from "react";
import {
  BackBtnInner,
  DashboardContentBottom,
  ImageLink,
  MobileBackBtn,
  NextBtn,
  NextBtnInner,
} from "./styles";
import { COLORS, ICONS } from "../../../../constants";
import ClipLoader from "react-spinners/ClipLoader";

const BottomButton = (props: any) => {
  const { handleStepInfo, currentstep, disabled, loading } = props;

  const handleBack = () => {

    let nextStep = currentstep - 1;
    if (nextStep < 0) {
      nextStep = 0;
    }
    handleStepInfo(nextStep);
  };

  const handleNext = () => {
    let nextStep = currentstep + 1;

    if (nextStep > 3) {
      nextStep = 3;
    }
    handleStepInfo(nextStep);
  };

  return (
    <DashboardContentBottom className="DashboardContentBottom">
      <ImageLink className="ImageLink">
        <MobileBackBtn
          className="MobileBackBtn"
          onClick={handleBack}
        >
          <BackBtnInner
            className="BackBtnInner"
            src={ICONS.back_arrow}
          />
          Back&nbsp;&nbsp;&nbsp;
        </MobileBackBtn>
        <NextBtn
          className="NextBtn"
          disabled={disabled}
          onClick={handleNext}
        >
          Next&nbsp;&nbsp;&nbsp;
          {
            loading ?
              <ClipLoader color={COLORS.blue} loading={loading} size={15} />
              :
              <NextBtnInner
                className="NextBtnInner"
                src={ICONS.new_next_arrow_black}
              />

          }
        </NextBtn>
      </ImageLink>
    </DashboardContentBottom>
  );
};

export default BottomButton;
