/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  disableSplashScreen,
  enableSplashScreen,
  getToken,
  setToken,
  setUser,
} from "../utils/helper";
import ReactGA from "react-ga4";
import { LayoutSplashScreen } from "../components/core/MetronicSplashScreen";
import AuthorizationAPI from "../api/authorization";
import ReportAPI from "../api/report";
import SubscriptionAPI from "../api/subscription";
import { message } from "antd";
import { TMapCar } from "../api/report/types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { COLORS } from "../constants";

export const AppContext = createContext<any>({});

export const useAuth = () => {
  return useContext(AppContext);
};

export const AppcontextProvider = ({ children }: any) => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [apiKey, setApiKey] = useState<any>(null);
  const [authStep, setAuthStep] = useState<number>(0);
  const [allReports, setAllReports] = useState<any>(null);
  const [currentReport, setCurrentReport] = useState<any>(null);
  const [allPlanTypes, setAllPlanTypes] = useState<any[]>([]);
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [mapCars, setMapCars] = useState<TMapCar[]>([])
  const [mapCarsTop, setMapCarsTop] = useState<TMapCar[]>([])
  const [mapCarsBottom, setMapCarsBottom] = useState<TMapCar[]>([])
  const [loadAllReports, setLoadAllReports] = useState<boolean>(true);
  const [openBigMapStatus, setOpenBigMapStatus] = useState<boolean>(false);
  const [loadingDashboardLayout, setLoadingDashboardLayout] = useState<boolean>(false);
  const [carDemand, setCarDemand] = useState<number>(0);
  const [carRevenue, setCarRevenue] = useState<number>(0);
  const [carPurchasePrice, setCarPurchasePrice] = useState<number>(0);
  const [mapCenter, setMapCenter] = useState<any[]>([]);
  const [detailMap, setDetailMap] = useState<boolean>(false);
  const [apiKeyCheckout, setApiKeyCheckOut] = useState<string>("");
  const [openSubscription, setOpenSubscription] = useState<boolean>(false);
  const [selectedCar, setSelectedCar] = useState<any>(null);

  const [state, setState] = useState<any>({
    user: null,
    initialLoading: true,
    showMobileMenu: false,
    notificationStatus: 0,
  });

  const handleMobileMenu = (value: boolean) => {
    setState((x: any) => ({ ...x, showMobileMenu: value }));
  };

  const handleGetAllReports = async () => {
    try {
      setLoadAllReports(true);
      const token = getToken();
      if (token) {
        const res = await ReportAPI.getAllReports();
        setAllReports(res.reports);
        setLoadAllReports(false);
      }
    } catch (error) { }
  };

  const handleGetAllPlans = async () => {
    try {
      const token = getToken();
      if (token) {
        const res = await SubscriptionAPI.getAllPlans();
        setAllPlanTypes(res.data);
      }
    } catch (error) { }
  };

  const handleGetAllProducts = async () => {
    try {
      const token = getToken();
      if (token) {
        const res = await SubscriptionAPI.getAllProducts();
        setAllProducts(res.res);
      }
    } catch (error) { }
  };

  const handleGetKeyOfMine = async () => {
    try {
      const token = getToken();
      if (token) {
        const res = await SubscriptionAPI.getApiKey();
        if (res) {
          setApiKey(res.apikey);
        } else {
          setApiKey(null);
        }
      }
    } catch (error) {
      setApiKey(null);
    }
  };

  const handlePayment = async (plantype: string) => {
    const selectedProduct = allProducts.find(
      (item: any) => item.title === plantype
    );
    if (selectedProduct) {
      try {
        const res = await SubscriptionAPI.makePaymentRequest(
          selectedProduct.id,
          currentUser.email,
        );
        if (res.url) {
          window.location.replace(res.url);
        }
      } catch (err) {
      } finally {
      }
    }
  }

  const CarReportLink = () => (
    <div>
      You don't have enough car report credits &nbsp;
      <span
        style={{
          color: `${COLORS.primary}`, borderRadius: '3px', padding: '3px', textDecorationLine: 'underline',
          textUnderlineOffset: '5px', cursor: 'pointer'
        }}

        onClick={() => handlePayment("One Car Report")}
      >Buy more credits</span>
    </div>
  );
  const MarketReportLink = () => (
    <div>
      You don't have enough market report credits &nbsp;
      <span
        style={{
          color: `${COLORS.primary}`, borderRadius: '3px', padding: '3px', textDecorationLine: 'underline',
          textUnderlineOffset: '5px', cursor: 'pointer'
        }}
        onClick={() => handlePayment('One Market Report')}
      >Buy more credits</span>
    </div>
  );

  const handleCreateNewReport = async (reportInfo: any) => {
    const newReport = {
      title: `Car Report for ${reportInfo.make} ${reportInfo.model} ${reportInfo.year[0]}-${reportInfo.year[1]}`,
      type: "report",
      city: reportInfo.city,
      make: reportInfo.make,
      model: reportInfo.model,
      year: reportInfo.year,
      lat: parseFloat(reportInfo.lat),
      lng: parseFloat(reportInfo.lng),
    };
    const body = { newReport };
    try {
      const res = await ReportAPI.addReport(body);
      setAllReports((reports: any) => [res.report, ...reports]);
      setCurrentReport(res.report);
      ReactGA.event({
        category: 'Car Report',
        action: 'Create',
        label: `Car Report for ${reportInfo.make} ${reportInfo.model} ${reportInfo.year}`
      });
      setDetailMap(false);
      setApiKey(res.apiKey)
      return res.report;
    } catch (error: any) {
      if (error?.response?.data?.error?.message === "You don't have enough car report credits") {
        toast.error(CarReportLink, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
      else {
        message.error(
          error?.response?.data?.error?.message ?? "Something went wrong!", 5
        );
      }
    }
  };

  const handleSendMail = async (userinfo: any) => {
    const mail = {
      to: "serdabinjonmerda@gmail.com",
      userinfo: userinfo,
    };
    try {
      const res = await ReportAPI.sendMail(mail);
      return res;
    } catch (error) {
      return null;
    }
  };


  const handleCreateNewMarketReport = async (marketInfo: any) => {
    const newReport = {
      title: `Market Report for ${marketInfo.city}`,
      type: "marketoverview",
      lat: parseFloat(marketInfo.lat),
      lng: parseFloat(marketInfo.lng),
      city: marketInfo.city,
    };
    const body = { newReport };
    try {
      const res = await ReportAPI.addReport(body);

      ReactGA.event({
        category: 'Market Report',
        action: 'Create',
        label: `Market Report for ${marketInfo.city}`
      });

      setAllReports((reports: any) => [res.report, ...reports]);
      setCurrentReport(res.report);
      setDetailMap(false);
      setApiKey(res.apiKey)
      return res.report;
    } catch (error: any) {
      if (error?.response?.data?.error?.message === "You don't have enough market report credits") {
        toast.error(MarketReportLink, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
      else {
        message.error(
          error?.response?.data?.error?.message ?? "Something went wrong!", 5
        );
      }
    }
  };

  const handleDeleteReport = async (id: any) => {
    await ReportAPI.deleteReport(id);
    const reports = allReports?.filter((item: any) => item.id !== id);
    setAllReports([...reports]);
  };

  useEffect(() => {
    if (currentUser) {
      handleGetAllReports();
      handleGetAllPlans();
      handleGetAllProducts();
      handleGetKeyOfMine();
    }
  }, [currentUser]);

  const logout = () => {
    setCurrentUser(null);
    setUser("");
    setToken("");
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        currentUser,
        authStep,
        allReports,
        currentReport,
        allPlanTypes,
        allProducts,
        apiKey,
        mapCars,
        mapCarsTop,
        mapCarsBottom,
        loadAllReports,
        openBigMapStatus,
        loadingDashboardLayout,
        carDemand,
        carRevenue,
        carPurchasePrice,
        mapCenter,
        detailMap,
        apiKeyCheckout,
        openSubscription,
        selectedCar,
        setMapCenter,
        logout,
        setCurrentUser,
        handleMobileMenu,
        setAuthStep,
        setAllReports,
        handleCreateNewReport,
        handleDeleteReport,
        setCurrentReport,
        handleCreateNewMarketReport,
        handleGetAllProducts,
        handleGetKeyOfMine,
        setApiKey,
        handleSendMail,
        setMapCars,
        setMapCarsTop,
        setMapCarsBottom,
        setOpenBigMapStatus,
        setLoadingDashboardLayout,
        setCarDemand,
        setCarRevenue,
        setCarPurchasePrice,
        setDetailMap,
        setApiKeyCheckOut,
        setOpenSubscription,
        setSelectedCar
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

type WithChildren = {
  children?: ReactNode;
};

export const AuthInit: FC<WithChildren> = ({ children }) => {
  const { logout, setCurrentUser } = useAuth();
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  const requestUser = async () => {
    try {
      const me = await AuthorizationAPI.me();
      setCurrentUser(me);
    } catch (error) {
      await logout();
    } finally {
      disableSplashScreen();
      setShowSplashScreen(false);
    }
  };

  useEffect(() => {
    enableSplashScreen();
    requestUser();
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};
