import React, { useState, useMemo, useEffect } from "react";
import {
  CompertitorFunction,
  CompertitorPrice,
  CompertitorTips,
  CompetitorItemDescription,
  CompetitorItemImage,
  CompetitorItemImageInner,
  CompetitorRevenue,
  CompetitorRevenueDetail,
  CompetitorRevenueGraph,
  CompetitorRevenueGraphIcon,
  CompetitorRevenueGraphItem,
  CompetitorRevenueShow,
  CompetitorsContainer,
  CompetitorsHeader,
  CompetitorsHeaderTitle,
  CompetitorsHeaderViewAll,
  CompetitorsItem,
  CompetitorsItemList,
  CompetitorsLockTitle,
  Competitorss,
  DescriptionTitle,
  DescriptionTitleGroup,
  DescriptionTitleInner,
  DescriptionTitleStarAndButton,
  GraphItemIcon,
  TipItem,
  TipItemIcon,
  TipItemName,
  TitleStars,
  ViewAllButton,
} from "../../styles";
import { ICONS } from "../../../../constants";
import { Modal, Rate, Tooltip } from "antd";
import MiniLineChart from "../../../../components/custom/miniLineChart";

import { ArrowRightOutlined } from "@ant-design/icons";
import { Award, BarChart2, CreditCard } from "react-feather";
import {
  CompertitorFunctionw,
  CompertitorPricew,
  CompertitorTipsw,
  CompetitorItemDescriptionw,
  CompetitorItemFIrstw,
  CompetitorItemImagew,
  CompetitorRevenueDetailw,
  CompetitorRevenueGraphIconw,
  CompetitorRevenueGraphItemw,
  CompetitorRevenueGraphw,
  CompetitorRevenueShoww,
  CompetitorRevenuew,
  CompetitorsItemListw,
  CompetitorsItemw,
  DescriptionTitleGroupw,
  DescriptionTitleInnerw,
  DescriptionTitleStarAndButtonw,
  DescriptionTitlew,
  GraphItemIconw,
  TipItemw,
  TitleStarsw,
  ViewAllButtonw,
} from "./styles";
import {
  ChartAnnualROIData,
  ChartRateData,
  ChartRevenueData,
} from "../../data";
import {
  LockContainer,
  LockContainerButton,
  LockContainerButtonWrapper,
  LockContainerHeader,
  LockWrapper,
} from "./lockStyle";
import SubScription from "../../../../components/custom/subScription";

const Lock = () => {
  const airport = <span>Airport Pickup Offered</span>;
  const door = <span>Number of Doors</span>;
  const seat = <span>Number of Seats</span>;
  const plan = <span>Insurance Plans</span>;
  const fuel = <span>Fuel Type</span>;

  //eslint-disable-next-line
  const [arrow, setArrow] = useState("Show");
  const [responsiveShow, setResponsiveShow] = useState<boolean>(false);
  const [showSubScription, setShowSubScription] = useState<boolean>(false);

  const clickHandle = () => {
    setShowSubScription(true);
  };
  const CancelHandle = () => {
    setShowSubScription(false);
  };
  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }
    if (arrow === "Show") {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  useEffect(() => {
    if (window.innerWidth < 815) {
      setResponsiveShow(true);
    } else {
      setResponsiveShow(false);
    }
    //eslint-disable-next-line
  }, [window.innerWidth]);

  return (
    <CompetitorsContainer className="CompetitorsContainer">
      {" "}
      <CompetitorsLockTitle className="CompetitorsLockTitle">Your Competitors</CompetitorsLockTitle>
      <Competitorss className="competitors">
        <CompetitorsHeader className="competitors-Header">
          <CompetitorsHeaderTitle className="CompetitorsHeaderTitle">
            Your Competitors
          </CompetitorsHeaderTitle>
          <CompetitorsHeaderViewAll className="CompetitorsHeaderViewAll">
            View all <ArrowRightOutlined />
          </CompetitorsHeaderViewAll>
        </CompetitorsHeader>
        {!responsiveShow && (
          <CompetitorsItemList className="competitors-item-list">
            <CompetitorsItem className="CompetitorsItem">
              <CompetitorItemImage className="CompetitorItemImage">
                <CompetitorItemImageInner
                  src={ICONS.car_GTMustang_Turbo}
                ></CompetitorItemImageInner>
              </CompetitorItemImage>
              <CompetitorItemDescription className="CompetitorItemDescription">
                <DescriptionTitle className="DescriptionTitle">
                  <DescriptionTitleGroup className="DescriptionTitleGroup">
                    <DescriptionTitleInner className="DescriptionTitleInner">
                      GT Mustang Turbo
                    </DescriptionTitleInner>
                    <CompertitorPrice className="CompertitorPrice">
                      $150/day
                    </CompertitorPrice>
                  </DescriptionTitleGroup>
                  <DescriptionTitleStarAndButton className="DescriptionTitleStarAndButton">
                    <TitleStars className="TitleStars">
                      <Rate
                        defaultValue={5}
                        disabled
                        style={{ color: "black", fontSize: "16px" }}
                      />
                    </TitleStars>
                    <ViewAllButton className="ViewAllButton">
                      See It On Turo
                    </ViewAllButton>
                  </DescriptionTitleStarAndButton>
                </DescriptionTitle>

                <CompertitorFunction className="CompertitorFunction">
                  <CompetitorRevenue className="CompetitorRevenue">
                    <CompetitorRevenueDetail className="CompetitorRevenueDetail">
                      <CompetitorRevenueGraphItem className="CompetitorRevenueGraphItem">
                        $22.7k{" "}
                        <CompetitorRevenueShow className="CompetitorRevenueShow">
                          Revenue
                        </CompetitorRevenueShow>
                      </CompetitorRevenueGraphItem>
                      <GraphItemIcon
                        className="GraphItemIcon"
                        style={{ backgroundColor: "#6610f22b" }}
                      >
                        <CreditCard color="#6610f2" />
                      </GraphItemIcon>
                    </CompetitorRevenueDetail>
                    <CompetitorRevenueGraph className="CompetitorRevenueGraph">
                      <CompetitorRevenueGraphIcon className="CompetitorRevenueGraphIcon">
                        <MiniLineChart
                          data={ChartRevenueData}
                          color="#6610f2"
                        />
                      </CompetitorRevenueGraphIcon>
                    </CompetitorRevenueGraph>
                  </CompetitorRevenue>
                  <CompetitorRevenue className="CompetitorRevenue">
                    <CompetitorRevenueDetail className="CompetitorRevenueDetail">
                      <CompetitorRevenueGraphItem className="CompetitorRevenueGraphItem">
                        68%{" "}
                        <CompetitorRevenueShow className="CompetitorRevenueShow">
                          Occupancy
                        </CompetitorRevenueShow>
                      </CompetitorRevenueGraphItem>
                      <GraphItemIcon
                        className="GraphItemIcon"
                        style={{ backgroundColor: "#ff9f434f" }}
                      >
                        <Award color="#ff9f43" />
                      </GraphItemIcon>
                    </CompetitorRevenueDetail>
                    <CompetitorRevenueGraph className="CompetitorRevenueGraph">
                      <CompetitorRevenueGraphIcon className="CompetitorRevenueGraphIcon">
                        <MiniLineChart
                          data={ChartAnnualROIData}
                          color="#ff9f43"
                        />
                      </CompetitorRevenueGraphIcon>
                    </CompetitorRevenueGraph>
                  </CompetitorRevenue>
                  <CompetitorRevenue className="CompetitorRevenue">
                    <CompetitorRevenueDetail className="CompetitorRevenueDetail">
                      <CompetitorRevenueGraphItem className="CompetitorRevenueGraphItem">
                        $91.4k
                        <CompetitorRevenueShow className="CompetitorRevenueShow">
                          Daily Rate
                        </CompetitorRevenueShow>
                      </CompetitorRevenueGraphItem>
                      <GraphItemIcon
                        className="GraphItemIcon"
                        style={{ backgroundColor: "#ea54554d" }}
                      >
                        <BarChart2 color="#ea5455" />
                      </GraphItemIcon>
                    </CompetitorRevenueDetail>
                    <CompetitorRevenueGraph className="CompetitorRevenueGraph">
                      <CompetitorRevenueGraphIcon className="CompetitorRevenueGraphIcon">
                        <MiniLineChart data={ChartRateData} color="#ea5455" />
                      </CompetitorRevenueGraphIcon>
                    </CompetitorRevenueGraph>
                  </CompetitorRevenue>
                </CompertitorFunction>
                <CompertitorTips className="CompertitorTips">
                  <Tooltip
                    placement="topLeft"
                    title={airport}
                    arrow={mergedArrow}
                  >
                    <TipItem className="TipItem">
                      <TipItemIcon
                        src={ICONS.airplane}
                        style={{ width: "25px" }}
                      ></TipItemIcon>
                      <TipItemName>Yes</TipItemName>
                    </TipItem>
                  </Tooltip>
                  <Tooltip placement="topLeft" title={fuel} arrow={mergedArrow}>
                    <TipItem className="TipItem">
                      <TipItemIcon src={ICONS.diesel}></TipItemIcon>
                      <TipItemName>Diesel</TipItemName>
                    </TipItem>
                  </Tooltip>
                  <Tooltip placement="topLeft" title={door} arrow={mergedArrow}>
                    <TipItem className="TipItem">
                      <TipItemIcon src={ICONS.door}></TipItemIcon>
                      <TipItemName>2 Doors</TipItemName>
                    </TipItem>
                  </Tooltip>
                  <Tooltip placement="topLeft" title={seat} arrow={mergedArrow}>
                    <TipItem className="TipItem">
                      <TipItemIcon src={ICONS.seat}></TipItemIcon>
                      <TipItemName>Seats</TipItemName>
                    </TipItem>
                  </Tooltip>
                  <Tooltip placement="topLeft" title={plan} arrow={mergedArrow}>
                    <TipItem className="TipItem">
                      <TipItemIcon src={ICONS.available}></TipItemIcon>
                      <TipItemName>Available</TipItemName>
                    </TipItem>
                  </Tooltip>
                </CompertitorTips>
              </CompetitorItemDescription>
            </CompetitorsItem>
          </CompetitorsItemList>
        )}
        {responsiveShow && (
          <CompetitorsItemListw className="competitors-item-list">
            <CompetitorsItemw className="CompetitorsItem">
              <CompetitorItemFIrstw className="CompetitorItemFIrst">
                <CompetitorItemImagew className="CompetitorItemImage">
                  <CompetitorItemImageInner
                    src={ICONS.car_GTMustang_Turbo}
                  ></CompetitorItemImageInner>
                </CompetitorItemImagew>
                <DescriptionTitlew className="DescriptionTitle">
                  <DescriptionTitleGroupw className="DescriptionTitleGroup">
                    <DescriptionTitleInnerw className="DescriptionTitleInner">
                      GT Mustang Turbo
                    </DescriptionTitleInnerw>
                    <CompertitorPricew className="CompertitorPrice">
                      $150/day
                    </CompertitorPricew>
                  </DescriptionTitleGroupw>
                  <DescriptionTitleStarAndButtonw className="DescriptionTitleStarAndButton">
                    <TitleStarsw className="TitleStars">
                      <Rate
                        defaultValue={5}
                        disabled
                        style={{ color: "black", fontSize: "16px" }}
                      />
                    </TitleStarsw>
                    <ViewAllButtonw className="ViewAllButton">
                      See It On Turo
                    </ViewAllButtonw>
                  </DescriptionTitleStarAndButtonw>
                </DescriptionTitlew>
              </CompetitorItemFIrstw>

              <CompetitorItemDescriptionw className="CompetitorItemDescription">
                <CompertitorFunctionw className="CompertitorFunction">
                  <CompetitorRevenuew className="CompetitorRevenue">
                    <CompetitorRevenueDetailw className="CompetitorRevenueDetail">
                      <CompetitorRevenueGraphItemw className="CompetitorRevenueGraphItem">
                        $22.7k{" "}
                        <CompetitorRevenueShoww className="CompetitorRevenueShow">
                          Revenue
                        </CompetitorRevenueShoww>
                      </CompetitorRevenueGraphItemw>
                      <GraphItemIcon
                        className="GraphItemIcon"
                        style={{ backgroundColor: "#6610f22b" }}
                      >
                        <CreditCard color="#6610f2" />
                      </GraphItemIcon>
                    </CompetitorRevenueDetailw>
                    <CompetitorRevenueGraphw className="CompetitorRevenueGraph">
                      <CompetitorRevenueGraphIconw className="CompetitorRevenueGraphIcon">
                        <MiniLineChart
                          data={ChartRevenueData}
                          color="#6610f2"
                        />
                      </CompetitorRevenueGraphIconw>
                    </CompetitorRevenueGraphw>
                  </CompetitorRevenuew>
                  <CompetitorRevenuew className="CompetitorRevenue">
                    <CompetitorRevenueDetailw className="CompetitorRevenueDetail">
                      <CompetitorRevenueGraphItemw className="CompetitorRevenueGraphItem">
                        68%{" "}
                        <CompetitorRevenueShoww className="CompetitorRevenueShow">
                          Occupancy
                        </CompetitorRevenueShoww>
                      </CompetitorRevenueGraphItemw>
                      <GraphItemIconw
                        className="GraphItemIcon"
                        style={{ backgroundColor: "#ff9f434f" }}
                      >
                        <Award color="#ff9f43" />
                      </GraphItemIconw>
                    </CompetitorRevenueDetailw>
                    <CompetitorRevenueGraphw className="CompetitorRevenueGraph">
                      <CompetitorRevenueGraphIconw className="CompetitorRevenueGraphIcon">
                        <MiniLineChart
                          data={ChartAnnualROIData}
                          color="#ff9f43"
                        />
                      </CompetitorRevenueGraphIconw>
                    </CompetitorRevenueGraphw>
                  </CompetitorRevenuew>
                  <CompetitorRevenuew className="CompetitorRevenue">
                    <CompetitorRevenueDetailw className="CompetitorRevenueDetail">
                      <CompetitorRevenueGraphItemw className="CompetitorRevenueGraphItem">
                        $91.4k
                        <CompetitorRevenueShoww className="CompetitorRevenueShow">
                          Daily Rate
                        </CompetitorRevenueShoww>
                      </CompetitorRevenueGraphItemw>
                      <GraphItemIcon
                        className="GraphItemIcon"
                        style={{ backgroundColor: "#ea54554d" }}
                      >
                        <BarChart2 color="#ea5455" />
                      </GraphItemIcon>
                    </CompetitorRevenueDetailw>
                    <CompetitorRevenueGraphw className="CompetitorRevenueGraph">
                      <CompetitorRevenueGraphIconw className="CompetitorRevenueGraphIcon">
                        <MiniLineChart data={ChartRateData} color="#ea5455" />
                      </CompetitorRevenueGraphIconw>
                    </CompetitorRevenueGraphw>
                  </CompetitorRevenuew>
                </CompertitorFunctionw>
                <CompertitorTipsw className="CompertitorTips">
                  <Tooltip
                    placement="topLeft"
                    title={airport}
                    arrow={mergedArrow}
                  >
                    <TipItemw className="TipItem">
                      <TipItemIcon
                        src={ICONS.airplane}
                        style={{ width: "25px" }}
                      ></TipItemIcon>
                      <TipItemName>Yes</TipItemName>
                    </TipItemw>
                  </Tooltip>
                  <Tooltip placement="topLeft" title={fuel} arrow={mergedArrow}>
                    <TipItemw className="TipItem">
                      <TipItemIcon src={ICONS.diesel}></TipItemIcon>
                      <TipItemName>Diesel</TipItemName>
                    </TipItemw>
                  </Tooltip>
                  <Tooltip placement="topLeft" title={door} arrow={mergedArrow}>
                    <TipItemw className="TipItem">
                      <TipItemIcon src={ICONS.door}></TipItemIcon>
                      <TipItemName>2 Doors</TipItemName>
                    </TipItemw>
                  </Tooltip>
                  <Tooltip placement="topLeft" title={seat} arrow={mergedArrow}>
                    <TipItemw className="TipItem">
                      <TipItemIcon src={ICONS.seat}></TipItemIcon>
                      <TipItemName>Seats</TipItemName>
                    </TipItemw>
                  </Tooltip>
                  <Tooltip placement="topLeft" title={plan} arrow={mergedArrow}>
                    <TipItemw className="TipItem">
                      <TipItemIcon src={ICONS.available}></TipItemIcon>
                      <TipItemName>Available</TipItemName>
                    </TipItemw>
                  </Tooltip>
                </CompertitorTipsw>
              </CompetitorItemDescriptionw>
            </CompetitorsItemw>
          </CompetitorsItemListw>
        )}

        <LockWrapper className="LockWrapper">
          <LockContainer className="LockContainer">
            <LockContainerHeader className="LockContainerHeader">
              Want to unlock even more insights?
            </LockContainerHeader>
            <LockContainerButtonWrapper className="LockContainerButtonWrapper">
              <LockContainerButton
                className="LockContainerButton"
                onClick={clickHandle}
              >
                Upgrade your plan
              </LockContainerButton>
            </LockContainerButtonWrapper>
          </LockContainer>
        </LockWrapper>

        <Modal
          open={showSubScription}
          onCancel={CancelHandle}
          width={1200}
          centered
          style={{ overflow: "auto" }}
          footer=""
        >
          <SubScription />
        </Modal>
      </Competitorss>
    </CompetitorsContainer>
  );
};

export default Lock;
