import styled from '@emotion/styled';
import { Theme } from '@mui/material';

export const PageLayoutMain = styled.div<{ theme?: Theme }>`
${({ theme }) => `
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
    ${theme.breakpoints.down('md')} {
      height: 100%;
      overflow: unset;
    }
  `}
`;

export const PageLayoutContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative;
    & > div {
      margin-top: 150px;
      ${theme.breakpoints.down('md')} {
        margin-top: 50px;
      }
    }
  `}
`;

export const PageLayoutLeft = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    ${theme.breakpoints.up('md')} {
      padding: 0px 2.5% 2.5%;
    }
    ${theme.breakpoints.up('lg')} {
      padding: 0px 7.5% 2.5%;
    }
    ${theme.breakpoints.up('xl')} {
      padding: 0px 7.5% 0px 12.5%; 
    }

    & > div {
      margin-top: 50px;
      ${theme.breakpoints.down('md')} {
        margin-top: 50px;
      }
    }
    `}
    
`;

export const PageLayoutRightContainer = styled.div<{ theme?: Theme, imageUrl?: string }>`
  ${({ theme, imageUrl }) => `
    background-image: url(${imageUrl});
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 100dvh;
    ${theme.breakpoints.down('md')} {
      min-height: 900px;
    }
    ${theme.breakpoints.down('xs')} {
      min-height: 500px;
    }
  `}
`;