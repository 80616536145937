import React from 'react';

const CheckIcon = ({ ...props }) => (
  <svg width="13" height="9" viewBox="0 0 13 9" {...props}>
    <path
      d="M12.48 0.328549C12.1087 -0.0432273 11.5058 -0.0429929 11.134 0.328549L5.07623 6.38655L2.38382 3.69416C2.01204 3.32238 1.4094 3.32238 1.03762 3.69416C0.665845 4.06593 0.665845 4.66857 1.03762 5.04035L4.40299 8.40572C4.58876 8.59149 4.83236 8.68462 5.07597 8.68462C5.31959 8.68462 5.56342 8.59173 5.74919 8.40572L12.48 1.67472C12.8517 1.3032 12.8517 0.700302 12.48 0.328549Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckIcon;
