import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { FONT_FAMILY, SIZES } from "../../../../../constants";

export const TopBottomTableContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        position:relative;
    `}
`;

export const TopBottomTableLockContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        
    `}
`;

export const TopBottomTableLockTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-family:${FONT_FAMILY.medium};
      font-size:${SIZES.body1}px;
      padding:10px;
    `}
`;

