import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  ScatterChartContainer,
  ScatterChartContainerLockTitle,
  ScatterChartContainerLockTitle1,
} from "./styles";
import {
  LockContainer,
  LockContainerButton,
  LockContainerButtonWrapper,
  LockContainerHeader,
  LockWrapper,
} from "./lockStyle";
import { Modal } from "antd";
import { useAuth } from "../../../../context/AppContext";
import { ClipLoaderWrapper } from "../../../earningPotential/styles";
import ClipLoader from "react-spinners/ClipLoader";
import { COLORS } from "../../../../constants";
import ReportAPI from "../../../../api/report";
import { useParams } from "react-router-dom";
import SubScription from "../../../../components/custom/subScription";

const DemandChart = (props: any) => {
  const { apiKey, currentReport } = useAuth();
  const { id } = useParams()
  const { direction, warning, primary, success } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const [highData, setHighData] = useState<any[]>([]);
  const [lowData, setLowData] = useState<any[]>([]);
  const [series, setSeries] = useState<any[]>([]);
  const options: any = {
    chart: {
      zoom: {
        enabled: false,
        type: "xy",
      },
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "start",
    },
    colors: [warning, primary, success],

    xaxis: {
      tickAmount: 10,
      title: {
        text: "MSRP $",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "black",
          fontSize: "12px",
          fontFamily: "NunitoSans-Regular",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
      labels: {
        formatter(value: any) {
          return `$ ${value?.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
      },
    },
    yaxis: {
      oppoyear: direction === "rtl",
      title: {
        text: "Demand(% of time rented)",
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "black",
          fontSize: "12px",
          fontFamily: "NunitoSans-Regular",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },
      labels: {
        formatter(val: any) {
          return "%  " + val * 100;
        },
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

        return (
          '<div class="toolTipContainer">' +
          '<div class="item">' +
          "Model : " +
          data.make +
          "</div>" +
          '<div class="item">' +
          "Make : " +
          data.model +
          "</div>" +
          '<div class="item">' +
          "Year : " +
          data.year +
          "</div>" +
          '<div class="item">' +
          "MSRP : " +
          " $ " +
          data.x +
          "</div>" +
          '<div class="item">' +
          "Demand : " +
          data.y * 100 +
          " % " +
          "</div>" +
          "<divL>"
        );
      },
    },
  };


  const handleGetTopBottomTen = async () => {
    setLoading(true);
    try {
      const res = await ReportAPI.getTopBottomTen(id);
      if (res) {
        let highData: any = []
        let lowData: any = []
        res.forEach((car: any, index: number) => {
          if (car.placement === 'top') {
            const obj = {
              x: car.msrp,
              y: car.portion_rented,
              model: car.model,
              make: car.make,
              year: car.year,
            }
            highData.push(obj)
            setHighData(highData);
          } else if (car.placement === 'bottom') {
            const obj = {
              x: car.msrp,
              y: car.portion_rented,
              model: car.model,
              make: car.make,
              year: car.year,
            }
            lowData.push(obj)
            setLowData(lowData);
          }
        })
        setLoading(false);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    if (currentReport) {
      handleGetTopBottomTen();
    }
    //eslint-disable-next-line
  }, [currentReport]);

  useEffect(() => {
    if (highData && lowData) {
      setSeries([
        {
          name: "High expected profit",
          data: highData
        },
        {
          name: "Low expected profit",
          data: lowData,
        },
      ])
    }
  }, [highData, lowData])

  const [showSubScription, setShowSubScription] = useState<boolean>(false);

  const clickHandle = () => {
    setShowSubScription(true);
  };
  const CancelHandle = () => {
    setShowSubScription(false);
  };


  const lockseries = [
    {
      name: "High expected profit",
      data: [
        {
          x: 23030,
          y: 43,
          model: "Mercedes-Benz",
          make: "make",
          year: "2017",
        },
        {
          x: 41321,
          y: 31,
          model: "Toyota",
          make: "make",
          year: "2019",
        },
        {
          x: 32321,
          y: 23,
          model: "Nissan",
          make: "make",
          year: "2014",
        },
        {
          x: 43242,
          y: 28,
          model: "Kia",
          make: "make",
          year: "2012",
        },
        {
          x: 45621,
          y: 24,
          model: "Toyota",
          make: "make",
          year: "2013",
        },
        {
          x: 54615,
          y: 34,
          model: "Dodge",
          make: "make",
          year: "2019",
        },
        {
          x: 65782,
          y: 36,
          model: "Volkswagen",
          make: "make",
          year: "2015",
        },
        {
          x: 78546,
          y: 31,
          model: "Dodge",
          make: "make",
          year: "2016",
        },
        {
          x: 81055,
          y: 24,
          model: "Fisker",
          make: "make",
          year: "2016",
        },
        {
          x: 156045,
          y: 22,
          model: "Subaru",
          make: "make",
          year: "2017",
        },
      ],
    },
    {
      name: "Low expected profit",
      data: [
        {
          x: 45562,
          y: 26,
          model: "Tesla",
          make: "make",
          year: "2014",
        },
        {
          x: 41321,
          y: 34,
          model: "Jeep",
          make: "make",
          year: "2018",
        },
        {
          x: 59551,
          y: 45,
          model: "Maserati",
          make: "make",
          year: "2011",
        },
        {
          x: 65188,
          y: 34,
          model: "Chevrolet",
          make: "make",
          year: "2018",
        },
        {
          x: 74451,
          y: 41,
          model: "Hyundai",
          make: "make",
          year: "2019",
        },
        {
          x: 45612,
          y: 40,
          model: "FIAT",
          make: "make",
          year: "2011",
        },
        {
          x: 94545,
          y: 28,
          model: "Mercedes-c",
          make: "make",
          year: "2013",
        },
        {
          x: 84515,
          y: 24,
          model: "Honda",
          make: "make",
          year: "2014",
        },
        {
          x: 68845,
          y: 26,
          model: "Audi",
          make: "make",
          year: "2019",
        },
        {
          x: 110545,
          y: 39,
          model: "Scion",
          make: "make",
          year: "2012",
        },
      ],
    },
  ];

  return (
    <>
      {!apiKey?.plan_type ? (
        <>
          <ScatterChartContainerLockTitle className="ScatterChartContainerLockTitle">
            Demand (% of time rented)
          </ScatterChartContainerLockTitle>
          <ScatterChartContainer className="ScatterChartContainer">
            <ScatterChartContainerLockTitle className="ScatterChartContainerLockTitle">
              Demand (% of time rented)
            </ScatterChartContainerLockTitle>
            <ScatterChartContainerLockTitle1 className="ScatterChartContainerLockTitle1">
              City: {currentReport?.city}
            </ScatterChartContainerLockTitle1>
            <Chart
              options={options}
              series={lockseries}
              type="scatter"
              height={400}
            />{" "}
            <LockWrapper className="LockWrapper">
              <LockContainer className="LockContainer">
                <LockContainerHeader className="LockContainerHeader">
                  Want to unlock even more insights?
                </LockContainerHeader>
                <LockContainerButtonWrapper className="LockContainerButtonWrapper">
                  <LockContainerButton
                    className="LockContainerButton"
                    onClick={clickHandle}
                  >
                    Upgrade your plan
                  </LockContainerButton>
                </LockContainerButtonWrapper>
              </LockContainer>
              <Modal
                open={showSubScription}
                onCancel={CancelHandle}
                width={1200}
                centered
                style={{ overflow: "auto" }}
                footer=""
              >
                <SubScription />
              </Modal>
            </LockWrapper>
          </ScatterChartContainer>
        </>
      ) : (
        <ScatterChartContainer className="ScatterChartContainer">
          <ScatterChartContainerLockTitle className="ScatterChartContainerLockTitle">
            Demand (% of time rented)
          </ScatterChartContainerLockTitle>
          <ScatterChartContainerLockTitle1 className="ScatterChartContainerLockTitle1">
            City: {currentReport?.city}
          </ScatterChartContainerLockTitle1>
          <Chart
            options={options}
            series={series}
            type="scatter"
            height={400}
          />{" "}
          {loading && (
            <ClipLoaderWrapper className="ClipLoaderWrapper">
              <ClipLoader
                color={COLORS.black}
                loading={loading}
                size={50}
              />
            </ClipLoaderWrapper>
          )}
        </ScatterChartContainer>
      )}
    </>
  );
};

export default DemandChart;
