import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, SIZES } from "../../../../constants";

export const CurrentStatusGraphContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:10px;
        position:relative;
        font-size:${SIZES.body4}px;
        font-family:${FONT_FAMILY.regular};
        display:flex;
        flex-direction:column;
        gap:10px;
        border:1px solid ${COLORS.gray};
        border-radius:10px;
  `}
`;
export const CurrentStatusGraphTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body1}px;
        font-family:${FONT_FAMILY.medium};
        padding-left:15px;
  `}
`;
export const CurrentStatusGraphSubTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        padding-left:15px;

  `}
`;
export const CurrentStatusGraphBody = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

  `}
`;


export const ScatterChartContainerLockBody = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        padding-top:20px;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const ScatterChartContainerLockTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        
          font-family:${FONT_FAMILY.medium};
          font-size:${SIZES.body1}px;
          padding-left:10px;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const ScatterChartContainerLockTitle1 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        
          font-family:${FONT_FAMILY.regular};
          font-size:${SIZES.body4}px;
          padding-left:10px;

        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;

