import React, { useEffect, useState } from "react";
import SubHeader from "../../components/custom/subHeader";
import {
  DashboardLeftSdie,
  DashboardReportConent,
  DashboardRightSdie,
  MapOpenButton,
  TurboMainContainerBody,
} from "./style";
import { CaretLeftOutlined, CaretRightFilled } from "@ant-design/icons";
import { COLORS } from "../../constants";
import Maps from "../../components/custom/map";
import GoogleMap from "../../pages/earningPotential/components/googleMap";
import ToList from "../../pages/earningPotential/components/toList";
import { ClipLoaderWrapperBig, ClipLoaderWrapperBig2, NoneReportsWrapper } from "../../pages/earningPotential/styles";
import ClipLoader from "react-spinners/ClipLoader";
import { useAuth } from "../../context/AppContext";
import { LockContainer, LockContainerButton, LockContainerButtonWrapper, LockContainerHeader } from "../../components/table/lockStyle";
import { useModal } from "../../hooks";
import { Modal } from "../../components/custom";
import EarningCalulator from "../../components/custom/subHeader/earningCalulator";
import { useLocation } from "react-router-dom";
import SubscriptionAPI from "../../api/subscription";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TurboMainContainer = ({ children, ...props }: any) => {
  const { allReports, loadAllReports, setOpenBigMapStatus, openBigMapStatus, loadingDashboardLayout, apiKey, allProducts, currentUser } = useAuth();
  const [nModal, openNModal, closeNModal] = useModal(false);
  const [isCarReport, setIsCarReport] = useState<boolean>(false);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const handlePayment = async (plantype: string) => {
    const selectedProduct = allProducts.find(
      (item: any) => item.title === plantype
    );
    if (selectedProduct) {
      try {
        const res = await SubscriptionAPI.makePaymentRequest(
          selectedProduct.id,
          currentUser.email,
        );
        if (res.url) {
          window.location.replace(res.url);
        }
      } catch (err) {
      } finally {
      }
    }
  }

  const CarReportLink = () => (
    <div>
      You don't have enough car report credits &nbsp;
      <span
        style={{
          color: `${COLORS.primary}`, borderRadius: '3px', padding: '3px', textDecorationLine: 'underline',
          textUnderlineOffset: '5px', cursor: 'pointer'
        }}

        onClick={() => handlePayment("One Car Report")}
      >Buy more credits</span>
    </div>
  );
  const MarketReportLink = () => (
    <div>
      You don't have enough market report credits &nbsp;
      <span
        style={{
          color: `${COLORS.primary}`, borderRadius: '3px', padding: '3px', textDecorationLine: 'underline',
          textUnderlineOffset: '5px', cursor: 'pointer'
        }}
        onClick={() => handlePayment('One Market Report')}
      >Buy more credits</span>
    </div>
  );

  const handleClick = (val: any) => {
    if (apiKey) {
      if (val === 'car') {
        setIsCarReport(true)
        if (apiKey.total_car_reports > 0) {
          val === 'car' ? setIsCarReport(true) : setIsCarReport(false)
          openNModal();
        } else {
          toast.error(CarReportLink, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        if (apiKey.total_market_reports > 0) {
          val === 'car' ? setIsCarReport(true) : setIsCarReport(false)
          openNModal();
        } else {
          toast.error(MarketReportLink, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      }
    }
  }

  const handleOpenMap = () => {
    setOpenBigMapStatus(!openBigMapStatus);
  };

  const handleChange = (staus: boolean) => {
    setOpenBigMapStatus(staus);
  };

  useEffect(() => {
    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
    //eslint-disable-next-line
  }, [window.innerWidth])

  return (
    <TurboMainContainerBody location={location.pathname === '/report/0'} className="TurboMainContainerBody" >
      {loadAllReports ?
        <ClipLoaderWrapperBig className="ClipLoaderWrapperBig">
          <ClipLoader
            color={COLORS.black}
            loading={loadAllReports}
            size={50}
          />
        </ClipLoaderWrapperBig>
        :
        <>
          {allReports?.length > 0 ?
            <>
              {
                !isMobile && (
                  <SubHeader handleOpenMap={handleOpenMap} openBigMap={openBigMapStatus} />
                )
              }
              <DashboardReportConent className="DashboardReportConent">
                <DashboardLeftSdie
                  className="DashboardLeftSdie"
                  openBigMap={openBigMapStatus}
                  isMobile={isMobile}
                >
                  {children}
                </DashboardLeftSdie>
                <DashboardRightSdie
                  openBigMap={openBigMapStatus}
                  className="DashboardRightSdie"
                >
                  <MapOpenButton className="MapOpenButton" onClick={handleOpenMap}>
                    {openBigMapStatus ? (
                      <CaretRightFilled style={{ color: COLORS.white }} />
                    ) : (
                      <CaretLeftOutlined style={{ color: COLORS.white }} />
                    )}
                  </MapOpenButton>

                  <Maps />
                </DashboardRightSdie>

                {loadingDashboardLayout &&
                  <ClipLoaderWrapperBig className="ClipLoaderWrapperBig">
                    <ClipLoader
                      color={COLORS.black}
                      loading={loadingDashboardLayout}
                      size={50}
                    />
                  </ClipLoaderWrapperBig>
                }

              </DashboardReportConent>

              {openBigMapStatus ? (
                <ToList handleChange={handleChange} />
              ) : (
                <GoogleMap handleChange={handleChange} />
              )}
            </>
            :
            <>
              {
                !loadingDashboardLayout &&
                <NoneReportsWrapper className="NoneReportsWrapper">
                  <LockContainer className="LockContainer">
                    <LockContainerHeader className="LockContainerHeader">
                      There are no reports.
                    </LockContainerHeader>
                    <LockContainerButtonWrapper className="LockContainerButtonWrapper">
                      <LockContainerButton
                        className="LockContainerButton"
                        onClick={() => handleClick("car")}
                      >
                        Create Car Report
                      </LockContainerButton>
                      <LockContainerButton
                        className="LockContainerButton"
                        onClick={() => handleClick('market')}
                      >
                        Create Market Report
                      </LockContainerButton>
                    </LockContainerButtonWrapper>
                  </LockContainer>
                </NoneReportsWrapper>
              }

              {loadingDashboardLayout &&
                <ClipLoaderWrapperBig2 className="ClipLoaderWrapperBig2">
                  <ClipLoader
                    color={COLORS.black}
                    loading={loadingDashboardLayout}
                    size={50}
                  />
                </ClipLoaderWrapperBig2>
              }

              {nModal && (
                <Modal size="small" onClose={closeNModal}>
                  <EarningCalulator closeNModal={closeNModal} isCarReport={isCarReport} />
                </Modal>
              )}
            </>
          }

        </>
      }
    </TurboMainContainerBody>
  );
};

export default TurboMainContainer;
