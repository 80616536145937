import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, SIZES } from "../../../../constants";

export const PersonOwnCarInfoContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width:100%;
      display:flex;
      flex-direction:column;
      gap:10px;
  `}
`;
export const PersonOwnTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.h6}px;
      font-family:${FONT_FAMILY.semibold};
      
      ${theme.breakpoints.down("sm")} {
        font-size:${SIZES.body1}px;
      }
  `}
`;
export const PersonOwnStatus = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.h9}px;
      font-family:${FONT_FAMILY.medium};
      display:flex;
      align-items:center;
      gap:10px;

      ${theme.breakpoints.down("sm")} {
        
      }
  `}
`;
export const PersonOwnCarInfos = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      justify-content:space-between;
      width:100%;

      ${theme.breakpoints.down("sm")} {
          flex-wrap:wrap;
          gap:12px;
      }
  `}
`;
export const OwnCarInfoItemWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      justify-content:space-between;
      width:100%;
  `}
`;
export const OwnCarInfoItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.regular};
      display:flex;
      gap:8px;
      font-weight:600;
      justify-content:space-between;
      align-items:center;
      ${theme.breakpoints.down("sm")} {
        width:44%;
        font-size:${SIZES.body2}px;

    }
  `}
`;
export const OwncarInfoItemSpan = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      color:#818181;
      display:flex;
      align-items:center;
  `}
`;
export const PersonOwnCarFilterOptions = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      border-radius:8px;
      padding:14px 0;
      gap:10px;
      align-items:center;
      ${theme.breakpoints.down("sm")} {
        flex-direction:column;
        padding:0 0 10px 0;
    }

  `}
`;
export const PersonOwnCarInfoItemsWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        gap:10px;
        width:100%;
      ${theme.breakpoints.down("sm")} {
        gap:6px;
        flex-wrap:wrap;
    }
  `}
`;
export const PersonOwnCarInfoRow = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      gap:15px;
  `}
`;
export const PersonOwnCarInfoItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display: flex;
      flex-direction: column;
      gap: 3px;
      width:100%;
      ${theme.breakpoints.down("md")} {
        width:32%;
        justify-content:space-between;
    }
      ${theme.breakpoints.down("sm")} {
        // min-width:69px;
        flex-direction:row;
        width:100%;
    }
  `}
`;
export const PersonOwnCarInfoLabel = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.regular};
      padding-left: 5px;
      ${theme.breakpoints.down("sm")} {
        width:25%;
        display:flex;
        align-items:center;
        font-size:${SIZES.body2}px;
    }
  `}
`;
export const PersonOwnCarInfoSelects = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      gap:3px;
      ${theme.breakpoints.down("sm")} {
        width:75%;
    }
  `}
`;
export const InputWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width:100%;
      display:flex;
      gap:3px;
      height:55px;
      flex-direction:column;
      ${theme.breakpoints.down("sm")} {
        height:initial;
      }
  `}  
`;
export const RequiredValidate = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    color:${COLORS.red};
    font-size:8px;
   ${theme.breakpoints.down("md")} {
  }

  `}
`;
export const SelectOptionButtonWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      justify-content:center;
      gap:15px;
      margin-top:10px;
      ${theme.breakpoints.down("md")} {
    }
      ${theme.breakpoints.down("sm")} {
        width:100%;
        justify-content:flex-end;
    }
  `}
`;

export const SelectOptionButton = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body4}px;
        font-family:${FONT_FAMILY.medium};
        display:flex;
        gap:10px;
        align-items:center;
        justify-content:center;
        background-color: ${COLORS.primary};
        border:1px solid ${COLORS.primary};
        color: white;
        padding:7px 26px;
        border-radius: 18px;
        &:hover {
          background-color: transparent;
          color: ${COLORS.primary};
        }
       ${theme.breakpoints.down("md")} {
       }
       ${theme.breakpoints.down("sm")} {
         padding:4px 16px;
         font-size:${SIZES.body2}px;
          font-family:${FONT_FAMILY.regular};
       }
    `}
`;
export const ClearSelectOptionButton = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body4}px;
        font-family:${FONT_FAMILY.medium};
        display:flex;
        gap:10px;
        align-items:center;
        justify-content:center;
        background-color: ${COLORS.white};
        border:1px solid ${COLORS.primary};
        color: ${COLORS.primary};
        padding:7px 26px;
        border-radius: 18px;
        &:hover {
          background-color: transparent;
          color: ${COLORS.primary};
        }
       ${theme.breakpoints.down("md")} {
       }
       ${theme.breakpoints.down("sm")} {
         padding:4px 16px;
         font-size:${SIZES.body2}px;
          font-family:${FONT_FAMILY.regular};
       }
    `}
`;
export const PersonOwnButtonWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display: flex;
        align-items:end;        
    `}
`;
