import { ICONS } from "../../../../constants";

export const DASHBOARDDATAS = [
    {
      name: "host",
      icon: ICONS.host,
      title: "Host / Aspiring Host",
      hover: ICONS.b_host,
    },
    {
      name: "investor",
      icon: ICONS.investor,
      title: "Investor",
      hover: ICONS.b_investor,
      height: 30,
    },
    {
      name: "car_dealer",
      icon: ICONS.car_dealer,
      title: "Car Dealer",
      hover: ICONS.b_car_dealer,
    },
    {
      name: "bank",
      icon: ICONS.bank,
      title: "Bank or Lender",
      hover: ICONS.b_bank,
    },
    {
      name: "tourism",
      icon: ICONS.tourism,
      title: "Tourism Board",
      hover: ICONS.b_tourism,
      height: 35,
    },
    {
      name: "press",
      icon: ICONS.press,
      title: "Press",
      hover: ICONS.b_press,
      height: 30,
    },
    {
      name: "tellus",
      icon: ICONS.other,
      hover: ICONS.b_other,
      title: "Not Listed here? Tell us!",
      height: 30,
    },
  ];