import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, MSIZES, SIZES } from "../../../../constants";
import { Link } from "react-router-dom";

export const WhatLookingContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display:flex;
        flex-direction:column;
        max-width:1440px;
        padding:40px 25px;

        ${theme.breakpoints.down("md")} {
            margin:initial;
            flex:1;

        }
    `}
`;
export const WhatLookingBackBtn = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display:flex;
    `}
`;
export const WhatLookingBackBtnInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    `}
`;
export const ImageLink = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display: flex;
        flex-direction: row-reverse;
        
        ${theme.breakpoints.down("md")} {
            display:flex;
            justify-content:center;
        }
    `}
`;

export const BackBtnWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        position: absolute;
        left: 55px;
        top: 50px;
        &:hover {
            cursor:pointer;
        }
    `}
`;

export const BackBtn = styled(Link)<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:12px 39px;
        background-color:${COLORS.white_smoke};
        border-radius: 33px;
        display:flex;
        justify-content:center;
        align-items:center;
 
        trainsition:.3s;
        border:1px solid ${COLORS.white_smoke};
        &:hover {
            background-color:transparent;
            color:${COLORS.white_smoke};
            border:1px solid ${COLORS.white_smoke};
            cursor:pointer;

        }
        ${theme.breakpoints.down("md")} {
            display:none;
        }
    `}
`;
export const NextBtn = styled(Link)<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:12px 39px;
        background-color:black;
        border-radius: 33px;
        display:flex;
        justify-content:center;
        align-items:center;
        color:white;
        font-size:${SIZES.body1}px;
        font-family:${FONT_FAMILY.semibold};
        border:1px solid ${COLORS.black};

        &:hover {
            background-color:transparent;
            color:${COLORS.primary};
            border:1px solid ${COLORS.primary};
        }
        ${theme.breakpoints.down("lg")} {
       
            width:100%;
            margin-right:0;
            padding:8px 20px;
            font-size:${SIZES.body1}px;
        }  
    `}
`;
export const NextBtnInner = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        width:30px;
        ${theme.breakpoints.down("md")} {
            width:20px;
        }
        ${theme.breakpoints.down("sm")} {
            width:18px;
        }
    `}
`;

export const BackBtnInner = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        width: 18px;
        ${theme.breakpoints.down("sm")} {
            width:18px;
        }
    `}
`;
export const WhatLookingContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        ${theme.breakpoints.down("lg")} {
            flex-direction:column;
            padding: 40px 25px;
            margin-bottom:initial;
            align-items: center;
            justify-content: center;
        }
        ${theme.breakpoints.down("md")} {
            flex-direction:column;
            padding: 40px 25px;
            margin-bottom:initial;
        }
    `}
`;
export const WhatLookingTiTle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        text-align:center;
        font-size:${SIZES.h5}px;
        font-family:${FONT_FAMILY.semibold};
        width:36%;
        display:flex;
        align-items:center;
        justify-content:center;
        ${theme.breakpoints.down("lg")} {
            width:100%;
            margin-top:0;
            margin-bottom:50px;
        }
        ${theme.breakpoints.down("sm")} {
            width:100%;
            margin-top:0;
            font-size:${MSIZES.h2}px;
            font-family:${FONT_FAMILY.bold};
            margin-bottom:50px;
        }
    `}
`;

export const WhatLookingRightSection = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        position:relative;
        width:63%;
        justify-content:center;
        align-items:center;
        ${theme.breakpoints.down("lg")} {
            width:100%;
        }
        ${theme.breakpoints.down("md")} {
            width:100%;
        }
    `}
`;
export const WhatLookingContentRight = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        position:relative;
        display: flex;
        justify-content: center;
        flex-direction: column;    
        width:100%;
        ${theme.breakpoints.down("lg")} {
            padding-left:0;
            margin-top:0;
            justify-content:initial;
        }
    `}
`;
export const RectangleItemGroup = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        gap:26px;
        display:flex;
        flex-wrap:wrap;
        margin-bottom:50px;
        align-items:center;
        justify-content:center;
        gap:11px;

        ${theme.breakpoints.down("md")} {
            gap:16px;
        }
    `}
`;
export const RectangleItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:160px;
        height:160px;
        border:1px solid ${COLORS.gray};
        border-radius:2px;
        display:flex;
        align-items:center;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        transition: all .3s;
        &:hover {
            color:${COLORS.primary};
            border-color:${COLORS.primary};
            cursor:pointer;
        }
        ${theme.breakpoints.down("md")} {
            width:160px;
            height:160px;
        }
        ${theme.breakpoints.down("sm")} {
            width:117px;
            height:117px;
            align-items:initial;
            padding:27px 9px;
        }
    `}
`;
export const ItemContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%; 
        display:flex;
        flex-direction:column;
        text-align: center;
        align-items: center;
        font-size:${SIZES.h8}px;
        font-family:${FONT_FAMILY.semibold};

        ${theme.breakpoints.down("md")} {
            font-size:${MSIZES.h2}px;
            font-family:${FONT_FAMILY.bold};
            display:flex;
            justify-content:center;
        }
        ${theme.breakpoints.down("sm")} {
            font-size:${MSIZES.body2}px;
            font-family:${FONT_FAMILY.bold};
            display:flex;
            justify-content:center;
        }

    `}
`;
export const ItemImage = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        width:50px;
        margin-bottom:17px;
    `}
`;
export const ItemName = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        transition: all .3s;
    `}
`;
export const InputSpecify = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
        color:#AAAAAA;
        width:100%;
        font-size: ${SIZES.body1}px;
        font-family: ${FONT_FAMILY.regular};
        border-bottom:1px solid #AAAAAA;
        outline:0;
        padding:7px 0;
        margin-bottom:10px;
    `}
`;
export const NumberStatus = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    `}
`;

export const MobileNextButton = styled(Link)<{ theme?: Theme }>`
  ${({ theme }) => `
        display:none;
        ${theme.breakpoints.down("md")} {
            width:100%;
            display:flex;
            align-items:center;
            justify-content:center;
            margin-top:120px;   
        }
    `}
`;
export const MNButton = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    
        ${theme.breakpoints.down("md")} {
            width:82px;
            height:30px;
            display:flex;
            justify-content:space-between;
            align-items:center;

        }
    `}
`;
export const MNBLabel = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    
        ${theme.breakpoints.down("md")} {
            font-size:${MSIZES.body1}px;
            font-family:${FONT_FAMILY.semibold};
            display:flex;
            align-itmes:center;
            justify-content:center;
        }
    `}
`;
export const MNBImage = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
    
        ${theme.breakpoints.down("md")} {
            width:30px;
            height:30px;
            padding:5px;
            background-color:${COLORS.primary};
            border-radius:50%;
        }
    `}
`;

export const ManyCarBackground = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width: fit-content;
        position: absolute;
        bottom:10px;
        left: 32px;
        z-index: 1;

        ${theme.breakpoints.down("lg")} {
            display:none;
        }
    `}
`;

export const ManyCarImg = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        z-index: 1;
        width: 310px;
        height: 267px;
    }
    `}
`;
