import React, { useEffect, useMemo, useState } from "react";

import {
  GoSeeBtnWrapper,
  HostDescriptionContainer,
  HostDescriptionHeader,
  HostDescriptionHeaderLeft,
  HostImage,
  HostImageWrapper,
  HostInfoItem,
  HostInfoItemIcon,
  HostInfoItemIconRank,
  HostInfoItemTitle,
  HostInfoItemValue,
  HostInformations,
  HostName,
  TopTenHostdivBody,
  TopTenHostdivBodyInner,
  TopTenHostsContainerCardList,
  TopTenHostsContainerw,
} from "./styles";
import { COLORS, ICONS } from "../../../../constants";
import "./index.css";
import { Link, useParams } from "react-router-dom";
import {
  ClearSelectOptionButton,
  InputWrapper,
  PersonOwnCarFilterOptions,
  PersonOwnCarInfoItem,
  PersonOwnCarInfoItemsWrapper,
  PersonOwnCarInfoLabel,
  PersonOwnCarInfoSelects,
  RequiredValidate,
  SelectOptionButton,
  SelectOptionButtonWrapper,
} from "../personOwnCarInfo/styles";
import { Input } from "antd";
import Lock from "./Lock";
import { useAuth } from "../../../../context/AppContext";
import { ClipLoaderWrapper, PaginationCompetitors } from "../../../earningPotential/styles";
import ClipLoader from "react-spinners/ClipLoader";
import { Pagination } from "../../../../components/ui";
import { numTothreeComma, removeComma } from "../../../../utils/fetch_data";
import ReportAPI from "../../../../api/report";
import { usePagination } from "../../../../hooks";
import { nFormatter, sortByProperty } from "../../../../utils/helper";
import { TableEmpty } from "../../../../components/table/styles";

const TopTenHosts = (props: any) => {
  const { currentPage } = props;
  const { id } = useParams();
  const { apiKey, currentReport, setMapCars, setMapCarsTop, setMapCarsBottom } = useAuth();
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const sorter = "expec_month_rev";
  const sortOrder = 'desc';
  const [totalData, setTotalData] = useState<any[]>([])

  const [filterValues, setFilterValues] = useState<any>({
    minNoOfCar: '',
    maxNoOfCar: '',
    minEstimatedROI: '',
    maxEstimatedROI: '',
    minMonthlyRevenue: '',
    maxMonthlyRevenue: '',
  });

  const [errors, setErrors] = useState<any>({
    minNoOfCar: '',
    maxNoOfCar: '',
    minEstimatedROI: '',
    maxEstimatedROI: '',
    minMonthlyRevenue: '',
    maxMonthlyRevenue: '',
  });

  const gridStyle: any = {
    width: "100%",
    display: "flex",
    borderRadius: "10px",
  };

  useEffect(() => {
    if (parseInt(filterValues.minNoOfCar) > parseInt(filterValues.maxNoOfCar)) {
      setErrors((info: any) => ({ ...info, minNoOfCar: "Min must be less than Max" }));
      setErrors((info: any) => ({ ...info, maxNoOfCar: "Max must be greater than Min" }));
    } else {
      setErrors((info: any) => ({ ...info, minNoOfCar: "" }));
      setErrors((info: any) => ({ ...info, maxNoOfCar: "" }));
    }
    if (parseInt(filterValues.minEstimatedROI) > parseInt(filterValues.maxEstimatedROI)) {
      setErrors((info: any) => ({ ...info, minEstimatedROI: "Min must be less than Max" }));
      setErrors((info: any) => ({ ...info, maxEstimatedROI: "Max must be greater than Min" }));
    } else {
      setErrors((info: any) => ({ ...info, minEstimatedROI: "" }));
      setErrors((info: any) => ({ ...info, maxEstimatedROI: "" }));
    }
    if (parseInt(filterValues.minMonthlyRevenue) > parseInt(filterValues.maxMonthlyRevenue)) {
      setErrors((info: any) => ({ ...info, minMonthlyRevenue: "Min must be less than Max" }));
      setErrors((info: any) => ({ ...info, maxMonthlyRevenue: "Max must be greater than Min" }));
    } else {
      setErrors((info: any) => ({ ...info, minMonthlyRevenue: "" }));
      setErrors((info: any) => ({ ...info, maxMonthlyRevenue: "" }));
    }
  }, [filterValues])


  const PageSize = 20;
  const { pagesCount, page, setTotalResults, handlePageChange } = usePagination(
    {
      limit: PageSize,
      page: 1,
      onChange: async (params, setPage) => {
        setPage(params.page);
        setTotalResults(totalData?.length);
      },
    }
  );

  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return totalData?.slice(firstPageIndex, lastPageIndex);
  }, [page, totalData, PageSize]);

  const handleGetReportData = async () => {
    setLoadingReport(true);
    try {
      const res = await ReportAPI.getTopOwners(id);
      if (res) {
        const sortedData = sortByProperty<any>(res, sorter, sortOrder);
        const ranked = sortedData.map((item: any, index) => {
          return {
            ...item,
            rank: index + 1
          }
        })
        setTotalData((ranked));
        setTotalResults(res.length);
        setLoadingReport(false)
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    if (currentReport) {
      handleGetReportData();
    }
    // eslint-disable-next-line
  }, [currentReport]);

  useEffect(() => {
    if (currentPage.toString() === "2") {
      setMapCars([]);
      setMapCarsTop([]);
      setMapCarsBottom([]);
    }
    // eslint-disable-next-line
  }, [currentPage])

  const handleChangeFiters = (event: any) => {
    const name = event.target.name;
    const value = (event.target.value ? removeComma(event.target.value) : '');
    if (!Number.isNaN(value)) {
      setFilterValues((data: any) => ({
        ...data,
        [name]: value
      }))
    }
  };


  const handleApply = async () => {
    setLoading(true);
    const value = {
      noCar_range: [parseInt(filterValues.minNoOfCar), parseInt(filterValues.maxNoOfCar)],
      roi_range: [filterValues.minEstimatedROI, filterValues.maxEstimatedROI],
      revenue_range: [filterValues.minMonthlyRevenue, filterValues.maxMonthlyRevenue],
      report_id: id,
    }
    const body = { value }
    try {
      let res = await ReportAPI.getFilterTopTenOwnerData(body);
      if (res) {
        const sortedData = sortByProperty<any>(res, sorter, sortOrder);
        const ranked = sortedData.map((item: any, index) => {
          return {
            ...item,
            rank: index + 1
          }
        })
        setTotalData((ranked));
        setLoading(false);
      }
    } catch (error) {

    }
  }
  const handleClearAllInfo = async () => {
    setFilterValues({
      minNoOfCar: '',
      maxNoOfCar: '',
      minEstimatedROI: '',
      maxEstimatedROI: '',
      minMonthlyRevenue: '',
      maxMonthlyRevenue: '',
    })
    setLoading(true);
    try {
      const res = await ReportAPI.getTopOwners(id);
      if (res) {
        const sortedData = sortByProperty<any>(res, sorter, sortOrder);
        const ranked = sortedData.map((item: any, index) => {
          return {
            ...item,
            rank: index + 1
          }
        })
        setTotalData((ranked));
        setTotalResults(res.length);
        setLoading(false)
      }
    } catch (error) {
    }
  }
  return (
    <>
      {!apiKey?.plan_type ? (
        <Lock />
      ) : (
        <TopTenHostsContainerw className="TopTenHostsContainer">
          <PersonOwnCarFilterOptions className="PersonOwnCarFilterOptions">
            <PersonOwnCarInfoItemsWrapper className="PersonOwnCarInfoItemsWrapper">
              <PersonOwnCarInfoItem className="PersonOwnCarInfoItem">
                <PersonOwnCarInfoLabel className="PersonOwnCarInfoLabel">
                  No. Of Cars
                </PersonOwnCarInfoLabel>
                <PersonOwnCarInfoSelects className="PersonOwnCarInfoSelects">
                  <InputWrapper className="InputWrapper">
                    <Input
                      placeholder="min"
                      name="minNoOfCar"
                      onChange={handleChangeFiters}
                      value={(numTothreeComma((filterValues.minNoOfCar).toString()))}
                      style={{ display: 'flex', alignItems: 'center', height: 39 }}
                    />
                    {errors.minNoOfCar && (
                      <RequiredValidate className="RequiredValidate">
                        {errors.minNoOfCar}
                      </RequiredValidate>
                    )}
                  </InputWrapper>
                  <InputWrapper className="InputWrapper">
                    <Input
                      placeholder="max"
                      name="maxNoOfCar"
                      onChange={handleChangeFiters}
                      value={(numTothreeComma((filterValues.maxNoOfCar).toString()))}
                      style={{ display: 'flex', alignItems: 'center', height: 39 }}
                    />
                    {errors.maxNoOfCar && (
                      <RequiredValidate className="RequiredValidate">
                        {errors.maxNoOfCar}
                      </RequiredValidate>
                    )}
                  </InputWrapper>
                </PersonOwnCarInfoSelects>
              </PersonOwnCarInfoItem>

              <PersonOwnCarInfoItem className="PersonOwnCarInfoItem">
                <PersonOwnCarInfoLabel className="PersonOwnCarInfoLabel">
                  Estimated ROI
                </PersonOwnCarInfoLabel>
                <PersonOwnCarInfoSelects className="PersonOwnCarInfoSelects">
                  <InputWrapper className="InputWrapper">
                    <Input
                      placeholder="min"
                      name="minEstimatedROI"
                      onChange={handleChangeFiters}
                      value={(numTothreeComma((filterValues.minEstimatedROI).toString()))}
                      suffix='%'
                      style={{ display: 'flex', alignItems: 'center', height: 39 }}
                    />
                    {errors.minEstimatedROI && (
                      <RequiredValidate className="RequiredValidate">
                        {errors.minEstimatedROI}
                      </RequiredValidate>
                    )}
                  </InputWrapper>
                  <InputWrapper className="InputWrapper">
                    <Input
                      placeholder="max"
                      name="maxEstimatedROI"
                      onChange={handleChangeFiters}
                      value={(numTothreeComma((filterValues.maxEstimatedROI).toString()))}
                      suffix='%'
                      style={{ display: 'flex', alignItems: 'center', height: 39 }}
                    />
                    {errors.maxEstimatedROI && (
                      <RequiredValidate className="RequiredValidate">
                        {errors.maxEstimatedROI}
                      </RequiredValidate>
                    )}
                  </InputWrapper>
                </PersonOwnCarInfoSelects>
              </PersonOwnCarInfoItem>

              <PersonOwnCarInfoItem className="PersonOwnCarInfoItem">
                <PersonOwnCarInfoLabel className="PersonOwnCarInfoLabel">
                  Monthly Revenue
                </PersonOwnCarInfoLabel>
                <PersonOwnCarInfoSelects className="PersonOwnCarInfoSelects">
                  <InputWrapper className="InputWrapper">
                    <Input
                      placeholder="min"
                      name="minMonthlyRevenue"
                      onChange={handleChangeFiters}
                      value={(numTothreeComma((filterValues.minMonthlyRevenue).toString()))}
                      prefix='$'
                      style={{ display: 'flex', alignItems: 'center', height: 39 }}
                    />
                    {errors.minMonthlyRevenue && (
                      <RequiredValidate className="RequiredValidate">
                        {errors.minMonthlyRevenue}
                      </RequiredValidate>
                    )}
                  </InputWrapper>
                  <InputWrapper className="InputWrapper">
                    <Input
                      placeholder="max"
                      name="maxMonthlyRevenue"
                      onChange={handleChangeFiters}
                      value={(numTothreeComma((filterValues.maxMonthlyRevenue).toString()))}
                      prefix='$'
                      style={{ display: 'flex', alignItems: 'center', height: 39 }}
                    />
                    {errors.maxMonthlyRevenue && (
                      <RequiredValidate className="RequiredValidate">
                        {errors.maxMonthlyRevenue}
                      </RequiredValidate>
                    )}
                  </InputWrapper>
                </PersonOwnCarInfoSelects>
              </PersonOwnCarInfoItem>

            </PersonOwnCarInfoItemsWrapper>

            <SelectOptionButtonWrapper className="SelectOptionButtonWrapper">
              <SelectOptionButton className="selectOptionUpdate" onClick={handleApply}>
                Apply
              </SelectOptionButton>
              <ClearSelectOptionButton
                className="selectOptionUpdate"
                onClick={handleClearAllInfo}
              >
                Clear
              </ClearSelectOptionButton>
            </SelectOptionButtonWrapper>
          </PersonOwnCarFilterOptions>

          {loadingReport ? (
            <TopTenHostsContainerCardList className="TopTenHostsContainerCardList">
        
              {loadingReport && (
                <ClipLoaderWrapper className="ClipLoaderWrapper">
                  <ClipLoader
                    color={COLORS.black}
                    loading={loadingReport}
                    size={50}
                  />
                </ClipLoaderWrapper>
              )}
            </TopTenHostsContainerCardList>
          ) : (
            <TopTenHostsContainerCardList className="TopTenHostsContainerCardList">
              <>
                {
                  currentTableData.length > 0 ?
                    <>
                      {
                        currentTableData?.map((item, index) => (
                          <Link to={`/marketoverview/${id}/${(item.owner_id)}`} key={index}>
                            <TopTenHostdivBody className="TopTenHostdivBody" title="" >
                              <TopTenHostdivBodyInner
                                className="TopTenHostdivBodyInner"
                                style={gridStyle}
                              >
                                <HostImageWrapper className="HostImageWrapper">
                                  <HostImage src={item.image_link}></HostImage>
                                </HostImageWrapper>

                                <HostDescriptionContainer className="HostDescriptionContainer">
                                  <HostDescriptionHeader className="HostDescriptionHeader">
                                    <HostDescriptionHeaderLeft className="HostDescriptionHeaderLeft">
                                      <HostName className="HostName">
                                        {item.name}
                                      </HostName>
                                    </HostDescriptionHeaderLeft>
                                    <GoSeeBtnWrapper className="GoSeeBtnWrapper">
                                    </GoSeeBtnWrapper>
                                  </HostDescriptionHeader>

                                  <HostInformations className="HostInformations">
                                    <HostInfoItem className="HostInfoItem">
                                      <HostInfoItemIcon
                                        className="HostInfoItemIcon"
                                        src={ICONS.carNo}
                                      ></HostInfoItemIcon>
                                      <HostInfoItemTitle className="HostInfoItemTitle">
                                        No. Of Cars
                                      </HostInfoItemTitle>
                                      <HostInfoItemValue className="HostInfoItemValue">
                                        {item?.vehicles?.length}
                                      </HostInfoItemValue>
                                    </HostInfoItem>
                                    <HostInfoItem className="HostInfoItem">
                                      <HostInfoItemIcon
                                        className="HostInfoItemIcon"
                                        src={ICONS.estimatedROI}
                                      ></HostInfoItemIcon>
                                      <HostInfoItemTitle className="HostInfoItemTitle">
                                        Estimated ROI
                                      </HostInfoItemTitle>
                                      <HostInfoItemValue className="HostInfoItemValue">
                                        {item.monthly_roi}%
                                      </HostInfoItemValue>
                                    </HostInfoItem>
                                    <HostInfoItem className="HostInfoItem">
                                      <HostInfoItemIcon
                                        className="HostInfoItemIcon"
                                        src={ICONS.estimatedMon}
                                      ></HostInfoItemIcon>
                                      <HostInfoItemTitle className="HostInfoItemTitle">
                                        Estimated Mon. Revenue
                                      </HostInfoItemTitle>
                                      <HostInfoItemValue className="HostInfoItemValue">
                                        ${nFormatter(item.expec_month_rev, 1)}
                                      </HostInfoItemValue>
                                    </HostInfoItem>
                                    <HostInfoItem className="HostInfoItem">
                                      <HostInfoItemIconRank
                                        className="HostInfoItemIcon"
                                        src={ICONS.rank}
                                      ></HostInfoItemIconRank>
                                      <HostInfoItemTitle className="HostInfoItemTitle">
                                        Rank
                                      </HostInfoItemTitle>
                                      <HostInfoItemValue className="HostInfoItemValue">
                                        {item.rank}
                                        {
                                          item.rank === 1 && "st"
                                        }
                                        {
                                          item.rank === 2 && "nd"
                                        }
                                        {
                                          item.rank === 3 && "rd"
                                        }
                                        {
                                          item.rank > 3 && "th"
                                        }
                                      </HostInfoItemValue>
                                    </HostInfoItem>
                                  </HostInformations>
                                </HostDescriptionContainer>
                              </TopTenHostdivBodyInner>
                            </TopTenHostdivBody>
                          </Link>
                        ))
                      }
                    </>
                    :
                    <TableEmpty>No Data</TableEmpty>

                }
                {loading && (
                  <ClipLoaderWrapper className="ClipLoaderWrapper">
                    <ClipLoader
                      color={COLORS.black}
                      loading={loading}
                      size={50}
                    />
                  </ClipLoaderWrapper>
                )}
                <PaginationCompetitors className="PaginationCompetitors">
                  <Pagination
                    onChange={(_e, x) => handlePageChange(x)}
                    page={page}
                    count={pagesCount}
                  />
                </PaginationCompetitors>
              </>
            </TopTenHostsContainerCardList>
          )}

        </TopTenHostsContainerw>
      )}
    </>
  );
};

export default TopTenHosts;
