import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, MSIZES, SIZES } from "../../../../constants";

export const WhatLookingContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display:flex;
        flex-direction:column;
        max-width:1440px;
        ${theme.breakpoints.down("lg")} {
            margin:initial;
             padding:40px 25px 0 25px;
          }
        ${theme.breakpoints.down("md")} {
            width:100%;
            margin-bottom:25px;
            flex:1;
          }
    `}
`;
export const ImageLink = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display: flex;
        flex-direction: row-reverse;

        ${theme.breakpoints.down("lg")} {
            display: flex;
            padding: 24px 32px;
            -webkit-box-pack: end;
            justify-content: end;
            gap: 32px;
            width: 100%;
            bottom: 0px;
            background-color: rgb(255, 255, 255);
            flex-direction:row;
       
            
        }
        ${theme.breakpoints.down("sm")} {
            
        }
    `}
`;
export const BackBtnWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        position: absolute;
        left: 55px;
        top: 50px;
        &:hover {
            cursor:pointer;
        }
    `}
`;

export const BackBtn = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:12px 39px;
        background-color:${COLORS.white_smoke};
        border-radius: 33px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid ${COLORS.white_smoke};
        &:hover {
            background-color:transparent;
            color:${COLORS.white_smoke};
            border:1px solid ${COLORS.white_smoke};
            cursor:pointer;
        }
        ${theme.breakpoints.down("lg")} {
            display:none;
          }
    `}
`;

export const MobileBackBtn = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:12px 39px;
        background-color:#eeeeee;
        border-radius: 33px;
        display:flex;
        justify-content:center;
        align-items:center;
        color:black;
        font-size:${SIZES.body1}px;
        font-family:${FONT_FAMILY.semibold};
        border: 1px solid rgb(189, 189, 189);
        margin-right:30px;

        &:hover {
            background-color:transparent;
            color:${COLORS.primary};
            border:1px solid ${COLORS.primary};
        }
        ${theme.breakpoints.down("lg")} {
            width:100%;
            margin-right:0;
            padding:8px 20px;
            font-size:16px;
        } 
    `}
`;
export const BackBtnInner = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        width: 18px;
        margin-left: 10px;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }
    `}
`;
export const NextBtnInner = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        width:30px;
        ${theme.breakpoints.down("md")} {
            width:20px;
        }
        ${theme.breakpoints.down("sm")} {
            width:18px;
        }
    `}
`;
export const WhatLookingContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        align-items:center;
        justify-content:center;
        padding:0 27px;

        ${theme.breakpoints.down("lg")} {
            flex-direction:column;
            padding:0 0
        }
        ${theme.breakpoints.down("md")} {
            flex-direction:column;
            padding:0 0
        }
        ${theme.breakpoints.down("md")} {
            max-height:initial;
        }
    `}
`;
export const WhatLookingContentBottom = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display:none;
    ${theme.breakpoints.down("md")} {
        display:flex;
        width:100%;
        flex-direction:column;
        align-items:end;
        border-top:1px solid #dbdbdb;
        position:sticky;
        bottom:0;
    }
    ${theme.breakpoints.down("sm")} {

    }

      
    `}
`;
export const WhatLookingContentBottomInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:64%;
    `}
`;
export const WhatLookingTiTle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        text-align:center;
        font-size:${SIZES.h5}px;
        font-family:${FONT_FAMILY.semibold};
        width:36%;
        ${theme.breakpoints.down("lg")} {
            width:100%;
            margin-bottom:50px;
        }
   
        ${theme.breakpoints.down("sm")} {
            font-size:${MSIZES.h2}px;
            font-family:${FONT_FAMILY.bold};
            margin-top:0;
            width:initial;
            width:100%;
            margin-bottom:50px;
        }
    `}
`;

export const WhatLookingRightSection = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
         align-self: center;

        ${theme.breakpoints.down("lg")} {
            width:100%;
        }
        ${theme.breakpoints.down("sm")} {

        }
    `}
`;
export const WhatLookingContentRight = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
          display: flex;
            flex-direction: column;
            -webkit-box-pack: center;
            justify-content: center;

        ${theme.breakpoints.down("md")} {

        }
        
    `}
`;
export const RectangleItemGroup = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
            display: grid;
            gap: 16px;
            grid-template-columns: repeat(3, 1fr);
            width: 100%;


        ${theme.breakpoints.down("sm")} {
                grid-template-columns: 1fr;

        }
    `}
`;

export const RectangleItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        outline: 0px;
        border: 1px solid;
        margin: 0px;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        font-family: aktiv-grotesk, sans-serif;
        min-width: 64px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-weight: 500;
        font-size: 16px;
        text-transform: none;
        line-height: 22px;
        letter-spacing: 0.4px;
        box-shadow: none;
        border-radius: 32px;
        color: rgb(255, 255, 255);
        display: flex;
        width: 100%;
        -webkit-box-align: center;
        align-items: center;
        align-self: stretch;
        padding: 17px;
        cursor: pointer;
        background-color: rgb(255, 255, 255);
            height: 150px;
            -webkit-box-pack: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 8px;
            text-align: center;
            border-color: rgb(224, 224, 224);

        ${theme.breakpoints.down("sm")} {
            height: 56px;
            -webkit-box-pack: start;
            justify-content: start;
            flex-direction: row;
            border-radius: 4px;
            text-align: left;
            border-color: rgb(189, 189, 189);
        }
    `}
`;
export const ItemContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%; 
        display:flex;
        flex-direction:column;
        text-align: center;
        align-items: center;

        ${theme.breakpoints.down("md")} {
            font-size:${MSIZES.body1}px;
        }
        ${theme.breakpoints.down("sm")} {
            font-size:${MSIZES.body2}px;
        }
    `}
`;
export const ItemImage = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        -webkit-user-select: none;
        user-select: none;
        width: 1em;
        height: 1em;
        display: inline-block;
        fill: currentcolor;
        flex-shrink: 0;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        width:42px;
        height:42px;
        margin-bottom:10px;
        color: rgb(28, 27, 31);
        ${theme.breakpoints.down("md")} {
            font-size: 42px;
            margin-right: 0px;
            display: block;
        }
        ${theme.breakpoints.down("sm")} {
            ont-size: 24px;
            display: block;
            margin-bottom:0;
            width:23px;
            height:23px;
            margin-right:5px;
        }
    `}
`;

export const ItemName = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    margin: 0px;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgb(31, 32, 32);
    `}
`;
export const InputSpecify = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        border-bottom:1px solid #AAAAAA;
        outline:0;
        font-size: ${SIZES.body1}px;
        font-family: ${FONT_FAMILY.regular};
        padding:7px 0;
        margin-bottom:10px;
        margin-top:10px;
        ${theme.breakpoints.down("sm")} {
            font-size:${SIZES.body4}px;
        }
    `}
`;
export const NumberStatus = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        margin-bottom:50px;
        ${theme.breakpoints.down("sm")} {
            margin-bottom:10px;
            font-size:${SIZES.body4}px;
        }
    `}
`;

export const MNButton = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    
        ${theme.breakpoints.down("md")} {
            width:82px;
            height:30px;
            display:flex;
            justify-content:space-between;
            align-items:center;
        }
    `}
`;
export const MNBLabel = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    
        ${theme.breakpoints.down("md")} {
            font-size:${MSIZES.body1}px;
            font-family:${FONT_FAMILY.semibold};
            display:flex;
            align-itmes:center;
            justify-content:center;
        }
    `}
`;
export const MNBImage = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
    
        ${theme.breakpoints.down("md")} {
            width:30px;
            height:30px;
            padding:5px;
            background-color:${COLORS.primary};
            border-radius:50%;
        }
    `}
`;
export const WhatLookingBackground = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width: fit-content;
        position: absolute;
        bottom:10px;
        left: 32px;
        z-index: 1;

        ${theme.breakpoints.down("lg")} {
            display:none;
        }
    `}
`;

export const WhatLookingImg = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        z-index: 1;
        height:24.5vh;
    `}
`;

