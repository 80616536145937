import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, MSIZES, SIZES } from "../../../../constants";
import { Link } from "react-router-dom";

export const MoreInfoContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display:flex;
        flex-direction:column;
        max-width:1440px;
        padding:40px 25px;
        ${theme.breakpoints.down("lg")} {
            padding:41px 25px;
            margin:initial;
            flex:1;

        }
    `}
`;

export const BackBtnWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        position: absolute;
        left: 55px;
        top: 50px;
        &:hover {
            cursor:pointer;
        }
    `}
`;

export const BackBtn = styled(Link)<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:12px 39px;
        background-color:${COLORS.white_smoke};
        border-radius: 33px;
        display:flex;
        justify-content:center;
        align-items:center;
        border:1px solid transparent;
        &:hover {
            background-color:transparent;
            color:${COLORS.white_smoke};
            border:1px solid ${COLORS.white_smoke};
            cursor:pointer;
        }
        ${theme.breakpoints.down("lg")} {
            display:none;
        }
    `}
`;
export const NextBtn = styled(Link)<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:12px 39px;
        background-color:black;
        border-radius: 33px;
        display:flex;
        justify-content:center;
        align-items:center;
        color:white;
        font-size:${SIZES.body1}px;
        font-family:${FONT_FAMILY.semibold};
        margin-right:30px;
        &:hover {
            background-color:transparent;
            color:${COLORS.primary};
            border:1px solid ${COLORS.primary};
        }
        ${theme.breakpoints.down("lg")} {
       
          width:100%;
          margin-right:0;
          padding:8px 20px;
          font-size:${SIZES.body1}px;
      }  
    `}
`;

export const MoreInfoContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        ${theme.breakpoints.down("lg")} {
            flex-direction:column;
            padding:0;
            min-height:initial;
            justify-content:center;
            align-items:center;
            margin-bottom: 38px;
        }
        ${theme.breakpoints.down("md")} {
            flex-direction:column;
            padding:0;
            min-height:initial;
        }
    `}
`;
export const MoreInfoTiTle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        text-align:center;
        font-size:${SIZES.h5}px;
        font-family:${FONT_FAMILY.semibold};
        width:50%;
        display: flex;
        align-items: center;
        justify-content: center;
        ${theme.breakpoints.down("lg")} {
            width:100%;
            margin-bottom:70px;
        }
        ${theme.breakpoints.down("sm")} {
            margin-top:0;
            width:100%;
            font-size:${MSIZES.h2}px;
            font-family:${FONT_FAMILY.bold};
            margin-bottom:48px;
        }
    `}
`;

export const MoreInfoRightInput = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:50%;
        display:flex;
        align-items:center;
        ${theme.breakpoints.down("lg")} {
            width:100%;
        }
        ${theme.breakpoints.down("md")} {
            width:100%;
        }
    `}
`;

export const MoreInfoUpWindow = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        width:100%;
        ${theme.breakpoints.down("md")} {
            width:100%;
            padding:0;
        }
    `}
`;
export const InputUNALN = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
    `}
`;
export const InputFName = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
     padding:13px 18px;
        margin-right:14px;
        border:1px solid #DEDEDE;
        outline:0;
        width:50%;
        border-radius:6px;
        margin:14px 15px 17px 0;
        font-size:${SIZES.body2}px;

    `}
`;
export const InputLName = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
         padding:13px 18px;
        border:1px solid #DEDEDE;
        outline:0;
        width:50%;
        border-radius:6px;
        margin:14px 0 17px 0;
        font-size:${SIZES.body2}px;

    `}
`;
export const InputInfo = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
         padding:13px 18px;
        border:1px solid ${COLORS.black};
        outline:0;
        width:100%;
        border-radius:6px;
        margin:14px 0 0 0;
        font-size:${SIZES.body1}px;
        ${theme.breakpoints.down("sm")} {
            font-size:${MSIZES.body2}px;
            padding:12px 20px;
            margin:0 0 10px 0;
        }
    `}
`;

export const InputPassword = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:13px 18px;
        border:1px solid #DEDEDE;
        outline:0;
        width:100%;
        border-radius:6px;
        margin:14px 0 17px 0;
        font-size:${SIZES.body1}px;

    `}
`;
export const Descrpition = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    font-family:${FONT_FAMILY.regular};
    margin:26px 0 54px;
    color:${COLORS.primary};
    text-align:center;
    `}
  &:hover {
    cursor: pointer;
  }
`;
export const MoreInfoUpBtn = styled(Link)<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    font-family:${FONT_FAMILY.bold};
    color:white;
    padding:10px 30px;
    background-color:black;
    border-radius:20px;
    outline:0;
    text-align:center;
    `}
`;
export const ForgotModal = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    padding:30px;
    color:black;
    `}
`;
export const ModalIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    padding:0 30px 20px 30px;
    width:100%;
    `}
`;

export const Forgot_label1 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    font-family:${FONT_FAMILY.semibold};
    padding:0 30px 10px 30px;
    text-align:center;
    `}
`;

export const Forgot_label2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    font-family:${FONT_FAMILY.regular};
    padding:0 30px;
    `}
`;

export const InputForm = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    padding:30px 0 10px 0;
    `}
`;

export const InputEmails = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    padding:13px 18px;
    border:1px solid #DEDEDE;
    outline:0;
    width:100%;
    border-radius:6px;
    margin:14px 0 17px 0;
    `}
`;

export const SendBtn = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    font-family:${FONT_FAMILY.medium};
    color:white;
    padding:10px 30px;
    background-color:black;
    border-radius:20px;
    outline:0;
    text-align:center;
    margin-top:15px;
    `}
`;

export const MobileNextButton = styled(Link)<{ theme?: Theme }>`
  ${({ theme }) => `
        display:none;
        ${theme.breakpoints.down("md")} {
            width:100%;
            display:flex;
            align-items:center;
            justify-content:center;
        }
    `}
`;
export const MNButton = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    
        ${theme.breakpoints.down("md")} {
            width:82px;
            height:30px;
            display:flex;
            justify-content:space-between;
            align-items:center;

        }
    `}
`;
export const MNBLabel = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    
        ${theme.breakpoints.down("md")} {
            font-size:${MSIZES.body1}px;
            font-family:${FONT_FAMILY.semibold};
            display:flex;
            align-itmes:center;
            justify-content:center;
        }
    `}
`;
export const MNBImage = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
    
        ${theme.breakpoints.down("md")} {
            width:30px;
            height:30px;
            padding:5px;
            background-color:${COLORS.primary};
            border-radius:50%;
        }
    `}
`;

export const MoreInfoBackground = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width: fit-content;
        position: absolute;
        bottom:10px;
        left: 32px;
        z-index: 1;
        ${theme.breakpoints.down("lg")} {
            display:none;
        }

    `}
`;

export const MoreInfoImg = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        z-index: 1;
        width: 371px;
        height: 250px;
    }
    `}
`;

export const MobileBackBtn = styled(Link)<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:12px 39px;
        background-color:#eeeeee;
        border-radius: 33px;
        display:flex;
        justify-content:center;
        align-items:center;
        color:black;
        font-size:${SIZES.body1}px;
        font-family:${FONT_FAMILY.semibold};
        border: 1px solid rgb(189, 189, 189);
        margin-right:30px;

        &:hover {
            background-color:transparent;
            color:${COLORS.primary};
            border:1px solid ${COLORS.primary};
        }
        ${theme.breakpoints.down("lg")} {
      
        } 
    `}
`;
export const BackBtnInner = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        width:18px;
        margin-left: 10px;
        ${theme.breakpoints.down("md")} {
            width:15px;
        }
        ${theme.breakpoints.down("sm")} {
            width:15px;
        }
    `}
`;

export const ImageLink = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display: flex;
        flex-direction: row-reverse;

        ${theme.breakpoints.down("lg")} {
            display: flex;
            padding: 24px 32px;
            -webkit-box-pack: end;
            justify-content: end;
            gap: 32px;
            width: 100%;
            bottom: 0px;
            background-color: rgb(255, 255, 255);
            flex-direction:row;
       
            
        }
        ${theme.breakpoints.down("sm")} {
            
        }
    `}
`;

export const MoreInfoContentBottom = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display:none;
    ${theme.breakpoints.down("lg")} {
        display:flex;
        width:100%;
        flex-direction:column;
        align-items:end;
        border-top:1px solid #dbdbdb;
        position:sticky;
        bottom:0;
    }
    ${theme.breakpoints.down("sm")} {
        position:absolute;
    }

      
    `}
`;

export const NextBtnInner = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        width:30px;
        ${theme.breakpoints.down("md")} {
            width:20px;
        }
        ${theme.breakpoints.down("sm")} {
            width:18px;
        }
    `}
`;
