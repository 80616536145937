import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, MSIZES, SIZES } from "../../constants";
import { Link } from "react-router-dom";

export const LandingIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 40px;
    `}
`;

export const TurboFoxContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 100%;
      display: flex;
      padding: 0;
      flex-direction: column;
    `}
`;
export const TurboFoxTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 50px;
      font-weight: bold;
      font-family:${FONT_FAMILY.bold};
      ${theme.breakpoints.down("md")}{
        font-size: ${SIZES.h4}px;
      }
      ${theme.breakpoints.down("sm")}{
        font-size:${MSIZES.h1}px;
      }

    `}
`;
export const GreenSections = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 100%;
      max-width: 850px;
      text-align: center;
      color: ${COLORS.green};
      justify-content: center;
      ${theme.breakpoints.down("sm")}{
          max-width: 295px !important;
      }

      ${theme.breakpoints.down("md")}{
        max-width: 504px;
    }
    `}
`;
export const GreenSection = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  color: ${COLORS.green};
 
    `}
`;
export const TitleInner = styled.span<{ theme?: Theme }>`
  ${({ theme }) => `
    color: ${COLORS.dark} !important;
  `}
`;
export const EstimateSide = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    `}
`;
export const Estimate = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  font-size: ${SIZES.h2}px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
    `}
`;
export const EstimateInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      max-width: 515px;
      display: flex;
      justify-content: center;
      padding: 30px 0;
      text-align: center;
      font-size:${SIZES.body1}px;
      
      ${theme.breakpoints.down("md")}{
        font-size:${MSIZES.body1}px;
        max-width: 300px;
        padding: 13px 0;
      }
    `}
`;

export const RectangleShape = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
    height: 1020px;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: -1;
    margin-top: -70px;

    ${theme.breakpoints.down("md")}{
      display:none;
    }

    ${theme.breakpoints.down("lg")}{
      height: 1020px;
    }
    `}
`;

export const RectangleShape2 = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
    height: 1020px;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: -1;
    margin-top: 0px;

    ${theme.breakpoints.down("md")}{
      display:none;
    }

    ${theme.breakpoints.down("lg")}{
      height: 1120px;
    }
    `}
`;

export const WindowArticle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 100%;
      padding:0 75px;
      display: flex;
      justify-content: space-between;
      max-width: 1440px;
      margin: auto;
      gap:50px;

      ${theme.breakpoints.down("lg")}{
        padding: 0 30px;
        gap:20px;
        flex-direction: row
      }
      ${theme.breakpoints.down("md")}{
        padding:20px 30px;
        gap:20px;
        flex-direction:column
      }
      ${theme.breakpoints.down("xs")}{
        padding: 10px 15px;
      }

 
    `}
`;
export const Windows = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  width: 50%;
  background-color: ${COLORS.dark};
  height:fit-content;
  border-radius: 12px;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  margin-top: 140px;
  ${theme.breakpoints.down("md")}{
      width:100%;
      padding:20px;
      height:fit-content;
      margin-top: 0px;
    }
    `}
`;

export const WindowTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-family: ${FONT_FAMILY.semibold};
    font-size: ${SIZES.h5}px;
    text-align: center;
    max-width: 350px;
  ${theme.breakpoints.down("xs")}{
       font-size: ${MSIZES.h2}px;
       max-width: 220px;
    }
    `}
`;

export const InfoInput = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 100%;
      margin-top: 24px;
    `}
`;

export const CalculatorSide = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 100%;
      padding:0 75px;
      display: flex;
      justify-content: space-between;
      max-width: 1440px;
      margin: auto;
      gap:50px;
      align-items: center;
      margin-top: 20px;

      ${theme.breakpoints.down("lg")}{
        padding: 0 30px;
        gap:20px;
        flex-direction: row
      }
      ${theme.breakpoints.down("md")}{
        padding:20px 30px;
        gap:20px;
        flex-direction:column
      }
      ${theme.breakpoints.down("xs")}{
        padding: 10px 15px;
      }
    `}
`;

export const CalculatorSide3 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 100%;
      padding:0 75px;
      display: flex;
      justify-content: space-between;
      max-width: 1440px;
      margin: auto;
      gap:50px;
      align-items: center;
      margin-top: 150px;

      ${theme.breakpoints.down("lg")}{
        padding: 0 30px;
        gap:20px;
        flex-direction: row
      }
      ${theme.breakpoints.down("md")}{
        padding:20px 30px;
        gap:20px;
        flex-direction:column;
        margin-top: 30px;
      }
      ${theme.breakpoints.down("xs")}{
        padding: 10px 15px;
      }
    `}
`;

export const Input = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 100%;
    padding: 15px;
    font-family: ${FONT_FAMILY.regular};
    font-size: ${SIZES.body1}px;
    background: ${COLORS.dark};
    border:1px solid ${COLORS.gray} !important;
    &.active{
      border-radius:3px;
    }

    ${theme.breakpoints.down("md")}{
      font-size:${MSIZES.body1}px;
      height: 40px;
    }
`}
`;
export const Article = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 50%;
    padding: 2px;
    font-size:${SIZES.h2}px;
    margin-top: 180px;

    ${theme.breakpoints.down("md")}{
      width:100%;
      font-size:${MSIZES.body1}px;
      margin-top: 0;
    }
`}
`;

export const Article3 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 50%;
    padding: 2px;
    font-size:${SIZES.h2}px;
    // margin-top: 180px;

    ${theme.breakpoints.down("md")}{
      width:100%;
      font-size:${MSIZES.body1}px;
      margin-top: 0;
    }
`}
`;

export const Article2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 50%;
    padding: 2px;
    font-size:${SIZES.h2}px;
    margin-top: 180px;

    ${theme.breakpoints.down("lg")}{
      margin-top: 80px;
    }

    ${theme.breakpoints.down("md")}{
      width:100%;
      font-size:${MSIZES.body1}px;
      margin-top: 0;
    }
`}
`;

export const CalculateInfoInput = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  width: 100%;
  margin-top: 50px;
`}
`;

export const BlueFont = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display:flex;
    gap:10px;
    color: ${COLORS.blue};
    align-items: center;
  `}
`;

export const RedFont = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display:flex;
    gap:10px;
    color: ${COLORS.red};
    align-items: center;
  `}
`;

export const GreenFont = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display:flex;
    gap:10px;
    color: ${COLORS.green};
    align-items: center;
  `}
`;

export const CheckSide = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display: flex;
    margin-top: 24px;
    ${theme.breakpoints.down("md")}{
      margin-top: 15px;
    }
  `}
`;

export const CheckIconImage = styled.img`
  width: 30px;
  height: 30px;
`;

export const Description = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size: ${SIZES.body1}px;
    margin-left: 25px;
     ${theme.breakpoints.down("md")}{
      font-size:${MSIZES.h4}px;
        margin-left: 15px;
    }
   `}
`;
export const CalculateButtons = styled.button<{
  theme?: Theme;
}>`
  ${({ theme }) => `
    color: ${COLORS.dark};
    padding: 12px 0;
    background-color: ${COLORS.light_gray};
    width: 100%;
    border-radius: 8px;
    font-family: ${FONT_FAMILY.medium};
    font-size: ${SIZES.body1}px;
    border: 1px solid ${COLORS.light_gray};
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    ${theme.breakpoints.down("md")}{
      font-size:${MSIZES.h4}px;
      padding: 9px 0;
    }

      &:hover {
      background: ${COLORS.black};
      color: ${COLORS.white};
      }
  `}
`;
export const CalculateButton = styled.button<{
  theme?: Theme;
  disabled: boolean;
}>`
  ${({ theme, disabled }) => `
    color: ${COLORS.dark};
    padding: 12px 0;
    background-color: ${COLORS.light_gray};
    width: 100%;
    border-radius: 8px;
    font-family: ${FONT_FAMILY.medium};
    font-size: ${SIZES.body1}px;
    border: 1px solid ${COLORS.light_gray};
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    &:hover {
      background: ${disabled ? COLORS.light_gray : COLORS.black};
      color: ${disabled ? COLORS.dark : COLORS.white};
    }
    ${theme.breakpoints.down("md")}{
      font-size:${MSIZES.h4}px;
      padding: 9px 0;
    }
  `}
`;

export const ArticleParagraph = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
font-size: 1rem;
    font-weight: bold;
`}
`;

export const CalculatorArticle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    padding: 0 20px;
    width: 50%;
  
    
    ${theme.breakpoints.down("md")}{
      width:100%;
      padding:0 20px;
      font-size:${MSIZES.h2}px;
    }
  `}
`;

export const CalculatorArticleParagraph = styled.div<{ theme?: Theme }>`
  font-size: 24px;
  font-weight: bold;
`;

export const ArticleHeaderParagraph = styled.div<{ theme?: Theme }>`
  font-size: 24px;
  font-weight: bold;
  color: #15e596;
  display: flex;
  gap: 10px;
`;

export const ArticleSide = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

font-size: 16px;
${theme.breakpoints.down("md")}{

  font-size:${MSIZES.h2}px;
}
`}
`;

export const ArticleEnd = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
  margin-top: 34px;
  font-size: 16px;
  ${theme.breakpoints.down("md")}{
  
    font-size:${MSIZES.h2}px;
  }
  `}
`;

export const ArticleItem = styled.div<{ theme?: Theme }>`
  display: flex;
  width: 92%;
  font-size: 16px;
  padding: 0;
  align-items: start;
`;

export const BlueCheck = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
  width: 30px;
  max-width: 30px;
  margin-top: 5px;

    ${theme.breakpoints.down("md")}{
      width: 20px;
      height: 20px;
      }
    `}
`;

export const Img = styled.img<{ theme?: Theme }>`
  width: 100%;
  margin-top: 22px;
`;

export const ImgDescription = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  padding: 11px;
  ${theme.breakpoints.down("md")}{
  font-size:${MSIZES.h2}px;
  }

  `}
`;

export const ImgArticle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
 width: 50%;
  height: 100%;
  margin-top: 200px;
  ${theme.breakpoints.down("md")}{
    display:none;
  }

  `}
`;

export const ImgArticle2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
 width: 50%;
  height: 100%;
  ${theme.breakpoints.down("md")}{
    display:none;
  }

  `}
`;

export const ImgArticleImage = styled.img<{ theme?: Theme }>`
  width: 100%;
`;
export const StyledDiv = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-family: ${FONT_FAMILY.regular};
    font-size: ${SIZES.body1}px;
    margin-top: 28px;
      ${theme.breakpoints.down("md")}{
         font-size: ${MSIZES.body1}px;
           margin-top: 18px;
      }
      ${theme.breakpoints.down("sm")}{
      }
   `}
`;

export const ImgShape2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    object-fit:cover; 

    width: 50%;

    display:block;
  ${theme.breakpoints.down("md")}{
    width:100%;
    display:none;
  }
`}
`;
export const ImgShape2Mobile = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  max-width: 500px;
  display: flex;
  justify-content: center;
  width:100%;
  margin: auto;
  padding: 20px;
   ${theme.breakpoints.up("md")}{
    display:none;
   }
`}
`;

export const RightShapeSide = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
width: 45%;
padding: 20px;
${theme.breakpoints.down("md")}{
  width:100%
}
`}
`;

export const FirstShapeInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  font-size: 32px;
  font-weight: bold;

  
  ${theme.breakpoints.down("md")}{
  font-size:${MSIZES.h2}px;
  }

  `}
`;

export const Shape = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  padding: 11px;
  font-size: 16px;
  margin-top: 20px;
  ${theme.breakpoints.down("md")}{
  font-size:${MSIZES.h2}px;
  }

  `}
`;

export const WhyCarRental = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `


display: flex;
height:fit-content;
margin-bottom:30px;
padding:20px 140px;
${theme.breakpoints.down("lg")}{
  padding:20px 80px;
}
${theme.breakpoints.down("md")}{
  padding:0px 4px;
  gap:20px;
  flex-direction:column-reverse;
}
`}
`;

// export const StyledImg = styled.img`
//   width: 100%;
// `;
export const StyledImg = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
width: 100%;
  max-width: 550px;
  ${theme.breakpoints.down("lg")}{
    max-width: 450px;
  }
  ${theme.breakpoints.down("md")}{
    max-width: 400px;
  }
  `}
`;
export const StyledImg2 = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
width: 100%;
  max-width: 450px;
  ${theme.breakpoints.down("lg")}{
    max-width: 400px;
  }
  ${theme.breakpoints.down("md")}{
    max-width: 300px;
  }
  `}
`;
export const StyledImg3 = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
width: 100%;
  max-width: 575px;
  ${theme.breakpoints.down("lg")}{
    max-width: 550px;
  }
  ${theme.breakpoints.down("md")}{
    max-width: 450px;
  }
  `}
`;

export const ReasonDescription = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
padding: 20px;
width: 50%;
${theme.breakpoints.down("md")}{
  width:100%
}
`}
`;

export const ReasonDescriptionTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  font-size: ${SIZES.h5}px;
  font-family: ${FONT_FAMILY.semibold};
  ${theme.breakpoints.down("md")}{
  font-size:${MSIZES.h2}px;
  }

  `}
`;

export const ReasonDescriptionTitleSpan = styled.span<{ theme?: Theme }>`
  color: ${COLORS.primary};
`;

export const DescriptionImage = styled.div<{ theme?: Theme }>`
  padding: 20px;
  width: 50%;
`;

export const ThinkDescription = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  font-size: 16px;
  ${theme.breakpoints.down("md")}{
  font-size:${MSIZES.h2}px;
  }

  `}
`;

export const CheckIcon = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 30px;
    height: 30px;
    ${theme.breakpoints.down("md")}{
      width: 20px;
      height: 20px;
      }
    `}
`;
export const CheckImage = styled.img<{ theme?: Theme }>`
  width: 50px;
  height: 50px;
`;
export const CheckItem = styled.div<{ theme?: Theme }>`
  display: flex;
  align-items: center;
`;

export const CheckoutDescription = styled.div<{ theme?: Theme }>`
  padding: 10px;
`;

export const CheckIconDescription = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  padding: 10px;
  font-size: 16px;
  ${theme.breakpoints.down("md")}{
  font-size:${MSIZES.h2}px;
  }

  `}
`;

export const DescriptionImageImg = styled.div<{ theme?: Theme }>`
  width: 50%;
  display: block;
  ${({ theme }) => `
display:none;
`}
`;

export const FaqImage = styled.div<{ theme?: Theme }>`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const FaqImageInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

position: absolute;
width: 130px;
height: 130px;
top: -30px;
z-index:300;
${theme.breakpoints.down("sm")}{
  right:20px;
  top:-5px;
  width:90px;
  height:90px;
}
`}
`;

export const TuroFaqs = styled.div<{ theme?: Theme }>`
  margin-top: 30px;
  width: 100%;
  color: black;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffeef4;

  justify-content: center;
`;

export const TuroFaqsTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  font-size: 35px;
  font-family: ${FONT_FAMILY.semibold};
  text-align: center;
  padding-top: 120px;
  ${theme.breakpoints.down("md")}{
  font-size: ${MSIZES.h2}px;
  padding-top: 60px;
  }

  `}
`;

export const Description1 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  font-size: ${SIZES.body1}px;
  display: flex;
  justify-content: center;
  padding: 42px 0 40px 0;
  text-align: center;
  max-width: 700px;
  ${theme.breakpoints.down("md")}{
    padding: 15px 0 20px 0;
    font-size: ${MSIZES.body2}px;
    }
    `}
`;

export const TuroFaqsInner = styled.div<{ theme?: Theme }>`
  width: 100%;
`;

export const Minus = styled.div<{ theme?: Theme }>`
  background: url("../../assets/icons/faq_open.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const HowMoney = styled.div<{ theme?: Theme }>`
  display: flex;
  border-bottom: 1px solid ${COLORS.gray};
  justify-content: center;
  align-items: flex-start;
  padding: 22px;
  max-width: 950px;
  cursor: pointer;
`;

export const HowMoneyInner = styled.div<{ theme?: Theme }>`
  width: 40px;
  height: 5px;
  cursor: pointer;
  margin-top: 2px;
`;

export const HowMoneyInnerInner = styled.div<{ theme?: Theme }>`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 10px;
`;

export const InnerMoney = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size: ${SIZES.body1}px;
    margin-top: 10px;
    ${theme.breakpoints.down("md")}{
      font-size:${MSIZES.body2}px;
      }
    `}
`;

export const Line = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size: 20px;
    font-family: ${FONT_FAMILY.medium};
    color: ${COLORS.blue};
      ${theme.breakpoints.down("md")}{
        font-size:${MSIZES.h3}px;
      }

  `}
`;
export const IncomeDesc = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size: 20px;
    font-family: ${FONT_FAMILY.medium};
    ${theme.breakpoints.down("md")}{
      font-size:${MSIZES.h3}px;
      }
  `}
`;

export const IncomeTuro = styled.div<{ theme?: Theme }>`
  display: flex;
  border-bottom: 1px solid #b0b0b0;
`;

export const CarImage = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  position: relative;
  display: flex;
  max-width: 80%;
  margin: auto;
  margin-top: -130px;
  ${theme.breakpoints.down("xs")}{
   justify-content: center;
  `}
`;

export const CarImage2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-width: 80%;
  margin: auto;
  margin-top: -130px;
  ${theme.breakpoints.down("md")}{
    margin-top: 0px;
    }
  ${theme.breakpoints.down("xs")}{
    margin-top: 0px;
    justify-content: center;
    }
`}
`;

export const CarImageInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 530px;
${theme.breakpoints.down("md")}{
    width: 230px;
  }
`}
`;

export const CarImageInnerImg = styled.img<{ theme?: Theme }>`
  width: 100%;
`;

export const EmptySide = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  border-bottom: 2px solid ${COLORS.gray};
  border-top: 2px solid ${COLORS.gray};
  max-width: 60%;
  margin: auto;
  margin-top: -80px;
  padding: 50px 0px;
  color: ${COLORS.white};

  ${theme.breakpoints.down("md")}{
    margin-top: -30px;
    max-width: 80%;
  }
  `}
`;

export const UnlockVacation = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  font-size: ${SIZES.h5}px;
  text-align: center;
  font-family: ${FONT_FAMILY.medium};
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  ${theme.breakpoints.down("md")}{
    font-size: ${MSIZES.h2}px;
  }
  `}
`;

export const UnlockVacationInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 500px;
    text-align: center;
${theme.breakpoints.down("md")}{
  width:100%
}
`}
`;

export const UnlockColor = styled.span`
  color: ${COLORS.blue};
`;

export const DescriptionAirdna = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  font-size: ${SIZES.body1}px;
  font-family: ${FONT_FAMILY.regular};
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 700px;
  margin: auto;
  ${theme.breakpoints.down("md")}{
    font-size: ${MSIZES.body2}px;
  }
`}
`;

export const ExpBtn = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
  background-color: ${COLORS.red};
  color: white;
  font-size: ${SIZES.body1}px;
  font-family: ${FONT_FAMILY.medium};
  border-radius: 8px;
  padding: 12px 40px;
  cursor: pointer;
  z-index: 100;
  border: 1px solid ${COLORS.red};
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  ${theme.breakpoints.down("md")}{
    font-size: ${MSIZES.body2}px;
  }

  &:hover {
    color: white;
    background-color: transparent;
  }
`}
`;

export const ExploreBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

export const CarImage1 = styled.div`
  position: relative;
  padding: 60px 0px;
`;

export const CarImage1Inner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
width: 500px;
height: 300px;
position: absolute;
right: -60px;
bottom: -200px;
${theme.breakpoints.down("md")}{
  display:flex;
  max-width:100%;
  
  justify-content:center;
  align-items-center;
  
  bottom:-200px;
  right:0;
}
`}
`;

export const SignupNewsletter = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 100%;
      background-color: ${COLORS.white};
      height: fit-content;
      margin-top: -40px;
      padding: 40px;
      ${theme.breakpoints.down("md")}{
        margin-top: 50px;
        padding:10px;
      }
`}
`;

export const Unlockhosting = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 100%;
      background-color: ${COLORS.black};
      height: fit-content;
      margin-top: -40px;
      padding: 40px;
      ${theme.breakpoints.down("md")}{
        margin-top: 50px;
        padding:10px;
      }
`}
`;

export const SignupNewsletterInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  display:flex;
  justify-content:space-between;
  max-width: 1440px;
  margin: auto;
  width:100%;
  ${theme.breakpoints.down("lg")}{
    // display: grid;
    // grid-template-columns: repeat(2, minmax(0, 1fr));
    // gap:30px;
  }
  ${theme.breakpoints.down("sm")}{
  //   display: grid;
  //   gap:20px;
  // flex-direction:column;
  // grid-template-columns: repeat(1, minmax(0, 1fr));

  }
  `}
`;

export const FirstSection = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  min-width:350px;
${theme.breakpoints.down("md")}{
}
`}
`;

export const FirstSectionTitleInputWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  display:flex;
  flex-direction:column;
  width:100%;
  justify-content:space-between;
  ${theme.breakpoints.down("sm")}{
    flex-direction:column;
  }
  `}
`;
export const FirstSectionTitleWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display:flex;
    flex-direction:column;
  ${theme.breakpoints.down("md")}{
  }
  `}
`;
export const FirstSeTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  font-size: ${SIZES.h5}px;
  font-family: ${FONT_FAMILY.bold};
  ${theme.breakpoints.down("md")}{
    font-size: ${MSIZES.h2}px;
  }
  `}
`;

export const FirstSeDes = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  font-size: ${SIZES.body4}px;
  font-family: ${FONT_FAMILY.medium};
  padding: 7px 0 30px 0;
  min-width:267px;
   ${theme.breakpoints.down("md")}{
    font-size: ${MSIZES.body2}px;
  }
  `}
`;

export const FirstSeEmailInput = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
border: 1px solid #9f9f9f;
border-radius: 50px;
outline: none;
display: flex;
padding: 3px 3px 3px 0px;
color: black;
height: 45px;
min-width: 350px;
background-color: #ffffff;
justify-content:space-between;
${theme.breakpoints.down("md")}{
  // width:100%;
  // min-width:100%;
  // height: 40px;
}
`}
`;

export const FirstSeFooterFirst = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  font-size: ${MSIZES.body1}px;
  font-family: ${FONT_FAMILY.medium};
  padding: 10px 0;
  ${theme.breakpoints.down("md")}{
    font-size: ${SIZES.body2}px;
  }
  `}
`;
export const EmailInptWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display:flex;
    flex-direction:column;
  ${theme.breakpoints.down("md")}{
  }
  `}
`;
export const EmailInput = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
  flex: 1;
  border-radius: 50px;
  padding-left: 20px;
  outline: none;
  font-size: ${SIZES.body4}px;
  font-family: ${FONT_FAMILY.regular};
  ${theme.breakpoints.down("md")}{
    font-size: ${MSIZES.body2}px;
  }
  `}
`;
export const SubscribeButton = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
    border-radius: 50px;
    background-color: black;
    color: white;
    padding: 4px 20px;
    font-size: ${SIZES.body4}px;
    font-family: ${FONT_FAMILY.regular};
    border: 1px solid ${COLORS.black};
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    ${theme.breakpoints.down("md")}{
      font-size: ${MSIZES.body2}px;
    }
    &:hover {
      color: ${COLORS.black};
      background: transparent;
    }
  `}
`;

export const SecondSection = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `


${theme.breakpoints.down("lg")}{
  margin-left:0;
}
`}
`;

export const MobileSection = styled.a<{ theme?: Theme }>`
  ${({ theme }) => `
  diplay:flex;
  justify-content: center;
  ${theme.breakpoints.down("md")}{
  }
  `}
`;

export const SecondItem = styled.a<{ theme?: Theme }>`
  ${({ theme }) => `
  text-decoration: none;
  padding: 5px 10px;
  width: 230px;
  font-family: ${FONT_FAMILY.regular};
  font-size: ${MSIZES.body2}px;
  display: block;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  ${theme.breakpoints.down("lg")}{
    font-size: ${MSIZES.body3}px;
  }
  &:hover {
    color: ${COLORS.light_blue};
  }
  `}
`;
export const BoldItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

  padding: 10px;
  font-family: ${FONT_FAMILY.bold};
  font-size: ${SIZES.body1}px;
  ${theme.breakpoints.down("lg")}{
    font-size: ${MSIZES.body2}px;
  }
  `}
`;

export const ReallyEndSection = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display: flex;
    width: 100%;
    font-size: ${MSIZES.body2}px;
    font-family: ${FONT_FAMILY.regular};
    justify-content: space-between;
    // max-width: 1440px;
    // margin: auto;
    padding:16px 10px;
    align-items:center;
    border-top: 1px solid ${COLORS.black};
    ${theme.breakpoints.down("sm")}{
      flex-direction:column;
      align-items:initial;
      gap:4px;
       padding:10px 10px;
    }
  `}
`;

export const EndF = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

${theme.breakpoints.down("md")}{
}
`}
`;

export const EndE = styled.a<{ theme?: Theme }>`
  ${({ theme }) => `
  font-family: ${FONT_FAMILY.semibold};
${theme.breakpoints.down("md")}{
    font-size:${SIZES.body2}px;
}
`}
`;
export const EndEList = styled.a<{ theme?: Theme }>`
  ${({ theme }) => `
    font-family: ${FONT_FAMILY.semibold};
    ${theme.breakpoints.down("md")}{
    font-size:${SIZES.body2}px;
    }
  `}
`;
export const FooterFlexSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Image = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
  width: 100%;
  max-width: 400px;
  ${theme.breakpoints.down("md")}{
    max-width: 350px;
  }
  ${theme.breakpoints.down("sm")}{
    max-width: 250px;
  }
  `}
`;

export const CImage = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
    max-width: 350px;
    ${theme.breakpoints.down("lg")}{
      max-width: 250px;
    }
    ${theme.breakpoints.down("md")}{
      max-width: 200px;
    }
    `}
`;
export const Paragraph = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size: ${SIZES.h5}px;
    font-family: ${FONT_FAMILY.semibold};
    display: block;
    ${theme.breakpoints.down("md")}{
      font-size:20px;
      display: none;
    }
`}
`;

export const Paragraph3 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size: ${SIZES.h5}px;
    font-family: ${FONT_FAMILY.semibold};
    display: block;
    ${theme.breakpoints.down("md")}{
      font-size: ${MSIZES.h2}px;
    }
`}
`;

export const Paragraph2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size: ${SIZES.h5}px;
    font-family: ${FONT_FAMILY.semibold};
    display: block;
    ${theme.breakpoints.down("md")}{
      font-size:20px;
    }
`}
`;

export const ParagraphMobile = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size: ${SIZES.h5}px;
    font-family: ${FONT_FAMILY.semibold};
    display: none;
    ${theme.breakpoints.down("md")}{
      font-size: ${MSIZES.h2}px;
      display: block;
      margin-top: 40px;
    }
`}
`;
export const Row = styled.div`
  display: flex;
  gap: 20px;
  padding 10px 0;
  align-items: center;
`;

export const FooterIconRow = styled.div`
  display: flex;
  gap: 10px;
`;
export const FooterIcon = styled(Link)`
    display: flex;
    align-items: center;
`;

export const TuroContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    background-color: #ffeef4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-bottom: 100px;
`}
`;

export const EndEMobile = styled.div``;

export const EndF2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

  padding:  20px 0;
  font-size:${MSIZES.body3}px;
  ${theme.breakpoints.up("md")}{
    display:none
  }
`}
`;

export const EndE2 = styled.a<{ theme?: Theme }>`
  ${({ theme }) => `
    color:black;
    padding: 20px 0;
    font-family: ${FONT_FAMILY.semibold};
    font-size:${MSIZES.body3}px;
    ${theme.breakpoints.up("md")}{
      display:none
    }
`}
`;


export const Flex = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  display: flex;
  align-items: center;
  gap:10px;
  ${theme.breakpoints.up("md")}{
  }

`}
`;
export const HiddenSmall = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  display:none;
  ${theme.breakpoints.up("xl")}{
   display:block;

  }

`}
`;

export const RectangleShapeImage = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
  background: url('../../assets/images/Rectangle2.png');
  height: 140%;
  width: 100%;
  position: absolute;
 
  left: 0;
  top: -150px;
  z-index: -30;
  ${theme.breakpoints.down("md")}{
    top: 20px;
 display:none;
  }
    `}
`;

export const SmallRectangleShapeImage = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
 
  display:none;
  ${theme.breakpoints.down("md")}{
    top: 20px;
    display:block;
    
    z-index: -30;
    height: 150%;
    width: 100%;
    position: absolute;
    left: 0;
    top: -15px;
  }
    `}
`;

export const SmallRectangleShapeImage1 = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
 
    display:none;
  ${theme.breakpoints.down("md")}{
    display:block;
    z-index: -30;
    height: 800px;
    width: 100%;
    position: absolute;
    left: 0;
    // top: 120px;
  }
    `}
`;

export const SmallRectangleShapeImage2 = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
 
    display:none;
  ${theme.breakpoints.down("md")}{
    display:block;
    z-index: -30;
    height: 1200px;
    width: 100%;
    position: absolute;
    left: 0;
    // top: 120px;
  }
    `}
`;

export const FlexBetween = styled.div`
  display: flex;

  justify-content: space-between;
  min-width: 500px;
`;

export const CarIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 30px;
    height: 30px;
    ${theme.breakpoints.down("md")}{
      width:20px;
      height:20px;
    }
  `}
`;

export const RedSpan = styled.span`
  color: ${COLORS.red};
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const OpenImage = styled.img`
  width: 20px;
  cursor: pointer;
    0.2s;
`;
export const PlusMinus = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-top: 2px;

  &.active {
    &:before {
      transform: translateY(-50%) rotate(-90deg);
      opacity: 0;
    }
    &:after {
      transform: translateY(-50%) rotate(0);
    }
  }

  &:before,
  &:after {
    content: "";
    display: block;
    background-color: #333;
    position: absolute;
    top: 50%;
    left: 0;
      0.35s;
    width: 100%;
    height: 3px;
  }

  &:before {
    transform: translateY(-50%);
  }

  &:after {
    transform: translateY(-50%) rotate(90deg);
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const InputContainerYear = styled.div`
  display:flex;
  flex-direction:column;
`;
export const InputYearWrapper = styled.div`
  display:flex;
  width:100%;
  align-items:center;
  border-bottom:1px solid #E3E3E3;
  padding-bottom:10px;
  gap:6px;
`;

export const InputYearWrapper2 = styled.div`
  display:flex;
  width:100%;
  flex-direction: column;
`;

export const StyledInput = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
  width: 100%;
  padding: 10px 10px;
  border-bottom: 1px solid ${COLORS.gray};
  background: ${COLORS.dark};
  outline: none;
  font-family: ${FONT_FAMILY.regular};
  font-size: ${SIZES.body1}px;  

    ${theme.breakpoints.down("md")}{
      font-size:${MSIZES.body1}px;
      height: 40px;
    }
  `}
`;

export const InputIcon = styled.div`
  width: 20px;
  color: black;
  margin-right: 20px;
`;
export const InputIcon2 = styled.div`
  width: 30px;
  color: black;
  margin-right: 20px;
`;
export const InputIconYear = styled.div`
  width: 20px;
  color: black;
  top: 10px;
`;
