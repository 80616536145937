import React, { forwardRef } from 'react';
import { CardMain } from './styles';
import { TCardProps, TCardRef } from './types';

const Card = forwardRef<TCardRef, TCardProps>(
  ({ animation = 'none', ...props }, ref) => (
    <CardMain animation={animation} ref={ref} {...props} />
  )
);

export default Card;
