import React, { useState } from "react";
import {
  EditModalButtonGroup,
  EditModalEditBtn,
  EditModalUpdateBtn,
  EditPasswordContainer,
  EditPasswordInput,
  EditPasswordInputItem,
  EditPasswordInputLabel,
  RequiredValidates,
} from "../styles";
import { Alert, message } from "antd";
import ClipLoader from "react-spinners/ClipLoader";
import { COLORS } from "../../../constants";
import { validateEightNumber } from "../../../utils/authValidation";
import { useAuth } from "../../../context/AppContext";
import AuthorizationAPI from "../../../api/authorization";

const EditPassword = (props: any) => {
  const { handleCancel, setIsModalOpen } = props;

  const { currentUser } = useAuth();
  const [userinfo, setUserInfo] = useState<any>({
    currentPassword: "",
    password: "",
    passwordConfirmation: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errors, setErrors] = useState<any>({
    currentPassword: "",
    password: "",
    passwordConfirmation: "",
  });
  const UpdatePassword = async () => {
    setErrorMsg("");
    if (!userinfo.currentPassword) {
      setErrors((info: any) => ({
        ...info,
        currentPassword: "Current Password is required",
      }));
    }
    if (!userinfo.password) {
      setErrors((info: any) => ({ ...info, password: "Password is required" }));
    }
    if (!userinfo.passwordConfirmation) {
      setErrors((info: any) => ({
        ...info,
        passwordConfirmation: "Confirm Password is required",
      }));
    }
    if (currentUser.provider === 'local') {
      if (!validateEightNumber(userinfo.currentPassword)) {
        return;
      }
    }
    if (!validateEightNumber(userinfo.password)) {
      return;
    }
    if (!validateEightNumber(userinfo.passwordConfirmation)) {
      return;
    }
    setLoading(true);

    const values = {
      currentPassword:
        currentUser.provider === "google"
          ? "google"
          : userinfo.currentPassword,
      password: userinfo.password,
      passwordConfirmation: userinfo.passwordConfirmation,
    };

    AuthorizationAPI.changePassword(values)
      .then((data: any) => {
        setIsModalOpen(false);
        message.success(`Your password is changed successfully`);
      })
      .catch((error) => {
        setErrorMsg(
          error?.response?.data?.error?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event: any) => {
    const field = event.target.name;
    const value = event.target.value;
    setUserInfo((info: any) => ({ ...info, [field]: value }));
    switch (field) {
      case "currentPassword":
        if (!validateEightNumber(value)) {
          setErrors((info: any) => ({
            ...info,
            currentPassword: "Password must be at least eight characters",
          }));
        } else {
          setErrors((info: any) => ({ ...info, currentPassword: "" }));
        }
        break;
      case "password":
        if (!validateEightNumber(value)) {
          setErrors((info: any) => ({
            ...info,
            password: "Password must be at least eight characters",
          }));
        } else {
          setErrors((info: any) => ({ ...info, password: "" }));
        }
        break;
      case "passwordConfirmation":
        if (!validateEightNumber(value)) {
          setErrors((info: any) => ({
            ...info,
            passwordConfirmation: "Password must be at least eight characters",
          }));
        } else {
          setErrors((info: any) => ({ ...info, passwordConfirmation: "" }));
        }
        break;

      default:
        break;
    }
  };

  return (
    <EditPasswordContainer className="EditPasswordContainer">
      {errorMsg ? (
        <Alert
          className="alert_error"
          message={errorMsg}
          type="error"
          closable
          afterClose={() => setErrorMsg("")}
        />
      ) : null}
      <EditPasswordInputItem className="EditPasswordInputItem">
        {currentUser.provider === "local" && (
          <>
            <EditPasswordInputLabel className="EditPasswordInputLabel">
              Old Password
            </EditPasswordInputLabel>
            <EditPasswordInput
              className="EditcurrentPasswordInput"
              name="currentPassword"

              value={userinfo.currentPassword}
              onChange={handleChange}
            ></EditPasswordInput>
            {errors.currentPassword && (
              <RequiredValidates className="RequiredValidates">
                {errors.currentPassword}
              </RequiredValidates>
            )}
          </>
        )}
      </EditPasswordInputItem>
      <EditPasswordInputItem>
        <EditPasswordInputLabel>New Password</EditPasswordInputLabel>
        <EditPasswordInput
          className="EditpasswordInput"
          name="password"
          value={userinfo.password}
          onChange={handleChange}
        ></EditPasswordInput>
        {errors.password && (
          <RequiredValidates className="RequiredValidates">
            {errors.password}
          </RequiredValidates>
        )}
      </EditPasswordInputItem>
      <EditPasswordInputItem>
        <EditPasswordInputLabel>Confirm New Password</EditPasswordInputLabel>
        <EditPasswordInput
          className="EditConfrimPasswordInput"
          name="passwordConfirmation"
          value={userinfo.passwordConfirmation}
          onChange={handleChange}
        ></EditPasswordInput>
        {errors.passwordConfirmation && (
          <RequiredValidates className="RequiredValidates">
            {errors.passwordConfirmation}
          </RequiredValidates>
        )}
      </EditPasswordInputItem>

      <EditModalButtonGroup className="EditModalButton">
        <EditModalEditBtn
          className="EditModalEditBtn"
          onClick={handleCancel}
        >
          Cancel
        </EditModalEditBtn>
        <EditModalUpdateBtn
          className="EditModalUpdateBtn"
          onClick={UpdatePassword}
        >
          {loading ? (
            <ClipLoader color={COLORS.blue} loading={loading} size={20} />
          ) : (
            "Update"
          )}
        </EditModalUpdateBtn>
      </EditModalButtonGroup>
    </EditPasswordContainer>
  );
};

export default EditPassword;
