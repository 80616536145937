import React, { createContext, useContext, useEffect } from 'react'
import { enableSplashScreen } from '../../utils/helper'

const LayoutContext = createContext<any>({

})


const LayoutProvider = ({ children }: any) => {

  const setLayout = (_themeConfig: Partial<any>) => {
    enableSplashScreen()
    const bodyClasses = Array.from(document.body.classList)
    bodyClasses.forEach((cl) => document.body.classList.remove(cl))
    setTimeout(() => {
      // disableSplashScreen()
    }, 500)
  }
  const value: any = {
    setLayout,
  }

  useEffect(() => {
    // disableSplashScreen()
  }, [])

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export { LayoutContext, LayoutProvider }

export function useLayout() {
  return useContext(LayoutContext)
}
