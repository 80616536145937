import React, { useEffect, useState } from "react";
import {
  ButtonSectionItem,
  ImageHiddenSection,
  ImageRestSection,
  ImageRestSectionDes,
  ImageSectionDescItem,
  ImageSectionItem,
  ItemPeriod,
  ItemPrice,
  ItemPriceSpan,
  ItemTilte,
  ItemTilteExplore,
  ItemTitleIcon,
  SubScribeButtonWrapper,
  SubScriptionContainer,
  SubScriptionContent,
  SubScriptionContentButton,
  SubScriptionContentImageSection,
  SubScriptionContentImageSectionSecond,
  SubScriptionHeader,
} from "./styles";

import { CheckOutlined } from "@ant-design/icons";
import {
  FeatureTableItemColumn,
  FeatureTableItemFirst,
  FeatureTableItemNext,
  FeaturesTableItem,
  MyListingFeaturesTable,
  MyListingFeaturesTitle,
  TableItemIcon,
} from "../myListingFeatures/styles";
import { COLORS, ICONS, IMAGES } from "../../../constants";
import "./subScription.css";
import SubscriptionAPI from "../../../api/subscription";
import { useAuth } from "../../../context/AppContext";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation } from "react-router-dom";
import { setCheckoutOrigin } from "../../../utils/helper";
import { Modal } from "antd";
import ContactModal from "./contactModal";

const SubScription = () => {
  const { currentUser, allProducts, apiKey, setApiKey } = useAuth();
  const location = useLocation();
  const [showDescription, setShowDescription] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading1, setLoading1] = useState<boolean>(false);
  const [loading2, setLoading2] = useState<boolean>(false);
  const [loading3, setLoading3] = useState<boolean>(false);
  //eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (window.innerWidth < 900) {
      setShowDescription(false);
    } else {
      setShowDescription(true);
    }
    //eslint-disable-next-line
  }, [window.innerWidth]);

  // eslint-disable-next-line
  const handleGetInTouch = () => {
    setShowDescription(false);
    setShowModal(true);
  };

  const handleOk = () => {
    setShowModal(false);
  };
  const handleCancel = () => {
    setShowModal(false);
  };

  const handlePayment = async (plantype: string) => {
    localStorage.setItem("apiKeyCheckout", apiKey?.checkout_session);
    const selectedProduct = allProducts.find(
      (item: any) => item.title === plantype
    );
    if (selectedProduct) {
      try {
        switch (plantype) {
          case "Basic Plan":
            setLoading1(true);
            break;
          case "Pro Plan":
            setLoading2(true);
            break;
          case "Ultimate Plan":
            setLoading3(true);
            break;

          default:
            break;
        }
        setCheckoutOrigin(location.pathname);
        const res = await SubscriptionAPI.makePaymentRequest(
          selectedProduct.id,
          currentUser.email,
        );
        if (res.url) {
          window.location.replace(res.url);
        }
        setLoading1(false);
        setLoading2(false);
        setLoading3(false);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading1(false);
        setLoading2(false);
        setLoading3(false);
      }
    }
  };

  const handleFreePlan = async () => {
    if (apiKey) {
      setLoading(true);
      try {
        const res = await SubscriptionAPI.changeToFreePlan(apiKey.id);
        setApiKey(res.apikey);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <SubScriptionContainer className="SubScriptionContainer">
      <SubScriptionHeader>Subscriptions Options</SubScriptionHeader>
      <SubScriptionContent className="SubScriptionContent">
        <SubScriptionContentImageSection className="SubScriptionContentImageSection">
          <ImageHiddenSection className="ImageRestSection"></ImageHiddenSection>
          <ImageRestSection className="ImageRestSection">
            <ImageSectionItem className="ImageSectionItem">
              <ItemTilte className="ItemTilte">
                <ItemTitleIcon className="ItemTitleIcon" src={IMAGES.free} />
                Free
              </ItemTilte>
              <ItemPrice className="ItemPrice">
                $0 <ItemPriceSpan className="ItemPriceSpan">/mo</ItemPriceSpan>
              </ItemPrice>
              <ItemPeriod className="ItemPeriod">Free Forever</ItemPeriod>
            </ImageSectionItem>
            <ImageSectionItem className="ImageSectionItem">
              <ItemTilte className="ItemTilte">
                <ItemTitleIcon className="ItemTitleIcon" src={IMAGES.basic} />
                Basic
              </ItemTilte>
              <ItemPrice className="ItemPrice">
                $15 <ItemPriceSpan className="ItemPriceSpan">/mo</ItemPriceSpan>
              </ItemPrice>
              <ItemPeriod className="ItemPeriod">Billed annually</ItemPeriod>
            </ImageSectionItem>
            <ImageSectionItem className="ImageSectionItem">
              <ItemTilte className="ItemTilte">
                <ItemTitleIcon className="ItemTitleIcon" src={IMAGES.pro} />
                Pro
              </ItemTilte>
              <ItemPrice className="ItemPrice">
                $25 <ItemPriceSpan className="ItemPriceSpan">/mo</ItemPriceSpan>
              </ItemPrice>
              <ItemPeriod className="ItemPeriod">Billed annually</ItemPeriod>
            </ImageSectionItem>
            <ImageSectionItem className="ImageSectionItem">
              <ItemTilte className="ItemTilte">
                <ItemTitleIcon className="ItemTitleIcon" src={IMAGES.premium} />
                Ultimate
              </ItemTilte>
              <ItemPrice className="ItemPrice">
                $149 <ItemPriceSpan className="ItemPriceSpan">/mo</ItemPriceSpan>
              </ItemPrice>
              <ItemPeriod className="ItemPeriod">Billed annually</ItemPeriod>
            </ImageSectionItem>
            {/* <ImageSectionItem className="ImageSectionItem">
              <ItemTilte className="ItemTilte">
                <ItemTitleIcon className="ItemTitleIcon" src={IMAGES.premium} />
                Premium
              </ItemTilte>
              <ItemPrice className="ItemPrice">
                <ItemPriceSpans className="ItemPriceSpan">
                  Get in touch
                </ItemPriceSpans>
              </ItemPrice>
              <ItemPeriod className="ItemPeriod"></ItemPeriod>
            </ImageSectionItem> */}
          </ImageRestSection>
        </SubScriptionContentImageSection>
        <SubScriptionContentImageSectionSecond className="SubScriptionContentImageSection">
          <ImageHiddenSection className="ImageHiddenSection"></ImageHiddenSection>
          <ImageRestSectionDes className="ImageRestSectionDes">
            <ImageSectionDescItem className="ImageSectionDescItem">
              <ItemTilteExplore className="ItemTilte">
                Get a peek into your market and start building confidence.
              </ItemTilteExplore>
            </ImageSectionDescItem>
            <ImageSectionDescItem className="ImageSectionDescItem">
              <ItemTilteExplore className="ItemTilte">
                Jumpstart your portfolio and begin revenue growth
              </ItemTilteExplore>
            </ImageSectionDescItem>
            <ImageSectionDescItem className="ImageSectionDescItem">
              <ItemTilteExplore className="ItemTilte">
                Expand strategically for maximized earnings and comprehensive
                market awareness.
              </ItemTilteExplore>
            </ImageSectionDescItem>
            <ImageSectionDescItem className="ImageSectionDescItem">
              <ItemTilteExplore className="ItemTilte">
                Optimize revenue and dominate multiple markets with unmatched
                insights.
              </ItemTilteExplore>
            </ImageSectionDescItem>
          </ImageRestSectionDes>
        </SubScriptionContentImageSectionSecond>
        <SubScriptionContentButton className="SubScriptionContentButton">
          <ImageHiddenSection className="ImageHiddenSection"></ImageHiddenSection>
          <ImageRestSection className="ImageRestSection">
            <ButtonSectionItem className="ButtonSectionItem">
              <SubScribeButtonWrapper
                className="SubScribeButtonWrapper"
                onClick={() => handleFreePlan()}
              >
                {loading ? (
                  <ClipLoader
                    color={COLORS.blue}
                    loading={loading}
                    size={20}
                  />
                ) : (
                  <>
                    {!apiKey?.plan_type || apiKey?.plan_type?.name === "Free Plan" ? (
                      <>
                        {showDescription ? <CheckOutlined /> : ""}
                        {showDescription ? "Your Current Plan" : "Current"}
                      </>
                    ) : (
                      <>{showDescription ? "Subscribe" : "Select"}</>
                    )}
                  </>
                )}
              </SubScribeButtonWrapper>
            </ButtonSectionItem>
            <ButtonSectionItem className="ButtonSectionItem">
              <SubScribeButtonWrapper
                className="SubScribeButtonWrapper"
                onClick={() => handlePayment("Basic Plan")}
              >
                {loading1 ? (
                  <ClipLoader
                    color={COLORS.blue}
                    loading={loading1}
                    size={20}
                  />
                ) : (
                  <>
                    {apiKey?.plan_type?.name === "Basic Plan" ? (
                      <>
                        {showDescription ? <CheckOutlined /> : ""}
                        {showDescription ? "Your Current Plan" : "Current"}
                      </>
                    ) : (
                      <>{showDescription ? "Subscribe" : "Select"}</>
                    )}
                  </>
                )}
              </SubScribeButtonWrapper>
            </ButtonSectionItem>
            <ButtonSectionItem className="ButtonSectionItem">
              <SubScribeButtonWrapper
                className="SubScribeButtonWrapper"
                onClick={() => handlePayment("Pro Plan")}
              >
                {loading2 ? (
                  <ClipLoader
                    color={COLORS.blue}
                    loading={loading2}
                    size={20}
                  />
                ) : (
                  <>
                    {apiKey?.plan_type?.name === "Pro Plan" ? (
                      <>
                        {showDescription ? <CheckOutlined /> : ""}
                        {showDescription ? "Your Current Plan" : "Current"}
                      </>
                    ) : (
                      <>{showDescription ? "Subscribe" : "Select"}</>
                    )}
                  </>
                )}
              </SubScribeButtonWrapper>
            </ButtonSectionItem>
            <ButtonSectionItem className="ButtonSectionItem">
              <SubScribeButtonWrapper
                className="SubScribeButtonWrapper"
                onClick={() => handlePayment("Ultimate Plan")}
              >
                {loading3 ? (
                  <ClipLoader
                    color={COLORS.blue}
                    loading={loading3}
                    size={20}
                  />
                ) : (
                  <>
                    {apiKey?.plan_type?.name === "Ultimate Plan" ? (
                      <>
                        {showDescription ? <CheckOutlined /> : ""}
                        {showDescription ? "Your Current Plan" : "Current"}
                      </>
                    ) : (
                      <>{showDescription ? "Subscribe" : "Select"}</>
                    )}
                  </>
                )}
              </SubScribeButtonWrapper>
            </ButtonSectionItem>
            {/* <ButtonSectionItem className="ButtonSectionItem">
              <SubScribeButtonWrapper
                className="SubScribeButtonWrapper"
                onClick={handleGetInTouch}
              >
                {loading3 ? (
                  <ClipLoader
                    color={COLORS.blue}
                    loading={loading3}
                    size={20}
                  />
                ) : (
                  <>
                    {apiKey?.plan_type?.name === "Premium Plan" ? (
                      <>
                        {showDescription ? <CheckOutlined /> : ""}
                        {showDescription ? "Your Current Plan" : "Current"}
                      </>
                    ) : (
                      <>{showDescription ? "Subscribe" : "Select"}</>
                    )}
                  </>
                )}
              </SubScribeButtonWrapper>
            </ButtonSectionItem> */}
          </ImageRestSection>
        </SubScriptionContentButton>
        <MyListingFeaturesTitle></MyListingFeaturesTitle>
        <MyListingFeaturesTable className="MyListingFeaturesTable">
          <FeaturesTableItem className="FeaturesTableItem">
            <FeatureTableItemFirst className="FeatureTableItemFirst">
              Car Reports
            </FeatureTableItemFirst>
            <FeatureTableItemNext className="FeatureTableItemNext">
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                1 (Limited)
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                5
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                10
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                80
              </FeatureTableItemColumn>
            </FeatureTableItemNext>
          </FeaturesTableItem>
          <FeaturesTableItem className="FeaturesTableItem">
            <FeatureTableItemFirst className="FeatureTableItemFirst">
              Market Reports
            </FeatureTableItemFirst>
            <FeatureTableItemNext className="FeatureTableItemNext">
              <FeatureTableItemColumn className="FeatureTableItemColumn"></FeatureTableItemColumn>

              <FeatureTableItemColumn className="FeatureTableItemColumn">
                2
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                4
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                30
              </FeatureTableItemColumn>
            </FeatureTableItemNext>
          </FeaturesTableItem>
          <FeaturesTableItem className="FeaturesTableItem">
            <FeatureTableItemFirst className="FeatureTableItemFirst">
              Seasonality of Earnings
            </FeatureTableItemFirst>
            <FeatureTableItemNext className="FeatureTableItemNext">
              <FeatureTableItemColumn className="FeatureTableItemColumn"></FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
            </FeatureTableItemNext>
          </FeaturesTableItem>
          <FeaturesTableItem className="FeaturesTableItem">
            <FeatureTableItemFirst className="FeatureTableItemFirst">
              Insights on Rental Demand
            </FeatureTableItemFirst>
            <FeatureTableItemNext className="FeatureTableItemNext">
              <FeatureTableItemColumn className="FeatureTableItemColumn"></FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
            </FeatureTableItemNext>
          </FeaturesTableItem>
          <FeaturesTableItem className="FeaturesTableItem">
            <FeatureTableItemFirst className="FeatureTableItemFirst">
              Competitor Cars
            </FeatureTableItemFirst>
            <FeatureTableItemNext className="FeatureTableItemNext">
              <FeatureTableItemColumn className="FeatureTableItemColumn"></FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
            </FeatureTableItemNext>
          </FeaturesTableItem>
          <FeaturesTableItem className="FeaturesTableItem">
            <FeatureTableItemFirst className="FeatureTableItemFirst">
              Comprehensive Extras Analysis
            </FeatureTableItemFirst>
            <FeatureTableItemNext className="FeatureTableItemNext">
              <FeatureTableItemColumn className="FeatureTableItemColumn"></FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
            </FeatureTableItemNext>
          </FeaturesTableItem>
          <FeaturesTableItem className="FeaturesTableItem">
            <FeatureTableItemFirst className="FeatureTableItemFirst">
              Top Hosts & Portfolio Analysis
            </FeatureTableItemFirst>
            <FeatureTableItemNext className="FeatureTableItemNext">
              <FeatureTableItemColumn className="FeatureTableItemColumn"></FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
            </FeatureTableItemNext>
          </FeaturesTableItem>
          <FeaturesTableItem className="FeaturesTableItem">
            <FeatureTableItemFirst className="FeatureTableItemFirst">
              Top Performer Insights
            </FeatureTableItemFirst>
            <FeatureTableItemNext className="FeatureTableItemNext">
              <FeatureTableItemColumn className="FeatureTableItemColumn"></FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                <TableItemIcon src={ICONS.subscriptioonCheck}></TableItemIcon>
              </FeatureTableItemColumn>
            </FeatureTableItemNext>
          </FeaturesTableItem>
          <FeaturesTableItem className="FeaturesTableItem">
            <FeatureTableItemFirst className="FeatureTableItemFirst">
              Users
            </FeatureTableItemFirst>
            <FeatureTableItemNext className="FeatureTableItemNext">
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                1
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                1
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                1
              </FeatureTableItemColumn>
              <FeatureTableItemColumn className="FeatureTableItemColumn">
                1
              </FeatureTableItemColumn>
            </FeatureTableItemNext>
          </FeaturesTableItem>
        </MyListingFeaturesTable>
      </SubScriptionContent>

      <Modal
        title="Speak with a specialist"
        open={showModal}
        className='ContactModalBody'
        onOk={handleOk}
        onCancel={handleCancel}
        footer=""
        centered
        style={{ padding: '30px' }}
      >
        <ContactModal contactLoading={setLoading3} showModal={setShowModal} />
      </Modal>
    </SubScriptionContainer>
  );
};

export default SubScription;
