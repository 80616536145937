import React, { useState, useMemo, useEffect } from "react";
import {
  ClipLoaderWrapper,
  CompertitorFunction,
  CompertitorTips,
  CompetitorItemDescription,
  CompetitorItemImage,
  CompetitorItemImageInner,
  CompetitorRevenue,
  CompetitorRevenueDetail,
  CompetitorRevenueGraph,
  CompetitorRevenueGraphIcon,
  CompetitorRevenueGraphItem,
  CompetitorRevenueShow,
  CompetitorsHeader,
  CompetitorsHeaderTitle,
  CompetitorsHeaderViewAll,
  CompetitorsItem,
  CompetitorsItemList,
  Competitorss,
  DescriptionTitle,
  DescriptionTitleGroup,
  DescriptionTitleInner,
  DescriptionTitleStarAndButton,
  GraphItemIcon,
  PaginationCompetitors,
  TipItem,
  TipItemIcon,
  TipItemName,
  TitleStars,
  ViewAllButton,
} from "../../styles";
import { COLORS, ICONS } from "../../../../constants";
import { Tooltip } from "antd";
import MiniLineChart from "../../../../components/custom/miniLineChart";

import { StarFilled } from "@ant-design/icons";
import { Award, BarChart2, CreditCard } from "react-feather";
import {
  CompertitorFunctionw,
  CompertitorTipsw,
  CompetitorItemDescriptionw,
  CompetitorItemFIrstw,
  CompetitorItemImagew,
  CompetitorRevenueDetailw,
  CompetitorRevenueGraphIconw,
  CompetitorRevenueGraphItemw,
  CompetitorRevenueGraphw,
  CompetitorRevenueShoww,
  CompetitorRevenuew,
  CompetitorsItemListw,
  CompetitorsItemw,
  DescriptionTitleGroupw,
  DescriptionTitleInnerw,
  DescriptionTitleStarAndButtonw,
  DescriptionTitlew,
  GraphItemIconw,
  TipItemw,
  TitleStarsw,
  ViewAllButtonw,
} from "./styles";
import Lock from "./lock";
import { useAuth } from "../../../../context/AppContext";
import ClipLoader from "react-spinners/ClipLoader";
import { Pagination } from "../../../../components/ui";
import ReportAPI from "../../../../api/report";
import { nFormatter } from "../../../../utils/helper";
import { competitorDataParsing } from "../../../../utils/fetch_data";
import { usePagination } from "../../../../hooks";

const Competitors = (props: any) => {
  const { id } = props;
  const airport = <span>Airport Pickup Offered</span>;
  const door = <span>Number of Doors</span>;
  const seat = <span>Number of Seats</span>;
  const plan = <span>Insurance Plans</span>;
  const fuel = <span>Fuel Type</span>;

  const { apiKey, currentReport, setMapCars, setMapCenter, setDetailMap } = useAuth();
  //eslint-disable-next-line
  const [arrow, setArrow] = useState("Show");
  const [responsiveShow, setResponsiveShow] = useState<boolean>(false);
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [totalData, setTotalData] = useState<any[]>([])

  const PageSize = 2;

  const { pagesCount, page, setTotalResults, handlePageChange } = usePagination(
    {
      limit: PageSize,
      page: 1,
      onChange: async (params, setPage) => {
        setPage(params.page);
        setTotalResults(totalData?.length);
      },
    }
  );

  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return totalData?.slice(firstPageIndex, lastPageIndex);
  }, [page, totalData, PageSize]);

  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }
    if (arrow === "Show") {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  useEffect(() => {
    if (window.innerWidth < 815) {
      setResponsiveShow(true);
    } else {
      setResponsiveShow(false);
    }
    //eslint-disable-next-line
  }, [window.innerWidth]);

  const handleGetReportData = async () => {
    setLoadingReport(true);
    try {
      const res = await ReportAPI.getCompetitors(id);
      if (res) {
        setTotalData(res);
        setTotalResults(res?.length);
        setLoadingReport(false);
      }
    } catch (error: any) {
    }
  };

  useEffect(() => {
    if (currentReport) {
      handleGetReportData();
    }
    // eslint-disable-next-line
  }, [currentReport]);

  const handleClickTuro = (link: string) => {
    window.open(link, "_blank");
  }

  useEffect(() => {
    if (currentTableData) {
      const vehicles = currentTableData.map((item: any, index: any) => {
        return {
          ...item?.vehicle,
          no: index + 1
        }
      }
      );
      setMapCars(vehicles)
    }
    //eslint-disable-next-line
  }, [currentTableData])

  const handleClickCompetetor = (competitor: any) => {
    setMapCenter({
      lat: competitor.latitude,
      lng: competitor.longitude
    })
    setDetailMap(true);
  }

  return (
    <>
      {!apiKey?.plan_type ? (
        <Lock />
      ) : (
        <Competitorss className="competitors">
          <CompetitorsHeader className="competitors-Header">
            <CompetitorsHeaderTitle className="CompetitorsHeaderTitle">
              Your Competitors
            </CompetitorsHeaderTitle>
            <CompetitorsHeaderViewAll className="CompetitorsHeaderViewAll">
            </CompetitorsHeaderViewAll>
          </CompetitorsHeader>

          {responsiveShow ? (
            <>
              {
                currentTableData.map((item, index) => (
                  <CompetitorsItemListw className="competitors-item-list" key={index} onClick={() => handleClickCompetetor(item)}>
                    <CompetitorsItemw className="CompetitorsItem">
                      <CompetitorItemFIrstw className="CompetitorItemFIrst">
                        <CompetitorItemImagew className="CompetitorItemImage">
                          <CompetitorItemImageInner
                            src={item?.image_link}
                          ></CompetitorItemImageInner>
                        </CompetitorItemImagew>
                        <DescriptionTitlew className="DescriptionTitle">
                          <DescriptionTitleGroupw className="DescriptionTitleGroup">
                            <DescriptionTitleInnerw className="DescriptionTitleInner">
                              {item?.name}
                            </DescriptionTitleInnerw>
                          </DescriptionTitleGroupw>
                          <DescriptionTitleStarAndButtonw className="DescriptionTitleStarAndButton">
                            <TitleStarsw className="TitleStars">
                              {item?.rating.stars === 'unknown' ? 0 : item?.rating.stars} <StarFilled />
                            </TitleStarsw>
                            <ViewAllButtonw className="ViewAllButton">
                              See It On Turo
                            </ViewAllButtonw>
                          </DescriptionTitleStarAndButtonw>
                        </DescriptionTitlew>
                      </CompetitorItemFIrstw>

                      <CompetitorItemDescriptionw className="CompetitorItemDescription">
                        <CompertitorFunctionw className="CompertitorFunction">
                          <CompetitorRevenuew className="CompetitorRevenue">
                            <CompetitorRevenueDetailw className="CompetitorRevenueDetail">
                              <CompetitorRevenueGraphItemw className="CompetitorRevenueGraphItem">
                                ${nFormatter(item?.vehicle?.expec_month_rev, 0)}
                                <CompetitorRevenueShoww className="CompetitorRevenueShow">
                                  Revenue
                                </CompetitorRevenueShoww>
                              </CompetitorRevenueGraphItemw>
                              <GraphItemIcon
                                className="GraphItemIcon"
                                style={{ backgroundColor: "#6610f22b" }}
                              >
                                <CreditCard color="#6610f2" />
                              </GraphItemIcon>
                            </CompetitorRevenueDetailw>
                            <CompetitorRevenueGraphw className="CompetitorRevenueGraph">
                              <CompetitorRevenueGraphIconw className="CompetitorRevenueGraphIcon">
                                <MiniLineChart
                                  data={competitorDataParsing(item?.history, 'revenue')}
                                  color="#6610f2"
                                />
                              </CompetitorRevenueGraphIconw>
                            </CompetitorRevenueGraphw>
                          </CompetitorRevenuew>
                          <CompetitorRevenuew className="CompetitorRevenue">
                            <CompetitorRevenueDetailw className="CompetitorRevenueDetail">
                              <CompetitorRevenueGraphItemw className="CompetitorRevenueGraphItem">
                              {item?.means.occupancy ? (item?.means.occupancy * 100).toFixed(0) : "Unknown"}%{" "}
                                <CompetitorRevenueShoww className="CompetitorRevenueShow">
                                  Occupancy
                                </CompetitorRevenueShoww>
                              </CompetitorRevenueGraphItemw>
                              <GraphItemIconw
                                className="GraphItemIcon"
                                style={{ backgroundColor: "#ff9f434f" }}
                              >
                                <Award color="#ff9f43" />
                              </GraphItemIconw>
                            </CompetitorRevenueDetailw>
                            <CompetitorRevenueGraphw className="CompetitorRevenueGraph">
                              <CompetitorRevenueGraphIconw className="CompetitorRevenueGraphIcon">
                                <MiniLineChart
                                  data={competitorDataParsing(item?.history, 'occupancy')}
                                  color="#ff9f43"
                                  status="occupancy"
                                />
                              </CompetitorRevenueGraphIconw>
                            </CompetitorRevenueGraphw>
                          </CompetitorRevenuew>
                          <CompetitorRevenuew className="CompetitorRevenue">
                            <CompetitorRevenueDetailw className="CompetitorRevenueDetail">
                              <CompetitorRevenueGraphItemw className="CompetitorRevenueGraphItem">
                                ${nFormatter(item?.means.rate, 0)}
                                <CompetitorRevenueShoww className="CompetitorRevenueShow">
                                  Daily Rate
                                </CompetitorRevenueShoww>
                              </CompetitorRevenueGraphItemw>
                              <GraphItemIcon
                                className="GraphItemIcon"
                                style={{ backgroundColor: "#ea54554d" }}
                              >
                                <BarChart2 color="#ea5455" />
                              </GraphItemIcon>
                            </CompetitorRevenueDetailw>
                            <CompetitorRevenueGraphw className="CompetitorRevenueGraph">
                              <CompetitorRevenueGraphIconw className="CompetitorRevenueGraphIcon">
                                <MiniLineChart data={competitorDataParsing(item?.history, 'rate')} color="#ea5455" />
                              </CompetitorRevenueGraphIconw>
                            </CompetitorRevenueGraphw>
                          </CompetitorRevenuew>
                        </CompertitorFunctionw>
                        <CompertitorTipsw className="CompertitorTips">
                          <Tooltip
                            placement="topLeft"
                            title={airport}
                            arrow={mergedArrow}
                          >
                            <TipItemw className="TipItem">
                              <TipItemIcon
                                src={ICONS.airplane}
                                style={{ width: "25px" }}
                              ></TipItemIcon>
                              <TipItemName>{item?.airport ? 'Yes' : 'No'}</TipItemName>
                            </TipItemw>
                          </Tooltip>
                          <Tooltip
                            placement="topLeft"
                            title={fuel}
                            arrow={mergedArrow}
                          >
                            <TipItemw className="TipItem">
                              <TipItemIcon src={ICONS.diesel}></TipItemIcon>
                              <TipItemName>{item?.fuelType}</TipItemName>
                            </TipItemw>
                          </Tooltip>
                          <Tooltip
                            placement="topLeft"
                            title={door}
                            arrow={mergedArrow}
                          >
                            <TipItemw className="TipItem">
                              <TipItemIcon src={ICONS.door}></TipItemIcon>
                              <TipItemName>{item?.doors ? parseInt(item?.doors) : '?'} Doors</TipItemName>
                            </TipItemw>
                          </Tooltip>
                          <Tooltip
                            placement="topLeft"
                            title={seat}
                            arrow={mergedArrow}
                          >
                            <TipItemw className="TipItem">
                              <TipItemIcon src={ICONS.seat}></TipItemIcon>
                              <TipItemName>{item?.seats} Seats</TipItemName>
                            </TipItemw>
                          </Tooltip>
                          <Tooltip
                            placement="topLeft"
                            title={plan}
                            arrow={mergedArrow}
                          >
                            <TipItemw className="TipItem">
                              <TipItemIcon src={ICONS.available}></TipItemIcon>
                              <TipItemName>{item?.insurance}</TipItemName>
                            </TipItemw>
                          </Tooltip>
                        </CompertitorTipsw>
                      </CompetitorItemDescriptionw>
                    </CompetitorsItemw>
                  </CompetitorsItemListw>
                ))
              }
              <PaginationCompetitors className="PaginationCompetitors">
                <Pagination
                  onChange={(_e, x) => handlePageChange(x)}
                  page={page}
                  count={pagesCount}
                />
              </PaginationCompetitors>
            </>
          ) : (
            <>
              {
                currentTableData?.map((item, index) => {
                  return (
                    <CompetitorsItemList className="competitors-item-list" key={index} onClick={() => handleClickCompetetor(item)}>
                      <CompetitorsItem className="CompetitorsItem">
                        <CompetitorItemImage className="CompetitorItemImage">
                          <CompetitorItemImageInner
                            src={item?.image_link}
                          ></CompetitorItemImageInner>
                        </CompetitorItemImage>
                        <CompetitorItemDescription className="CompetitorItemDescription">
                          <DescriptionTitle className="DescriptionTitle">
                            <DescriptionTitleGroup className="DescriptionTitleGroup">
                              <DescriptionTitleInner className="DescriptionTitleInner">
                                {item?.name}
                              </DescriptionTitleInner>
                            </DescriptionTitleGroup>
                            <DescriptionTitleStarAndButton className="DescriptionTitleStarAndButton">
                              <TitleStars className="TitleStars">
                                {item?.rating.stars === 'unknown' ? 0 : item?.rating.stars} <StarFilled />
                              </TitleStars>
                              <ViewAllButton className="ViewAllButton" onClick={() => handleClickTuro(item?.turo_link)}>
                                See It On Turo
                              </ViewAllButton>
                            </DescriptionTitleStarAndButton>
                          </DescriptionTitle>

                          <CompertitorFunction className="CompertitorFunction">
                            <CompetitorRevenue className="CompetitorRevenue">
                              <CompetitorRevenueDetail className="CompetitorRevenueDetail">
                                <CompetitorRevenueGraphItem className="CompetitorRevenueGraphItem">
                                  ${nFormatter(item?.vehicle?.expec_month_rev, 0)}
                                  <CompetitorRevenueShow className="CompetitorRevenueShow">
                                    Revenue
                                  </CompetitorRevenueShow>
                                </CompetitorRevenueGraphItem>
                                <GraphItemIcon
                                  className="GraphItemIcon"
                                  style={{ backgroundColor: "#6610f22b" }}
                                >
                                  <CreditCard color="#6610f2" />
                                </GraphItemIcon>
                              </CompetitorRevenueDetail>
                              <CompetitorRevenueGraph className="CompetitorRevenueGraph">
                                <CompetitorRevenueGraphIcon className="CompetitorRevenueGraphIcon">
                                  <MiniLineChart
                                    data={competitorDataParsing(item?.history, 'revenue')}
                                    color="#6610f2"
                                  />
                                </CompetitorRevenueGraphIcon>
                              </CompetitorRevenueGraph>
                            </CompetitorRevenue>
                            <CompetitorRevenue className="CompetitorRevenue">
                              <CompetitorRevenueDetail className="CompetitorRevenueDetail">
                                <CompetitorRevenueGraphItem className="CompetitorRevenueGraphItem">
                                  {item?.means.occupancy ? (item?.means.occupancy * 100).toFixed(0) : "Unknown"}%{" "}
                                  <CompetitorRevenueShow className="CompetitorRevenueShow">
                                    Occupancy
                                  </CompetitorRevenueShow>
                                </CompetitorRevenueGraphItem>
                                <GraphItemIcon
                                  className="GraphItemIcon"
                                  style={{ backgroundColor: "#ff9f434f" }}
                                >
                                  <Award color="#ff9f43" />
                                </GraphItemIcon>
                              </CompetitorRevenueDetail>
                              <CompetitorRevenueGraph className="CompetitorRevenueGraph">
                                <CompetitorRevenueGraphIcon className="CompetitorRevenueGraphIcon">
                                  <MiniLineChart
                                    data={competitorDataParsing(item?.history, 'occupancy')}
                                    color="#ff9f43"
                                    status="occupancy"
                                  />
                                </CompetitorRevenueGraphIcon>
                              </CompetitorRevenueGraph>
                            </CompetitorRevenue>
                            <CompetitorRevenue className="CompetitorRevenue">
                              <CompetitorRevenueDetail className="CompetitorRevenueDetail">
                                <CompetitorRevenueGraphItem className="CompetitorRevenueGraphItem">
                                  ${nFormatter(item?.means.rate, 0)}
                                  <CompetitorRevenueShow className="CompetitorRevenueShow">
                                    Daily Rate
                                  </CompetitorRevenueShow>
                                </CompetitorRevenueGraphItem>
                                <GraphItemIcon
                                  className="GraphItemIcon"
                                  style={{ backgroundColor: "#ea54554d" }}
                                >
                                  <BarChart2 color="#ea5455" />
                                </GraphItemIcon>
                              </CompetitorRevenueDetail>
                              <CompetitorRevenueGraph className="CompetitorRevenueGraph">
                                <CompetitorRevenueGraphIcon className="CompetitorRevenueGraphIcon">
                                  <MiniLineChart data={competitorDataParsing(item?.history, 'rate')} color="#ea5455" />
                                </CompetitorRevenueGraphIcon>
                              </CompetitorRevenueGraph>
                            </CompetitorRevenue>
                          </CompertitorFunction>
                          <CompertitorTips className="CompertitorTips">
                            <Tooltip
                              placement="topLeft"
                              title={airport}
                              arrow={mergedArrow}
                            >
                              <TipItem className="TipItem">
                                <TipItemIcon
                                  src={ICONS.airplane}
                                  style={{ width: "25px" }}
                                ></TipItemIcon>
                                <TipItemName>{item?.airport ? 'Yes' : 'No'}</TipItemName>
                              </TipItem>
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={fuel}
                              arrow={mergedArrow}
                            >
                              <TipItem className="TipItem">
                                <TipItemIcon src={ICONS.diesel}></TipItemIcon>
                                <TipItemName>{item?.fuelType}</TipItemName>
                              </TipItem>
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={door}
                              arrow={mergedArrow}
                            >
                              <TipItem className="TipItem">
                                <TipItemIcon src={ICONS.door}></TipItemIcon>
                                <TipItemName>{item?.doors ? parseInt(item?.doors) : '?'} Doors</TipItemName>
                              </TipItem>
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={seat}
                              arrow={mergedArrow}
                            >
                              <TipItem className="TipItem">
                                <TipItemIcon src={ICONS.seat}></TipItemIcon>
                                <TipItemName>{item?.seats} Seats</TipItemName>
                              </TipItem>
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={plan}
                              arrow={mergedArrow}
                            >
                              <TipItem className="TipItem">
                                <TipItemIcon src={ICONS.available}></TipItemIcon>
                                <TipItemName>{item?.insurance}</TipItemName>
                              </TipItem>
                            </Tooltip>
                          </CompertitorTips>
                        </CompetitorItemDescription>
                      </CompetitorsItem>
                    </CompetitorsItemList>
                  )
                })
              }

              <PaginationCompetitors className="PaginationCompetitors">
                <Pagination
                  onChange={(_e, x) => handlePageChange(x)}
                  page={page}
                  count={pagesCount}
                />
              </PaginationCompetitors>
            </>
          )}

          {loadingReport && (
            <ClipLoaderWrapper className="ClipLoaderWrapper">
              <ClipLoader
                color={COLORS.black}
                loading={loadingReport}
                size={50}
              />
            </ClipLoaderWrapper>
          )}
        </Competitorss>
      )}
    </>
  );
};

export default Competitors;
