import axiosInstance from "../../axios/axiosInstance";
import { API } from "../../utils/constant";
import { getToken } from "../../utils/helper";

const ReportAPI = {
  getAllReports: async () => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/report/get_all`, {
      headers: headers
    });
    return data;
  },

  getReport: async (id: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/reports/${id}`, {
      headers: headers
    });
    return data;
  },
  getEstimateValue: async (email: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/reports/${email}`, {
      headers: headers
    });
    return data;
  },

  addReport: async (body: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.post(`${API}/api/reports`, body, {
      headers: headers
    });
    return data;
  },
  sendMail: async (body: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.post(`${API}/api/send_mail`, body, {
      headers: headers
    });
    return data;
  },
  sendPersonEmail: async (body: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.post(`${API}/api/person-emails`, body, {
      headers: headers
    });
    return data;
  },

  addFreeReport: async (body: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.post(`${API}/api/report/free_report`, body, {
      headers: headers
    });
    return data;
  },
  getFilterRevenueData: async (body: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.post(`${API}/api/market-report/filter_revenue`, body, {
      headers: headers
    });
    return data;
  },
  getFilterTopTenOwnerData: async (body: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.post(`${API}/api/market-report/filter_topten_owner`, body, {
      headers: headers
    });
    return data;
  },

  updateReport: async (body: any, id: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.post(`${API}/api/report/update_report/${id}`, body, {
      headers: headers
    });
    return data;
  },

  deleteReport: async (id: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.delete(`${API}/api/reports/${id}`, {
      headers: headers
    });
    return data;
  },

  getRDAOreport: async (id: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/car-report/car-rdao/${id}`, {
      headers: headers
    });
    return data;
  },

  getCompetitors: async (id: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/car-report/competitors/${id}`, {
      headers: headers
    });
    return data;
  },

  getExtraInfos: async (id: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/car-report/get_extra/${id}`, {
      headers: headers
    });
    return data;
  },
  getRDAMarketreport: async (id: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/market-report/market-rda/${id}`, {
      headers: headers
    });
    return data;
  },

  getTopOwners: async (id: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/market-report/top-hosts/${id}`, {
      headers: headers
    });
    return data;
  },

  getHostDetails: async (report_id: any, host_id: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/market-report/top-hosts-owner/${report_id}/${host_id}`, {
      headers: headers
    });
    return data;
  },

  getTopBottomTen: async (id: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/market-report/top-bottom-ten/${id}`, {
      headers: headers
    });
    return data;
  },
  getCarData: async (id: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/market-report/car-data/${id}`, {
      headers: headers
    });
    return data;
  },
  getAllSelectionData: async (id: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/market-report/makeModelYear-data/${id}`, {
      headers: headers
    });
    return data;
  },
  getMakeModelYearList: async (body: any) => {
    const { data } = await axiosInstance.post(`${API}/api/report/get_make_model_list`, body)
    return data;
  },
  getTomBottomTrend: async (id: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/market-report/topBottom-trendData/${id}`, {
      headers: headers
    });
    return data;
  },
  getExpectedRevenueLastTwelve: async (id: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/market-report/trend/${id}`, {
      headers: headers
    });
    return data;
  },
};

export default ReportAPI;
