import React from 'react';

const AddIcon = ({ ...props }) => (
  <svg width="24" height="19" viewBox="0 0 19 19" fill="none" {...props}>
    <g clipPath="url(#clip0_697_2983)">
      <path
        d="M9.5 0C4.26138 0 0 4.26138 0 9.5C0 14.7386 4.26138 19 9.5 19C14.7386 19 19 14.7379 19 9.5C19 4.26212 14.7386 0 9.5 0ZM9.5 17.5283C5.07378 17.5283 1.47172 13.927 1.47172 9.5C1.47172 5.07304 5.07378 1.47172 9.5 1.47172C13.9262 1.47172 17.5283 5.07304 17.5283 9.5C17.5283 13.927 13.927 17.5283 9.5 17.5283Z"
        fill="currentColor"
      />
      <path
        d="M13.1795 8.69787H10.236V5.75443C10.236 5.34823 9.9071 5.01855 9.50016 5.01855C9.09322 5.01855 8.76428 5.34823 8.76428 5.75443V8.69787H5.82084C5.4139 8.69787 5.08496 9.02755 5.08496 9.43375C5.08496 9.83995 5.4139 10.1696 5.82084 10.1696H8.76428V13.1131C8.76428 13.5193 9.09322 13.849 9.50016 13.849C9.9071 13.849 10.236 13.5193 10.236 13.1131V10.1696H13.1795C13.5864 10.1696 13.9154 9.83995 13.9154 9.43375C13.9154 9.02755 13.5864 8.69787 13.1795 8.69787Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_697_2983">
        <rect width="19" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AddIcon;
