import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, SIZES } from "../../constants";

export const LocationPageContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body1}px;
        font-family:${FONT_FAMILY.regular};
        display:flex;
        flex-direction:column;
        gap:10px;
    `}
`;
export const LocationPageHeader = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-family:${FONT_FAMILY.medium};
    `}
`;
export const LocationPageBody = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    `}
`;
export const LocationPageBodyRow = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        gap:30px;
    `}
`;
export const LocationPageBodyItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:50%;
        display:flex;
        flex-direction:column;
    `}
`;
export const LocationItemImageSection = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `

    `}
`;
export const LocationItemDescription = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        gap:10px;
        border:1px solid ${COLORS.gray};
    `}
`;
export const LocationItemDescriptionWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        gap:20px;
    `}
`;
export const LocationItemDescrptionFirst = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        justify-content:space-between;
        padding:12px 40px;
        align-items:center;
        
    `}
`;
export const LocationItemDescrptionFirstLabel = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-family:${FONT_FAMILY.semibold};
        display:flex;
        align-items:center;
    `}
`;
export const LocationItemDescrptionFirstIcon = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        align-items:center;
    `}
`;
export const LocationItemDescrptionTwo = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        gap:10px;
        justify-content:space-around;
        margin-bottom:10px;
    `}
`;
export const LocationItemDescrptionThird = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body4}px;
        text-align:center;
        color:${COLORS.dove_gray};
    `}
`;
export const LocationItemDescrptionFirstCircle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:1px 4px;
        border:3px solid ${COLORS.primary};
        border-radius:50%;
    `}
`;
export const LocationItemDescrptionFirstCircleInner = styled.div<{
  theme?: Theme;
}>`
  font-family: ${FONT_FAMILY.semibold};
  font-size: ${SIZES.body4}px;
  ${({ theme }) => `

    `};
`;
export const LocationItemDescrptionTwoItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

    `}
`;
export const LocationItemDescrptionTwoItemFirst = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-family:${FONT_FAMILY.medium};
    `}
`;
export const LocationItemDescrptionTwoItemTwo = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body4}px;
        color:${COLORS.dove_gray};
    `}
`;
