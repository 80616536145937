import React, { useEffect, useState } from "react";
import {
  CancelSubScriptionButton,
  ChangePasswordBody,
  ChangePasswordBtn,
  ChangeSubScription,
  ChangeSubScriptionButton,
  ChangeSubScriptionDescription1,
  ChangeSubScriptionDescription2,
  ChangeSubScriptionLabel,
  ChangeSubScriptionLeftSection,
  ChangeSubScriptionRightSection,
  OKButtonWrapper,
  PersonInfoDivItem,
  PersonInfoDivItemTitle,
  PersonInfoInput,
  PersonalInfoBody,
  PersonalInfoContainer,
  PersonalInfoContainerInner,
  PersonalInfoDiv,
  PersonalInfoTitle,
  ProfileInfo,
  SaveButton,
} from "./styles";
import { Alert, Modal, message } from "antd";

import "./index.css";
import SubScription from "../../components/custom/subScription";
import { useAuth } from "../../context/AppContext";

import EditPassword from "./editPassword";
import { validateName } from "../../utils/authValidation";
import AuthorizationAPI from "../../api/authorization";
import { RequiredValidate } from "../auth/login/styles";
import ClipLoader from "react-spinners/ClipLoader";
import { COLORS } from "../../constants";
import SubscriptionAPI from "../../api/subscription";

const PersonalInfo = () => {
  const { currentUser, setCurrentUser, apiKey, setApiKey } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAdjustEstimatedModal, setShowAdjustEstimatedModal] =
    useState(false);
  const [user, setUser] = useState<any>({});
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [loading2, setLoading2] = useState<boolean>(false);

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  const [userinfo, setUserInfo] = useState<any>({
    firstName: currentUser.firstname,
    lastName: currentUser.lastname,
  });

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const CancelHandle = () => {
    setShowAdjustEstimatedModal(false);
  };
  const clickHandle = () => {
    setShowAdjustEstimatedModal(true);
  };

  const handleFreePlan = async () => {
    if (apiKey) {
      setLoading2(true);
      try {
        const res = await SubscriptionAPI.changeToFreePlan(apiKey.id);
        setApiKey(res.apikey);
      } catch (error) {
      } finally {
        setLoading2(false);
      }
    }
  };

  ////// save action///////////

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errors, setErrors] = useState<any>({
    firstName: "",
    lastName: "",
  });

  const handleSave = async () => {
    setErrorMsg("");

    if (!userinfo.firstName) {
      setErrors((info: any) => ({
        ...info,
        firstName: "First Name is required",
      }));
    }
    if (!userinfo.lastName) {
      setErrors((info: any) => ({
        ...info,
        lastName: "Last Name is required",
      }));
    }

    if (!validateName(userinfo.firstName)) {
      return;
    }
    if (!validateName(userinfo.lastName)) {
      return;
    }

    setLoading(true);
    const values = {
      firstname: userinfo.firstName,
      lastname: userinfo.lastName,
    };

    AuthorizationAPI.saveInfos(currentUser.id, values)
      .then((data: any) => {
        setCurrentUser(data);
        message.success(`Successfully saved!`);
      })
      .catch((error) => {
        setErrorMsg(
          error?.response?.data?.error?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event: any) => {
    const field = event.target.name;
    const value = event.target.value;
    if (event) setSaveDisabled(false);
    setUserInfo((info: any) => ({ ...info, [field]: value }));
    switch (field) {
      case "firstName":
        if (!validateName(value)) {
          setErrors((info: any) => ({
            ...info,
            firstName: "firstName is invalid",
          }));
        } else {
          setErrors((info: any) => ({ ...info, firstName: "" }));
        }
        break;
      case "lastName":
        if (!validateName(value)) {
          setErrors((info: any) => ({
            ...info,
            lastName: "lastName is invalid",
          }));
        } else {
          setErrors((info: any) => ({ ...info, lastName: "" }));
        }
        break;

      default:
        break;
    }
  };

  return (
    <PersonalInfoContainer className="PersonalInfoContainer">
      <PersonalInfoContainerInner className="PersonalInfoContainerInner">
        <PersonalInfoTitle className="PersonalInfoTitle">
          Subscription & Profile
        </PersonalInfoTitle>

        <ChangeSubScription className="ChangeSubScription">
          <ChangeSubScriptionLeftSection className="ChangeSubScriptionLeftSection">
            <ChangeSubScriptionLabel className="ChangeSubScriptionLabel">
              Your subscription
            </ChangeSubScriptionLabel>
            <ChangeSubScriptionDescription1 className="ChangeSubScriptionDescription">
              {apiKey?.plan_type?.name ? apiKey?.plan_type?.name : 'Free Plan'}
            </ChangeSubScriptionDescription1>
            <ChangeSubScriptionDescription2 className="ChangeSubScriptionDescription">
            </ChangeSubScriptionDescription2>
          </ChangeSubScriptionLeftSection>
          <ChangeSubScriptionRightSection className="ChangeSubScriptionRightSection">
            <ChangeSubScriptionButton
              className="ChangeSubScriptionButton"
              onClick={clickHandle}
            >
              Change Plan
            </ChangeSubScriptionButton>
            <CancelSubScriptionButton
              className="CancelSubScriptionButton"
              onClick={handleFreePlan}
            >
              {loading2? (
                <ClipLoader color={COLORS.blue} loading={loading2} size={20} />
              ) : (
                "Cancel Plan"
              )}

            </CancelSubScriptionButton>
          </ChangeSubScriptionRightSection>
        </ChangeSubScription>

        <ProfileInfo className="ProfileInfo">
          <PersonalInfoBody className="PersonalInfoBody">
            {errorMsg ? (
              <Alert
                className="alert_error"
                message={errorMsg}
                type="error"
                closable
                afterClose={() => setErrorMsg("")}
              />
            ) : null}
            <PersonalInfoDiv className="PersonalInfoDiv">
              <PersonInfoDivItem>
                <PersonInfoDivItemTitle>First Name</PersonInfoDivItemTitle>
                <PersonInfoInput
                  className="PersonInfoInput"
                  value={userinfo.firstName}
                  name="firstName"
                  onChange={handleChange}
                ></PersonInfoInput>
                {errors.firstName && (
                  <RequiredValidate className="RequiredValidate">
                    {errors.firstName}
                  </RequiredValidate>
                )}
              </PersonInfoDivItem>
              <PersonInfoDivItem>
                <PersonInfoDivItemTitle>Last Name</PersonInfoDivItemTitle>
                <PersonInfoInput
                  className="PersonInfoInput"
                  name="lastName"
                  value={userinfo.lastName}
                  onChange={handleChange}
                ></PersonInfoInput>
                {errors.lastName && (
                  <RequiredValidate className="RequiredValidate">
                    {errors.lastName}
                  </RequiredValidate>
                )}
              </PersonInfoDivItem>
            </PersonalInfoDiv>
            <PersonalInfoDiv className="PersonalInfoDiv">
              <PersonInfoDivItem>
                <PersonInfoDivItemTitle>Email</PersonInfoDivItemTitle>
                <PersonInfoInput
                  className="PersonInfoInput"
                  value={user.email}
                  disabled
                ></PersonInfoInput>
              </PersonInfoDivItem>
              <PersonInfoDivItem>
                <PersonInfoDivItemTitle>Password</PersonInfoDivItemTitle>
                <PersonInfoInput
                  className="PersonInfoInput"
                  type="password"
                  value="123123123"
                  disabled
                ></PersonInfoInput>
              </PersonInfoDivItem>
            </PersonalInfoDiv>
            <ChangePasswordBody className="ChangePasswordBody">
              <ChangePasswordBtn
                className="ChangePasswordBtn"
                onClick={handleClick}
              >
                Change password
              </ChangePasswordBtn>
            </ChangePasswordBody>
          </PersonalInfoBody>
          <OKButtonWrapper className="OKButtonWrapper">
            <SaveButton
              className="SaveButton"
              onClick={handleSave}
              disabled={saveDisabled}
            >
              {loading ? (
                <ClipLoader color={COLORS.blue} loading={loading} size={20} />
              ) : (
                "Save"
              )}
            </SaveButton>
          </OKButtonWrapper>
        </ProfileInfo>

        <Modal
          title="Edit Password"
          className="ChangePasswordModal"
          open={isModalOpen}
          onOk={CancelHandle}
          onCancel={handleCancel}
          okText="Update"
          footer=""
          centered
        >
          <EditPassword
            handleCancel={handleCancel}
            setIsModalOpen={setIsModalOpen}
          />
        </Modal>

        <Modal
          open={showAdjustEstimatedModal}
          onCancel={CancelHandle}
          width={1200}
          centered
          style={{ overflow: "auto" }}
          footer=""
        >
          <SubScription />
        </Modal>
      </PersonalInfoContainerInner>
    </PersonalInfoContainer>
  );
};

export default PersonalInfo;
