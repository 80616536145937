import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { Link } from "react-router-dom";
import { COLORS } from "../../../constants";

export const SidebarMain = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display: flex;
    height:100%;
    flex-direction: column;
    gap: ${theme.spacing(4)};
    align-items: center;
    padding: ${theme.spacing(5)} 0;
    background-color: ${COLORS.white};
    position: relative;
    border:1px solid ${COLORS.gray};

    ${theme.breakpoints.down("lg")} {
      padding-top: ${theme.spacing(20)}
    }
    `}
`;

export const SidebarLogoLink = styled(Link)`
  display: inline;
`;

export const SidebarLogo = styled.img`
  height: 20px;
`;

export const SidebarItems = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: ${theme.spacing(3.75)};
        padding-left: ${theme.spacing(2.5)};
    `}
`;

export const SidebarCancel = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display: none;
      cursor: pointer;

      ${theme.breakpoints.down("lg")} {
        position: absolute;
        display: block;
        top: 10px;
        right: 10px;

        svg {
          width: 20px;
          height: 20px;
          color: #d3d3d3;
        }
      }
    `}
`;
export const ReportSearchWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display:flex;
        justify-content:end;
      ${theme.breakpoints.down("lg")} {

    `}
`;
export const SidebarItemNestedWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
         padding-left: ${theme.spacing(7)};
        width:100%;
        overflow:auto;
        gap:5px;
        display:flex;
        flex-direction:column;
        ::-webkit-scrollbar {
          width: 2px;
          height: 2px;
        }
      ${theme.breakpoints.down("lg")} {

    `}
`;
