import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS } from "../../../constants";

export const AuthTimeLineContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      position:absolute;
      color: rgb(33, 33, 33);
      transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      box-shadow: none;
      display: flex;
      flex-direction: row;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
      background: rgb(255, 255, 255);
      padding: 0px;
      top: 77px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      z-index: 200;
      trainsition:.5s;
    ${theme.breakpoints.down("md")} {
    }
    ${theme.breakpoints.down("sm")} {
      position: sticky;
      top:58px;
    }

  `}
`;
export const ContainerSpan = styled.span<{ theme?: Theme }>`
  ${({ theme }) => `
      position: relative;
      overflow: hidden;
      display: block;
      z-index: 0;
      background-color: #dcf0ff;
      width:100%;
      height:6px;
    ${theme.breakpoints.down("sm")} {

    }

  `}
`;
export const SpanInner = styled.span<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
    top: 0px;
    transition: transform 0.4s linear 0s;
    transform-origin: left center;
    background-color: ${COLORS.primary};
    transform: translateX(-83%);
    heigth:6px;
    ${theme.breakpoints.down("sm")} {

    }

  `}
`;
