import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { Stack } from '../system';
import { COLORS, FONT_FAMILY, MSIZES } from '../../constants';

export const TableContainer = styled(Stack)`${({ theme }) => `
    position:relative;
  `}
`;

export const TableWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    border-radius: 4px;
    border: 0.77px solid ${COLORS.gray};
    background-color: ${COLORS.gray}60;

    overflow-x: auto;
    overflow-y: hidden;
  `}
`;

export const TableMain = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size:14px;
`;

export const TableLableContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableHead = styled.thead`
  width: 100%;
  font-family: ${FONT_FAMILY.medium};
`;

export const TableBody = styled.tbody`
  width: 100%;
  font-family: ${FONT_FAMILY.regular};
  position:relative;
`;

export const TableHeadRow = styled.tr<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 100%;
    background-color: ${theme.palette.secondary.main}10;
    border-bottom: 1px solid ${theme.palette.secondary.main}20;
  `}
`;

export const TableBodyRow = styled.tr<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 100%;
    background-color: #fafafa;
    border-bottom: 1px solid ${COLORS.dark}30;
    &:not(:last-child) {
    }
  `}
`;

export const TableBodyRow2 = styled.tr<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 100%;
    background-color: ${COLORS.white};
    border-bottom: 1px solid ${COLORS.dark}30;
    &:not(:last-child) {
    }
  `}
`;

export const TableHeadCell = styled.th<{ theme?: Theme; action?: boolean }>`
  ${({ theme, action }) => `
    padding: ${theme.spacing(3)};
        text-align: left;
        color: ${COLORS.dark};
        font-weight: 500;
        white-space: nowrap; 
        position: relative;
        cursor: pointer;
        ${action
      ? `
          padding: ${theme.spacing(6)} ${theme.spacing(5)} ${theme.spacing(
        6
      )} ${theme.spacing(5)};
          min-width: 50px;
          width: 50px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          padding-right: unset;
          margin: unset;
          width: unset;
          padding: 1.5rem 1.25rem 1.5rem 1.25rem
        `
      : ''
    }
    `}
`;

export const TableBodyCell = styled.td<{ theme?: Theme; action?: boolean }>`
  ${({ theme, action }) => `
        padding: ${theme.spacing(2.5)};
        text-align: left;
        color: ${COLORS.dark}99;
        white-space: nowrap; 

        ${action ? `padding: ${theme.spacing(8)} ${theme.spacing(5)} ${theme.spacing(8)} ${theme.spacing(5)};
          min-width: 50px;
          width: 50px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          padding-right: 0 !important;
        `
        : ''
    }

    ${theme.breakpoints.down("sm")} {
      font-size:${MSIZES.body2}px;
      padding: ${theme.spacing(2)};
    }
    `}
`;

export const TableEmpty = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background: #f8f8f8;
    padding: ${theme.spacing(5)};
    min-height: 200px;
    color: ${COLORS.black};
    font-size: 18px;
    font-family: ${FONT_FAMILY.medium};
  `}
`;

export const TableHeadCellAction = styled.div`
  min-width: 50px;
  width: 50px;
  max-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;
`;
