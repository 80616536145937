import React from 'react';

const SearchIcon = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 18 18"
    fill="currentColor"
    {...props}
  >
    <path d="M16.2746 14.1751L13.9496 11.8501C13.4246 12.6751 12.6746 13.4251 11.8496 13.9501L14.1746 16.2751C14.4746 16.5751 14.9246 16.5751 15.2246 16.2751L16.2746 15.2251C16.5746 14.9251 16.5746 14.4751 16.2746 14.1751Z" />
    <path d="M8.25 15C4.5 15 1.5 12 1.5 8.25C1.5 4.5 4.5 1.5 8.25 1.5C12 1.5 15 4.5 15 8.25C15 12 12 15 8.25 15ZM8.25 3C5.325 3 3 5.325 3 8.25C3 11.175 5.325 13.5 8.25 13.5C11.175 13.5 13.5 11.175 13.5 8.25C13.5 5.325 11.175 3 8.25 3ZM6 8.25C6 6.975 6.975 6 8.25 6C8.7 6 9 5.7 9 5.25C9 4.8 8.7 4.5 8.25 4.5C6.15 4.5 4.5 6.15 4.5 8.25C4.5 8.7 4.8 9 5.25 9C5.7 9 6 8.7 6 8.25Z" />
  </svg>
);

export default SearchIcon;
