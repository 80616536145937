import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { Navbar, Navigation } from "../../components/custom";
import { COLORS } from "../../constants";

export const DashboardLayoutMain = styled.div <{
  theme?: Theme;
}>`
  ${({ theme }) => `
  width: 100%;
  height: 100dvh;
  
    ${theme.breakpoints.down("lg")} {
      height:initial;
      ::-webkit-scrollbar {
        width: 0;
      }
    }

    ${theme.breakpoints.down("sm")} {

    }
  `}
`;
export const DashboardLayoutNavbar = styled(Navigation) <{
  theme?: Theme;
}>`
  ${({ theme }) => `
  width: 100%;
  height: 80px;
    ${theme.breakpoints.down("lg")} {
    }

    ${theme.breakpoints.down("sm")} {

    }
  `}
`;
export const DashboardLayoutBox = styled.div <{
  theme?: Theme;
}>`
  ${({ theme }) => `
  width: 100%;
  height: calc(100% - 65px);
  display: flex;
    ${theme.breakpoints.down("lg")} {
      height:initial;
      position:relative;
    }

    ${theme.breakpoints.down("sm")} {

    }
  `}
`;


export const DashboardLayoutSidebar = styled.div<{
  theme?: Theme;
  showMobileMenu?: boolean;
}>`
  ${({ theme, showMobileMenu }) => `
    min-width: 200px;
    width: 200px;
    height: 100%;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;

    ${theme.breakpoints.down("lg")} {
      position: fixed;
      height: 100dvh;
      top: 0;
      left: 0;
      background: ${COLORS.white};
      z-index: 9999;
    }
    
  `}
`;

export const PageLayoutSidebar = styled(Navbar) <{
  theme?: Theme;
  showMobileMenu?: boolean;
}>`
  ${({ theme, showMobileMenu }) => `
    width: 100%;
    height: 350px;
    position: fixed;
    top: 40px;
    transform-origin: top;
    transform 0.3s ease-in-out;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    z-index: 200;
    overflow: hidden;
    background: ${COLORS.white};
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    box-shadow: -1px 4px 13px 3px rgba(0,0,0,0.57);
    -webkit-box-shadow: -1px 4px 13px 3px rgba(0,0,0,0.57);
    -moz-box-shadow: -1px 4px 13px 3px rgba(0,0,0,0.57);

    display: none;
    ${theme.breakpoints.down("md")} {
      display: flex;
      flex-direction: column;
      justify-content: center:
      align-items: center;
      width: 100%
    }

    ${theme.breakpoints.down("sm")} {
      height: calc(100dvh - 150px);
    }
  `}
`;

export const DashboardLayoutContainerOuter = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 100%;
    height: 100%;
    background-color: white;
    overflow-x: hidden;
    overflow-y: auto;
    border-top:1px solid ${COLORS.gray};
    ${theme.breakpoints.down("lg")} {
      border-top:none;
    }
    `}
`;

export const DashboardLayoutContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 100%;
    height:100%;
    display: flex;
    // gap: ${theme.spacing(5)};
  `}
`;

export const DashboardLayoutContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  // width: calc(100% - 350px - ${theme.spacing(5)});
  width:100%;
  display:flex;
  flex-direction:column;
  position:relative;

  ${theme.breakpoints.down("lg")} {
      width: 100%;

  }
  `}
`;
export const TurboMainContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  // width: calc(100% - 350px - ${theme.spacing(5)});
  width:100%;
  display:flex;
  flex-direction:column;
  position:relative;

  ${theme.breakpoints.down("lg")} {
      width: 100%;

  }
  `}
`;

export const DashboardLayoutWidgets = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 350px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(5)};
    ${theme.breakpoints.down("lg")} {
      display: none;
    }
  `}
`;
