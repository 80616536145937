import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { FONT_FAMILY, SIZES } from "../../../constants";

export const MyListingFeaturesWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;
      gap:4px;

      ${theme.breakpoints.down("sm")} {
        font-size:${SIZES.body4}px;
        font-family:${FONT_FAMILY.regular};
      }
  `}
`;
export const MyListingFeaturesTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:18px;
      font-family:${FONT_FAMILY.bold};
      margin:20px 0;
      ${theme.breakpoints.down("sm")} {
        font-size:${SIZES.body1}px;
      }
  `}
`;
export const MyListingFeaturesTable = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;
      width:100%;
      overflow:auto;
      padding-bottom:10px;
        ${theme.breakpoints.down("sm")} {
          ::-webkit-scrollbar {
            width: 3px;
            height: 3px;
            }
        }
  `}
`;

export const FeaturesTableItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
          display:flex;
          width:100%;
          ${theme.breakpoints.down("md")} {
            flex-wrap:wrap;
          }
    `}
`;
export const FeatureTableItemFirst = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:11px 12px;
        border:1px solid #e6e6e6;
        width:20%;
        font-family:${FONT_FAMILY.medium};

        ${theme.breakpoints.down("md")} {
          width:100%;
        }
    `}
`;
export const FeatureTableItemNext = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:80%;
        display:flex;
        ${theme.breakpoints.down("md")} {
          width:100%;
        }
    `}
`;
export const FeatureTableItemColumn = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `        

        padding:15px 12px;
        width:25%;
        border:1px solid #e6e6e6;
        display:flex;
        align-items:center;
        text-align:center;
        justify-content:center;
        ${theme.breakpoints.down("md")} {
          padding:15px 9px;
        }
    `}
`;
export const TableItemIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `        
        width:18px;
    `}
`;
