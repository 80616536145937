import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, SIZES, MSIZES } from "../../../constants";
import { Button, Input, Select } from "antd";

export const AdjustEstimateModalContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    padding: 0 30px;
    ${theme.breakpoints.down("md")} {
      // height:calc(100dvh - 125px);
      padding: 0 7px;
    }
    ${theme.breakpoints.down("sm")} {
      height:calc(100dvh - 125px);
      padding: 0 7px;
      overflow:auto;
    }
`}
`;
export const AdjustEstimateModalTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

    `}
`;
export const AdjustEstimateModalSubTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        color:black;
        font-size:${SIZES.h9}px;
        font-family:${FONT_FAMILY.regular};
        margin-bottom:26px;    
        position: sticky;
        background-color: white;
        top: 0;
        z-index: 9999;

        ${theme.breakpoints.down("sm")} {
          font-size:${MSIZES.h3}px;
        }
    `}
`;
export const AdjustEstimateModalHeader = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
          display:flex;
          width:100%;
          margin-bottom:15px;
          gap:14px;

          ${theme.breakpoints.down("md")} {
            flex-direction:column;
          }
    `}
`;
export const AdjustEstimateScrollWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        overflow: auto;
        // max-height: calc(100dvh - 500px);
        display:flex;
        flex-direction:column;
        gap:3px;

        ${theme.breakpoints.down("md")} {
            height:350px;
            overflow:auto;
        }
        ${theme.breakpoints.down("sm")} {
          overflow:initial;
          max-height:initial;
          overflow:initial;
          height:initial;
        }
    `}
`;
export const AdjustEstimateModalSection = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        border:1px solid rgba(0, 0, 0, 0.12);
        gap:4px;
    `}
`;
export const AdjustEstimateModalSection2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-wrap:wrap;
        border-right:1px solid ${COLORS.gray};

        ${theme.breakpoints.down("md")} {
          border-right:initial;
          border-bottom:1px solid ${COLORS.gray};
        }
        
    `}
`;
export const AdjustEstimateModalSection3 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-wrap:wrap;
    `}
`;

export const AdjustEstimateModalSectionPlan = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      background-color:rgba(63, 81, 181, 0.063);
      align-items:center;
      padding:16px;
      justify-content:space-between;

      ${theme.breakpoints.down("md")} {
        width:100%;
        border-right:initial;
        border-bottom:1px solid ${COLORS.gray};
        flex-direction:column;
        align-items:initial;
      }
      
    `}
`;

export const EstimateInputDiv = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        gap:30px;

        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
          flex-direction:column;
        }
    `}
`;
export const EstimateInputDivInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        width:100%;
        background-color:rgba(63, 81, 181, 0.063);
        padding:16px;
    `}
`;
export const EstimateInputItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        width:100%;
        justify-content:space-between;
    `}
`;
export const EstimateInputItemTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body4}px;
        font-family:${FONT_FAMILY.regular};
        color:black;
        display:flex;
        align-items:center;
    `}
`;
export const EstimateInputItemInput = styled(Input)<{ theme?: Theme }>`
  ${({ theme }) => `
          border:1px solid #CFD3D4;
          outline:0;
          width:190px;
    `}
`;
export const EstimateInputItemLabel = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:18px;
        font-family:${FONT_FAMILY.medium};
    `}
`;
export const AdjustEstimateModalSectionPlanTitle = styled.div<{
  theme?: Theme;
}>`
  color: ${COLORS.black};
  margin-bottom: 4px;
`;
export const EstimateModalSectionItem = styled.div<{
  theme?: Theme;
}>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        gap:13px;
        width:25%;
        padding:10px;
        justify-content:space-between;
        ${theme.breakpoints.down("md")} {
          width:33%;
        }

    `}
`;
export const EstimateModalSectionItemNew = styled.div<{
  theme?: Theme;
}>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        gap:13px;
        width:33.3%;
        padding:10px;
        justify-content:space-between;
        ${theme.breakpoints.down("md")} {
          width:33%;
        }
    `}
`;
export const EMSITitle = styled.div<{
  theme?: Theme;
}>`
  ${({ theme }) => `
        font-size:${SIZES.body1}px;
        font-family:${FONT_FAMILY.regular};
        color:black;
        ${theme.breakpoints.down("md")} {
          font-size:${SIZES.body4}px;
        }
    `}
`;
export const EMSIPrice = styled.div<{
  theme?: Theme;
}>`
  ${({ theme }) => `
      font-size:${SIZES.h8}px;
      font-family:${FONT_FAMILY.semibold};
      ${theme.breakpoints.down("sm")} {
        font-size:${SIZES.body1}px;
      }
  `}
`;
export const AdjustEstimateModalSectionPlanSelect = styled(Select)<{
  theme?: Theme;
}>`
  ${({ theme }) => `
  
  `}
`;
export const InputInfoControl = styled.div<{
  theme?: Theme;
}>`
  ${({ theme }) => `
      width:100%;
      display: flex;
       justify-content: right;
       margin-top:20px;
       ${theme.breakpoints.down("md")} {
 
      }

  `}
`;
export const InputInfoControlInner = styled.div<{
  theme?: Theme;
}>`
  ${({ theme }) => `
      width:100%;
      display:flex;
      justify-content:space-between;
  `}
`;
export const InputInfoResetBtn = styled(Button)<{
  theme?: Theme;
}>`
  ${({ theme }) => `
    font-size:${SIZES.body4}px;
    font-family:${FONT_FAMILY.medium};
    padding:9px 15px;
    border:1px solid black;
    border-radius:20px;
    display:flex;
    align-items:center;
    &:hover {
      border:1px solid #1098e7;
      color:#1098e7
    }
  `}
`;
export const InputInfoSaveBtn = styled(Button)<{
  theme?: Theme;
}>`
  ${({ theme }) => `
    font-size:${SIZES.body4}px;
    font-family:${FONT_FAMILY.medium};
    color:white;
    padding:9px 15px;
    border-radius:20px;
    margin-left:10px;
    display:flex;
    align-items:center;
    border:1px solid #1098e7;
    background-color:#1098e7;
    &:hover {
      background-color:white;
      color:#1098e7;
    }
  `}
`;
