import React, { useState, useEffect, useRef } from "react";
import {
  DashboardLayoutMain,
  DashboardLayoutNavbar,
  DashboardLayoutBox,
  DashboardLayoutSidebar,
  DashboardLayoutContainerOuter,
} from "./styles";

import { TDashboardLayoutProps } from "./types";
import { useAuth } from "../../context/AppContext";
import { Sidebar } from "../../components/custom";

const DashboardLayout = ({ children, ...props }: TDashboardLayoutProps) => {
  const myRef = useRef<any>();
  const { showMobileMenu, handleMobileMenu } = useAuth();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (width >= 1200) {
      handleMobileMenu(true);
    } else {
      handleMobileMenu(false);
    }
    // eslint-disable-next-line
  }, [width]);

  //----------- outClick event---------///

  const handleClickInside = () => {
    if (showMobileMenu && width < 1200) {
      handleMobileMenu(!showMobileMenu)
    }
  };

  return (
    <DashboardLayoutMain {...props} className="dashboard-layout-main">
      <DashboardLayoutNavbar className="dash-board-layout-navbar" />
      <DashboardLayoutBox className="dashboard-layoutbox">
        <DashboardLayoutSidebar
          className="dashboard-layout-sidebar"
          style={
            showMobileMenu
              ? { transform: "translateX(0)" }
              : { transform: "translateX(-100%)" }
          }
          ref={myRef}
        >
          <Sidebar {...props} />
        </DashboardLayoutSidebar>
        <DashboardLayoutContainerOuter className="dash-board-layout-container-outer"
          onClick={handleClickInside}
        >
          {children}
        </DashboardLayoutContainerOuter>
      </DashboardLayoutBox>

    </DashboardLayoutMain>
  );
};

export default DashboardLayout;
