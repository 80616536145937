import React, { useEffect } from "react";
import { DashboardLeftSdieInner } from "./styles";
import "./index.css";
import MontlyReveue from "./components/montlyReveue";
import LeftSideDescription from "./components/leftSideDescription";
import MarketInfo from "./components/marketInfo";
import ExtraOffer from "./components/extraOffer";
import Competitors from "./components/competitors";
import MoneyInfos from "./components/moneyInfos";
import AnnualRevenue from "./components/annualRevenue";
import FinancialCalculator from "./components/financialCalculator";
import TurboMainContainer from "../../layout/turboMainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/AppContext";

const EarningPotential = () => {
  const { id } = useParams();
  const { allReports, setCurrentReport, setMapCars, setLoadingDashboardLayout, openBigMapStatus, setDetailMap } = useAuth();
  const navigate = useNavigate();

  const handlePage = () => {
    let report = allReports.find((item: any) => item.report_id === id);
    if (!report) {
      report = allReports[0];
      navigate(`/${report.type}/${report.report_id}`, {
        replace: true,
      });
      setCurrentReport(report);
      setDetailMap(false);
    } else {
      setCurrentReport(report);
      setDetailMap(false);
    }
  };

  useEffect(() => {
    setMapCars([]);
    if (allReports && allReports !== undefined) {
      if (allReports.length > 0) {
        handlePage();
      }
      setLoadingDashboardLayout(false);
    }

    //eslint-disable-next-line
  }, [allReports, id]);


  useEffect(() => {
    if (!openBigMapStatus) {
      const scrollPosition = localStorage.getItem('scrollPosition');
      if (scrollPosition) {
        window.scrollTo({ top: parseFloat(scrollPosition) });
        localStorage.removeItem('scrollPosition');
      }
    }
  }, [openBigMapStatus])

  return (
    <TurboMainContainer className="TurboMainContainer">
      <DashboardLeftSdieInner className="DashboardLeftSdieInner">

        <LeftSideDescription />

        <MarketInfo />

        <MoneyInfos id={id} />

        <FinancialCalculator />

        <MontlyReveue id={id} />

        <AnnualRevenue id={id} />

        <ExtraOffer id={id} />

        <Competitors id={id} />

      </DashboardLeftSdieInner>
    </TurboMainContainer>
  );
};

export default EarningPotential;
