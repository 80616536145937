import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { Link } from "react-router-dom";
import { COLORS, FONT_FAMILY, MSIZES, SIZES } from "../../../constants";
import { Input } from "antd";

export const LoginUpContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        padding:60px 75px 30px 75px;
        display:flex;
        max-width:1440px;
        margin:auto;
        ${theme.breakpoints.down("md")} {
            padding:0;
            margin:initial;
          }
    `}
`;

export const LoginUpContentLeft = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:50%;
        padding:0 0 0 40px;

     ${theme.breakpoints.down("md")} {
            display:none;
          }

    `}
`;

export const SRTitle1 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.h4}px;
        font-family:${FONT_FAMILY.semibold};
    `}
`;
export const SRTitle2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.h4}px;
    font-family:${FONT_FAMILY.semibold};
    color:${COLORS.primary};
    padding-bottom:15px;
    `}
`;
export const SRTitle3 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body1}px;
    font-family:${FONT_FAMILY.medium};
    padding-bottom:41px;
    `}
`;

export const SRCheckItems = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-family:${FONT_FAMILY.regular};
    font-size:${SIZES.body1}px;
    `}
`;
export const CItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        margin-bottom:20px;
    `}
`;

export const IitemImg = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        margin-right:10px;
    `}
`;
export const IitemDes = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display: flex;
        align-items: center;
        font-family:${FONT_FAMILY.regular}
    `}
`;

export const LoginUpContentRight = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:0 25px 0 20px ;
          width:50%;
        ${theme.breakpoints.down("md")} {
            padding:30px 25px;
            width:100%;
          }
    `}
`;
export const CRArticle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
          display:none;
        ${theme.breakpoints.down("md")} {
            display:block;
            font-size:${MSIZES.h1}px;
            font-family:${FONT_FAMILY.bold};
            text-align:center;
            display:flex;
            align-items:center;
            justify-content:center;
            ${theme.breakpoints.down("sm")} {
                flex-direction:column;
            }
        }
    `}
`;
export const CRArticle1 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            color:${COLORS.black};
            margin-right:5px;
        }
    `}
`;
export const CRArticle2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            color:${COLORS.primary};
        }
    `}
`;

export const CRTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.h5}px;
        font-family:${FONT_FAMILY.medium};
        text-align:center;
        padding:0 0 45px 0;

        ${theme.breakpoints.down("md")} {
            font-size:${MSIZES.h2}px;
            padding:15px 0 15px 0;

          }
    `}
`;
export const ContinueGoogle = styled.a<{ theme?: Theme }>`
  ${({ theme }) => `

        align-items:center;
        display:flex;
        justify-content:center;
        margin-bottom:2px;
        border-radius: 10px;
        width:100%;
        ${theme.breakpoints.down("md")} {
            border-radius:50px;
          }
    `}
`;
export const GooleInner = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `

        ${theme.breakpoints.down("md")} {
            padding:11px 66px;
            border-radius:30px;
        }
    `}
`;
export const GoogleIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        margin-right:30px;

        ${theme.breakpoints.down("sm")} {
            width:20px;
            margin-right:10px;
        }
    `}
`;
export const GBName = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
       font-size:${SIZES.body1}px;
       font-family:${FONT_FAMILY.medium};

       ${theme.breakpoints.down("md")} {
        font-size:${MSIZES.body2}px;
        }
    `}
`;
export const OrLine = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display:flex;
        position:relative;
        margin:14px 0 14px 0;

        ${theme.breakpoints.down("sm")} {
            height:69px;
        }
    
    `}
`;
export const LineDiv1 = styled.hr<{ theme?: Theme }>`
  ${({ theme }) => `
    margin-top:37px;
    border:1px solid #DEDEDE;
    width:50%;
`}
`;
export const DivL = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    padding:20px;
    font-size:${SIZES.body1}px;
    font-family:${FONT_FAMILY.regular};
    
`}
`;
export const LineDiv2 = styled.hr<{ theme?: Theme }>`
  ${({ theme }) => `
    margin-top:37px;
    border:1px solid #DEDEDE;
    width:50%;
`}
`;
export const LoginUpWindow = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:0 0 30px 0;
        display:flex;
        flex-direction:column;
        ${theme.breakpoints.down("sm")} {
            padding:0 0 10px 0;
        }
    `}
`;
export const InputUNALN = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        gap:10px;
        ${theme.breakpoints.down("sm")} {
            flex-direction:column;
        }
    `}
`;
export const InputFName = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
     padding:13px 18px;
        margin-right:14px;
        border:1px solid #DEDEDE;
        outline:0;
        width:50%;
        border-radius:6px;
        margin:14px 15px 17px 0;
        font-size:${SIZES.body2}px;

        ${theme.breakpoints.down("sm")} {
            width:100%;
            font-size:${MSIZES.body2}px;
            padding:12px 20px;
        }

    `}
`;
export const InputLName = styled(Input)<{ theme?: Theme }>`
  ${({ theme }) => `
         padding:13px 18px;
        border:1px solid #DEDEDE;
        outline:0;
        width:100%;
        border-radius:6px;
        font-size:${SIZES.body1}px;
        ${theme.breakpoints.down("sm")} {
            width:100%;
            font-size:${MSIZES.body2}px;
            padding:12px 20px;
        }
    `}
`;
export const InputLNamePassword = styled(Input.Password)<{ theme?: Theme }>`
  ${({ theme }) => `
         padding:13px 18px;
        border:1px solid #DEDEDE;
        outline:0;
        width:100%;
        border-radius:6px;
        font-size:${SIZES.body1}px;
        ${theme.breakpoints.down("sm")} {
            width:100%;
            font-size:${MSIZES.body2}px;
            padding:12px 20px;
        }
    `}
`;
export const InputEmail = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
         padding:13px 18px;
        border:1px solid #DEDEDE;
        outline:0;
        width:100%;
        border-radius:6px;
        margin:14px 0 17px 0;
        font-size:${SIZES.body1}px;
        ${theme.breakpoints.down("sm")} {
            width:100%;
            font-size:${MSIZES.body2}px;
            margin:0 0 14px 0;
            padding:12px 20px;

        }
    `}
`;

export const InputPassword = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:13px 18px;
        border:1px solid #DEDEDE;
        outline:0;
        width:100%;
        border-radius:6px;
        margin:14px 0 17px 0;
        font-size:${SIZES.body1}px;
        ${theme.breakpoints.down("sm")} {
            width:100%;
            font-size:${MSIZES.body2}px;
            margin:0 0 14px 0;
            padding:12px 20px;

        } 

    `}
`;
export const Descrpition = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body1}px;
    font-family:${FONT_FAMILY.regular};
    margin:26px 0;
    color:${COLORS.black};
    text-align:right;
        &:hover {
            color:${COLORS.primary}
        }
    ${theme.breakpoints.down("sm")} {
        font-size:${MSIZES.body2}px;
        text-align:end;
        color:${COLORS.primary};
    } 
    `}
  &:hover {
    cursor: pointer;
  }
`;
export const LoginUpBtn = styled(Link)<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.h8}px;
    font-family:${FONT_FAMILY.bold};
    color:white;
    padding:10px 30px;
    background-color:black;
    border-radius:40px;
    outline:0;
    text-align:center;

    
    ${theme.breakpoints.down("sm")} {
        font-size:${MSIZES.body2}px;
    } 

    `}
`;
export const AccountCreate = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display:flex;
    justify-content:center;
    margin-top:10px;
    ${theme.breakpoints.down("lg")} {
      display:none;
    } 
    ${theme.breakpoints.down("md")} {
      display:none;
    } 
    ${theme.breakpoints.down("sm")} {
      display:none;
    } 

    `}
`;
export const ForgotModal = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body2}px;
        padding:0 42px;
        color:black;

        ${theme.breakpoints.down("sm")} {
            padding:0;
            display: flex;
            flex-direction: column;
            align-items: center;
        } 
    `}
`;
export const ModalIconWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    `}
`;
export const ModalIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    padding: 10px;
    height: 220px;
}

    ${theme.breakpoints.down("sm")} {
        width: 194px;
        height: 55px;
    } 

    `}
`;

export const ForgotLabel1 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.h8}px;
    font-family:${FONT_FAMILY.semibold};
    padding:0 30px 10px 30px;
    text-align:center;

    ${theme.breakpoints.down("sm")} {
        font-size:${MSIZES.body2}px;
    } 
    `}
`;

export const ForgotLabel2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body1}px;
    font-family:${FONT_FAMILY.regular};
    text-align:center;
    ${theme.breakpoints.down("sm")} {
        font-size:${MSIZES.body2}px;
        text-align:center;
        padding:0;
    } 
    `}
`;

export const InputForm = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    padding:30px 0 0 0;

    ${theme.breakpoints.down("sm")} {
        font-size:${MSIZES.body2}px;
        width:100%;
    } 
    `}
`;

export const InputEmails = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body1}px;
    padding:13px 18px;
    border:1px solid #DEDEDE;
    outline:0;
    width:100%;
    border-radius:6px;
    margin:14px 0 17px 0;

    
    ${theme.breakpoints.down("sm")} {
        font-size:${MSIZES.body2}px;
        padding:12px 20px;
        margin:0 0 40px 0;
    } 
    `}
`;

export const SendBtn = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.h9}px;
    font-family:${FONT_FAMILY.medium};
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:13px 0px;
    background-color:black;
    border-radius:40px;
    outline:0;
    margin-top: 30px;
    text-align:center;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    border:1px solid #DEDEDE;
    width: 100%;

    &:hover {
        color:${COLORS.primary};
        background-color:transparent;
        border:1px solid ${COLORS.primary};
    }

    ${theme.breakpoints.down("sm")} {
        font-size:${MSIZES.body2}px;
        margin-top:15px;
    } 
    `}
`;

export const HeaderRightSide = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
   font-family:${FONT_FAMILY.regular};
   display:flex;
   align-items:center;
   justify-content:center;
   margin-top:10px;
   ${theme.breakpoints.down("md")} {

  }
  `}
`;
export const HeaderPtag = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
   font-family:${FONT_FAMILY.regular};
   margin-right:10px;

   ${theme.breakpoints.down("md")} {
    font-size:${MSIZES.body2}px;
}
  `}
`;
export const HeaderLoginBtn = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
   font-family:${FONT_FAMILY.medium};
   color:${COLORS.blue};

   ${theme.breakpoints.down("md")} {
    font-size:${MSIZES.body2}px;
}

  `}
`;

export const RequiredValidate = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    color:${COLORS.red};
    padding:0 10px;
    margin-top: 1px;
    font-size:13px;
   ${theme.breakpoints.down("md")} {
  }

  `}
`;

export const ForgotPasswordContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display: flex;
    justify-content: flex-end;
    width: 100%
  `}
`;

export const Button = styled.button<{
  theme?: Theme;
  borderRadius: any;
  padding: any;
  backgroundColor: any;
  color: any;
}>`
  ${({ theme, borderRadius, padding, backgroundColor, color }) => `
    display:flex;
    text-align:center;
    align-items:center;
    justify-content:center;
    background-color:${backgroundColor};
    color:${color};
    padding:${padding.split(" ")[0]}px ${padding.split(" ")[1]}px;
    outline:0;
    text-align:center;
    font-size:${SIZES.h8}px;
    font-family:${FONT_FAMILY.bold};
    border-radius:${borderRadius}px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    border:1px solid #DEDEDE;
     .5s;
    &:hover {
        color:${COLORS.primary};
        background-color:transparent;
        border:1px solid ${COLORS.primary};

    }

      ${theme.breakpoints.down("md")} {
          border-radius:50px;
          font-size:${SIZES.body1}px;
          font-weight:${FONT_FAMILY.semibold};
          padding:10px 15px;
      }
  `}
  }
`;
