import React from "react";
import Chart from "react-apexcharts";

import "./index.css";

const ApexChartsColumnRevenues = (props: any) => {
  const { demand, categories } = props;
  const Fakeseries = [
    {
      name: "demand",
      data: [31, 40, 28, 51, 42, 109, 100, 76, 56, 87, 45, 88],
    },
  ];

  const options: any = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          chart: {
            height: 250,
          },
        }
      }
    ],
    xaxis: {
      type: "category",
      categories: categories,
    },
    yaxis: {
      show: true,
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
        return (
          '<div class="toolTipContainer">' +
          "<span>" +
          series[seriesIndex][dataPointIndex]?.toFixed(0) +
          "%" +
          "</span>" +
          "</div>"
        );
      },
      style: {
        fontSize: "20px",
        fontFamily: "Roboto",
      },
      x: {
        show: true,
        format: "HH:mm",
      },
      y: {
        show: true,
        formatter: (value: any) => `${value.toFixed(0)} %`,
      },
      marker: {
        show: false,
      },
    },
  };

  return <Chart options={options} series={demand ? demand.series : Fakeseries} type="area" height={350} />;
};

export default ApexChartsColumnRevenues;
