
//////////////fetch average daily rate data///////////

export const ChartRevenueData: any = {
  series: [{ name: "Revenue", data: [28, 28.3, 29] }],
  analyticsData: { subscribers: 92600 },
};
export const ChartDemandData: any = {
  series: [{ name: "Demand", data: [12, 13.1, 11] }],
  analyticsData: { subscribers: 92600 },
};
export const ChartRateData: any = {
  series: [{ name: "Average Daily Rate", data: [13, 15, 14] }],
  analyticsData: { subscribers: 92600 },
};
export const ChartAnnualROIData: any = {
  series: [{ name: "Annual ROI", data: [15, 15, 14, 23, 14] }],
  analyticsData: { subscribers: 92600 },
};
export const ChartRevenueLongData: any = {
  series: [{ name: "Revenue", data: [28, 28.3, 29, 23, 34, 23, 24, 33, 24.3, 29.3] }],
  analyticsData: { subscribers: 92600 },
};
export const ChartDemandLongData: any = {
  series: [{ name: "Demand", data: [12, 13.1, 11, 32, 12, 23, 13, 15,18.9, 28.5] }],
  analyticsData: { subscribers: 92600 },
};
export const ChartRateLongData: any = {
  series: [
    {
      name: "Average Daily Rate",
      data: [15, 12.1, 13, 22, 32, 23, 13, 15,12.9, 23.5],
    },
  ],
  analyticsData: { subscribers: 92600 },
};
export const ChartAnnualROILongData: any = {
  series: [{ name: "Annual ROI", data: [15, 15, 14, 21, 32, 18, 22, 21, 29, 24] }],
  analyticsData: { subscribers: 92600 },
};
