const validateName = (name: string) => {
  const nameRegex = /(.|\s)*\S(.|\s)*/;
  return nameRegex.test(name);
};

const validateEmailAddress = (email: string) => {
  const emailRegex =
    /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;

  return emailRegex.test(email);
};

const validateEightNumber = (password: string) => {
  const passwordRegex = /^.{8,}$/;
  return passwordRegex.test(password);
};
const validateOneLowerCase = (password: string) => {
  const passwordRegex = /^(?=.*?[a-z])/;
  return passwordRegex.test(password);
};
const validateOneUpperCase = (password: string) => {
  const passwordRegex = /^(?=.*?[A-Z])/;
  return passwordRegex.test(password);
};
const validateOneDigit = (password: string) => {
  const passwordRegex = /^(?=.*?[0-9])/;
  return passwordRegex.test(password);
};
const validateOneSpecial = (password: string) => {
  const passwordRegex = /^(?=.*?[#?!@$%^&*-])/;
  return passwordRegex.test(password);
};

const validatePhoneNumber = (phoneNumber: string) => {
  // eslint-disable-next-line
  const reg1 = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;
  return reg1.test(phoneNumber);
};

export {
  validateName,
  validateEmailAddress,
  validateEightNumber,
  validateOneLowerCase,
  validateOneUpperCase,
  validateOneDigit,
  validateOneSpecial,
  validatePhoneNumber,
};
