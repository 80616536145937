import React, { useState } from "react";
import {
  Article,
  BlueCheck,
  CalculatorSide,
  CarImage,
  CheckIcon,
  CheckSide,
  Description,
  Description1,
  DescriptionAirdna,
  EmptySide,
  EndE,
  EndF,
  EstimateInner,
  EstimateSide,
  ExpBtn,
  ExploreBtn,
  FaqImage,
  FaqImageInner,
  FirstSeDes,
  FirstSeFooterFirst,
  FirstSeTitle,
  FirstSection,
  GreenSections,
  HowMoney,
  HowMoneyInner,
  HowMoneyInnerInner,
  ImgArticle,
  IncomeDesc,
  InnerMoney,
  Line,
  ReallyEndSection,
  ReasonDescriptionTitle,
  ReasonDescriptionTitleSpan,
  RectangleShape,
  RectangleShape2,
  SignupNewsletter,
  SignupNewsletterInner,
  StyledDiv,
  StyledImg,
  TitleInner,
  TurboFoxContainer,
  TurboFoxTitle,
  TuroFaqs,
  TuroFaqsInner,
  TuroFaqsTitle,
  UnlockColor,
  UnlockVacation,
  UnlockVacationInner,
  WindowArticle,
  Image,
  BlueFont,
  Paragraph,
  Row,
  FooterIconRow,
  FooterIcon,
  SmallRectangleShapeImage1,
  CarIcon,
  ParagraphMobile,
  Article2,
  GreenFont,
  ImgShape2Mobile,
  Paragraph2,
  ImgArticle2,
  Article3,
  RedSpan,
  Flex,
  CalculatorSide3,
  Paragraph3,
  SmallRectangleShapeImage2,
  TuroContainer,
  PlusMinus,
  CarImage2,
  EndEList,
  CImage,
  StyledImg2,
  StyledImg3,
  FirstSectionTitleWrapper,
  FirstSectionTitleInputWrapper,
  Unlockhosting,
} from "./styles";

import AOS from "aos";
import "aos/dist/aos.css";
import { ICONS, IMAGES } from "../../constants";
import { PageLayout } from "../../layout";
import { faqs } from "./data";
import { Collapse } from "react-collapse";
import { InstagramFilled, TikTokFilled, YoutubeFilled } from "@ant-design/icons";
import "./index.css";
import { HOST } from "../../utils/constant";
import Calculator from "./calulator";

const LandingPage = () => {
  AOS.init();
  const [openedFaq, setOpenedFaq] = useState(1);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <PageLayout className="page-layout">
      <TurboFoxContainer
        className="turbofox-container"
        data-aos="fade-down"
        data-aos-duration="800"
      >
        <TurboFoxTitle className="turbofox-title">
          <GreenSections className="green-sections">
            TurboFox Calculator:{" "}
            <TitleInner>How much can I make on Turo?</TitleInner>
          </GreenSections>
        </TurboFoxTitle>
        <EstimateSide className="estimate-side">
          <EstimateInner className="estimate-inner">
            Estimate the income potential of any Turo car rental in the US
          </EstimateInner>
        </EstimateSide>
        <WindowArticle className="window-article">
          <RectangleShape
            className="rectangle-shape"
            src={IMAGES.retangle1}
          ></RectangleShape>
          <SmallRectangleShapeImage1
            className="small-shape-image1"
            src={IMAGES.retangleMobile1}
          ></SmallRectangleShapeImage1>
          <ParagraphMobile className="paragraph-mobile">
            Boost Your Car Rental Earnings&nbsp;-{" "}
            <BlueFont className="blue-font">
              It's Free to Check! <CarIcon src={ICONS.car}></CarIcon>{" "}
            </BlueFont>
          </ParagraphMobile>
          <Calculator />
          <Article className="article">
            <Paragraph className="paragraph">
              Boost Your Car Rental Earnings&nbsp;-{" "}
              <BlueFont className="blue-font">
                It's Free to Check! <CarIcon src={ICONS.car}></CarIcon>{" "}
              </BlueFont>
            </Paragraph>
            <StyledDiv className="styled-div">
              Did you know the top cars on Turo earn more than 40% return on
              investment per year? See how much money you can make renting out
              your car on Turo. Our easy tool uses millions of Turo car rental
              data points and vehicle pricing data to help you figure out your
              potential earnings and potential return, or how much cash you can
              earn with your car.
            </StyledDiv>
            <CheckSide className="check-side">
              <CheckIcon className="check-icon">
                <BlueCheck
                  className="blue-check"
                  src={ICONS.blue_check}
                  alt=""
                />
              </CheckIcon>
              <Description className="description">
                Find Out the Best Times to Rent Your Car: Learn when you can
                make the most money by renting out your car.
              </Description>
            </CheckSide>
            <CheckSide className="check-side">
              <CheckIcon className="check-icon">
                <BlueCheck
                  className="blue-check"
                  src={ICONS.blue_check}
                  alt=""
                />
              </CheckIcon>
              <Description className="description">
                Track How Your Car Does Over Time: Understand How your car's
                earning changes during different times of the year.
              </Description>
            </CheckSide>
            <CheckSide className="check-side">
              <CheckIcon className="check-icon">
                <BlueCheck
                  className="blue-check"
                  src={ICONS.blue_check}
                  alt=""
                />
              </CheckIcon>
              <Description className="description">
                Figure Out Your Total Profit: Calculate how much you'll earn
                from rentals and how much you'll get when sell your car, giving
                you the full picture of your earnings.
              </Description>
            </CheckSide>
          </Article>
        </WindowArticle>
        <CalculatorSide className="calculator-article1">
          <Article2 className="article">
            <Paragraph2 className="paragraph">
              Meet the Top Turo Car
              <GreenFont className="blue-font">
                Earning Calculator! <CarIcon src={ICONS.car}></CarIcon>{" "}
              </GreenFont>
            </Paragraph2>
            <StyledDiv className="styled-div">
              Before, figuring out how much money you could make by renting out
              your car was just a guess. You know it can make money, but how
              much? When is it a smart choice to rent out your car?
              <br />
              Introducing our awesome car profit calculator for Turo hosts and
              investors. Just give us any address, make and model, and we'll
              show you special estimates for:
            </StyledDiv>
            <ImgShape2Mobile>
              <Image src={IMAGES.smallskeleton1} alt="" />
            </ImgShape2Mobile>
            <CheckSide className="check-side">
              <CheckIcon className="check-icon">
                <BlueCheck
                  className="blue-check"
                  src={ICONS.blue_check}
                  alt=""
                />
              </CheckIcon>
              <Description className="description">
                How much money you could make in a year <br /> - your annual
                earnings.
              </Description>
            </CheckSide>
            <CheckSide className="check-side">
              <CheckIcon className="check-icon">
                <BlueCheck
                  className="blue-check"
                  src={ICONS.blue_check}
                  alt=""
                />
              </CheckIcon>
              <Description className="description">
                The average daily price you can charge.
              </Description>
            </CheckSide>
            <CheckSide className="check-side">
              <CheckIcon className="check-icon">
                <BlueCheck
                  className="blue-check"
                  src={ICONS.blue_check}
                  alt=""
                />
              </CheckIcon>
              <Description className="description">
                How often your car might be rented out - your car's demand.
              </Description>
            </CheckSide>
            <CheckSide className="check-side">
              <CheckIcon className="check-icon">
                <BlueCheck
                  className="blue-check"
                  src={ICONS.blue_check}
                  alt=""
                />
              </CheckIcon>
              <Description className="description">
                How different times of the year might change your earnings - the
                market seasonality.
              </Description>
            </CheckSide>
            <CheckSide className="check-side">
              <CheckIcon className="check-icon">
                <BlueCheck
                  className="blue-check"
                  src={ICONS.blue_check}
                  alt=""
                />
              </CheckIcon>
              <Description className="description">
                How your car compares to other rental cars nearby.
              </Description>
            </CheckSide>
            <CheckSide className="check-side">
              <CheckIcon className="check-icon">
                <BlueCheck
                  className="blue-check"
                  src={ICONS.blue_check}
                  alt=""
                />
              </CheckIcon>
              <Description className="description">
                Which vehicle protection plan is right for you and how it
                effects annual vehicle earnings.
              </Description>
            </CheckSide>
            <CheckSide className="check-side">
              <CheckIcon className="check-icon">
                <BlueCheck
                  className="blue-check"
                  src={ICONS.blue_check}
                  alt=""
                />
              </CheckIcon>
              <Description className="description">
                How the vehicle down payment and vehicle purchase price impact
                actual earnings.
              </Description>
            </CheckSide>
          </Article2>
          <ImgArticle>
            <StyledImg src={IMAGES.bigskeleton1} alt="" />
          </ImgArticle>
        </CalculatorSide>
        <CalculatorSide3 className="calculator-article2">
          <RectangleShape2
            className="rectangle-shape"
            src={IMAGES.retangle2}
          ></RectangleShape2>
          <SmallRectangleShapeImage2
            className="small-shape-image1"
            src={IMAGES.retangleMobile2}
          ></SmallRectangleShapeImage2>
          <ImgArticle2>
            <StyledImg2 src={IMAGES.bigskeleton2} alt="" />
          </ImgArticle2>
          <Article3 className="article">
            <Paragraph3 className="paragraph">
              How Does Our Car Earning Calculator
              <Flex className="flex">
                Work for Turo&nbsp;
                <RedSpan className="red-span">
                  Hosts? <CarIcon src={ICONS.car}></CarIcon>
                </RedSpan>
              </Flex>
            </Paragraph3>

            <ImgShape2Mobile>
              <Image src={IMAGES.smallskeleton2} alt="" />
            </ImgShape2Mobile>

            <StyledDiv className="styled-div">
              When you type in an address, our calculator looks at other
              distinct vehicles nearby that are also rented out. It makes a list
              and focuses on the ones most like yours. The more cars like yours,
              the more it helps us guess your earnings
            </StyledDiv>
            <StyledDiv className="styled-div">
              Our calculator also thinks about things that affect all car
              rentals, like different times of the year, how many people want to
              rent cars, how many days per month nearby cars rent for, and how
              much money you could make.
            </StyledDiv>
            <StyledDiv className="styled-div">
              By looking at cars like yours, our tool makes really good guesses
              about how much money you could make by renting out your car at any
              address in the US. This way, you get a clear idea of what you
              might earn if you rent out your car a lot.
            </StyledDiv>
          </Article3>
        </CalculatorSide3>
        <CalculatorSide className="calculator-articale3">
          <Article2 className="article">
            <ReasonDescriptionTitle>
              <ReasonDescriptionTitleSpan>Why use</ReasonDescriptionTitleSpan> a
              Car Rental Earnings <br />
              <Flex>
                Calculator for Turo? &nbsp;<CarIcon src={ICONS.car}></CarIcon>{" "}
              </Flex>
            </ReasonDescriptionTitle>
            <StyledDiv className="styled-div">
              If you're thinking about renting out your car for the first time,
              or if you want to rent out more cars, our calculator is here to
              help you find out how much money you could make. Here's how you
              can use our car rental earnings calculator:
            </StyledDiv>
            <ImgShape2Mobile>
              <Image src={IMAGES.smallskeleton3} alt="" />
            </ImgShape2Mobile>
            <CheckSide className="check-side">
              <CheckIcon className="check-icon">
                <BlueCheck
                  className="blue-check"
                  src={ICONS.red_check}
                  alt=""
                />
              </CheckIcon>
              <Description className="description">
                People who rarely use their car and are thinking about renting
                it out.
              </Description>
            </CheckSide>
            <CheckSide className="check-side">
              <CheckIcon className="check-icon">
                <BlueCheck
                  className="blue-check"
                  src={ICONS.red_check}
                  alt=""
                />
              </CheckIcon>
              <Description className="description">
                Investors and folks who manage lots of cars, trying to figure
                out how much they'll earn from a new car they're thinking of
                adding.
              </Description>
            </CheckSide>
            <CheckSide className="check-side">
              <CheckIcon className="check-icon">
                <BlueCheck
                  className="blue-check"
                  src={ICONS.red_check}
                  alt=""
                />
              </CheckIcon>
              <Description className="description">
                Car salespeople or advisors helping buyers understand how much
                they could earn by renting out a car they buy.
              </Description>
            </CheckSide>
            <CheckSide className="check-side">
              <CheckIcon className="check-icon">
                <BlueCheck
                  className="blue-check"
                  src={ICONS.red_check}
                  alt=""
                />
              </CheckIcon>
              <Description className="description">
                Gig economy drivers who are curious about the earnings from
                renting their car versus driving for services like Uber, Lyft or
                DoorDash.
              </Description>
            </CheckSide>
          </Article2>
          <ImgArticle className="img-article">
            <StyledImg3
              className="styled-img"
              src={IMAGES.bigskeleton3}
              alt=""
            />
          </ImgArticle>
        </CalculatorSide>
        <FaqImage className="faq-img">
          <FaqImageInner className="faq-image-inner">
            <StyledImg className="styled-img" src={IMAGES.faq} alt="" />
          </FaqImageInner>
        </FaqImage>
        <TuroFaqs
          className="turo-faqs"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <TuroFaqsInner className="turo-faqs-inner">
            <TuroContainer className="turo-container">
              <TuroFaqsTitle className="turo-faqs-title">
                Turo Car Rental Earnings FAQs
              </TuroFaqsTitle>
              <Description1 className="description1">
                Curious about how much you can earn by renting your car on Turo,
                or if it's a good choice in your area? Here are some
                easy-to-understand answers to common questions.
              </Description1>
              {faqs.map((faq, index) => (
                ///index = 2
                <HowMoney
                  key={index}
                  className={openedFaq === index + 1 ? "active" : ""}
                  onClick={() =>
                    setOpenedFaq(openedFaq !== index + 1 ? index + 1 : 0)
                  }
                >
                  <HowMoneyInner className="how-money-inner">
                    <PlusMinus
                      className={openedFaq === index + 1 ? "active" : ""}
                      onClick={() =>
                        setOpenedFaq(openedFaq !== index + 1 ? index + 1 : 0)
                      }
                    />
                  </HowMoneyInner>
                  <HowMoneyInnerInner className="how-money-inner">
                    {openedFaq !== index + 1 ? (
                      <IncomeDesc
                        className={openedFaq === index + 1 ? "active" : ""}
                      >
                        {faq.title}
                      </IncomeDesc>
                    ) : (
                      <Line className="active">{faq.title}</Line>
                    )}
                    <Collapse
                      isOpened={openedFaq === index + 1}
                      className="collapse"
                    >
                      <InnerMoney
                        className={openedFaq === index + 1 ? "active" : ""}
                      >
                        {faq.description}
                      </InnerMoney>
                    </Collapse>
                  </HowMoneyInnerInner>
                </HowMoney>
              ))}
            </TuroContainer>
          </TuroFaqsInner>
        </TuroFaqs>
        <Unlockhosting className="unlock-hosting">
          <CarImage
            data-aos="zoom-in"
            data-aos-duration="1800"
            className="car-image"
          >
            <CImage className="img" src={IMAGES.carImage2} alt="" />
          </CarImage>
          <EmptySide className="empty-slide">
            <UnlockVacation className="unlock-vacation">
              <UnlockVacationInner className="unlock-vacation-inner">
                <UnlockColor className="unlock-color">Unlock</UnlockColor>{" "}
                your car hosting cheat code.
              </UnlockVacationInner>
            </UnlockVacation>
            <DescriptionAirdna className="description-airdna">
              TurboFox’s powerful yet easy-to-use tools make hosting and investing a breeze. This means having every insight you need, all in one place, always up-to-date.
            </DescriptionAirdna>
            <ExploreBtn className="explore-btn" onClick={handleScrollTop}>
              <ExpBtn className="exp-btn">Explore for free</ExpBtn>
            </ExploreBtn>
          </EmptySide>
          <CarImage2
            data-aos="zoom-in"
            data-aos-duration="1800"
            className="car-image"
          >
            <CImage className="img" src={IMAGES.carImage1} alt="" />
          </CarImage2>
        </Unlockhosting>
        <SignupNewsletter className="signup-new-letter">
          <SignupNewsletterInner className="signup-news-letter-inner">
            <FirstSection className="first-section">
              <FirstSectionTitleInputWrapper className="FirstSectionTitleInputWrapper">
                <FirstSectionTitleWrapper className="FirstSectionTitleWrapper">
                  <FirstSeTitle className="first-title">
                    Sign up for our newsletter
                  </FirstSeTitle>
                  <FirstSeDes className="first-sedes">
                    Get expert tips and secrets to help build your car rental empire
                  </FirstSeDes>
                </FirstSectionTitleWrapper>
                <div data-theme-1>
                  <iframe src="https://turbofox.substack.com/embed" title="turbofox" width="100%" height="150"></iframe>
                </div>
              </FirstSectionTitleInputWrapper>
              <StyledDiv className="styled-div">
                <Row className="row">
                  <FirstSeFooterFirst className="first-footer-first">
                    hello@useturbofox.com | USA
                  </FirstSeFooterFirst>
                  <FooterIconRow className="footer-icon-row">
                    <FooterIcon
                      className="footer-icon"
                      to="https://www.tiktok.com/@useturbofox"
                    >
                      <TikTokFilled style={{ fontSize: '25px' }} />
                    </FooterIcon>
                    <FooterIcon
                      className="footer-icon"
                      to="https://www.youtube.com/channel/UChRgQ1T9kl4F3g4o1HOcKGA"
                    >
                      <YoutubeFilled style={{ fontSize: '25px' }} />
                    </FooterIcon>
                    <FooterIcon
                      className="footer-icon"
                      to="https://www.instagram.com/useturbofox"
                    >
                      <InstagramFilled style={{ fontSize: '25px' }} />
                    </FooterIcon>
                  </FooterIconRow>
                </Row>
              </StyledDiv>
            </FirstSection>
          </SignupNewsletterInner>
        </SignupNewsletter>
        <ReallyEndSection className="really-end-section">
          <EndF className="endf">
            © 2024 TurboFox. All rights reserved.
          </EndF>
          <Flex className="flex">
            <EndE className="ende" href={`${HOST}/privacy`} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </EndE>
            <EndEList className="endelist" href={`${HOST}/terms`} target="_blank" rel="noopener noreferrer">Terms of service</EndEList>
          </Flex>

        </ReallyEndSection>
      </TurboFoxContainer>
    </PageLayout >
  );
};

export default LandingPage;
