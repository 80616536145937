import React from "react";

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";

import StatsWithAreaChart from "../statsWithAreaChart";
import { kFormatter } from "../../../utils/Utils";
import { numToString } from "../../../utils/fetch_data";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  {
    id: "lineChart",
  }
);

const MiniLineChart = (props: any) => {
  const { data, height, color, status } = props;
  const options = {
    chart: {
      id: "Average Daily Rate",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      show: false,
    },
    // colors: [warning],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2.5,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100],
      },
    },

    xaxis: {
      labels: {
        show: true,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
        return (
          '<div class="toolTipContainer">' +
          "<span>" +
          `
          ${status === "demand" || status === "annualROI" || status === "occupancy"? "" : "$"}` +
          `${status === "occupancy" ? numToString(series[seriesIndex][dataPointIndex] * 100) : numToString(series[seriesIndex][dataPointIndex])}`  +
          `${status === "demand" || status === "annualROI" || status === "occupancy" ? "%" : ""}` +
          "</span>" +
          "</div>"
        );
      },
      style: {
        fontSize: "20px",
        fontFamily: "Roboto",
      },
      x: {
        show: true,
        format: "HH:mm",
      },
      y: {
        formatter: (value: any) => `${value} %`,
      },
      marker: {
        show: false,
      },
    },
    colors: [color],
  };

  return (
    <>
      { data &&
        <StatsWithAreaChart
          color={color}
          stats={kFormatter(data?.analyticsData?.subscribers)}
          statTitle="Orders Received"
          options={options}
          series={data.series}
          height={height}
          type="area"
        />
      }
    </>
  );
};

export default MiniLineChart;
