import React, { useMemo, useState } from "react";
import {
  TableContainer,
  TableWrapper,
  TableMain,
  TableHead,
  TableBody,
  TableBodyCell,
  TableHeadRow,
  TableBodyRow,
  TableEmpty,
  TableBodyRow2,
} from "./styles";
import { TTableProps, TTableHeadItem } from "./types";
import getObjectDynamicPath from "../../utils/extended-proto";
import { sortByProperty } from "../../utils/helper";
import TableFilter from "./tableFilter";

import {
  LockContainer,
  LockContainerButton,
  LockContainerButtonWrapper,
  LockContainerHeader,
  LockWrapper,
} from "./lockStyle";
import { Modal } from "antd";
import SubScription from "../custom/subScription";

export type Tsorter = keyof any;

const LockTable = ({
  head = [],
  items = [],
  renderItem = (_b) => { },
  ...props
}: TTableProps) => {
  const visibleItems = head.filter((x) => x.visible);

  const [sorter, setSorter] = useState<Tsorter>("job_id");
  const [showSubScription, setShowSubScription] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);

  const clickHandle = () => {
    setShowSubScription(true);
  };
  const CancelHandle = () => {
    setShowSubScription(false);
  };
  const data = useMemo<any[]>(() => {
    return sortByProperty<any>(items, sorter, sortOrder ?? "asc");
  }, [sorter, sortOrder, items]);

  return (
    <TableContainer {...props} className="table-container">
      <TableWrapper className="table-wrapper">
        <TableMain className="table-main">
          <TableHead className="table-head">
            <TableHeadRow className="table-head">
              {visibleItems.map((x: any) => (
                <TableFilter
                  key={x.reference}
                  name={x.reference}
                  label={x.label}
                  active={sorter}
                  onClickfilter={(e) => {
                    setSorter(x.reference);
                    setSortOrder(e);
                  }}
                />
              ))}
            </TableHeadRow>
          </TableHead>
          {!!data.length && (
            <TableBody className="table-body">
              {data.map((rowData: any, rowIndex: number) => {
                if (rowIndex % 2 === 0) {
                  return (
                    <TableBodyRow2
                      key={Math.random() * 10000}
                      className="table-body-row"
                    >
                      {visibleItems.map((a: TTableHeadItem, b: number) => (
                        <TableBodyCell
                          key={Math.random() * 10000}
                          className="table-body-cell"
                        >
                          {renderItem({
                            headItem: a,
                            cell: {
                              index: b,
                              data: getObjectDynamicPath(rowData, a.reference),
                            },
                            row: {
                              index: rowIndex,
                              data: rowData,
                            },
                            table: items,
                          })}
                        </TableBodyCell>
                      ))}
                    </TableBodyRow2>
                  );
                } else {
                  return (
                    <TableBodyRow
                      key={Math.random() * 10000}
                      className="table-body-row"
                    >
                      {visibleItems.map((a: TTableHeadItem, b: number) => (
                        <TableBodyCell
                          key={Math.random() * 10000}
                          className="table-body-cell"
                        >
                          {renderItem({
                            headItem: a,
                            cell: {
                              index: b,
                              data: getObjectDynamicPath(rowData, a.reference),
                            },
                            row: {
                              index: rowIndex,
                              data: rowData,
                            },
                            table: items,
                          })}
                        </TableBodyCell>
                      ))}
                    </TableBodyRow>
                  );
                }
              })}

            </TableBody>
          )}
        </TableMain>
        <LockWrapper className="LockWrapper">
          <LockContainer className="LockContainer">
            <LockContainerHeader className="LockContainerHeader">
              Want to unlock even more insights?
            </LockContainerHeader>
            <LockContainerButtonWrapper className="LockContainerButtonWrapper">
              <LockContainerButton
                className="LockContainerButton"
                onClick={clickHandle}
              >
                Upgrade your plan
              </LockContainerButton>
            </LockContainerButtonWrapper>
          </LockContainer>
          <Modal
            open={showSubScription}
            onCancel={CancelHandle}
            width={1200}
            centered
            style={{ overflow: "auto" }}
            footer=""
          >
            <SubScription />
          </Modal>
        </LockWrapper>
        {!data.length && <TableEmpty>No records</TableEmpty>}
      </TableWrapper>
    </TableContainer>
  );
};

export default LockTable;
