import React, { useRef } from 'react';
import {
  ModalActions,
  ModalBody,
  ModalHead,
  ModalMain,
  ModalWrapper,
  ModalTitle,
  ModalClose,
} from './styles';
import { TModalProps } from './types';
import { CloseOutlined } from '@ant-design/icons';

const Modal = ({
  title,
  onClose,
  size = 'small',
  children,
  actions = [],
  ...props
}: TModalProps) => {
  const wrapperRef = useRef<null | HTMLDivElement>(null);

  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === wrapperRef.current && onClose) {
      onClose();
    }
  };

  return (
    <ModalWrapper ref={wrapperRef} onMouseDown={handleClose} {...props} className='modal-wrapper'>
      <ModalMain size={size} animation="slide-right" className='modal-main'>
        <ModalHead>
          <ModalTitle className='modal-title'>{title}</ModalTitle>
          <ModalClose onClick={onClose} className='modal-close'>
            <CloseOutlined />
          </ModalClose>
        </ModalHead>
        <ModalBody className='modal-body'>{children}</ModalBody>
        {!!actions.length && <ModalActions className='modal-action'>{actions}</ModalActions>}
      </ModalMain>
    </ModalWrapper>
  );
};

export default Modal;
