import React, { useState } from "react";
import {
  Market,
  MarketInfos,
  MarketSpan,
  MarketSpans,
  MarketType,
} from "../../styles";
import { useAuth } from "../../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { Modal, message } from "antd";
import {
  InformModalContainerButton,
  InformModalContainerButtonWrapper,
  UpgradePlanButton,
  UpgradePlanButtonWrapper,
  UpgradePlanContainer,
  UpgradePlanContainerContent,
  UpgradePlanContainerIcon,
  UpgradePlanContainerSubContent,
} from "./styles";
import SubScription from "../../../../components/custom/subScription";
import { ICONS } from "../../../../constants";

const MarketInfo = (props: any) => {
  const { currentReport, handleCreateNewMarketReport, apiKey, setLoadingDashboardLayout } = useAuth();
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [showMiniModal, setShowMiniModal] = useState<boolean>(false);

  const handleOk = () => {
    setShowUpgradeModal(false);
  };
  const handleCancel = () => {
    setShowUpgradeModal(false);
    setShowMiniModal(false)
  };

  const [showSubScription, setShowSubScription] = useState<boolean>(false);

  const clickHandle = () => {
    setShowUpgradeModal(false);
    setShowSubScription(true);
  };
  const CancelHandle = () => {
    setShowSubScription(false);
  };
  const navigate = useNavigate();

  const handleMarketOverview = async () => {
    setLoadingDashboardLayout(true);
    setShowMiniModal(false);
    try {
      const marketInfo = {
        city: currentReport.city,
        "lat": currentReport.lat,
        "lng": currentReport.lng,
      }
      const res = await handleCreateNewMarketReport(marketInfo);
      navigate(`/marketoverview/${res?.report_id}`, { replace: true });
      setLoadingDashboardLayout(false);
    } catch (error: any) {
      message.error(
        error?.response?.data?.error?.message ?? "Something went wrong!"
      );
      setLoadingDashboardLayout(false);
    }
  };

  const handleShowMiniModal = () => {
    if (apiKey?.total_market_reports > 0) {
      setShowMiniModal(true);
    } else {
      setShowUpgradeModal(true);
    }
  }

  return (
    <>
      <MarketInfos className="MarketInfo">
        <Market>
          Market :
          <MarketSpan onClick={handleShowMiniModal}>
            {currentReport?.city}
          </MarketSpan>
        </Market>
        <MarketType>
          Make & Model :{" "}
          <MarketSpans>
            {currentReport?.make} {currentReport?.model} {currentReport?.year[0]}-{currentReport?.year[1]}
          </MarketSpans>
        </MarketType>

        <Modal
          open={showUpgradeModal}
          onOk={handleOk}
          onCancel={handleCancel}
          centered
          width={400}
          footer=""
        >
          <UpgradePlanContainer className="UpgradePlanContainer">
            <UpgradePlanContainerIcon
              src={ICONS.lock}
              className="UpgradePlanContainerIcon"
            ></UpgradePlanContainerIcon>
            <UpgradePlanContainerContent className="UpgradePlanContainerContent">
              You don't have enough Market's report
            </UpgradePlanContainerContent>
            <UpgradePlanButtonWrapper className="UpgradePlanButtonWrapper">
              <UpgradePlanButton
                className="UpgradePlanButton"
                onClick={clickHandle}
              >
                Upgrade your plan
              </UpgradePlanButton>
            </UpgradePlanButtonWrapper>
          </UpgradePlanContainer>
        </Modal>
        <Modal
          open={showSubScription}
          onCancel={CancelHandle}
          width={1200}
          centered
          style={{ overflow: "auto" }}
          footer=""
        >
          <SubScription />
        </Modal>

        <Modal
          open={showMiniModal}
          onOk={handleOk}
          onCancel={handleCancel}
          centered
          className="InformModal"
          width={400}
          footer=""
        >
          <UpgradePlanContainer className="UpgradePlanContainer">
            <UpgradePlanContainerContent className="UpgradePlanContainerContent">
              Create Market Report?
            </UpgradePlanContainerContent>
            <UpgradePlanContainerSubContent className="UpgradePlanContainerSubContent">
              This will use one Market Report credit.
            </UpgradePlanContainerSubContent>
            <InformModalContainerButtonWrapper className="InformModalContainerButtonWrapper">
              <InformModalContainerButton
                className="InformModalContainerButton"
                onClick={handleMarketOverview}
              >
                Create Report
              </InformModalContainerButton>
              <InformModalContainerButton
                className="InformModalContainerButton"
                onClick={handleCancel}
              >
                Cancel
              </InformModalContainerButton>
            </InformModalContainerButtonWrapper>
          </UpgradePlanContainer>
        </Modal>
      </MarketInfos>
    </>
  );
};

export default MarketInfo;
