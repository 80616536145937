import logo from "../assets/images/logo.png";
import bigskeleton1 from "../assets/images/big_skeleton1.png";
import bigskeleton2 from "../assets/images/big_skeletion2.png";
import bigskeleton3 from "../assets/images/big_skeletion3.png";
import smallskeleton1 from "../assets/images/small_skeleton1.png";
import smallskeleton2 from "../assets/images/small_skeleton2.png";
import smallskeleton3 from "../assets/images/small_skeleton3.png";
import faq from "../assets/images/faq.png";
import carImage1 from "../assets/images/car_img1.png";
import carImage2 from "../assets/images/car_img2.png";
import retangle1 from "../assets/images/Rectangle1.png";
import retangle2 from "../assets/images/Rectangle2.png";
import retangleMobile1 from "../assets/images/rectangle1Mobile.png";
import retangleMobile2 from "../assets/images/rectangle2Mobile.png";
import dashboard_bg from "../assets/images/dashboard_bg.png";
import manycars_bg from "../assets/images/manycars_bg.png";
import forgot_modal from "../assets/images/forgot_modal.png";
import login_bg from "../assets/images/login_bg.png";
import moreinfo_bg from "../assets/images/moreinfo_bg.png";
import whatlooking_bg from "../assets/images/whatlooking_bg.png";
import resetpassword from "../assets/images/resetpassword.png";

import location_car1 from "../assets/images/locationPage/location_car1.png";
import location_car2 from "../assets/images/locationPage/location_car2.png";
import location_car3 from "../assets/images/locationPage/location_car3.png";
import location_car4 from "../assets/images/locationPage/location_car4.png";
import locationItemIcon from "../assets/images/locationPage/locationItemIcon.png";

import basic from "../assets/images/subscription/basic.png";
import free from "../assets/images/subscription/free.png";
import premium from "../assets/images/subscription/premium.png";
import pro from "../assets/images/subscription/pro.png";

import person1 from "../assets/images/locationPage/300-1.jpg";
import person2 from "../assets/images/locationPage/300-20.jpg";
import person3 from "../assets/images/locationPage/300-4.jpg";

//eslint-disable-next-line
export default {
  logo,
  bigskeleton1,
  bigskeleton2,
  bigskeleton3,
  faq,
  carImage1,
  carImage2,
  retangle1,
  retangle2,
  smallskeleton1,
  smallskeleton2,
  smallskeleton3,
  retangleMobile1,
  retangleMobile2,
  dashboard_bg,
  manycars_bg,
  forgot_modal,
  login_bg,
  moreinfo_bg,
  whatlooking_bg,
  resetpassword,
  location_car1,
  location_car2,
  location_car3,
  location_car4,
  locationItemIcon,
  basic,
  pro,
  free,
  premium,
  person1,
  person2,
  person3
};
