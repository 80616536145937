import blue_check from "../assets/icons/blue_check.png";
import car from "../assets/icons/car.png";
import facebook from "../assets/icons/facebook.png";
import maker from "../assets/icons/maker.png";
import model from "../assets/icons/model.png";
import red_check from "../assets/icons/red_check.png";
import twiter from "../assets/icons/twiter.png";
import year from "../assets/icons/year.png";
import menu_open from "../assets/icons/menu_open.png";
import menu_close from "../assets/icons/menu_close.png";
import linkedIn from "../assets/icons/linkedin.png";
import tick from "../assets/icons/tick.png";
import Google from "../assets/icons/Google.png";
import investor from "../assets/icons/investor.png";
import back_arrow from "../assets/icons/back_arrow.png";
import bank from "../assets/icons/bank.png";
import competitor from "../assets/icons/competitor.png";
import google from "../assets/icons/Google.png";
import host from "../assets/icons/host.png";
import investment from "../assets/icons/investment.png";
import market_performance from "../assets/icons/market_performance.png";
import other from "../assets/icons/other.png";
import press from "../assets/icons/press.png";
import price_advice from "../assets/icons/price_advice.png";
import tourism from "../assets/icons/tourism.png";
import potential from "../assets/icons/potential.png";
import b_other from "../assets/icons/b_other.png";
import b_market_performance from "../assets/icons/b_performance.png";
import b_potential from "../assets/icons/b_potential.png";
import car_dealer from "../assets/icons/car_dealer.png";
import b_investor from "../assets/icons/b_investor.png";
import b_press from "../assets/icons/b_press.png";
import b_car_dealer from "../assets/icons/b_car_dealer.png";
import b_competitor from "../assets/icons/b_competitor.png";
import b_host from "../assets/icons/b_host.png";
import b_bank from "../assets/icons/b_bank.png";
import b_investment from "../assets/icons/b_investment.png";
import b_price_advice from "../assets/icons/b_price.png";
import b_tourism from "../assets/icons/b_tourism.png";
import m_back_arrow from "../assets/icons/m_back_arrow.png";
import new_next_arrow_black from "../assets/icons/new_next_arrow_black.png";
import new_next_arrow_blue from "../assets/icons/new_next_arrow_blue.png";

/////dashboard

import reveue from "../assets/icons/dashboard/reveue.png";
import Graph from "../assets/icons/dashboard/Graph.png";
import financial_calculator_model from "../assets/icons/dashboard/financial_calculator_model.png";
import car_GTMustang_Turbo from "../assets/icons/dashboard/detailvs/car_GTMustang_Turbo.png";
import car_Porsche_GTR from "../assets/icons/dashboard/detailvs/car_Porsche_GTR.png";
import viewAll_button from "../assets/icons/dashboard/detailvs/viewAll_button.png";
import airplane from "../assets/icons/dashboard/detailvs/airplane.png";
import available from "../assets/icons/dashboard/detailvs/available.png";
import diesel from "../assets/icons/dashboard/detailvs/diesel.png";
import door from "../assets/icons/dashboard/detailvs/door.png";
import seat from "../assets/icons/dashboard/detailvs/seat.png";
import GT_Mustang1 from "../assets/icons/dashboard/detailvs/GT_Mustang1.png";
import GT_Mustang2 from "../assets/icons/dashboard/detailvs/GT_Mustang2.png";
import Porsche_GTR1 from "../assets/icons/dashboard/detailvs/Porsche_GTR1.png";
import Porsche_GTR2 from "../assets/icons/dashboard/detailvs/Porsche_GTR2.png";
import Toyota_Supra1 from "../assets/icons/dashboard/detailvs/Toyota_Supra1.png";
import Toyota_Supra2 from "../assets/icons/dashboard/detailvs/Toyota_Supra2.png";
import report_icon from "../assets/icons/report-icon.png";
import account_icon from "../assets/icons/account-icon.png";

////top 10 Hosts

import kasandrab from "../assets/icons/dashboard/topTenHosts/kasandrab.png";
import marioJulioC from "../assets/icons/dashboard/topTenHosts/marioJulioC.png";
import melissad from "../assets/icons/dashboard/topTenHosts/melissad.png";
import nataliaz from "../assets/icons/dashboard/topTenHosts/nataliaz.png";
import stephena from "../assets/icons/dashboard/topTenHosts/stephena.png";
import unknown from "../assets/icons/dashboard/topTenHosts/unknown.png";

import carNo from "../assets/icons/dashboard/topTenHosts/carNo.png";
import estimatedMon from "../assets/icons/dashboard/topTenHosts/estimatedMon.png";
import estimatedROI from "../assets/icons/dashboard/topTenHosts/estimatedROI.png";
import rank from "../assets/icons/dashboard/topTenHosts/rank.png";

import subscriptioonCheck from "../assets/icons/dashboard/subscriptioonCheck.png";
import googleMap from "../assets/icons/dashboard/googleMap.png";
import list from "../assets/icons/dashboard/list.png";
import car_report_icon from "../assets/icons/dashboard/car_report_icon.png";
import market_report_icon from "../assets/icons/dashboard/market_report_icon.png";

import gooogleMapIcon_top from "../assets/icons/GoogleMap/mapIcon_top.png";
import gooogleMapIcon_bottom from "../assets/icons/GoogleMap/mapIcon_bottom.png";
import gooogleMapIcon_white from "../assets/icons/GoogleMap/marker-white.png";


import lock from "../assets/icons/lock.png";

import informIcon from "../assets/icons/dashboard/informIcon.png";

// eslint-disable-next-line
export default {
  blue_check,
  car,
  facebook,
  maker,
  model,
  red_check,
  twiter,
  linkedIn,
  year,
  menu_close,
  menu_open,
  tick,
  Google,
  investor,
  back_arrow,
  bank,
  competitor,
  google,
  host,
  investment,
  market_performance,
  other,
  press,
  price_advice,
  tourism,
  car_dealer,
  potential,
  b_other,
  b_market_performance,
  b_potential,
  b_investor,
  b_press,
  b_car_dealer,
  b_competitor,
  b_host,
  b_price_advice,
  b_tourism,
  b_investment,
  b_bank,
  m_back_arrow,
  new_next_arrow_black,
  new_next_arrow_blue,
  reveue,
  Graph,
  financial_calculator_model,
  car_GTMustang_Turbo,
  car_Porsche_GTR,
  viewAll_button,
  airplane,
  diesel,
  door,
  available,
  seat,
  GT_Mustang1,
  GT_Mustang2,
  Porsche_GTR1,
  Porsche_GTR2,
  Toyota_Supra1,
  Toyota_Supra2,
  report_icon,
  account_icon,
  kasandrab,
  marioJulioC,
  melissad,
  nataliaz,
  stephena,
  unknown,
  subscriptioonCheck,
  carNo,
  estimatedMon,
  estimatedROI,
  rank,
  googleMap,
  list,
  car_report_icon,
  market_report_icon,
  gooogleMapIcon_top,
  gooogleMapIcon_bottom,
  lock,
  informIcon,
  gooogleMapIcon_white,
};
