import React, { useEffect, useState } from "react";
import { Input, Select } from "antd";
import { DatePicker } from 'antd';
import {
  PersonOwnButtonWrapper,
  PersonOwnCarFilterOptions,
  PersonOwnCarInfoItem,
  PersonOwnCarInfoItemsWrapper,
  PersonOwnCarInfoLabel,
  PersonOwnCarInfoWrapper,
  PersonOwnCarInfoYearSelects,
  SelectOptionButton,
  SelectOptionButtonClear,
} from "./styles";

import "./index.css";
import CarDataTable from "./carDataTable";
import Lock from "./Lock";
import { useAuth } from "../../../../context/AppContext";
import { numTothreeComma, removeComma } from "../../../../utils/fetch_data";
import dayjs from 'dayjs';
import { THost } from "../../../../api/report/types";
import ReportAPI from "../../../../api/report";
import { useParams } from "react-router-dom";
import { InputWrapper, RequiredValidate } from "../personOwnCarInfo/styles";
import { sortMakeModel } from "../../../../utils/helper";

const CarData = (props: any) => {
  const { currentPage } = props;
  const { id } = useParams();
  const { apiKey, currentReport, setMapCarsTop, setMapCarsBottom } = useAuth();
  const [totalData, setTotalData] = useState<THost[]>([])
  const [makeModelData, setMakeModelData] = useState<any>()
  const [allModelOptions, setAllModelOptions] = useState<any>([])
  const [makes, setMakes] = useState<any[]>([])
  const [models, setModels] = useState<any[]>([])
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [minYear, setMinYear] = useState<string>('');
  const [maxYear, setMaxYear] = useState<string>('');

  const [selectMake, setSelectMake] = useState<any[]>([])
  const [selectModel, setSelectModel] = useState<any[]>([])

  const [filterValues, setFilterValues] = useState<any>({
    from: "",
    to: "",
    minROI: '',
    maxROI: '',
    minRevenue: '',
    maxRevenue: '',
    minMsrp: '',
    maxMsrp: '',
  });
  const dateFormat = 'YYYY';

  const [errors, setErrors] = useState<any>({
    minROI: '',
    maxROI: '',
    minRevenue: '',
    maxRevenue: '',
    minMsrp: '',
    maxMsrp: '',
  });

  ////////////////////////// Get Data //////////////////////////////////////////////

  const handleGetReportData = async () => {
    setLoadingReport(true);
    try {
      const res = await ReportAPI.getCarData(id);
      const res2 = await ReportAPI.getAllSelectionData(id);
      if (res) {
        setTotalData(res);
        setLoadingReport(false)
      }
      if (res2) {
        setMakeModelData(res2.data)

        let makeoptions = res2?.make?.map((make: any) => {
          return {
            type: "make",
            label: make,
            value: make,
          }
        })

        let modeloptions = res2?.model?.map((model: any) => {
          return {
            type: "model",
            label: model,
            value: model,
          }
        })

        makeoptions = sortMakeModel(makeoptions);
        modeloptions = sortMakeModel(modeloptions);

        setMakes(makeoptions)
        setModels(modeloptions);
        setAllModelOptions(modeloptions);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    if (currentReport) {
      setMapCarsTop([])
      setMapCarsBottom([])
      handleGetReportData();
    }
    // eslint-disable-next-line
  }, [currentReport]);

  useEffect(() => {
    setMapCarsTop([])
    setMapCarsBottom([])
    //eslint-disable-next-line
  }, [])

  ///////////////////////////////// End //////////////////////////////////////////////////


  ///////////////////////////////  Make & Model /////////////////////////////////////////////

  const handleAddMake = (value: any) => {
    setSelectMake((v) => ([...v, value]))
  }

  const handleAddModel = (value: any) => {
    setSelectModel((v) => [...v, value])
    let makeselects: any[] = [];

    if (makeModelData) {
      for (let make in makeModelData) {
        for (let model in makeModelData[make]) {
          if (model === value) {
            if (!selectMake.find((item: any) => item === make)) {
              makeselects = [...makeselects, make]
            }
          }
        }
      }
    }

    setSelectMake((makes: any) => [...makes, ...makeselects])
  }

  const handleRemoveMake = (val: any, event: any) => {
    const value = event.value;
    setSelectMake(selectMake.filter((item) => item !== value))
  }

  const handleRemoveModel = (val: any, event: any) => {
    const value = event.value;
    setSelectModel(selectModel.filter((item) => item !== value))
  }

  const handleClearMake = () => {
    setSelectMake([]);
    setSelectModel([]);
    let modeloptions = sortMakeModel(allModelOptions);
    setModels(modeloptions);
  };

  const handleClearModel = () => {
    setSelectModel([]);
  };

  useEffect(() => {
    let matcedData = [];
    if (makeModelData) {
      if (selectMake.length === 0) {
        let modeloptions = sortMakeModel(allModelOptions);

        setModels(modeloptions);
        setMinYear("")
        setMaxYear("")
      } else {
        for (let one of selectMake) {
          for (let dataKey in makeModelData) {
            if (one === dataKey) {
              matcedData.push(makeModelData[dataKey])
            }
          }
        }

        let modeloptions: any[] = [];
        matcedData.forEach((model) => {
          for (let key in model) {
            modeloptions = [...modeloptions, {
              type: "model",
              label: key,
              value: key,
            }]
          }
        })

        modeloptions = sortMakeModel(modeloptions);
        setModels(modeloptions);

        let yearArray: any = []
        matcedData?.forEach((item: any) => {
          for (let key in item) {
            if (typeof item[key] === 'object') {
              yearArray.push(...item[key])
            } 
          }
        })

        //@ts-ignore
        const uniqueArray = [...new Set(yearArray)]

        let sortedArray = uniqueArray.sort((a: any, b: any) => a - b)
        setMinYear(sortedArray[0].toString())
        setMaxYear(sortedArray[sortedArray.length - 1].toString())
      }
    }

    //eslint-disable-next-line
  }, [selectMake])

  ///////////////////////////////// end ////////////////////////////////////////////////////////


  const handleChangeMinYear = (date: Date, dateString: any) => {
    setFilterValues((data: any) => ({
      ...data,
      from: dateString
    }))
  };

  const handleChangeMaxYear = (date: Date, dateString: any) => {
    setFilterValues((data: any) => ({
      ...data,
      to: dateString
    }))
  };

  const handleChangeFiters = (event: any) => {
    const name = event.target.name;
    const value = (event.target.value ? removeComma(event.target.value) : '');
    if (!Number.isNaN(value)) {
      setFilterValues((data: any) => ({
        ...data,
        [name]: value
      }))
    }

  };

  useEffect(() => {
    if (parseInt(filterValues.minROI) > parseInt(filterValues.maxROI)) {
      setErrors((info: any) => ({ ...info, minROI: "Min must be less than Max" }));
      setErrors((info: any) => ({ ...info, maxROI: "Max must be greater than Min" }));
    } else {
      setErrors((info: any) => ({ ...info, minROI: "" }));
      setErrors((info: any) => ({ ...info, maxROI: "" }));
    }
    if (parseInt(filterValues.minRevenue) > parseInt(filterValues.maxRevenue)) {
      setErrors((info: any) => ({ ...info, minRevenue: "Min must be less than Max" }));
      setErrors((info: any) => ({ ...info, maxRevenue: "Max must be greater than Min" }));
    } else {
      setErrors((info: any) => ({ ...info, minRevenue: "" }));
      setErrors((info: any) => ({ ...info, maxRevenue: "" }));
    }
    if (parseInt(filterValues.minMsrp) > parseInt(filterValues.maxMsrp)) {
      setErrors((info: any) => ({ ...info, minMsrp: "Min must be less than Max" }));
      setErrors((info: any) => ({ ...info, maxMsrp: "Max must be greater than Min" }));
    } else {
      setErrors((info: any) => ({ ...info, minMsrp: "" }));
      setErrors((info: any) => ({ ...info, maxMsrp: "" }));
    }
  }, [filterValues])

  const handleApply = async () => {
    setLoadingReport(true)
    const value = {
      makes: selectMake,
      models: selectModel,
      year_range: [(filterValues.from), (filterValues.to)],
      roi_range: [filterValues.minROI, filterValues.maxROI],
      revenue_range: [filterValues.minRevenue, filterValues.maxRevenue],
      msrp_range: [filterValues.minMsrp, filterValues.maxMsrp],
      report_id: id,
    }

    const body = { value }
    try {
      let res = await ReportAPI.getFilterRevenueData(body);
      if (res) {
        setTotalData(res)
        setLoadingReport(false)
      }
    } catch (error) {

    }
  }
  const handleClearAllInfo = async () => {
    setSelectMake([])
    setSelectModel([])
    setMinYear('')
    setMaxYear('')
    setFilterValues({
      from: '',
      to: '',
      minROI: '',
      maxROI: '',
      minRevenue: '',
      maxRevenue: '',
      minMsrp: '',
      maxMsrp: '',
    })

    setLoadingReport(true)
    const value = {
      makes: [],
      models: [],
      year_range: ["", ""],
      roi_range: ["", ""],
      revenue_range: ["", ""],
      msrp_range: ["", ""],
      report_id: id,
    }

    const body = { value }
    try {
      let res = await ReportAPI.getFilterRevenueData(body);
      if (res) {
        setTotalData(res)
        setLoadingReport(false)
      }
    } catch (error) {

    }
  }

  return (
    <>
      {!apiKey?.plan_type ? (
        <Lock />
      ) : (
        <PersonOwnCarInfoWrapper>
          <PersonOwnCarFilterOptions className="PersonOwnCarFilterOptions">
            <PersonOwnCarInfoItemsWrapper className="PersonOwnCarInfoItemsWrapper">
              <PersonOwnCarInfoItem className="PersonOwnCarInfoItem">
                <PersonOwnCarInfoLabel className="PersonOwnCarInfoLabel">
                  Make
                </PersonOwnCarInfoLabel>
                <Select
                  className=""
                  mode="multiple"
                  allowClear
                  style={{
                    height: 39,
                    width: '100%'
                  }}
                  onSelect={handleAddMake}
                  placeholder="select make"
                  value={selectMake}
                  options={makes}
                  onClear={handleClearMake}
                  onDeselect={handleRemoveMake}
                />
              </PersonOwnCarInfoItem>
              <PersonOwnCarInfoItem className="PersonOwnCarInfoItem">
                <PersonOwnCarInfoLabel className="PersonOwnCarInfoLabel">
                  Model
                </PersonOwnCarInfoLabel>
                <Select
                  className=""
                  mode="multiple"
                  allowClear
                  style={{
                    height: 39,
                    width: '100%'
                  }}
                  placeholder="select model"
                  onSelect={handleAddModel}
                  value={selectModel}
                  options={models}
                  onClear={handleClearModel}
                  onDeselect={handleRemoveModel}
                />
              </PersonOwnCarInfoItem>
              <PersonOwnCarInfoItem className="PersonOwnCarInfoItem">
                <PersonOwnCarInfoLabel className="PersonOwnCarInfoLabel">
                  Year
                </PersonOwnCarInfoLabel>
                <PersonOwnCarInfoYearSelects className="PersonOwnCarInfoYearSelects">
                  <DatePicker
                    placeholder="from"
                    picker="year"
                    style={{ height: 39, width: '100%' }}
                    inputReadOnly={true}
                    //@ts-ignore
                    value={filterValues.from ? dayjs(filterValues.from, dateFormat) : undefined}
                    minDate={minYear ? dayjs(minYear, dateFormat) : undefined}
                    maxDate={filterValues.to ? dayjs(filterValues.to, dateFormat) : (maxYear ? dayjs(maxYear, dateFormat) : undefined)}
                    onChange={handleChangeMinYear}
                    allowClear
                  />
                  <DatePicker
                    placeholder="to"
                    picker="year"
                    style={{ height: 39, width: '100%' }}
                    inputReadOnly={true}
                    //@ts-ignore
                    value={filterValues.to ? dayjs(filterValues.to, dateFormat) : undefined}
                    minDate={filterValues.from ? dayjs(filterValues.from, dateFormat) : (minYear ? dayjs(minYear, dateFormat) : undefined)}
                    maxDate={maxYear ? dayjs(maxYear, dateFormat) : undefined}
                    onChange={handleChangeMaxYear}
                    allowClear
                  />
                </PersonOwnCarInfoYearSelects>
              </PersonOwnCarInfoItem>
              <PersonOwnCarInfoItem className="PersonOwnCarInfoItem">
                <PersonOwnCarInfoLabel className="PersonOwnCarInfoLabel">
                  ROI
                </PersonOwnCarInfoLabel>
                <PersonOwnCarInfoYearSelects className="PersonOwnCarInfoYearSelects">
                  <InputWrapper className="InputWrapper">
                    <Input
                      placeholder="min"
                      style={{ height: 39 }}
                      name="minROI"
                      suffix='%'
                      value={numTothreeComma((filterValues.minROI).toString())}
                      onChange={handleChangeFiters}
                    />
                    {errors.minROI && (
                      <RequiredValidate className="RequiredValidate">
                        {errors.minROI}
                      </RequiredValidate>
                    )}
                  </InputWrapper>
                  <InputWrapper className="InputWrapper">
                    <Input
                      placeholder="max"
                      style={{ height: 39 }}
                      name="maxROI"
                      suffix='%'
                      value={numTothreeComma((filterValues.maxROI).toString())}
                      onChange={handleChangeFiters}
                    />
                    {errors.maxROI && (
                      <RequiredValidate className="RequiredValidate">
                        {errors.maxROI}
                      </RequiredValidate>
                    )}
                  </InputWrapper>
                </PersonOwnCarInfoYearSelects>
              </PersonOwnCarInfoItem>
              <PersonOwnCarInfoItem className="PersonOwnCarInfoItem">
                <PersonOwnCarInfoLabel className="PersonOwnCarInfoLabel">
                  Revenue
                </PersonOwnCarInfoLabel>
                <PersonOwnCarInfoYearSelects className="PersonOwnCarInfoYearSelects">
                  <InputWrapper className="InputWrapper">
                    <Input
                      placeholder="min"
                      style={{ height: 39 }}
                      name="minRevenue"
                      prefix='$'
                      value={numTothreeComma((filterValues.minRevenue).toString())}
                      onChange={handleChangeFiters}
                    />
                    {errors.minRevenue && (
                      <RequiredValidate className="RequiredValidate">
                        {errors.minRevenue}
                      </RequiredValidate>
                    )}
                  </InputWrapper>
                  <InputWrapper className="InputWrapper">
                    <Input
                      placeholder="max"
                      style={{ height: 39 }}
                      name="maxRevenue"
                      prefix='$'
                      value={numTothreeComma((filterValues.maxRevenue).toString())}
                      onChange={handleChangeFiters}
                    />
                    {errors.maxRevenue && (
                      <RequiredValidate className="RequiredValidate">
                        {errors.maxRevenue}
                      </RequiredValidate>
                    )}
                  </InputWrapper>
                </PersonOwnCarInfoYearSelects>
              </PersonOwnCarInfoItem>
              <PersonOwnCarInfoItem className="PersonOwnCarInfoItem">
                <PersonOwnCarInfoLabel className="PersonOwnCarInfoLabel">
                  MSRP
                </PersonOwnCarInfoLabel>
                <PersonOwnCarInfoYearSelects className="PersonOwnCarInfoYearSelects">
                  <InputWrapper className="InputWrapper">
                    <Input
                      placeholder="min"
                      style={{ height: 39 }}
                      name="minMsrp"
                      prefix='$'
                      value={numTothreeComma((filterValues.minMsrp).toString())}
                      onChange={handleChangeFiters}
                    />
                    {errors.minMsrp && (
                      <RequiredValidate className="RequiredValidate">
                        {errors.minMsrp}
                      </RequiredValidate>
                    )}
                  </InputWrapper>
                  <InputWrapper className="InputWrapper">
                    <Input
                      placeholder="max"
                      style={{ height: 39 }}
                      name="maxMsrp"
                      prefix='$'
                      value={numTothreeComma((filterValues.maxMsrp).toString())}
                      onChange={handleChangeFiters}
                    />
                    {errors.maxMsrp && (
                      <RequiredValidate className="RequiredValidate">
                        {errors.maxMsrp}
                      </RequiredValidate>
                    )}
                  </InputWrapper>
                </PersonOwnCarInfoYearSelects>
              </PersonOwnCarInfoItem>
            </PersonOwnCarInfoItemsWrapper>

            <PersonOwnButtonWrapper className="PersonOwnButtonWrapper">
              <SelectOptionButton className="selectOptionUpdate" onClick={handleApply}>
                Apply
              </SelectOptionButton>
              <SelectOptionButtonClear
                className="selectOptionUpdate"
                onClick={handleClearAllInfo}
              >
                Clear
              </SelectOptionButtonClear>
            </PersonOwnButtonWrapper>
          </PersonOwnCarFilterOptions>

          <CarDataTable loadingReport={loadingReport} tableData={totalData} currentPage={currentPage} />
        </PersonOwnCarInfoWrapper>
      )}
    </>
  );
};

export default CarData;
