import React, { useState } from "react";
import {
  AdjustEstimateModalContainer,
  AdjustEstimateModalHeader,
  AdjustEstimateModalSection,
  AdjustEstimateModalSection2,
  AdjustEstimateModalSection3,
  AdjustEstimateModalSectionPlan,
  AdjustEstimateModalSectionPlanSelect,
  AdjustEstimateModalSectionPlanTitle,
  AdjustEstimateModalSubTitle,
  AdjustEstimateModalTitle,
  AdjustEstimateScrollWrapper,
  EMSIPrice,
  EMSITitle,
  EstimateInputDiv,
  EstimateInputDivInner,
  EstimateInputItem,
  EstimateInputItemInput,
  EstimateInputItemLabel,
  EstimateInputItemTitle,
  EstimateModalSectionItem,
  EstimateModalSectionItemNew,
  InputInfoControl,
  InputInfoControlInner,
  InputInfoResetBtn,
  InputInfoSaveBtn,
} from "./styles";

import "./adjustEstimateModal.css";
import { PMT } from "./PMT";
import { FV } from "./FV";
import { useAuth } from "../../../context/AppContext";
import ClipLoader from "react-spinners/ClipLoader";
import { COLORS } from "../../../constants";
import { message } from "antd";
import AuthorizationAPI from "../../../api/authorization";

const AdjustEstimateModal = (props: any) => {
  const { modalopen } = props;
  const { currentUser, carDemand, carRevenue, carPurchasePrice } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const estimatedValues = JSON.parse(currentUser.personEstimateValues ?? null)
  const [inputValue, setInputValue] = useState<any>({
    QC: estimatedValues ? estimatedValues.QC : (carPurchasePrice && carPurchasePrice!== 'unknown' ? carPurchasePrice: 15000), //purchase price
    QF: estimatedValues ? estimatedValues.QF : 5, //interest rate
    QE: estimatedValues ? estimatedValues.QE : 5000, //down payment
    QD: estimatedValues ? estimatedValues.QD : 48, //loan term
    QG: estimatedValues ? estimatedValues.QG : 100, //montly insurance
    QN: estimatedValues ? estimatedValues.QN : 2000, //yearly depreciation
    QH: estimatedValues ? estimatedValues.QH : 50, //monthly maintence
    QJ: estimatedValues ? estimatedValues.QJ : (carRevenue ? carRevenue: 900), //average monthly earning
    QL: estimatedValues ? estimatedValues.QL : 2, //Years in Service
    QK: estimatedValues ? estimatedValues.QK : (carDemand ? carDemand: 50), //Average Demand
    QM: estimatedValues ? estimatedValues.QM : 10, // Turo Protection Plan
  });

  const FG = PMT(
    inputValue.QF / 100 / 12,
    inputValue.QD,
    -1 * (inputValue.QC - inputValue.QE),
    0,
    0
  );
  const FF =
    12 *
    (inputValue.QG +
      inputValue.QH +
      FG +
      inputValue.QJ * (inputValue.QM / 100));
  const FD = inputValue.QJ * 12;
  const FH = FD - FF;
  const FE = FH * inputValue.QL;
  const FC = inputValue.QC - inputValue.QN * inputValue.QL;
  const FI = parseInt(
    FV(
      inputValue.QF / 100 / 12,
      inputValue.QL * 12,
      PMT(
        inputValue.QF / 100 / 12,
        inputValue.QD,
        -1 * (inputValue.QC - inputValue.QE),
        0,
        0
      ),
      -1 * (inputValue.QC - inputValue.QE),
      0
    )
  );
  const FJ = FC - FI;
  const FB = FJ + FE - inputValue.QE;

  const annual_Revenue = inputValue.QJ * 12;
  const lifetime_Revenue = inputValue.QJ * 12 * inputValue.QL;
  const annual_Expenses = Math.floor(
    12 *
    (inputValue.QG +
      inputValue.QH +
      FG +
      inputValue.QJ * (inputValue.QM / 100))
  );
  const lifetime_Expenses = Math.floor(FF * inputValue.QL);
  const annual_Rental_Profit = Math.floor(FD - FF);
  const lifetime_Rental_Profit = Math.floor(FH * inputValue.QL);
  const car_Resale_Value = inputValue.QC - inputValue.QN * inputValue.QL;
  const lifetime_Resale_Profit = Math.floor(FJ + FE - inputValue.QE);
  const average_Daily_Rate = Math.floor(
    ((inputValue.QJ * 12) / 365 / inputValue.QK) * 100
  );
  const monthly_Loan_Cost = Math.floor(
    PMT(
      inputValue.QF / 100 / 12,
      inputValue.QD,
      -1 * (inputValue.QC - inputValue.QE),
      0,
      0
    )
  );
  const total_ROI = ((FB / inputValue.QE) * 100 - 0.005)?.toFixed(2);
  const resale_Profit = FC - FI;
  const loan_Remaining = Math.floor(
    parseInt(
      FV(
        inputValue.QF / 100 / 12,
        inputValue.QL * 12,
        PMT(
          inputValue.QF / 100 / 12,
          inputValue.QD,
          -1 * (inputValue.QC - inputValue.QE),
          0,
          0
        ),
        -1 * (inputValue.QC - inputValue.QE),
        0
      )
    )
  );

  const onChange = (e: any) => {
    const field = e.target.name;
    const val = parseInt(e.target.value ? e.target.value : 0);

    setInputValue((value: any) => ({ ...value, [field]: val }));
  };
  const onChangeSelect = (value: any, option: any) => {
    setInputValue((val: any) => ({ ...val, QM: value }));
  };


  const resetHandle = () => {
    setInputValue({
      QC: carPurchasePrice && carPurchasePrice !== "unknown" ? carPurchasePrice: 15000, //purchase price
      QF: 5, //interest rate
      QE: 5000, //down payment
      QD: 48, //loan term
      QG: 100, //montly insurance
      QN: 2000, //yearly depreciation
      QH: 50, //monthly maintence
      QJ: carRevenue ? carRevenue: 900, //average monthly earning
      QL: 2, //Years in Service
      QK: carDemand ? carDemand: 50, //Average Demand
      QM: 10, // Turo Protection Plan
    });
  };
  const saveHandle = () => {
    setLoading(true);
    try {
      const body = {
        personEstimateValues: JSON.stringify(inputValue),
      }
      const res: any = AuthorizationAPI.upateUser(currentUser.id, body);
      if (res) {
        message.success("Succefully Saved!")
        modalopen(false)
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdjustEstimateModalContainer className="AdjustEstimateModalContainer">
      <AdjustEstimateModalTitle className="AdjustEstimateModalTitle"></AdjustEstimateModalTitle>
      <AdjustEstimateModalSubTitle className="AdjustEstimateModalSubTitle">
        Update the fields below to get a more precise estimate for your revenue
        and profit.
      </AdjustEstimateModalSubTitle>
      <AdjustEstimateModalHeader className="AdjustEstimateModalHeader">
        <AdjustEstimateModalSection2 className="AdjustEstimateModalSection2">
          <EstimateModalSectionItem className="EstimateModalSectionItem">
            <EMSITitle className="EMSITitle">Annual Revenue</EMSITitle>
            <EMSIPrice className="EMSIPrice">${annual_Revenue?.toFixed(0)}</EMSIPrice>
          </EstimateModalSectionItem>
          <EstimateModalSectionItem className="EstimateModalSectionItem">
            <EMSITitle className="EMSITitle">Lifetime Revenue</EMSITitle>
            <EMSIPrice className="EMSIPrice">${lifetime_Revenue?.toFixed(0)}</EMSIPrice>
          </EstimateModalSectionItem>
          <EstimateModalSectionItem className="Loan Remaining">
            <EMSITitle className="EMSITitle">Annual Expenses</EMSITitle>
            <EMSIPrice className="EMSIPrice">${annual_Expenses?.toFixed(0)}</EMSIPrice>
          </EstimateModalSectionItem>
          <EstimateModalSectionItem className="EstimateModalSectionItem">
            <EMSITitle className="EMSITitle">Lifetime Expenses</EMSITitle>
            <EMSIPrice className="EMSIPrice">${lifetime_Expenses?.toFixed(0)}</EMSIPrice>
          </EstimateModalSectionItem>
          <EstimateModalSectionItem className="EstimateModalSectionItem">
            <EMSITitle className="EMSITitle">Annual Rental Profit</EMSITitle>
            <EMSIPrice className="EMSIPrice">${annual_Rental_Profit?.toFixed(0)}</EMSIPrice>
          </EstimateModalSectionItem>
          <EstimateModalSectionItem className="EstimateModalSectionItem">
            <EMSITitle className="EMSITitle">Lifetime Rental Profit</EMSITitle>
            <EMSIPrice className="EMSIPrice">
              ${lifetime_Rental_Profit?.toFixed(0)}
            </EMSIPrice>
          </EstimateModalSectionItem>
        </AdjustEstimateModalSection2>
        <AdjustEstimateModalSection3 className="AdjustEstimateModalSection3">
          <EstimateModalSectionItemNew className="EstimateModalSectionItemNew">
            <EMSITitle className="EMSITitle">Car Resale Value</EMSITitle>
            <EMSIPrice className="EMSIPrice">${car_Resale_Value?.toFixed(0)}</EMSIPrice>
          </EstimateModalSectionItemNew>
          <EstimateModalSectionItemNew className="EstimateModalSectionItemNew">
            <EMSITitle className="EMSITitle">
              Lifetime + Resale Profit
            </EMSITitle>
            <EMSIPrice className="EMSIPrice">
              ${lifetime_Resale_Profit?.toFixed(0)}
            </EMSIPrice>
          </EstimateModalSectionItemNew>
          <EstimateModalSectionItemNew className="Loan Remaining">
            <EMSITitle className="EMSITitle">Loan Remaining</EMSITitle>
            <EMSIPrice className="EMSIPrice">${loan_Remaining?.toFixed(0)}</EMSIPrice>
          </EstimateModalSectionItemNew>
          <EstimateModalSectionItemNew className="EstimateModalSectionItemNew">
            <EMSITitle className="EMSITitle">Total ROI</EMSITitle>
            <EMSIPrice className="EMSIPrice">{total_ROI}%</EMSIPrice>
          </EstimateModalSectionItemNew>
          <EstimateModalSectionItemNew className="EstimateModalSectionItemNew">
            <EMSITitle className="EMSITitle">Resale Profit</EMSITitle>
            <EMSIPrice className="EMSIPrice">${resale_Profit?.toFixed(0)}</EMSIPrice>
          </EstimateModalSectionItemNew>
        </AdjustEstimateModalSection3>
      </AdjustEstimateModalHeader>

      <AdjustEstimateScrollWrapper className="AdjustEstimateScrollWrapper">
        <AdjustEstimateModalSection className="AdjustEstimateModalSection">
          <EstimateInputDiv className="EstimateInputDiv">
            <EstimateInputDivInner className="EstimateInputDivInner">
              <EstimateInputItem className="EstimateInputItem">
                <EstimateInputItemTitle className="EstimateInputItemTitle">
                  Purchase Price
                </EstimateInputItemTitle>
                <EstimateInputItemInput
                  style={{ width: "122px" }}
                  className="EstimateInputItemInput"
                  defaultValue={15000}
                  prefix={"$"}
                  name="QC"
                  value={inputValue.QC ? inputValue.QC.toFixed(0) : 0}
                  onChange={onChange}
                ></EstimateInputItemInput>
              </EstimateInputItem>
            </EstimateInputDivInner>
            <EstimateInputDivInner>
              <EstimateInputItem className="EstimateInputItem">
                <EstimateInputItemTitle className="EstimateInputItemTitle">
                  Interest Rate
                </EstimateInputItemTitle>
                <EstimateInputItemInput
                  style={{ width: "122px" }}
                  className="EstimateInputItemInput"
                  defaultValue={5}
                  suffix="%"
                  name="QF"
                  value={inputValue.QF}
                  onChange={onChange}
                ></EstimateInputItemInput>
              </EstimateInputItem>
            </EstimateInputDivInner>
          </EstimateInputDiv>
          <EstimateInputDiv className="EstimateInputDiv">
            <EstimateInputDivInner className="EstimateInputDivInner">
              <EstimateInputItem className="EstimateInputItem">
                <EstimateInputItemTitle className="EstimateInputItemTitle">
                  Down Payment
                </EstimateInputItemTitle>
                <EstimateInputItemInput
                  style={{ width: "122px" }}
                  className="EstimateInputItemInput"
                  prefix={"$"}
                  defaultValue={5000}
                  value={inputValue.QE}
                  name="QE"
                  onChange={onChange}
                ></EstimateInputItemInput>
              </EstimateInputItem>
            </EstimateInputDivInner>
            <EstimateInputDivInner className="EstimateInputDivInner">
              <EstimateInputItem className="EstimateInputItem">
                <EstimateInputItemTitle className="EstimateInputItemTitle">
                  Loan Term (Months)
                </EstimateInputItemTitle>
                <EstimateInputItemInput
                  style={{ width: "122px" }}
                  className="EstimateInputItemInput"
                  suffix="months"
                  defaultValue={48}
                  name="QD"
                  value={inputValue.QD}
                  onChange={onChange}
                ></EstimateInputItemInput>
              </EstimateInputItem>
            </EstimateInputDivInner>
          </EstimateInputDiv>
        </AdjustEstimateModalSection>
        <AdjustEstimateModalSection className="AdjustEstimateModalSection">
          <EstimateInputDiv className="EstimateInputDiv">
            <EstimateInputDivInner className="EstimateInputDivInner">
              <EstimateInputItem className="EstimateInputItem">
                <EstimateInputItemTitle className="EstimateInputItemTitle">
                  Monthly Insurance
                </EstimateInputItemTitle>
                <EstimateInputItemInput
                  style={{ width: "122px" }}
                  className="EstimateInputItemInput"
                  prefix="$"
                  defaultValue={100}
                  name="QG"
                  value={inputValue.QG}
                  onChange={onChange}
                ></EstimateInputItemInput>
              </EstimateInputItem>
            </EstimateInputDivInner>
            <EstimateInputDivInner className="EstimateInputDivInner">
              <EstimateInputItem className="EstimateInputItem">
                <EstimateInputItemTitle className="EstimateInputItemTitle">
                  Yearly Depreciation
                </EstimateInputItemTitle>
                <EstimateInputItemInput
                  style={{ width: "122px" }}
                  className="EstimateInputItemInput"
                  prefix="$"
                  defaultValue={2000}
                  name="QN"
                  value={inputValue.QN}
                  onChange={onChange}
                ></EstimateInputItemInput>
              </EstimateInputItem>
            </EstimateInputDivInner>
          </EstimateInputDiv>
          <EstimateInputDiv className="EstimateInputDiv">
            <EstimateInputDivInner className="EstimateInputDivInner">
              <EstimateInputItem className="EstimateInputItem">
                <EstimateInputItemTitle className="EstimateInputItemTitle">
                  Monthly Maintenance
                </EstimateInputItemTitle>
                <EstimateInputItemInput
                  style={{ width: "122px" }}
                  className="EstimateInputItemInput"
                  prefix="$"
                  name="QH"
                  onChange={onChange}
                  value={inputValue.QH}
                  defaultValue={50}
                ></EstimateInputItemInput>
              </EstimateInputItem>
            </EstimateInputDivInner>
            <EstimateInputDivInner className="EstimateInputDivInner">
              <EstimateInputItem className="EstimateInputItem">
                <EstimateInputItemTitle className="EstimateInputItemTitle">
                  Monthly Loan Cost
                </EstimateInputItemTitle>
                <EstimateInputItemLabel className="EstimateInputItemLabel">
                  ${monthly_Loan_Cost}
                </EstimateInputItemLabel>
              </EstimateInputItem>
            </EstimateInputDivInner>
          </EstimateInputDiv>
        </AdjustEstimateModalSection>
        <AdjustEstimateModalSection className="AdjustEstimateModalSection">
          <EstimateInputDiv className="EstimateInputDiv">
            <EstimateInputDivInner className="EstimateInputDivInner">
              <EstimateInputItem className="EstimateInputItem">
                <EstimateInputItemTitle className="EstimateInputItemTitle">
                  Average Monthly Earnings
                </EstimateInputItemTitle>
                <EstimateInputItemInput
                  style={{ width: "122px" }}
                  className="EstimateInputItemInput"
                  prefix="$"
                  defaultValue={900}
                  name="QJ"
                  value={inputValue.QJ ? inputValue.QJ.toFixed(0) : 0}
                  onChange={onChange}
                ></EstimateInputItemInput>
              </EstimateInputItem>
            </EstimateInputDivInner>
            <EstimateInputDivInner className="EstimateInputDivInner">
              <EstimateInputItem className="EstimateInputItem">
                <EstimateInputItemTitle className="EstimateInputItemTitle">
                  Years in Service
                </EstimateInputItemTitle>
                <EstimateInputItemInput
                  style={{ width: "122px" }}
                  className="EstimateInputItemInput"
                  suffix="years"
                  defaultValue={2}
                  name="QL"
                  value={inputValue.QL}
                  onChange={onChange}
                ></EstimateInputItemInput>
              </EstimateInputItem>
            </EstimateInputDivInner>
          </EstimateInputDiv>
          <EstimateInputDiv className="EstimateInputDiv">
            <EstimateInputDivInner className="EstimateInputDivInner">
              <EstimateInputItem className="EstimateInputItem">
                <EstimateInputItemTitle className="EstimateI nputItemTitle">
                  Average Demand
                </EstimateInputItemTitle>
                <EstimateInputItemInput
                  style={{ width: "122px" }}
                  className="EstimateInputItemInput"
                  suffix="%"
                  defaultValue={50}
                  name="QK"
                  value={inputValue.QK ? inputValue.QK.toFixed(0) : 0}
                  onChange={onChange}
                ></EstimateInputItemInput>
              </EstimateInputItem>
            </EstimateInputDivInner>
            <EstimateInputDivInner className="EstimateInputDivInner">
              <EstimateInputItem className="EstimateInputItem">
                <EstimateInputItemTitle className="EstimateInputItemTitle">
                  Average Daily Rate
                </EstimateInputItemTitle>
                <EstimateInputItemLabel className="EstimateInputItemLabel">
                  ${average_Daily_Rate}
                </EstimateInputItemLabel>
              </EstimateInputItem>
            </EstimateInputDivInner>
          </EstimateInputDiv>
        </AdjustEstimateModalSection>
        <AdjustEstimateModalSectionPlan className="AdjustEstimateModalSectionPlan">
          <AdjustEstimateModalSectionPlanTitle className="AdjustEstimateModalSectionPlanTitle">
            Turo Protection Plan
          </AdjustEstimateModalSectionPlanTitle>
          <AdjustEstimateModalSectionPlanSelect
            className="AdjustEstimateModalSectionPlanSelect"
            defaultValue={10}
            options={[
              { value: 40, label: "60 Plan - 40% Fee - $0 Deductible" },
              { value: 25, label: "75 Plan - 25% Fee - $250 Deductible" },
              { value: 20, label: "80 Plan - 20% Fee - $750 Deductible" },
              { value: 15, label: "85 Plan - 15% Fee - $1625 Deductible" },
              {
                value: 10,
                label: "90 Plan - 10% Fee - $2500 Deductible",
              },
            ]}
            value={inputValue.QM}
            onChange={onChangeSelect}
          />
        </AdjustEstimateModalSectionPlan>
      </AdjustEstimateScrollWrapper>

      <InputInfoControl className="InputInfoControl">
        <InputInfoControlInner className="InputInfoControlInner">
          <InputInfoResetBtn
            className="InputInfoResetBtn"
            onClick={resetHandle}
          >
            Reset To Default
          </InputInfoResetBtn>
          <InputInfoSaveBtn className="InputInfoSaveBtn" onClick={saveHandle}>
            {
              loading ? (
                <ClipLoader color={COLORS.blue} loading={loading} size={20} />
              ) : 'Save Changes'
            }
          </InputInfoSaveBtn>
        </InputInfoControlInner>
      </InputInfoControl>
    </AdjustEstimateModalContainer>
  );
};

export default AdjustEstimateModal;
