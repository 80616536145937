import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import {
  CItem,
  CRArticle,
  CRArticle1,
  CRArticle2,
  CRTitle,
  ContinueGoogle,
  DescriptionPolicy,
  DescriptionSpan,
  Descrpition,
  DivL,
  GoogleIcon,
  HeaderLoginBtn,
  HeaderPtag,
  HeaderRightSide,
  IitemDes,
  IitemImg,
  InputNameInfo,
  InputUNALN,
  LineDiv1,
  LineDiv2,
  OrLine,
  ResentButton,
  SRCheckItems,
  SRTitle1,
  SRTitle2,
  SRTitle3,
  SignUpContent,
  SignUpContentLeft,
  SignUpContentRight,
  SignUpWindow,
} from "./styles";
import { COLORS, ICONS } from "../../../constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "../../../layout/container";
import {
  Button,
  InputLName,
  InputLNamePassword,
  RequiredValidate,
} from "../login/styles";
import {
  validateEightNumber,
  validateEmailAddress,
  validateName,
} from "../../../utils/authValidation";
import AuthorizationAPI from "../../../api/authorization";
import { Alert, message } from "antd";
import ClipLoader from "react-spinners/ClipLoader";
import { API, HOST } from "../../../utils/constant";
import { setToken, setUser } from "../../../utils/helper";
import { useAuth } from "../../../context/AppContext";

const SignUp = () => {
  const path = useLocation();
  const { setCurrentUser } = useAuth()
  const url = path ? path.pathname.slice(1) : "";
  const label = url === "login" ? "  Sign Up" : url === "signup" && "  Login";
  const linkTo = url === "login" ? "signup" : url === "signup" && "login";
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [userinfo, setUserinfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState<boolean>(false);

  const handleLogin = async () => {
    setErrorMsg("");

    if (!userinfo.email) {
      setErrors((info: any) => ({ ...info, email: "Email is required" }));
    }
    if (!userinfo.password) {
      setErrors((info: any) => ({ ...info, password: "Password is required" }));
    }

    if (!validateEmailAddress(userinfo.email)) {
      return;
    }
    if (!validateEightNumber(userinfo.password)) {
      return;
    }

    setLoading(true);
    const values = {
      identifier: userinfo.email,
      password: userinfo.password,
    };

    AuthorizationAPI.login(values)
      .then((data: any) => {
        if (!data?.user.blocked) {
          setToken(data.jwt);
          setCurrentUser(data.user);
          setUser(data.user);
          message.success(
            `Welcome to TurboFox ${data.user.firstname + " " + data.user.lastname
            }!`
          );
          navigate("/data-register", { replace: true });
        } else {
          setErrorMsg("Your account has blocked!");
        }
      })
      .catch((error) => {
        setErrorMsg(
          error?.response?.data?.error?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const handleRegister = async () => {
    setErrorMsg("");
    setSuccessMsg("");

    if (!userinfo.firstName) {
      setErrors((info: any) => ({
        ...info,
        firstName: "FirstName is required",
      }));
    }
    if (!userinfo.lastName) {
      setErrors((info: any) => ({ ...info, lastName: "LastName is required" }));
    }

    if (!userinfo.email) {
      setErrors((info: any) => ({ ...info, email: "Email is required" }));
    }
    if (!userinfo.password) {
      setErrors((info: any) => ({ ...info, password: "Password is required" }));
    }

    if (!validateName(userinfo.firstName)) {
      return;
    }
    if (!validateName(userinfo.lastName)) {
      return;
    }
    if (!validateEmailAddress(userinfo.email)) {
      return;
    }
    if (!validateEightNumber(userinfo.password)) {
      return;
    }

    if (userinfo.password !== userinfo.confirmPassword) {
      return;
    }

    const values = {
      username: `${userinfo.firstName} ${userinfo.lastName}`,
      email: userinfo.email,
      password: userinfo.password,
      firstname: userinfo.firstName,
      lastname: userinfo.lastName,
    };

    setLoading(true);

    setErrorMsg("");
    setSuccessMsg("");
    AuthorizationAPI.register(values)
      .then((data) => {
        setSuccessMsg(
          `Please check your email (${values.email}) to confirm your account.`
        );
        message.success(
          `Please check your email (${values.email}) to confirm your account.`
        );

        ReactGA.event({
          category: 'User',
          action: 'Account Create',
          label: "User Id", // optional
          value: data.user.id, // optional, must be a number
        });

        handleLogin();
      })
      .catch((error) => {
        setErrorMsg(
          error?.response?.data?.error?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event: any) => {
    const field = event.target.name;
    const value = event.target.value;
    setUserinfo((info) => ({ ...info, [field]: value }));
    switch (field) {
      case "firstName":
        if (!validateName(value)) {
          setErrors((info) => ({
            ...info,
            firstName: "First Name is required",
          }));
        } else {
          setErrors((info) => ({ ...info, firstName: "" }));
        }
        break;
      case "lastName":
        if (!validateName(value)) {
          setErrors((info) => ({ ...info, lastName: "Last Name is required" }));
        } else {
          setErrors((info) => ({ ...info, lastName: "" }));
        }
        break;
      case "email":
        if (!validateEmailAddress(value)) {
          setErrors((info) => ({ ...info, email: "Email is invalid" }));
        } else {
          setErrors((info) => ({ ...info, email: "" }));
        }
        break;
      case "password":
        if (!validateEightNumber(value)) {
          setErrors((info) => ({
            ...info,
            password: "Password must be at least eight characters",
          }));
        } else {
          setErrors((info) => ({ ...info, password: "" }));
        }
        break;
      case "confirmPassword":
        if (userinfo.password !== value) {
          setErrors((info) => ({
            ...info,
            confirmPassword: "Passwords must match",
          }));
        } else {
          setErrors((info) => ({ ...info, confirmPassword: "" }));
        }
        break;

      default:
        break;
    }
  };
  const resendEmail = () => {
    setErrorMsg("");
    setSuccessMsg("");
    const values = { email: userinfo.email };

    AuthorizationAPI.resendEmailConfirmation(values)
      .then((data) => {
        message.success(
          `We sent you another email to <u>${values.email}</u> to confirm your account. Please check also your spam folder.`
        );
      })
      .catch((error) => {
        setErrorMsg(
          error?.response?.data?.error?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const keyEventHandle = async (e: any) => {
    if (e.key === "Enter") {
      setErrorMsg("");
      setSuccessMsg("");

      if (!userinfo.firstName) {
        setErrors((info: any) => ({
          ...info,
          firstName: "FirstName is required",
        }));
      }
      if (!userinfo.lastName) {
        setErrors((info: any) => ({
          ...info,
          lastName: "LastName is required",
        }));
      }

      if (!userinfo.email) {
        setErrors((info: any) => ({ ...info, email: "Email is required" }));
      }
      if (!userinfo.password) {
        setErrors((info: any) => ({
          ...info,
          password: "Password is required",
        }));
      }

      if (!validateName(userinfo.firstName)) {
        return;
      }
      if (!validateName(userinfo.lastName)) {
        return;
      }
      if (!validateEmailAddress(userinfo.email)) {
        return;
      }
      if (!validateEightNumber(userinfo.password)) {
        return;
      }

      if (userinfo.password !== userinfo.confirmPassword) {
        return;
      }

      const values = {
        username: `${userinfo.firstName} ${userinfo.lastName}`,
        email: userinfo.email,
        password: userinfo.password,
        firstname: userinfo.firstName,
        lastname: userinfo.lastName,
      };

      setLoading(true);

      setErrorMsg("");
      setSuccessMsg("");
      AuthorizationAPI.register(values)
        .then((data) => {
          setSuccessMsg(
            `Please check your email (${values.email}) to confirm your account.`
          );
          message.success(
            `Please check your email (${values.email}) to confirm your account.`
          );
        })
        .catch((error) => {
          setErrorMsg(
            error?.response?.data?.error?.message ?? "Something went wrong!"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Container>
      <SignUpContent className="SignUpContent">
        <SignUpContentLeft className="SignUpContentLeft">
          <SRTitle1 className="SRTitle1">Master</SRTitle1>
          <SRTitle2 className="SRTitle2">Turo Car Rentals</SRTitle2>
          <SRTitle3 className="SRTitle3">
            Get your free access. No credit card required.
          </SRTitle3>
          <SRCheckItems className="SRCheckItems">
            <CItem className="CItem">
              <IitemImg src={`${ICONS.tick}`}></IitemImg>
              <IitemDes className="IitemDes">Estimate rental revenue</IitemDes>
            </CItem>
            <CItem className="CItem">
              <IitemImg className="IitemImg" src={`${ICONS.tick}`}></IitemImg>
              <IitemDes className="IitemDes">
                Future occupancy & trends
              </IitemDes>
            </CItem>
            <CItem className="CItem">
              <IitemImg className="IitemImg" src={`${ICONS.tick}`}></IitemImg>
              <IitemDes className="IitemDes">Pricing strategy</IitemDes>
            </CItem>
            <CItem className="CItem">
              <IitemImg className="IitemImg" src={`${ICONS.tick}`}></IitemImg>
              <IitemDes className="IitemDes">Market research</IitemDes>
            </CItem>
            <CItem className="CItem">
              <IitemImg className="IitemImg" src={`${ICONS.tick}`}></IitemImg>
              <IitemDes className="IitemDes">
                Compare similar properties
              </IitemDes>
            </CItem>
          </SRCheckItems>
        </SignUpContentLeft>
        <SignUpContentRight className="SignUpContentRight">
          <CRArticle className="CRArticle">
            <CRArticle1>Master</CRArticle1>
            <CRArticle2>Turo Car Rentals</CRArticle2>
          </CRArticle>
          <CRTitle className="CRTitle">Create your free account</CRTitle>
          <ContinueGoogle
            className="ContinueGoogle"
            href={`${API}/api/connect/google`}
            aria-disabled={loading}
          >
            <Button
              className="GooleInner"
              borderRadius="10"
              disabled={loading}
              padding="13 15"
              backgroundColor="transparent"
              color="black"
            >
              <GoogleIcon
                className="GoogleIcon"
                src={`${ICONS.google}`}
              ></GoogleIcon>
              Continue with Google
            </Button>
          </ContinueGoogle>
          {errorMsg ? (
            <Alert
              className="alert_error"
              message={errorMsg}
              type="error"
              closable
              afterClose={() => setErrorMsg("")}
            />
          ) : null}
          {successMsg ? (
            <Alert
              className="alert_success"
              message={successMsg}
              type="success"
              closable
              afterClose={() => setSuccessMsg("")}
            />
          ) : null}

          {successMsg && (
            <ResentButton onClick={resendEmail}>
              <HeaderLoginBtn className="HeaderLoginBtn">
                {"Resent Verification Email"}
              </HeaderLoginBtn>
            </ResentButton>
          )}
          <OrLine className="OrLine">
            <LineDiv1 className="LineDiv1"></LineDiv1>
            <DivL className="DivL">or</DivL>
            <LineDiv2 className="LineDiv2"></LineDiv2>
          </OrLine>
          <SignUpWindow className="SignUpWindow">
            <InputUNALN className="InputUNALN">
              <InputNameInfo>
                <InputLName
                  className="InputLName"
                  placeholder="First Name"
                  name="firstName"
                  disabled={loading}
                  onKeyDown={keyEventHandle}
                  value={userinfo.firstName}
                  onChange={handleChange}
                ></InputLName>
                {errors.firstName && (
                  <RequiredValidate className="RequiredValidate">
                    {errors.firstName}
                  </RequiredValidate>
                )}
              </InputNameInfo>
              <InputNameInfo>
                <InputLName
                  className="InputLName"
                  placeholder="Last Name"
                  type="text"
                  disabled={loading}
                  name="lastName"
                  onKeyDown={keyEventHandle}
                  value={userinfo.lastName}
                  onChange={handleChange}
                ></InputLName>
                {errors.lastName && (
                  <RequiredValidate className="RequiredValidate">
                    {errors.lastName}
                  </RequiredValidate>
                )}
              </InputNameInfo>
            </InputUNALN>
            <InputNameInfo className="input-name-info">
              <InputLName
                className="InputEmail"
                type="email"
                name="email"
                disabled={loading}
                placeholder="Email"
                onKeyDown={keyEventHandle}
                value={userinfo.email}
                onChange={handleChange}
              ></InputLName>
              {errors.email && (
                <RequiredValidate className="RequiredValidate">
                  {errors.email}
                </RequiredValidate>
              )}
            </InputNameInfo>
            <InputNameInfo className="input-name-info">
              <InputLNamePassword
                type="password"
                name="password"
                className="InputPassword"
                placeholder="Password"
                disabled={loading}
                onKeyDown={keyEventHandle}
                value={userinfo.password}
                onChange={handleChange}
              ></InputLNamePassword>
              {errors.password && (
                <RequiredValidate className="RequiredValidate">
                  {errors.password}
                </RequiredValidate>
              )}
            </InputNameInfo>
            <InputNameInfo className="input-name-info">
              <InputLNamePassword
                type="password"
                name="confirmPassword"
                className="input-confirm-password"
                placeholder="Confirm Password"
                value={userinfo.confirmPassword}
                onKeyDown={keyEventHandle}
                disabled={loading}
                onChange={handleChange}
              ></InputLNamePassword>
              {errors.confirmPassword && (
                <RequiredValidate className="RequiredValidate">
                  {errors.confirmPassword}
                </RequiredValidate>
              )}
            </InputNameInfo>
            <Descrpition className="Descrpition">
              By creating an account, you agree to the
              <DescriptionSpan href={`${HOST}/terms`} target="_blank" rel="noopener noreferrer"> Terms of Service </DescriptionSpan> and
              have and read and understood the
              <DescriptionPolicy href={`${HOST}/privacy`} target="_blank" rel="noopener noreferrer"> Privacy Policy</DescriptionPolicy>
            </Descrpition>
            <Button
              className="SignUpBtn"
              borderRadius="60"
              padding="10 30"
              backgroundColor="black"
              disabled={loading}
              color="white"
              onClick={handleRegister}
            >
              {loading ? (
                <ClipLoader color={COLORS.blue} loading={loading} size={30} />
              ) : (
                " Sign Up"
              )}
            </Button>
          </SignUpWindow>

          <HeaderRightSide className="HeaderRightSide">
            <HeaderPtag className="HeaderPtag">
              {url === "signup"
                ? "Already have an account?  "
                : url === "login"
                  ? "Don’t have an account?  "
                  : ""}
            </HeaderPtag>
            <Link to={`/${linkTo}`}>
              <HeaderLoginBtn className="HeaderLoginBtn">
                {label}
              </HeaderLoginBtn>
            </Link>
          </HeaderRightSide>
        </SignUpContentRight>
      </SignUpContent>
    </Container>
  );
};

export default SignUp;
