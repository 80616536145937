import React, { useEffect, useRef, useState } from "react";
import { latLngBounds } from "leaflet";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import MarkerClusterGroup from 'react-leaflet-cluster'
import CustomMarker from "./customMarker";
import { useAuth } from "../../../context/AppContext";
import { TMapCar } from "../../../api/report/types";
import "leaflet/dist/leaflet.css";
import './index.css'

const Maps = () => {
  const center1: any = [40.7082635, -74.4639219, 9];
  const { mapCars, mapCarsTop, mapCarsBottom, mapCenter, detailMap, apiKey, selectedCar } = useAuth();

  function useSize(ref: any) {
    const [size, setSize] = useState<any>({})

    useEffect(() => {
      if (ref.current == null) return
      const observer = new ResizeObserver(([entry]) => setSize(entry.contentRect))
      observer.observe(ref.current)
      return () => observer.disconnect()
      //eslint-disable-next-line
    }, [])

    return size
  }

  const ResizeMap = ({ containerRef }: any) => {
    const map = useMap();
    const { width, height }: any = useSize(containerRef);

    useEffect(() => {
      if (map) {
        map.invalidateSize();
      }
      //eslint-disable-next-line
    }, [height, width]);

    return null;
  };

  const containerRef = useRef(null)

  const RecenterAutomatically = () => {
    const map = useMap();

    useEffect(() => {
      if (mapCenter.length > 0) {
        if (detailMap) {
          let markerBounds = latLngBounds([]);
          mapCenter.forEach((car: any) => {
            if (car.lat && car.lng) {
              markerBounds.extend([car.lat, car.lng])
            }
          });
          try {
            map.fitBounds(markerBounds)
          } catch (error) {
          }
        }
      }
      //eslint-disable-next-line
    }, [mapCenter, selectedCar]);
    return null;
  }

  const BoundAutoCar = () => {
    const map = useMap();
    useEffect(() => {
      if (mapCars?.length > 0 && !detailMap) {
        let markerBounds = latLngBounds([]);
        mapCars.forEach((car: any) => {
          if (car.latitude !== undefined && car.longitude !== undefined && car.latitude && car.longitude) {
            markerBounds.extend([car.latitude, car.longitude])
          }
        });
        try {
          map.fitBounds(markerBounds)
        } catch (error) {
        }
      }
      //eslint-disable-next-line
    }, [])
    return null;
  }

  const BoundAutoTop = () => {
    const map = useMap();
    useEffect(() => {
      if (mapCarsTop?.length > 0 && mapCarsBottom?.length > 0 && !detailMap) {
        let markerBounds = latLngBounds([]);
        mapCarsTop.forEach((car: any) => {
          if (car.latitude !== undefined && car.longitude !== undefined && car.latitude && car.longitude) {
            markerBounds.extend([car.latitude, car.longitude])
          }
        });
        mapCarsBottom.forEach((car: any) => {
          if (car.latitude !== undefined && car.longitude !== undefined && car.latitude && car.longitude) {
            markerBounds.extend([car.latitude, car.longitude])
          }
        });
        map.fitBounds(markerBounds)
      }
      //eslint-disable-next-line
    }, [])
    return null;
  }

  return (
    <div style={{ width: '100%', height: '100%', overflow: 'hidden' }} ref={containerRef}>
      <MapContainer center={center1} zoom={10} className="leaflet-map" id="map_container">
        <TileLayer
          attribution='&ampcopy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
        />
        <ResizeMap containerRef={containerRef} />
        <RecenterAutomatically />
        <BoundAutoTop />
        <BoundAutoCar />
        {
          selectedCar ?
            <CustomMarker
              car={selectedCar}
              id={selectedCar?.id}
            />
            :
            <>
              {
                apiKey?.plan_type?.name &&
                <MarkerClusterGroup >
                  {
                    mapCars?.map((car: TMapCar, index: any) => {
                      if (car.latitude !== undefined && car.longitude !== undefined && car.latitude && car.longitude) {
                        return (
                          <CustomMarker
                            key={index}
                            car={car}
                            id={selectedCar?.id}
                          />
                        )
                      } else {
                        return <div key={index}></div>
                      }
                    })
                  }
                </MarkerClusterGroup>
              }
            </>
        }
      </MapContainer>
    </div>
  );
};
export default Maps;
