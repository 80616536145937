import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { Button } from "antd";
import { COLORS, FONT_FAMILY, SIZES } from "../../../../constants";

export const CompetitorItemFIrstw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        ${theme.breakpoints.down("sm")} {

          }
    `}
`;

export const CompetitorsItemListw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      cursor: pointer;
      flex-direction:column;
      ${theme.breakpoints.down("lg")} {
        flex-direction:row;
        padding:12px;
      }
  `}
`;

export const CompetitorsItemw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    width:100%;
      border:1px solid #F6F6F7;
      padding:6px 5px;
      display:flex;
      justify-content:space-between;
      ${theme.breakpoints.down("lg")} {
        flex-direction:column;
      }
  `}
`;
export const CompetitorItemDescriptionw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;
      width:100%;
      padding:10px;
  `}
`;

export const CompertitorFunctionw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      justify-content:space-between;
  `}
`;

export const CompertitorTipsw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      align-items:center;
  `}
`;

export const TipItemw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display:flex;
    width:25%;
    gap:10px;
    align-items:center;
    flex-direction:column;
  `}
`;
export const TipItemIconw = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
      width:19px;
  `}
`;

export const TipItemNamew = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.regular};
      color:#8A8A8A;
  `}
`;

export const CompetitorItemImagew = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 116px;
      height: 116px;
      border-radius: 20px;
  `}
`;

export const DescriptionTitlew = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;
      margin-left:20px;
      gap:5px;
      flex:1;
      justify-content:space-around;
  `}
`;

export const CompetitorItemImageInnerw = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        height:100%;
  `}
`;

export const DescriptionTitleGroupw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
          display:flex;
          flex-direction:column;
          gap:5px;
    `}
`;

export const DescriptionTitleInnerw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.body1}px;
      font-family:${FONT_FAMILY.semibold};
      color:${COLORS.primary};
    `}
`;

export const CompertitorPricew = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:14px;
      font-family:${FONT_FAMILY.medium}
  `}
`;

export const DescriptionTitleStarAndButtonw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-direction:column;
      gap:5px;
      ${theme.breakpoints.down("md")} {
        flex-direction:row;
        justify-content:space-between;
      }
  `}
`;

export const TitleStarsw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:15px;
  `}
`;

export const ViewAllButtonw = styled(Button) <{ theme?: Theme }>`
  ${({ theme }) => `
      background-color: ${COLORS.primary};
      padding: 2.5px 12px;
      color: white;
      outline: 0;
      border: 1px solid ${COLORS.primary};
      border-radius: 20px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family:${FONT_FAMILY.regular};
      height:initial;
      &:hover {
        background-color:white;
        color:${COLORS.primary};
      }
      ${theme.breakpoints.down("sm")} {
        font-size:11px;
        padding:1.5px 4px;
      }
  `}
`;

export const CompetitorRevenuew = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        width:30%;
  `}
`;


export const CompetitorRevenueGraphw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 100%;
  `}
`;
export const CompetitorRevenueDetailw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      margin-bottom:10px;
      justify-content: space-between;
      align-items: center;

  `}
`;

export const CompetitorRevenueGraphItemw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-family:${FONT_FAMILY.bold};
      font-size:${SIZES.body1}px;
      display:flex;
      flex-direction:column;
  `}
`;

export const CompetitorRevenueGraphIconw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      margin-bottom:20px;
  `}
`;


export const CompetitorRevenueShoww = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        color:#8A8A8A;
        font-family:${FONT_FAMILY.regular};
        font-size:13px;
  `}
`;

export const GraphItemIconw = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width: 30px;
      height: 30px;
      background-color: #f4f7fe;
      border-radius: 50%;
      padding:5px;
      display:flex;
      justify-content:center;
      align-items:center;
      text-align:center;
  `}
`;