import React, { useEffect, useState } from "react";
import {
  NavigationMain,
  NavigationItems,
  NavigationProfileOuter,
  NavigationProfile,
  NavigationProfileName,
  NavigationProfileDropdown,
  NavigationProvileIcon,
  NavigationRouteName,
  NavigationMenu,
  NavigationMenuButton,
  NavigationNotification,
  NavigationItemAvatar,
  CarReporCalculator,
  CarReportCount,
  CarReportCountIcon,
  CarReportCountNum,
  MarketReportCountIcon,
} from "./styles";
import { TNavigationProps } from "./types";
import { ArrowDownIcon, LogoutIcon, MenuIcon } from "../../svg";
import { useMenu } from "../../../hooks";
import { useAuth } from "../../../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SidebarLogo } from "../sidebar/styles";
import { ICONS, IMAGES } from "../../../constants";

import { Modal } from "antd";
import SubScription from "../subScription";
import SubHeader from "../subHeader";


const Navigation = ({ ...props }: TNavigationProps) => {
  const [menuRef, open, setOpen, buttonRef] = useMenu(false);
  const { handleMobileMenu, showMobileMenu, currentUser, logout, apiKey, openBigMapStatus, setOpenBigMapStatus, loadingDashboardLayout, openSubscription, setOpenSubscription } = useAuth()

  const location = useLocation();
  const navigate = useNavigate();
  const handleMenu = () => {
    setOpen(!open);
  };

  const CancelHandle = () => {
    setOpenSubscription(false);
  };

  const handleLogout = async () => {
    await logout();
    navigate("/login", { replace: true });
  };

  const handleSidebar = () => {
    handleMobileMenu(!showMobileMenu);
  };

  const handleClick = () => {
    setOpenSubscription(true);
  };

  const [width, setWidth] = useState(window.innerWidth);

  const [mobileHeader, setMobileHeader] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (width >= 1200) {
      handleMobileMenu(true);
      setMobileHeader(false);
    } else {
      handleMobileMenu(false);
      setMobileHeader(true);
    }
    // eslint-disable-next-line
  }, [width]);

  const handleOpenMap = () => {
    setOpenBigMapStatus(!openBigMapStatus);
  };

  useEffect(() => {
    if (loadingDashboardLayout) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto"
    };
  }, [loadingDashboardLayout]);

  return (
    <>
      <NavigationMain {...props} className="navigation-main">
        <NavigationMenu className="navigation-menu">
          <NavigationMenuButton
            onClick={handleSidebar}
            className="navigation-menu-button"
          >
            <MenuIcon />
          </NavigationMenuButton>
          <NavigationRouteName to={'/login'} className="navigation-route-name">
            <SidebarLogo src={IMAGES.logo} />
          </NavigationRouteName>
        </NavigationMenu>
        <NavigationItems className="navigation-items">

          <CarReporCalculator
            className="CarReporCalculator"
            onClick={handleClick}
          >
            <CarReportCount className="CarReportCount">
              <CarReportCountIcon
                src={ICONS.car_report_icon}
              ></CarReportCountIcon>
              <CarReportCountNum> : {apiKey?.total_car_reports ? apiKey?.total_car_reports : 0}</CarReportCountNum>
            </CarReportCount>
            <CarReportCount className="CarReportCount">
              <MarketReportCountIcon
                src={ICONS.market_report_icon}
              ></MarketReportCountIcon>
              <CarReportCountNum> : {apiKey?.total_market_reports ? apiKey?.total_market_reports : 0}</CarReportCountNum>

            </CarReportCount>
          </CarReporCalculator>
          {currentUser?.picture && (
            <NavigationNotification
              src={currentUser?.picture}
              alt=""
              onClick={handleMenu}
            />
          )}
          {currentUser?.picture === null && (
            <NavigationItemAvatar className="NavigationItemAvatar" onClick={handleMenu}>
              {currentUser?.firstname[0]}
              {currentUser?.lastname[0]}
            </NavigationItemAvatar>
          )}
          <NavigationProfileOuter className="navigtation-profile-outer">
            <NavigationProfile
              onClick={handleMenu}
              className="navigation-profile"
            >
              <NavigationProfileName className="navigation-profile-name">
                {currentUser?.firstname}
              </NavigationProfileName>
              <NavigationProvileIcon
                ref={buttonRef}
                expanded={open}
                className="navigation-profile-icon"
              >
                <ArrowDownIcon />
              </NavigationProvileIcon>
            </NavigationProfile>
            {open && (
              <NavigationProfileDropdown
                className="navigation-profile-dropdown"
                items={[
                  {
                    icon: <LogoutIcon />,
                    label: "Logout",
                    action: handleLogout,
                  },
                ]}
                ref={menuRef}
              />
            )}

            <Modal
              open={openSubscription}
              onCancel={CancelHandle}
              width={1200}
              centered
              style={{ overflow: "auto" }}
              footer=""
            >
              <SubScription />
            </Modal>
          </NavigationProfileOuter>
        </NavigationItems>
      </NavigationMain>
      {
        mobileHeader && location.pathname !== "/report/0" &&  location.pathname !== "/account" && (
          <SubHeader handleOpenMap={handleOpenMap} openBigMap={openBigMapStatus} />
        )
      }
    </>
  );
};

export default Navigation;
