import React from "react";
import { GoogleMapButtonList, GoogleMapIcon } from "./styles";
import { ICONS } from "../../../../constants";
import { GoogleMapLabel } from "../googleMap/styles";

const ToList = (props: any) => {
  const { handleChange } = props;

  const handleClick = () => {
    handleChange(false);
  }

  return (
      <GoogleMapButtonList
        className="GoogleMapButtonList"
        onClick={handleClick}
      >
        <GoogleMapLabel>List</GoogleMapLabel> <GoogleMapIcon src={ICONS.list}></GoogleMapIcon>
      </GoogleMapButtonList>
  );
};

export default ToList;
