import { AUTH_TOKEN, AUTH_USER, CHECKOUT_ORIGIN } from "./constant";

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token: any) => {
  localStorage.setItem(AUTH_TOKEN, token);
};

export const getCheckoutOrigin = () => {
  return localStorage.getItem(CHECKOUT_ORIGIN);
};

export const setCheckoutOrigin = (token: any) => {
  localStorage.setItem(CHECKOUT_ORIGIN, token);
};

export const setUser = (user: any) => {
  localStorage.setItem(AUTH_USER, JSON.stringify(user));
};

export const getUser = () => {
  const user = localStorage.getItem(AUTH_USER);
  if (user) {
    return JSON.parse(user);
  } else {
    return null
  }
}

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};

export const checkYear = (year: any, ev: any, min: number, max: number) => {
  var text = /^[0-9]+$/;
  if (ev.type === "blur" || (ev.keyCode !== 8 && ev.keyCode !== 46)) {
    if (year !== 0) {
      if ((year !== "") && (!text.test(year))) {
        return "Please Enter Numeric Values Only";
      }

      if (year.length !== 4) {
        return "Year is not proper. Please check";
      }
      if ((year < min) || (year > max)) {
        return `Year should be in range ${min} to  ${max}`;
      }
      return false;
    }
  }
};

export const sortByProperty = <T>(
  arr: T[],
  property: keyof T,
  sortOrder: 'asc' | 'desc' = 'asc'
): T[] => {
  const sortedArray = [...arr].sort((a: any, b: any) => {
    if (property === 'Vehicle') {
      const comparison = `${a.make} ${a.model} ${a.year}` > `${b.make} ${b.model} ${b.year}` ? 1 : `${a.make} ${a.model} ${a.year}` < `${b.make} ${b.model} ${b.year}` ? -1 : 0;
      return sortOrder === 'asc' ? comparison : -comparison;
    } 
    else if (property === 'model') {
      const comparison = `${a.make} ${a.model} ${a.year}` > `${b.make} ${b.model} ${b.year}` ? 1 : `${a.make} ${a.model} ${a.year}` < `${b.make} ${b.model} ${b.year}` ? -1 : 0;
      return sortOrder === 'asc' ? comparison : -comparison;
    }
    else if (property === 'vehicle_make_model') {
      const comparison = `${a.make} ${a.model} ${a.year}` > `${b.make} ${b.model} ${b.year}` ? 1 : `${a.make} ${a.model} ${a.year}` < `${b.make} ${b.model} ${b.year}` ? -1 : 0;
      return sortOrder === 'asc' ? comparison : -comparison;
    }
    else {
      if (a[property] === "unknown") {
        return sortOrder === 'asc' ? -1 : 1;
      } 
      else if(b[property] === "unknown") {
        return sortOrder === 'asc' ? 1 : -1;
      }
      else {
        const comparison = a[property] > b[property] ? 1 : a[property] < b[property] ? -1 : 0;
        return sortOrder === 'asc' ? comparison : -comparison;
      }
    }
  });

  return sortedArray;
};

export const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

export const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}
export const enableSplashScreen2 = () => {
  const splashScreen = document.getElementById('checkout-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

export const disableSplashScreen2 = () => {
  const splashScreen = document.getElementById('checkout-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}

export const groupdAndSort = (arr: any, groupBy: string, sortBy: string) => {
  const sorted = arr.sort((a: any, b: any) => {
    if (a[sortBy] > b[sortBy]) {
        return a[groupBy].toLowerCase() < b[groupBy].toLowerCase() ? 1 : -1;
      }
       return b[groupBy].toLowerCase() < a[groupBy].toLowerCase() ? -1 : 1;;
    })

  return sorted
}

export function nFormatter(num: any, digits: any) {
  const lookup: any = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.findLast((item: any) => num >= item.value);
  return item ? (num / item.value)?.toFixed(digits).replace(regexp, "").concat(item.symbol) : "0";
}

export const sortMakeModel = (arr: any) => {
  arr.sort((a: any, b: any) => {
    // Convert names to lowercase for case-insensitive sorting
    const nameA = a.label.toLowerCase();
    const nameB = b.label.toLowerCase();
    
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  return arr;
}