import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { COLORS, FONT_FAMILY, SIZES } from '../../../constants';

export const HeaderContainer = styled.header<{ theme?: Theme }>`
  ${({ theme }) => `
    margin:0;
    width:100%;
    padding:25px 45px 25px 100px;
    display:flex;
    justify-content:space-between;
    border-bottom:1px solid #D3D3D3;
    font-size:${SIZES.body1}px;
    // margin:auto;
    ${theme.breakpoints.down("sm")} {
      justify-content:center;
      padding:23px 0;
    }

  `}
`;

export const HeaderLogo = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
    cursor: pointer;
    width: 185px;
    height:26px;
    background-position:center;
    background-size:cover;
    background-repeat:no-repeat;
    border:none;

    ${theme.breakpoints.down("sm")} {
      width:86px;
      height:12px;
    }
  `}
`;
export const HeaderRightSide = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
   font-family:${FONT_FAMILY.regular};
   display:flex;

   ${theme.breakpoints.down("md")} {
      display:none;
  }
  `}
`;
export const HeaderPtag = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
   font-family:${FONT_FAMILY.regular};
   margin-right:10px;
  `}
`;
export const HeaderLoginBtn = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
   font-family:${FONT_FAMILY.medium};
   color:${COLORS.blue};
  `}
`;

export const Titlecontainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    }
    ${theme.breakpoints.down("sm")} {
      white-space: pre;
      max-width: 220px;
      overflow: auto;
    }
  `}
`;

export const TitleEditcontainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    ${theme.breakpoints.down("sm")} {
      white-space: pre;
      max-width: 220px;
      overflow: auto;
    }
  `}
`;