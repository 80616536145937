import React, { useState } from "react";
import {
  WhatLookingContainer,
  WhatLookingContent,
  WhatLookingContentRight,
  WhatLookingRightSection,
  WhatLookingTiTle,
  InputSpecify,
  NumberStatus,
  RectangleItemGroup,
  WhatLookingBackground,
  WhatLookingImg,
  BackBtnInner,
} from "./styles";
import { COLORS, ICONS, IMAGES } from "../../../../constants";
import ItemRectangle from "../describes/itemRectangle";
import BackBtnModule from "../../components/bottomButton/backBtnModule";
import BottomButton from "../../components/bottomButton";
import { NextBtnContainer, NextBtns } from "../describes/styles";
import ClipLoader from "react-spinners/ClipLoader";
import { WHATLOOKINGDATAS } from "./data";

const WhatLooking = (props: any) => {
  const { handleStepInfo, handleUpdateUser, loading } = props;

  const [bool, setBool] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [customlooking, setCustomLooking] = useState<string>("");
  const [looking, setLooking] = useState<string>("");

  const [shosInputSpecify, setShowInputSpecify] = useState<boolean>(false);
  const onMouseOver = () => {
    setBool(true);
  };
  const onMouseLeave = () => {
    setBool(false);
  };

  const handleSelect = (name: string, title: string) => {
    setSelectedItem(name);
    setLooking(title);
    if (name === "other") {
      setShowInputSpecify(true);
    } else {
      setShowInputSpecify(false);
    }
  };

  const handelNext = (nextstep: number) => {
    handleStepInfo(nextstep);
    if (selectedItem === "other") {
      const userinfo = {
        lookingFor: customlooking,
      };
      handleUpdateUser(userinfo);
    } else {
      const userinfo = {
        lookingFor: looking,
      };
      handleUpdateUser(userinfo);
    }
  };

  const onChange = (value: any) => {
    setCustomLooking(value);
  };

  const disabled =
    !selectedItem || (selectedItem === "other" && customlooking.length === 0);

  return (
    <>
      <WhatLookingContainer className="WhatLookingContainer">
        <BackBtnModule handleStepInfo={handleStepInfo} currentstep={3} />

        <WhatLookingContent className="WhatLookingContent">
          <WhatLookingTiTle className="WhatLookingTiTle">
            What are you looking for?
          </WhatLookingTiTle>
          <WhatLookingRightSection className="WhatLookingRightSection">
            <WhatLookingContentRight className="WhatLookingContentRight">
              <RectangleItemGroup className="RectangleItemGroup">
                {WHATLOOKINGDATAS.map((item, index) => (
                  <ItemRectangle
                    data={item}
                    selectedItem={selectedItem}
                    key={index}
                    handleSelect={handleSelect}
                  />
                ))}
              </RectangleItemGroup>
              {shosInputSpecify && (
                <>
                  <InputSpecify
                    className="InputSpecify"
                    maxLength={150}
                    value={customlooking}
                    placeholder="Please specify..."
                    onChange={(e) => onChange(e.target.value)}
                  />
                  <NumberStatus className="NumberStatus">
                    {customlooking.length}/150
                  </NumberStatus>
                </>
              )}
            </WhatLookingContentRight>
          </WhatLookingRightSection>
        </WhatLookingContent>
        <WhatLookingBackground className="WhatLookingBackground">
          <WhatLookingImg src={IMAGES.whatlooking_bg}></WhatLookingImg>
        </WhatLookingBackground>
        <NextBtnContainer className="NextBtnContainer">
          <NextBtns
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onClick={() => handelNext(3)}
            disabled={disabled}
          >
            Next
            {loading ? (
              <>&nbsp;&nbsp;&nbsp;
                <ClipLoader color={COLORS.blue} loading={loading} size={20} />
              </>
            ) : (
              <BackBtnInner
                src={
                  bool && !disabled
                    ? ICONS.new_next_arrow_blue
                    : ICONS.new_next_arrow_black
                }
              />
            )}
          </NextBtns>
        </NextBtnContainer>
      </WhatLookingContainer>
      <BottomButton
        handleStepInfo={handelNext}
        currentstep={3}
        disabled={disabled}
        loading={loading}
      />
    </>
  );
};

export default WhatLooking;
