import React, { useEffect, useMemo, useState } from "react";
import { Pagination } from "../../../../../components/ui";
import { DTempCars, DTopTenHead } from "./data";
import { usePagination } from "../../../../../hooks";
import { TTableRenderItemObject } from "../../../../../components/table/types";
import { TSingleCar } from "../../../../../api/report/types";
import Table, { Tsorter } from "../../../../../components/table";
import { ClipLoaderWrapper } from "../../../../earningPotential/styles";
import ClipLoader from "react-spinners/ClipLoader";
import { COLORS } from "../../../../../constants";
import { TopBottomTableContainer } from "./styles";
import { nFormatter, sortByProperty } from "../../../../../utils/helper";
import { useAuth } from "../../../../../context/AppContext";

const CarDataTable = (props: any) => {
  const { tableData, loadingReport, currentPage } = props;
  const { setMapCars, setMapCarsTop, setMapCarsBottom, setMapCenter, setDetailMap, setSelectedCar } = useAuth();
  const [totalData, setTotalData] = useState<any[]>(DTempCars);
  const [sorter, setSorter] = useState<Tsorter>("monthly_roi");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>('desc');

  const handleClickVehicle = (car: any) => {
    setMapCenter(() => [{
      lat: car.latitude,
      lng: car.longitude
    }])
    setSelectedCar(car);
    setDetailMap(true);
  }

  const renderItem = ({ headItem, row }: TTableRenderItemObject) => {
    const car: any = row.data as TSingleCar;
    if (headItem.reference === "no") {
      return car.no;
    }
     if (headItem.reference === "Vehicle") {
      return (
        //eslint-disable-next-line
        <a  onClick={() => handleClickVehicle(car)}>
          { `${car.make} ${car.model} ${car.year}`}
        </a>
      )
    }
    if (headItem.reference === "monthly_roi") {
      return `${car[headItem.reference]}%`;
    }
    if (headItem.reference === "expec_month_rev") {
      if (car[headItem.reference]) {
        return `$${Math.round(car[headItem.reference])}`;
      } else {
        return `$${car[headItem.reference]}`
      }
    }
    if (headItem.reference === "msrp") {
      return `$${nFormatter(car[headItem.reference], 1)}`;
    }
    return car[headItem.reference];
  };

  useEffect(() => {
    if (tableData) {
      const sort =  sortByProperty<any>(tableData, sorter, sortOrder ?? "asc");
      const tempdata = sort.map((item: any, index: any) => ({
        ...item,
        no: index + 1
      }))
      setTotalData(tempdata)
      setTotalResults(tempdata.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData])

  const data = useMemo<any[]>(() => {
    return sortByProperty<any>(totalData, sorter, sortOrder ?? "asc");
    //eslint-disable-next-line
  }, [sorter, sortOrder, totalData]);

  const PageSize = 20;

  //eslint-disable-next-line
  const { pagesCount, page, setTotalResults, handlePageChange } = usePagination(
    {
      limit: PageSize,
      page: 1,
      onChange: async (params, setPage) => {
        setPage(params.page);
        setTotalResults(totalData.length);
      },
    }
  );

  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data?.slice(firstPageIndex, lastPageIndex);
  }, [page, data, PageSize]);


  useEffect(() => {
    if (currentPage?.toString() === "3" && currentTableData) {
      setMapCars(currentTableData);
      setMapCarsTop([]);
      setMapCarsBottom([]);
    }
    // eslint-disable-next-line
  }, [currentPage, currentTableData])

  return (
    <TopBottomTableContainer className="TopBottomTableContainer">
      {!loadingReport ? (
        <>
          <Table
            head={DTopTenHead}
            items={currentTableData}
            renderItem={renderItem}
            sorter={sorter}
            setSorter={setSorter}
            setSortOrder={setSortOrder}
          />
          <Pagination
            onChange={(_e, x) => handlePageChange(x)}
            page={page}
            count={pagesCount}
          />
        </>
      ) : (
        <>
          <Table
            head={DTopTenHead}
            items={currentTableData}
            renderItem={renderItem}
            sorter={sorter}
            setSorter={setSorter}
            setSortOrder={setSortOrder}
          />
          <Pagination
            onChange={(_e, x) => handlePageChange(x)}
            page={page}
            count={pagesCount}
          />
          {loadingReport && (
            <ClipLoaderWrapper className="ClipLoaderWrapper">
              <ClipLoader
                color={COLORS.black}
                loading={loadingReport}
                size={50}
              />
            </ClipLoaderWrapper>
          )}
        </>
      )}
    </TopBottomTableContainer>
  );
};

export default CarDataTable;
