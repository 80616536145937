export const getAverageDailyRate = (data: any) => {
  let avg_daily_rate =
    data.reduce((result: any, item: any, index: any) => {
      result += item["rate.daily"];
      return result;
    }, 0) / data.length;

  return Math.floor(avg_daily_rate);
};

export const sortDataByYear = (data: any) => {
  let sortedData = data.sort((a: any, b: any) => {
    return a["year"] - b["year"];
  });
  return sortedData;
};

export const getGraphStyleData = (data: any) => {
  let sortedData = sortDataByYear(data);

  let graphStyleData = sortedData.reduce(
    (result: any, item: any, index: number) => {
      const buf: any = {
        Date: item.year,
        AverageDailyRate: item["rate.daily"],
      };
      result[index] = buf;
      return result;
    },
    []
  );

  return graphStyleData;
};

export const showSomeCountData = (data: any) => {
  let somedata = data.reduce((result: any, item: any, index: any) => {
    if (index < 10) result = [...result, item.AverageDailyRate];
    return result;
  }, []);

  return somedata;
};

export const numToString = (num: number) => {
  if (num < 1000) {
    return num?.toFixed(0);
  } else if (num >= 1000) {
    let suffix = (num / 1000)?.toFixed(1);
    return `${suffix}K`;
  }
};

export const removeComma = (num: any) => {
  return (num.replace(/,/g, ''));
}

export const numTothreeComma = (num: any) => {
  let removedCommaNum = removeComma(num)
  return removedCommaNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
};

export const DataParsing = (data: any, columname: string) => {
  let sortedDate = sortData(Object.keys(data))
  let newData: any = [];
  sortedDate.forEach((item: any, index: number) => {
    const value = data[item][columname];
    if (columname === 'portion_rented' || columname === 'annual_roi') {
      if (value !== 'unknown')
        newData.push((data[item][columname] * 100).toFixed(0))
    } else {
      if (value !== 'unknown')
        newData.push(data[item][columname].toFixed(0))
    }
  });

  const graphData = {
    series: [{ name: "Revenue", data: newData }],
    analyticsData: { subscribers: 92600 },
  };

  return graphData;
};

export const competitorDataParsing = (data: any, graphName: string) => {
  let sortedData = sortDataByDate(data);
  let newData: any = []
  sortedData.forEach((element: any, index: number) => {
    newData.push(element[graphName])
  });
  const graphData = {
    series: [{ name: "Revenue", data: newData }],
    analyticsData: { subscribers: 92600 },
  };


  return graphData
}
export const expectedRevenueLastTwelveDataParsing = (sortedData: any) => {
  // let sortedData = sortDataByDateLastTwelveData(data);
  let monthlyAverageData: any = []
  let trendData: any = [];
  sortedData.forEach((element: any, index: number) => {
    if (element.trend_expec_monthly_rev) {
      monthlyAverageData.push(Math.round(element.trend_expec_monthly_rev))
    } else {
      monthlyAverageData.push(element.trend_expec_monthly_rev)
    }
    if (element.expec_monthly_rev) {
      trendData.push(Math.round(element.expec_monthly_rev))
    } else {
      trendData.push(element.expec_monthly_rev)
    }
  });

  const graphData = [
    {
      name: "Monthly Average",
      data: trendData,
    },
    {
      name: "Trend Line",
      data: monthlyAverageData,
    },
  ];
  return graphData
}

const sortDataByDate = (data: any) => {
  let sortedData: any[] = [];
  [...data].sort((a: any, b: any) => b.yyyy_mm_dd - a.yyyy_mm_dd).forEach((item, index) => {
    if (index < 12) {
      return sortedData.push(item)
    }
  })

  sortedData.sort((a, b) => a - b)
  return sortedData
}

export const MonthlyReveueDataParsing = (data: any, columname: string) => {
  let sortedDate = sortData(Object.keys(data))
  let newData: any = [];
  let dates: any = [];
  sortedDate.forEach((item: any, index: number) => {
    try {
      const value = (data[item][columname]).toFixed(0);
      newData.push(value)
      const dateObj = {
        x: data[item]['date']
      }
      dates.push(dateObj)

    } catch (error) {
      console.log(data[item][columname])
    }
  });

  let newArr = dates.map((item: any, index: number) => ({
    ...item, y: newData[index]
  }));
  return newArr;
};

export const sortData = (date: any) => {
  let sortedDate: any[] = [];
  ([...date].sort((a, b) => b - a)).forEach((date: any, index: number) => {
    if (index < 12) {
      return sortedDate.push(date)
    }
  })

  sortedDate.sort((a, b) => a - b)
  return sortedDate
}

export const resParse = (data: any) => {
  let keys = Object.keys(data);
  let result = [];

  for (let one in data) {
    result.push(data[one]);
  }

  let newData = result.map((item, index) => {
    keys.forEach((key, keyIndex) => {
      if (index === keyIndex) {
        item['id'] = key;
      }
    })
    return item;
  })
  return newData;
}

