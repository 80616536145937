import React from "react";

import { LayoutContent, LayoutMain } from "./style";
import AuthHeader from "../../components/custom/authHeader";
import { TPageLayoutProps } from "../page/type";
import AuthTimeLine from "../../components/custom/authTimeLine";
import { useAuth } from "../../context/AppContext";
import { useLocation } from "react-router-dom";

const Container = ({ children, ...props }: TPageLayoutProps) => {
  const { authStep } = useAuth();
  const path = useLocation();
  const url = path ? path.pathname.slice(1) : "";
  return (
    <LayoutMain {...props} className="layout-main">
      <AuthHeader />
      {authStep >= 0 && url === "data-register" && (
        <AuthTimeLine authStep={authStep} />
      )}
      <LayoutContent className="layout-content">{children}</LayoutContent>
    </LayoutMain>
  );
};

export default Container;