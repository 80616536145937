import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, MSIZES, SIZES } from "../../../constants";
import { Link } from "react-router-dom";

export const SignUpContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        padding:60px 75px 30px 75px;
        display:flex;
        max-width:1440px;
        margin:auto;
        ${theme.breakpoints.down("md")} {
            padding:0;
            margin:initial;
          }
    `}
`;

export const SignUpContentLeft = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:50%;
        padding:0 0 0 40px;

        ${theme.breakpoints.down("md")} {
            display:none;
          }

    `}
`;

export const SRTitle1 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.h4}px;
        font-family:${FONT_FAMILY.semibold};
    `}
`;
export const SRTitle2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.h4}px;
    font-family:${FONT_FAMILY.semibold};
    color:${COLORS.primary};
    padding-bottom:15px;
    `}
`;
export const SRTitle3 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body1}px;
    font-family:${FONT_FAMILY.medium};
    padding-bottom:41px;
    `}
`;

export const SRCheckItems = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-family:${FONT_FAMILY.regular};
    font-size:${SIZES.body1}px;
    `}
`;
export const CItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        margin-bottom:20px;
    `}
`;

export const IitemImg = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        margin-right:10px;
    `}
`;
export const IitemDes = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display: flex;
        align-items: center;
        font-family:${FONT_FAMILY.regular}
    `}
`;

export const SignUpContentRight = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:0 25px 0 20px ;
        width:50%;
        ${theme.breakpoints.down("md")} {
            padding:35px 25px 0 25px;
            width:100%;
          }
    `}
`;
export const CRArticle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
          display:none;
        ${theme.breakpoints.down("md")} {
            display:block;
            font-size:${MSIZES.h1}px;
            font-family:${FONT_FAMILY.bold};
            text-align:center;
            display:flex;
            align-items:center;
            justify-content:center;
        }
        ${theme.breakpoints.down("sm")} {
            flex-direction:column;
        }
    `}
`;
export const CRArticle1 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            color:${COLORS.black};
            margin-right:5px;
        }
    `}
`;
export const CRArticle2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            color:${COLORS.primary};
        }
    `}
`;
export const CRTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.h5}px;
        font-family:${FONT_FAMILY.medium};
        text-align:center;
        padding:0 0 45px 0;

        ${theme.breakpoints.down("md")} {
            font-size:${MSIZES.h2}px;
            padding:15px 0 15px 0;

          }
    `}
`;
export const ContinueGoogle = styled.a<{ theme?: Theme }>`
  ${({ theme }) => `

        align-items:center;
        display:flex;
        justify-content:center;
        margin-bottom:2px;
        border-radius: 10px;
        width:100%;
        ${theme.breakpoints.down("md")} {
            border-radius:30px;
          }
    `}
`;
export const GooleInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        align-items:center;
        padding:13px 0;
        color:#727272;
        display:flex;
        ${theme.breakpoints.down("md")} {
            padding:11px 66px;
            border-radius:30px;
        }
    `}
`;
export const GoogleIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        margin-right:30px;

        ${theme.breakpoints.down("sm")} {
            width:20px;
            margin-right:10px;
        }
    `}
`;
export const GBName = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
       font-size:${SIZES.body1}px;
       font-family:${FONT_FAMILY.medium};

       ${theme.breakpoints.down("md")} {
        font-size:${MSIZES.body2}px;
        }
    `}
`;
export const OrLine = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display:flex;
        position:relative;

    ${theme.breakpoints.down("sm")} {
        height:69px;
    }

    `}
`;
export const LineDiv1 = styled.hr<{ theme?: Theme }>`
  ${({ theme }) => `
    margin-top:37px;
    border:1px solid #DEDEDE;
    width:50%;
`}
`;
export const DivL = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    padding:20px;
    font-size:${SIZES.body1}px;
    font-family:${FONT_FAMILY.regular};
    
`}
`;
export const LineDiv2 = styled.hr<{ theme?: Theme }>`
  ${({ theme }) => `
    margin-top:37px;
    border:1px solid #DEDEDE;
    width:50%;
`}
`;
export const SignUpWindow = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:0 0 30px 0;
        display:flex;
        flex-direction:column;
        ${theme.breakpoints.down("sm")} {
            padding:0 0 10px 0;
        }
    `}
`;
export const InputUNALN = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        gap:10px;
        ${theme.breakpoints.down("sm")} {
            flex-direction:column;
            gap:0;
        }
    
    `}
`;
export const InputNameInfo = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        margin-bottom:10px;
        ${theme.breakpoints.down("sm")} {

        }
    
    `}
`;
export const InputLName = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
         padding:13px 18px;
        border:1px solid #DEDEDE;
        outline:0;
        width:100%;
        border-radius:6px;
        font-size:${SIZES.body1}px;
        ${theme.breakpoints.down("sm")} {
            width:100%;
            font-size:${MSIZES.body2}px;
            padding:12px 20px;
        }
    `}
`;
export const InputEmail = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
         padding:13px 18px;
        border:1px solid #DEDEDE;
        outline:0;
        width:100%;
        border-radius:6px;
        margin:14px 0 17px 0;
        font-size:${SIZES.body1}px;
        ${theme.breakpoints.down("sm")} {
            width:100%;
            font-size:${MSIZES.body2}px;
            margin:0 0 14px 0;
            padding:12px 20px;

        }
    `}
`;

export const InputPassword = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:13px 18px;
        border:1px solid #DEDEDE;
        outline:0;
        width:100%;
        border-radius:6px;
        margin:14px 0 17px 0;
        font-size:${SIZES.body1}px;
        ${theme.breakpoints.down("sm")} {
            width:100%;
            font-size:${MSIZES.body2}px;
            margin:0 0 14px 0;
            padding:12px 20px;

        } 

    `}
`;
export const Descrpition = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body1}px;
    font-family:${FONT_FAMILY.regular};
    text-align:center;
    max-width: 430px;
    text-align: center;
    margin: auto;
    padding-top: 11px;
    padding-bottom: 16px;
    ${theme.breakpoints.down("sm")} {
        padding-top: 0px;
        padding-bottom: 15px;
        font-size:${MSIZES.body2}px;
        text-align:initial;
    } 

    `}
`;
export const DescriptionSpan = styled.a<{ theme?: Theme }>`
  ${({ theme }) => `
        color:${COLORS.primary};
    `}
`;
export const DescriptionPolicy = styled.a<{ theme?: Theme }>`
  ${({ theme }) => `
      color:${COLORS.primary};
    `}
`;
export const SignUpBtn = styled(Link) <{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.h8}px;
    font-family:${FONT_FAMILY.bold};
    color:white;
    padding:13px 30px;
    background-color:black;
    border-radius:20px;
    outline:0;
    text-align:center;

    ${theme.breakpoints.down("sm")} {
        font-size:${MSIZES.body2}px;
    } 

    `}
`;
export const ForgotModal = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    padding:30px;
    color:black;
    `}
`;
export const ModalIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    padding:0 30px 20px 30px;
    width:100%;
    `}
`;

export const Forgot_label1 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    font-family:${FONT_FAMILY.semibold};
    padding:0 30px 10px 30px;
    text-align:center;

    ${theme.breakpoints.down("sm")} {
        font-size:${MSIZES.body2}px;
    } 

    `}
`;

export const Forgot_label2 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    font-family:${FONT_FAMILY.regular};
    padding:0 30px;
    `}
`;

export const InputForm = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    padding:30px 0 10px 0;
    `}
`;

export const InputEmails = styled.input<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    padding:13px 18px;
    border:1px solid #DEDEDE;
    outline:0;
    width:100%;
    border-radius:6px;
    margin:14px 0 17px 0;
    `}
`;

export const SendBtn = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.body2}px;
    font-family:${FONT_FAMILY.medium};
    color:white;
    padding:10px 30px;
    background-color:black;
    border-radius:20px;
    outline:0;
    text-align:center;
    margin-top:15px;
    `}
`;

export const HeaderRightSide = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
   font-family:${FONT_FAMILY.regular};
      display:flex;
      align-items:center;
      justify-content:center;
      margin-bottom: 50px;

   ${theme.breakpoints.down("md")} {

  }
  `}
`;
export const HeaderPtag = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
   font-family:${FONT_FAMILY.regular};
   margin-right:10px;

   ${theme.breakpoints.down("md")} {
    font-size:${MSIZES.body2}px;
    }
    
  `}
`;
export const HeaderLoginBtn = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
   font-family:${FONT_FAMILY.medium};
   color:${COLORS.blue};

   ${theme.breakpoints.down("md")} {
    font-size:${MSIZES.body2}px;
  }
  &:hover {
    color: ${COLORS.dark};
  }
  `}
`;

export const ResentButton = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display: flex;
      justify-content: center;
      cursor: pointer;
  `}
`;
