import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { COLORS } from '../../../constants';

export const SidebarMain = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(4)};
    align-items: flex-start;
    justify-content: flex-end;
    padding: ${theme.spacing(5)};
    padding-bottom: 30px;
    background-color: ${COLORS.white};
    position: relative;
    `}
`;

export const SidebarItems = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: ${theme.spacing(3.75)};
        padding-left: ${theme.spacing(2.5)};
    `}
`;

export const SidebarCancel = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display: none;
      cursor: pointer;

      ${theme.breakpoints.down('lg')} {
        position: absolute;
        display: block;
        top: 10px;
        right: 10px;

        svg {
          width: 20px;
          height: 20px;
          color: #d3d3d3;
        }
      }
    `}
`;
