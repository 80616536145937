import React from "react";
import {
  LocationItemDescription,
  LocationItemDescriptionWrapper,
  LocationItemDescrptionFirst,
  LocationItemDescrptionFirstCircle,
  LocationItemDescrptionFirstCircleInner,
  LocationItemDescrptionFirstIcon,
  LocationItemDescrptionFirstLabel,
  LocationItemDescrptionThird,
  LocationItemDescrptionTwo,
  LocationItemDescrptionTwoItem,
  LocationItemDescrptionTwoItemFirst,
  LocationItemDescrptionTwoItemTwo,
  LocationItemImageSection,
  LocationPageBody,
  LocationPageBodyItem,
  LocationPageBodyRow,
  LocationPageContainer,
  LocationPageHeader,
} from "./styles";
import TurboMainContainer from "../../layout/turboMainContainer";
import { IMAGES } from "../../constants";
import { HeartOutlined } from "@ant-design/icons";

const LocationPage = () => {
  return (
    <TurboMainContainer>
      <LocationPageContainer className="LocationPageContainer">
        <LocationPageHeader>400 Markets</LocationPageHeader>

        <LocationPageBody className="LocationPageBody">
          <LocationPageBodyRow className="LocationPageBodyRow">
            <LocationPageBodyItem className="LocationPageBodyItem">
              <LocationItemImageSection
                className="LocationItemImageSection"
                src={IMAGES.location_car1}
              ></LocationItemImageSection>
              <LocationItemDescription className="LocationItemDescription">
                <LocationItemDescrptionFirst className="LocationItemDescrptionFirst">
                  <LocationItemDescriptionWrapper className="LocationItemDescriptionWrapper">
                    <LocationItemDescrptionFirstCircle className="LocationItemDescrptionFirstCircle">
                      <LocationItemDescrptionFirstCircleInner className="LocationItemDescrptionFirstCircleInner">
                        98
                      </LocationItemDescrptionFirstCircleInner>
                    </LocationItemDescrptionFirstCircle>
                    <LocationItemDescrptionFirstLabel className="LocationItemDescrptionFirstLabel">
                      Ingolstadt
                    </LocationItemDescrptionFirstLabel>
                  </LocationItemDescriptionWrapper>
                  <LocationItemDescrptionFirstIcon className="LocationItemDescrptionFirstIcon">
                    <HeartOutlined />
                  </LocationItemDescrptionFirstIcon>
                </LocationItemDescrptionFirst>
                <LocationItemDescrptionTwo className="LocationItemDescrptionTwo">
                  <LocationItemDescrptionTwoItem>
                    <LocationItemDescrptionTwoItemFirst>
                      £20.6k
                    </LocationItemDescrptionTwoItemFirst>
                    <LocationItemDescrptionTwoItemTwo>
                      Revenue
                    </LocationItemDescrptionTwoItemTwo>
                  </LocationItemDescrptionTwoItem>
                  <LocationItemDescrptionTwoItem>
                    <LocationItemDescrptionTwoItemFirst>
                      57%
                    </LocationItemDescrptionTwoItemFirst>
                    <LocationItemDescrptionTwoItemTwo>
                      Occupancy
                    </LocationItemDescrptionTwoItemTwo>
                  </LocationItemDescrptionTwoItem>
                  <LocationItemDescrptionTwoItem>
                    <LocationItemDescrptionTwoItemFirst>
                      £98.4
                    </LocationItemDescrptionTwoItemFirst>
                    <LocationItemDescrptionTwoItemTwo>
                      Daily Rate
                    </LocationItemDescrptionTwoItemTwo>
                  </LocationItemDescrptionTwoItem>
                </LocationItemDescrptionTwo>
                <LocationItemDescrptionThird className="LocationItemDescrptionThird">
                  Over last 12 months
                </LocationItemDescrptionThird>
              </LocationItemDescription>
            </LocationPageBodyItem>
            <LocationPageBodyItem className="LocationPageBodyItem">
              <LocationItemImageSection
                className="LocationItemImageSection"
                src={IMAGES.location_car1}
              ></LocationItemImageSection>
              <LocationItemDescription className="LocationItemDescription">
                <LocationItemDescrptionFirst className="LocationItemDescrptionFirst">
                  <LocationItemDescriptionWrapper className="LocationItemDescriptionWrapper">
                    <LocationItemDescrptionFirstCircle className="LocationItemDescrptionFirstCircle">
                      <LocationItemDescrptionFirstCircleInner className="LocationItemDescrptionFirstCircleInner">
                        98
                      </LocationItemDescrptionFirstCircleInner>
                    </LocationItemDescrptionFirstCircle>
                    <LocationItemDescrptionFirstLabel className="LocationItemDescrptionFirstLabel">
                      Ingolstadt
                    </LocationItemDescrptionFirstLabel>
                  </LocationItemDescriptionWrapper>
                  <LocationItemDescrptionFirstIcon className="LocationItemDescrptionFirstIcon">
                    <HeartOutlined />
                  </LocationItemDescrptionFirstIcon>
                </LocationItemDescrptionFirst>
                <LocationItemDescrptionTwo className="LocationItemDescrptionTwo">
                  <LocationItemDescrptionTwoItem>
                    <LocationItemDescrptionTwoItemFirst>
                      £20.6k
                    </LocationItemDescrptionTwoItemFirst>
                    <LocationItemDescrptionTwoItemTwo>
                      Revenue
                    </LocationItemDescrptionTwoItemTwo>
                  </LocationItemDescrptionTwoItem>
                  <LocationItemDescrptionTwoItem>
                    <LocationItemDescrptionTwoItemFirst>
                      £20.6k
                    </LocationItemDescrptionTwoItemFirst>
                    <LocationItemDescrptionTwoItemTwo>
                      Revenue
                    </LocationItemDescrptionTwoItemTwo>
                  </LocationItemDescrptionTwoItem>
                  <LocationItemDescrptionTwoItem>
                    <LocationItemDescrptionTwoItemFirst>
                      £20.6k
                    </LocationItemDescrptionTwoItemFirst>
                    <LocationItemDescrptionTwoItemTwo>
                      Revenue
                    </LocationItemDescrptionTwoItemTwo>
                  </LocationItemDescrptionTwoItem>
                </LocationItemDescrptionTwo>
                <LocationItemDescrptionThird className="LocationItemDescrptionThird">
                  Over last 12 months
                </LocationItemDescrptionThird>
              </LocationItemDescription>
            </LocationPageBodyItem>
          </LocationPageBodyRow>
          <LocationPageBodyRow></LocationPageBodyRow>
        </LocationPageBody>
      </LocationPageContainer>
    </TurboMainContainer>
  );
};

export default LocationPage;
