import React, { useEffect, useState } from "react";
import { clarity } from 'react-microsoft-clarity';
import ReactGA from "react-ga4";
import { Link, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {
  CItem,
  CRTitle,
  ContinueGoogle,
  Descrpition,
  DivL,
  GoogleIcon,
  IitemDes,
  IitemImg,
  LineDiv1,
  LineDiv2,
  OrLine,
  SRCheckItems,
  SRTitle1,
  SRTitle2,
  SRTitle3,
  LoginUpContent,
  LoginUpContentLeft,
  LoginUpContentRight,
  LoginUpWindow,
  HeaderRightSide,
  HeaderPtag,
  HeaderLoginBtn,
  CRArticle,
  CRArticle1,
  CRArticle2,
  RequiredValidate,
  InputLName,
  Button,
  ForgotPasswordContainer,
  InputLNamePassword,
} from "./styles";

import { COLORS, ICONS } from "../../../constants";
import { useLocation } from "react-router-dom";
import Container from "../../../layout/container";
import { InputNameInfo } from "../signUp/styles";
import { useAuth } from "../../../context/AppContext";
import {
  validateEightNumber,
  validateEmailAddress,
} from "../../../utils/authValidation";
import AuthorizationAPI from "../../../api/authorization";
import { setToken, setUser } from "../../../utils/helper";
import { Alert, message, Modal } from "antd";
import ForgotPassword from "../components/forgotPassword";
import ResetPassword from "../components/resetPassword";
import { API } from "../../../utils/constant";

// import { useFormik } from 'formik'

const Login = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const code = query.get("code");
  const [showModal, setShowModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const location = useLocation();

  const url = location ? location.pathname.slice(1) : "";
  const label = url === "login" ? "  Sign Up" : url === "signup" && "  Login";
  const linkTo = url === "login" ? "signup" : url === "signup" && "login";

  const navigate = useNavigate();
  const { setCurrentUser } = useAuth();

  const [userinfo, setUserInfo] = useState<any>({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errors, setErrors] = useState<any>({
    email: "",
    password: "",
  });

  const handleLogin = async () => {
    setErrorMsg("");

    if (!userinfo.email) {
      setErrors((info: any) => ({ ...info, email: "Email is required" }));
    }
    if (!userinfo.password) {
      setErrors((info: any) => ({ ...info, password: "Password is required" }));
    }

    if (!validateEmailAddress(userinfo.email)) {
      return;
    }
    if (!validateEightNumber(userinfo.password)) {
      return;
    }

    setLoading(true);
    const values = {
      identifier: userinfo.email,
      password: userinfo.password,
    };

    AuthorizationAPI.login(values)
      .then((data: any) => {
        if (!data?.user.blocked) {
          setToken(data.jwt);
          setCurrentUser(data.user);
          setUser(data.user);
          
          if (clarity.hasStarted()) {
            clarity.identify('USER_ID', { userProperty: data.user.username });
          }

          ReactGA.event({
            category: 'User',
            action: 'Login',
            label: "User Id", // optional
            value: data.user.id, // optional, must be a number
          });

          message.success(
            `Welcome to TurboFox ${
              data.user.firstname + " " + data.user.lastname
            }!`
          );
          navigate("/data-register", { replace: true });
        } else {
          setErrorMsg("Your account has blocked!");
        }
      })
      .catch((error) => {
        setErrorMsg(
          error?.response?.data?.error?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event: any) => {
    const field = event.target.name;
    const value = event.target.value;

    setUserInfo((info: any) => ({ ...info, [field]: value }));
    switch (field) {
      case "email":
        if (!validateEmailAddress(value)) {
          setErrors((info: any) => ({ ...info, email: "Email is invalid" }));
        } else {
          setErrors((info: any) => ({ ...info, email: "" }));
        }
        break;
      case "password":
        if (!validateEightNumber(value)) {
          setErrors((info: any) => ({
            ...info,
            password: "Password must be at least eight characters",
          }));
        } else {
          setErrors((info: any) => ({ ...info, password: "" }));
        }
        break;

      default:
        break;
    }
  };

  const keyEventHandle = async (e: any) => {
    if (e.key === "Enter") {
      setErrorMsg("");

      if (!userinfo.email) {
        setErrors((info: any) => ({ ...info, email: "Email is required" }));
      }
      if (!userinfo.password) {
        setErrors((info: any) => ({
          ...info,
          password: "Password is required",
        }));
      }

      if (!validateEmailAddress(userinfo.email)) {
        return;
      }
      if (!validateEightNumber(userinfo.password)) {
        return;
      }

      setLoading(true);
      const values = {
        identifier: userinfo.email,
        password: userinfo.password,
      };

      AuthorizationAPI.login(values)
        .then((data: any) => {
          if (!data?.user.blocked) {
            setToken(data.jwt);
            setCurrentUser(data.user);
            setUser(data.user);
            message.success(
              `Welcome to TurboFox ${
                data.user.firstname + " " + data.user.lastname
              }!`
            );
            navigate("/data-register", { replace: true });
          } else {
            setErrorMsg("Your account has blocked!");
          }
        })
        .catch((error) => {
          setErrorMsg(
            error?.response?.data?.error?.message ?? "Something went wrong!"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (code) {
      setShowResetModal(true);
    }
  }, [code]);

  return (
    <Container>
      <LoginUpContent className="LoginUpContent">
        <LoginUpContentLeft className="LoginUpContentLeft">
          <SRTitle1 className="SRTitle1">Master</SRTitle1>
          <SRTitle2 className="SRTitle2">Turo Car Rentals</SRTitle2>
          <SRTitle3 className="SRTitle3">
            Get your free access. No credit card required.
          </SRTitle3>
          <SRCheckItems className="SRCheckItems">
            <CItem className="CItem">
              <IitemImg className="IitemImg" src={`${ICONS.tick}`}></IitemImg>
              <IitemDes className="IitemDes">Estimate rental revenue</IitemDes>
            </CItem>
            <CItem className="CItem">
              <IitemImg className="IitemImg" src={`${ICONS.tick}`}></IitemImg>
              <IitemDes className="IitemDes">
                Future demand & trends
              </IitemDes>
            </CItem>
            <CItem className="CItem">
              <IitemImg className="IitemImg" src={`${ICONS.tick}`}></IitemImg>
              <IitemDes className="IitemDes">Pricing strategy</IitemDes>
            </CItem>
            <CItem className="CItem">
              <IitemImg className="IitemImg" src={`${ICONS.tick}`}></IitemImg>
              <IitemDes className="IitemDes">Market research</IitemDes>
            </CItem>
            <CItem className="CItem">
              <IitemImg className="IitemImg" src={`${ICONS.tick}`}></IitemImg>
              <IitemDes className="IitemDes">
                Find winning vehicles
              </IitemDes>
            </CItem>
          </SRCheckItems>
        </LoginUpContentLeft>
        <LoginUpContentRight className="LoginUpContentRight">
          <CRArticle className="CRArticle">
            <CRArticle1>Master</CRArticle1>
            <CRArticle2>Turo Car Rentals</CRArticle2>
          </CRArticle>
          <CRTitle className="CRTitle">Welcome Back</CRTitle>
          <ContinueGoogle
            className="ContinueGoogle"
            href={`${API}/api/connect/google`}
            aria-disabled={loading}
          >
            <Button
              className="GooleInner"
              borderRadius="10"
              padding="13 15"
              disabled={loading}
              backgroundColor="transparent"
              color="black"
            >
              <GoogleIcon
                className="GoogleIcon"
                src={`${ICONS.google}`}
              ></GoogleIcon>
              Continue with Google
            </Button>
          </ContinueGoogle>
          {errorMsg ? (
            <Alert
              className="alert_error"
              message={errorMsg}
              type="error"
              closable
              afterClose={() => setErrorMsg("")}
            />
          ) : null}
          <OrLine className="OrLine">
            <LineDiv1 className="LineDiv1"></LineDiv1>
            <DivL className="DivL">or</DivL>
            <LineDiv2 className="LineDiv2"></LineDiv2>
          </OrLine>

          <LoginUpWindow className="LoginUpWindow">
            <InputNameInfo className="input-name-info">
              <InputLName
                className="InputEmail"
                type="email"
                name="email"
                placeholder="Email"
                value={userinfo.email}
                onChange={handleChange}
                onKeyDown={keyEventHandle}
              ></InputLName>
              {errors.email && (
                <RequiredValidate className="RequiredValidate">
                  {errors.email}
                </RequiredValidate>
              )}
            </InputNameInfo>
            <InputNameInfo input-name-info>
              <InputLNamePassword
                type="password"
                name="password"
                className="InputPassword"
                placeholder="Password"
                value={userinfo.password}
                onChange={handleChange}
                onKeyDown={keyEventHandle}
              ></InputLNamePassword>
              {errors.password && (
                <RequiredValidate className="RequiredValidate">
                  {errors.password}
                </RequiredValidate>
              )}
            </InputNameInfo>
            <ForgotPasswordContainer className="forgot-password-container">
              <Descrpition
                className="Descrpition"
                onClick={() => setShowModal(true)}
              >
                Forgot password?
              </Descrpition>
            </ForgotPasswordContainer>

            <Button
              value="Login"
              borderRadius="60"
              padding="10 30"
              backgroundColor="black"
              color="white"
              type="submit"
              onClick={handleLogin}
            >
              {loading ? (
                <ClipLoader color={COLORS.blue} loading={loading} size={30} />
              ) : (
                "Login"
              )}
            </Button>

            <HeaderRightSide className="HeaderRightSide">
              <HeaderPtag className="HeaderPtag">
                {url === "signup"
                  ? "Already have an account?  "
                  : url === "login"
                  ? "Don’t have an account?  "
                  : ""}
              </HeaderPtag>
              <Link to={`/${linkTo}`}>
                <HeaderLoginBtn className="HeaderLoginBtn">
                  {label}
                </HeaderLoginBtn>
              </Link>
            </HeaderRightSide>
          </LoginUpWindow>
        </LoginUpContentRight>

        <Modal
          title=""
          open={showModal}
          centered
          footer={false}
          closable={true}
          onCancel={() => setShowModal(false)}
        >
          <ForgotPassword />
        </Modal>

        <Modal
          title=""
          open={showResetModal}
          centered
          footer={false}
          closable={true}
          onCancel={() => setShowResetModal(false)}
        >
          <ResetPassword />
        </Modal>
      </LoginUpContent>
    </Container>
  );
};

export default Login;
