import React from "react";
import {
  PolicyContainer,
  PolicyContainerBody,
  PolicyContainerBodyInner,
  PolicyContainerBodyInnerDesc,
  PolicyContainerBodyItem,
  PolicyContainerBodyItemSubContent,
  PolicyContainerBodyItemSubContentDesc,
  PolicyContainerBodyItemSubContentTitle,
  PolicyContainerBodyItemTitle,
  PolicyContainerBodyItemUl,
  PolicyHeader,
  PolicyHeaderUpdated,
  PolicyHeaderWrapper,
} from "./styles";

const Policy = () => {

  return (
    <PolicyContainer className="PolicyContainer">
      <PolicyHeaderWrapper className="PolicyHeaderWrapper">
        <PolicyHeader className="PolicyHeader">
          Privacy Policy for useTurboFox.com
        </PolicyHeader>
        <PolicyHeaderUpdated className="PolicyHeaderUpdated">
          Effective Date: March 27, 2024
        </PolicyHeaderUpdated>
      </PolicyHeaderWrapper>
      <PolicyContainerBody className="PolicyContainerBody">
        <PolicyContainerBodyInner className="PolicyContainerBodyInner">
          <PolicyContainerBodyInnerDesc className="PolicyContainerBodyInnerDesc">
            Welcome to useTurboFox.com (the "Service"), a premier analytics and insights platform for Turo hosts. Our commitment to your privacy and the protection of your personal information is paramount. This Privacy Policy details our practices regarding the collection, use, and sharing of your information and your rights regarding that information.
          </PolicyContainerBodyInnerDesc>

          <PolicyContainerBodyItem className="PolicyContainerBodyItem">
            <PolicyContainerBodyItemTitle className="PolicyContainerBodyItemTitle">
              Information Collection and Use
            </PolicyContainerBodyItemTitle>
            <PolicyContainerBodyItemSubContent className="PolicyContainerBodyItemSubContent">
              <PolicyContainerBodyItemSubContentTitle className="PolicyContainerBodyItemSubContentTitle">
                Personal Identification Information
              </PolicyContainerBodyItemSubContentTitle>
              <PolicyContainerBodyItemSubContentDesc className="PolicyContainerBodyItemSubContentDesc">
                We collect information that personally identifies you ("Personal Information") when you:
              </PolicyContainerBodyItemSubContentDesc>
              <PolicyContainerBodyItemUl className="PolicyContainerBodyItemUl">
                <ul style={{ listStyleType: 'disc' }}>
                  <li>Register for an account</li>
                  <li>Use our Service</li>
                  <li>Make a transaction</li>
                </ul>
              </PolicyContainerBodyItemUl>
              <PolicyContainerBodyItemSubContentDesc className="PolicyContainerBodyItemSubContentDesc">
                This information may include, but is not limited to, your name, email address, and payment information. We utilize a third party payments provider for payment processing and never store your credit card details.
              </PolicyContainerBodyItemSubContentDesc>
            </PolicyContainerBodyItemSubContent>
            <PolicyContainerBodyItemSubContent className="PolicyContainerBodyItemSubContent">
              <PolicyContainerBodyItemSubContentTitle className="PolicyContainerBodyItemSubContentTitle">
                Usage and Device Information
              </PolicyContainerBodyItemSubContentTitle>
              <PolicyContainerBodyItemSubContentDesc className="PolicyContainerBodyItemSubContentDesc">
                When you access our Service, we automatically collect certain information about your device and how you use the Service, including IP addresses, browser type, language preferences, referring pages, and page interaction information.
              </PolicyContainerBodyItemSubContentDesc>
            </PolicyContainerBodyItemSubContent>
            <PolicyContainerBodyItemSubContent className="PolicyContainerBodyItemSubContent">
              <PolicyContainerBodyItemSubContentTitle className="PolicyContainerBodyItemSubContentTitle">
                Cookies and Tracking Technologies
              </PolicyContainerBodyItemSubContentTitle>
              <PolicyContainerBodyItemSubContentDesc className="PolicyContainerBodyItemSubContentDesc">
                We may employ cookies and similar technologies to track activities on our Service and store certain information. These technologies are used for maintaining your session, preferences, and enhancing your experience on our Service.
              </PolicyContainerBodyItemSubContentDesc>
            </PolicyContainerBodyItemSubContent>
          </PolicyContainerBodyItem>

          <PolicyContainerBodyItem className="PolicyContainerBodyItem">
            <PolicyContainerBodyItemTitle className="PolicyContainerBodyItemTitle">
              How We Use Your Information
            </PolicyContainerBodyItemTitle>
            <PolicyContainerBodyItemSubContent className="PolicyContainerBodyItemSubContent">
              <PolicyContainerBodyItemSubContentDesc className="PolicyContainerBodyItemSubContentDesc">
                We use the information we collect for several purposes:
              </PolicyContainerBodyItemSubContentDesc>
              <PolicyContainerBodyItemUl className="PolicyContainerBodyItemUl">
                <ul style={{ listStyleType: 'disc' }}>
                  <li>To provide and improve our Service</li>
                  <li>To personalize your experience</li>
                  <li>To communicate with you about our Service</li>
                  <li>To provide customer support</li>
                  <li>To detect, prevent and address technical issues</li>
                  <li>For marketing purposes, with your consent</li>
                  <li>To conduct analytics and research</li>
                </ul>
              </PolicyContainerBodyItemUl>
            </PolicyContainerBodyItemSubContent>
          </PolicyContainerBodyItem>


          <PolicyContainerBodyItem className="PolicyContainerBodyItem">
            <PolicyContainerBodyItemTitle className="PolicyContainerBodyItemTitle">
              Sharing and Disclosure of Information
            </PolicyContainerBodyItemTitle>
            <PolicyContainerBodyItemSubContentDesc className="PolicyContainerBodyItemSubContentDesc">
              We may share your information with third-party service providers who perform services on our behalf, such as hosting, analytics, payment processing, and customer service. These third parties are given access to your information as needed to perform these tasks and are bound by confidentiality obligations
            </PolicyContainerBodyItemSubContentDesc>
            <PolicyContainerBodyItemSubContent className="PolicyContainerBodyItemSubContent">
              <PolicyContainerBodyItemSubContentTitle className="PolicyContainerBodyItemSubContentTitle">
                Legal Requirements
              </PolicyContainerBodyItemSubContentTitle>
              <PolicyContainerBodyItemSubContentDesc className="PolicyContainerBodyItemSubContentDesc">
                useTurboFox.com may disclose your Personal Data in the good faith belief that such action is necessary to:
              </PolicyContainerBodyItemSubContentDesc>
              <PolicyContainerBodyItemUl className="PolicyContainerBodyItemUl">
                <ul style={{ listStyleType: 'disc' }}>
                  <li>Comply with a legal obligation</li>
                  <li>Protect and defend the rights or property of useTurboFox.com</li>
                  <li>Prevent or investigate possible wrongdoing in connection with the service</li>
                  <li>Protect the personal safety of users of the service or the public</li>
                  <li>Protect against legal liability</li>
                </ul>
              </PolicyContainerBodyItemUl>
            </PolicyContainerBodyItemSubContent>
          </PolicyContainerBodyItem>


          <PolicyContainerBodyItem className="PolicyContainerBodyItem">
            <PolicyContainerBodyItemTitle className="PolicyContainerBodyItemTitle">
              Data Security
            </PolicyContainerBodyItemTitle>
            <PolicyContainerBodyItemSubContentDesc className="PolicyContainerBodyItemSubContentDesc">
              The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. We strive to use commercially acceptable means to protect your Personal Data, including SSH 256-bit encryption and cloud-hosted services.
            </PolicyContainerBodyItemSubContentDesc>
          </PolicyContainerBodyItem>

          <PolicyContainerBodyItem className="PolicyContainerBodyItem">
            <PolicyContainerBodyItemTitle className="PolicyContainerBodyItemTitle">
              Your Privacy Rights
            </PolicyContainerBodyItemTitle>
            <PolicyContainerBodyItemSubContentDesc className="PolicyContainerBodyItemSubContentDesc">
              You have certain rights regarding the personal information we hold about you. These may include the rights to access, correct, delete, or restrict the use of your information. You may also have the right to object to the processing of your personal information or to receive a copy of your information in a portable format.
            </PolicyContainerBodyItemSubContentDesc>
          </PolicyContainerBodyItem>

          <PolicyContainerBodyItem className="PolicyContainerBodyItem">
            <PolicyContainerBodyItemTitle className="PolicyContainerBodyItemTitle">
              Changes to This Privacy Policy
            </PolicyContainerBodyItemTitle>
            <PolicyContainerBodyItemSubContentDesc className="PolicyContainerBodyItemSubContentDesc">
              We may update this Privacy Policy from time to time in response to changing legal, technical, or business developments. When we update our Privacy Policy, we will take appropriate measures to inform you, consistent with the significance of the changes we make.
            </PolicyContainerBodyItemSubContentDesc>
          </PolicyContainerBodyItem>

          <PolicyContainerBodyItem className="PolicyContainerBodyItem">
            <PolicyContainerBodyItemTitle className="PolicyContainerBodyItemTitle">
              Contact Us
            </PolicyContainerBodyItemTitle>
            <PolicyContainerBodyItemSubContentDesc className="PolicyContainerBodyItemSubContentDesc">
              If you have any questions or concerns about our use of your information or about this Privacy Policy, please contact us at support@useturbofox.com.

              By using our Service, you acknowledge that you have read and understand this Privacy Policy and agree to the collection and use of information in accordance with this policy.

            </PolicyContainerBodyItemSubContentDesc>
          </PolicyContainerBodyItem>

        </PolicyContainerBodyInner>

      </PolicyContainerBody>
    </PolicyContainer>
  );
};

export default Policy;
