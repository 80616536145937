import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, SIZES } from "../../../../constants";
import { Select } from "antd";

export const FilterSelectOptionGroup = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        gap:3px;
        justify-content:space-between;
    `}
`;

export const SelectOptionButton = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body4}px;
        font-family:${FONT_FAMILY.medium};
        display:flex;
        gap:10px;
        align-items:center;
        justify-content:center;
        background-color: ${COLORS.primary};
        border:1px solid ${COLORS.primary};
        color: white;
        padding:7px 26px;
        border-radius: 18px;
        &:hover {
          background-color: transparent;
          color: ${COLORS.primary};
        }
      ${theme.breakpoints.down("md")} {
      }
      ${theme.breakpoints.down("sm")} {
        padding:4px 16px;
        font-size:${SIZES.body2}px;
          font-family:${FONT_FAMILY.regular};
      }
    `}
`;
export const SelectOptionButtonClear = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body4}px;
        font-family:${FONT_FAMILY.medium};
        display:flex;
        gap:10px;
        align-items:center;
        justify-content:center;
        background-color: ${COLORS.white};
        border:1px solid ${COLORS.primary};
        color: ${COLORS.primary};
        padding:7px 26px;
        border-radius: 18px;
      ${theme.breakpoints.down("md")} {
      }
      ${theme.breakpoints.down("sm")} {
        padding:4px 16px;
        font-size:${SIZES.body2}px;
          font-family:${FONT_FAMILY.regular};
      }
    `}
`;
export const PersonOwnButtonWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display: flex;
        align-items:end;        
        width:100%;
        justify-content:end;
        gap:10px;
        ${theme.breakpoints.down("sm")} {
          width:100%;
          justify-content:end;
        }
    `}
`;

export const PersonOwnCarInfoContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width:100%;
      display:flex;
      flex-direction:column;
      gap:10px;
  `}
`;
export const PersonOwnTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.h6}px;
      font-family:${FONT_FAMILY.semibold};

  `}
`;
export const PersonOwnStatus = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.h9}px;
      font-family:${FONT_FAMILY.medium};
      display:flex;
      align-items:center;
      gap:10px;
  `}
`;
export const PersonOwnCarInfos = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      justify-content:space-between;
      width:100%;
  `}
`;
export const OwnCarInfoItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      width:25%;
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.regular};
      display:flex;
      gap:8px;
      font-weight:600;
  `}
`;
export const OwncarInfoItemSpan = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      color:#818181;
  `}
`;
export const PersonOwnCarInfoWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display:flex;
        height:100%;
        display:flex;
        flex-direction:column;
  `}
`;
export const PersonOwnCarFilterOptions = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      border-radius:8px;
      padding:14px 4px 0 4px;
      gap:15px;
      flex-direction:column;
      ${theme.breakpoints.down("sm")} {
        flex-wrap:wrap;
        justify-content:initial;
        gap:8px;
      }
  `}
`;
export const PersonOwnCarInfoItemsWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      flex-wrap:wrap;
      gap:5px;
      ${theme.breakpoints.down("sm")} {
        flex-wrap:wrap;

  `}
`;
export const PersonOwnCarInfoRow = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      gap:15px;
  `}
`;
export const PersonOwnCarInfoItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display: flex;
      flex-direction: column;
      gap: 3px;
      width:32.6%;

      ${theme.breakpoints.down("sm")} {
        width:49%;
      }
      ${theme.breakpoints.down("xs")} {
      }
  `}
`;
export const PersonOwnCarInfoLabel = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:${SIZES.body4}px;
      font-family:${FONT_FAMILY.regular};
      padding-left: 5px;
  `}
`;
export const AntSelect = styled(Select) <{ theme?: Theme }>`
  ${({ theme }) => `
      ::-webkit-scrollbar {
        width: 1px !important;
      }
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey !important; 
          border-radius: 10px !important;
        }
        
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: red !important; 
        border-radius: 10px !important;
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #b30000 !important; 
      }
  `}
`;
export const PersonOwnCarInfoYearSelects = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      display:flex;
      gap:4px;
  `}
`;
