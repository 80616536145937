import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { Link } from "react-router-dom";
import { COLORS, FONT_FAMILY, SIZES, MSIZES } from "../../../constants";

export const HeaderMain = styled.header<{ theme?: Theme }>`
  ${({ theme }) => `
        position: sticky;
        top: 30px;
        border-radius: 20px;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1000;
        background: ${COLORS.header};
        margin: 0 auto;
        backdrop-filter: blur(2px);

        ${theme.breakpoints.down("md")} {
          background: #fff;
          padding: 12px 18px !important;
          width: 100%;
          border-radius: 0;
          top: 0px;
          height: 60px !important;
        }
        ${theme.breakpoints.up("md")} {
          padding: 13px 2.5%;
          width: 90%
        }
        ${theme.breakpoints.up("lg")} {
          padding: 14px 3.5%;
          width: 85%
        }
        ${theme.breakpoints.up("xl")} {
          padding: 15px 5.5%;
          width: 70%
        }
        `}
`;

export const ModalWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    position: fixed;
    z-index: 8;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.gray}80;
    backdrop-filter: blur(2px);
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing(5)};
  `}
`;

export const HeaderLogo = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
    ${theme.breakpoints.down("xs")} {
      width: 86px;
    }
    ${theme.breakpoints.up("xs")} {
      width: 130px;
    }
  `}
`;

export const HeaderLogoLink = styled.a`
  text-decoration: none;
`;

export const HeaderNavLink = styled(Link) <{ theme?: Theme }>`
  ${({ theme }) => `
    font-family: ${FONT_FAMILY.medium};
    padding: 0 20px;
    width: 100%;
    font-size: ${SIZES.body1}px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;

    ${theme.breakpoints.down("lg")} {
      padding: 0 8px;
      font-size: 16px;
    }

    ${theme.breakpoints.down("md")} {
      font-size: ${MSIZES.body1}px;
    }

    &:hover {
      color: ${COLORS.blue};
    }
  `}
`;

export const NavigationMenuButton = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
        display: none;
        place-items: center;
        padding: ${theme.spacing(2.5)};
        cursor: pointer;

        ${theme.breakpoints.down("md")} {
          display: grid;
        }
    `}
`;

export const NavigationMenuIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }: any) => `
    width: 20px;
    `}
`;

export const NavigationItemContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
        display: none;
        ${theme.breakpoints.up("md")} {
          display: flex;
          align-items: center;
          
        }
    `}
`;

export const HeaderTryButton = styled(Link) <{ theme?: Theme }>`
  ${({ theme }) => `
    background: ${COLORS.dark};
    color: ${COLORS.white};    
    font-family: ${FONT_FAMILY.medium};
    padding: 11px 29px;
    border-radius: 7px;
    text-align: center;
    margin-right: 20px;
    width: 100%;
    font-size: ${SIZES.body1}px;
    border: 1px solid ${COLORS.dark};
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    ${theme.breakpoints.down("md")} {
      margin: 0 !important;
    }
    
    ${theme.breakpoints.down("lg")} {
      font-size: 16px;
    }

    ${theme.breakpoints.down("md")} {
      font-size: ${MSIZES.body1}px;
    }

    &:hover {
      color: ${COLORS.black};
      background: ${COLORS.white};
    }
 
  `}
`;

export const HeaderLoginButton = styled(Link) <{ theme?: Theme }>`
  ${({ theme }) => `
    color: ${COLORS.black};    
    font-family: ${FONT_FAMILY.medium};
    padding: 10px 25px;
    border-radius: 7px;
    border: 1px solid ${COLORS.gray};
    text-align: center;
    width: 100%;
    &:hover {
      color:
    }
    ${theme.breakpoints.down("xs")} {
      font-size: ${MSIZES.body1}px;
    }

    ${theme.breakpoints.up("xs")} {
      font-size: ${SIZES.body1}px;
    }
 
  `}
`;
