import React, { useEffect, useRef, useState } from 'react';
import {
  HeaderMain,
  HeaderLogo,
  HeaderLogoLink,
  HeaderNavLink,
  HeaderTryButton,
  NavigationMenuButton,
  NavigationItemContainer,
  ModalWrapper,
  NavigationMenuIcon
} from './styles';
import { ICONS, IMAGES } from '../../../constants';
import { useAuth } from '../../../context/AppContext';
import { PageLayoutSidebar } from '../../../layout/dashboard/styles';

const Header = ({ ...props }) => {
  const { showMobileMenu, handleMobileMenu } = useAuth();
  const wrapperRef = useRef<null | HTMLDivElement>(null);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleMobileMenu(false);
    // eslint-disable-next-line
  }, [width]);


  const handleSidebar = () => {
    handleMobileMenu(!showMobileMenu);
  };

  return (
    <>
      <HeaderMain {...props} className='header-main'>
        <HeaderLogoLink className='header-logo-link' href="/">
          <HeaderLogo src={IMAGES.logo} />
        </HeaderLogoLink>
        <NavigationItemContainer className='navigation-item-container'>
          <HeaderTryButton className='header-try-button' to={"/signup"}>
            Try&nbsp;for&nbsp;free
          </HeaderTryButton>
          <HeaderNavLink className='header-nav-link' to='/login'>
            Login
          </HeaderNavLink>
        </NavigationItemContainer>
        <NavigationMenuButton className='navigation-menu-button' onClick={handleSidebar}>
          {
            showMobileMenu ?
              <NavigationMenuIcon className='navigation-menu-icon' src={ICONS.menu_close} />
              :
              <NavigationMenuIcon className='navigation-menu-icon' src={ICONS.menu_open} />
          }
        </NavigationMenuButton>
      </HeaderMain>
      <PageLayoutSidebar showMobileMenu className='page-layout-sidebar'
        style={
          showMobileMenu
            ? { transform: 'scale(1, 1)' }
            : { transform: 'scale(1, 0)' }
        }
      />
      {
        showMobileMenu &&
        <ModalWrapper className='modal-wrapper' ref={wrapperRef} onMouseDown={handleSidebar} />
      }
    </>
  );
};

export default Header;
