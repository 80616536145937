import React, { useEffect, useMemo, useRef, useState } from "react";
import Table, { Tsorter } from "../../../../../components/table";
import { DTopTenHead } from "./data";
import { usePagination } from "../../../../../hooks";
import { TTableRenderItemObject } from "../../../../../components/table/types";
import { TSingleCar } from "../../../../../api/report/types";
import { TopBottomTableContainer } from "./styles";
import { nFormatter, sortByProperty } from "../../../../../utils/helper";
import { PaginationCompetitors } from "../../../../earningPotential/styles";
import { Pagination } from "../../../../../components/ui";
import { useAuth } from "../../../../../context/AppContext";

const PersonCarTable = (props: any) => {
  const { data } = props;
  const { setMapCars, setMapCenter, setDetailMap, setSelectedCar } = useAuth();
  const [totalCar, setTotalCar] = useState<any[]>([]);
  const [sorter, setSorter] = useState<Tsorter>("monthly_roi");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>('desc');

  useEffect(() => {
    if (data) {
      setTotalCar(data)
      setTotalResults(data.length);
    }
    //eslint-disable-next-line
  }, [data])

  const sortedData = useMemo<any[]>(() => {
    return sortByProperty<any>(totalCar, sorter, sortOrder ?? "asc");
  }, [sorter, sortOrder, totalCar]);

  const PageSize = 20;
  const { pagesCount, page, setTotalResults, handlePageChange } = usePagination(
    {
      limit: PageSize,
      page: 1,
      onChange: async (params, setPage) => {
        setPage(params.page);
        setTotalResults(totalCar?.length);
      },
    }
  );

  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return sortedData?.slice(firstPageIndex, lastPageIndex);

  }, [page, sortedData, PageSize]);

  const handleClickVehicle = (car: any) => {
    setMapCenter(() => [{
      lat: car.latitude,
      lng: car.longitude
    }])
    setDetailMap(true)
    setSelectedCar(car);
  }

  const renderItem = ({ headItem, row }: TTableRenderItemObject) => {
    const car: any = row.data as TSingleCar;

    if (headItem.reference === "no") {
      return car.no;
    }
    if (headItem.reference === "monthly_roi") {
      return `${car[headItem.reference]}%`;
    }
    if (headItem.reference === "expec_month_rev") {
      if (car[headItem.reference]) {
        return `$${Math.round(car[headItem.reference])}`;
      } else {
        return `$${car[headItem.reference]}`
      }
    }
    if (headItem.reference === "msrp") {
      return `$${nFormatter(car[headItem.reference], 1)}`;
    }
    if (headItem.reference === "vehicle_make_model") {
      return (
        //eslint-disable-next-line
        <a onClick={() => handleClickVehicle(car)} style={{ cursor: 'pointer' }}>
          {`${car.make} ${car.model} ${car.year}`}
        </a>
      )
    }
  };

  useEffect(() => {
    if (currentTableData) {
      setMapCars(currentTableData)
    }
    //eslint-disable-next-line
  }, [currentTableData])

  const divRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside of the div
    const handleClickOutside = (event: any) => {
      //@ts-ignore
      if (divRef.current && !divRef.current.contains(event.target)) {
        if (currentTableData) {
          setSelectedCar(null);
          setDetailMap(false);
        }
      }
    };

    // Add event listener when component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    //eslint-disable-next-line
  }, [divRef]);

  return (
    <TopBottomTableContainer ref={divRef}>
      <Table
        head={DTopTenHead}
        items={currentTableData}
        renderItem={renderItem}
        sorter={sorter}
        setSorter={setSorter}
        setSortOrder={setSortOrder}
      />
      <PaginationCompetitors className="PaginationCompetitors">
        <Pagination
          onChange={(_e, x) => handlePageChange(x)}
          page={page}
          count={pagesCount}
        />
      </PaginationCompetitors>
    </TopBottomTableContainer>
  );
};

export default PersonCarTable;
