import styled from '@emotion/styled';
import { Theme, MenuItem, TextField, Autocomplete, Chip } from '@mui/material';
import { DateTimePicker, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { Label } from '../../ui';
import { COLORS, FONT_FAMILY } from '../../../constants';

export const InputMain = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
    display: inline-flex;
    flex-direction: column;
    font-family: ${FONT_FAMILY.regular}! important;
    color: ${COLORS.white} !important;
    position: relative;
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    vertical-align: top;
    width: 100%;
    .MuiTextField-root {
      background: ${COLORS.dark};
    }
    .MuiFormLabel-root {
      line-height: 15px;
      color: #808289;
    }
    .MuiInputBase-root {
      &.MuiInputBase-multiline {
        padding: 0;
      }
      .MuiInputBase-input {
        padding: ${theme.spacing(3)} !important;
        text-overflow: ellipsis;
      }
      .MuiOutlinedInput-notchedOutline {
        border-bottom: 1px solid ${COLORS.gray};
      }
      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-bottom: 1px solid ${COLORS.gray};
        }
      }
      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-bottom: 1px solid ${COLORS.gray};
        }
      }
    }
    ${theme.breakpoints.down('md')} {
      .MuiInputBase-root {
        .MuiInputBase-input {
          font-size: 14px
        }
      }
    }
    ${theme.breakpoints.down('sm')} {
      .MuiInputBase-root {
        .MuiInputBase-input {
          padding: ${theme.spacing(1.5)} !important;        }
      }

      .MuiFormLabel-root {
        line-height: 15px;
        top: -5px;
        color: #808289;
      }
    }
  `}
`;

export const InputLabel = styled(Label) <{ theme?: Theme }>`
  ${({ theme }: any) => `
    margin-bottom: ${theme.spacing(0.5)};
    color: #7E839F;
    ${theme.breakpoints.down('md')} {
      font-size: 13px
    }
  `}
`;

export const InputSelect = styled(Autocomplete)`
  .MuiOutlinedInput-root {
    padding: 0 !important;
  }  
`;

export const InputSelectItem = styled(MenuItem)``;

export const InputText = styled(TextField)`
  width: 100%;
  background: ${COLORS.black};
  & .MuiInputBase-root.Mui-disabled {
    & svg {
      cursor: pointer;
    }
    & .MuiOutlinedInput-input.Mui-disabled {
      opacity: 1;
      color: ${COLORS.white} !important
      -webkit-text-fill-color: ${COLORS.white} !important ;
    }
  }
  & .MuiOutlinedInput-input {
    opacity: 1;
    font-family: ${FONT_FAMILY.regular}! important;
    color: ${COLORS.white} !important;
    -webkit-text-fill-color: ${COLORS.white} !important;
  }
`;

export const InputMultiSelect = styled(Autocomplete) <{ theme?: Theme }>`
${({ theme }: any) => `
  border-color: rgba(0, 0, 0, 0.26) !important;
  background: ${COLORS.white};
  input,
  fieldset {
    border: none !important;
  }

  .MuiOutlinedInput-root {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
  }

  .MuiAutocomplete-endAdornment {
      top: 7.5px !important;
      ${theme.breakpoints.down('md')} {
        top: 3.5px !important;
      };
      ${theme.breakpoints.down('sm')} {
        top: 1.5px !important;
      }
    }

    svg {
      color: #afaeb7
    }
    
    &:disabled {
      border: 1px solid rgba(0, 0, 0, 0.26) !important;
    }
  `}
`;

export const MultiSelectInputContainer = styled.div<{
  theme?: Theme;
  disabled?: boolean;
}>`
  ${({ theme, disabled }) => `
  border: ${disabled && disabled
      ? '1px solid #383746 !important'
      : '1px solid #383746'
    };
  border-radius: 5px;

  max-height: 42px;
  overflow-y: auto;

  &:disabled {
    border: 1px solid rgba(0, 0, 0, 0.26) !important;
  }

  &:active,
  &:focus {
    border-color: #448dc9ff;
  }
  `}
`;

export const InputDatepicker = styled(DatePicker) <{ theme?: Theme }>`
${({ theme }: any) => `
  .MuiButtonBase-root-MuiButton-root {
    color: #ffff;
  }
`}
`;

export const InputDateTimepicker = styled(DateTimePicker) <{ theme?: Theme }>`
${({ theme }: any) => `
    & .MuiInputBase-input-MuiOutlinedInput-input {
      background: ${COLORS.white};
    }
  `}
`;

export const InputTimepicker = styled(TimePicker) <{ theme?: Theme }>`
${({ theme }: any) => `
  .MuiButtonBase-root-MuiButton-root {
    color: #ffff;
  }
`}
`;

export const InputRow = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
    display: flex;
    gap: ${theme.spacing(2)};
    align-items: center;
    ${theme.breakpoints.down('sm')} {
      display: grid;
      gap: ${theme.spacing(2)};

      span {
        display: none;
      }
    }
  `}
`;

export const InputError = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
    color: ${theme.palette.error.main};
    font-size: 12px;
    font-weight: 500;
  `}
`;

export const InputChip = styled(Chip) <{ theme?: Theme }>`
  ${({ theme }: any) => `
    .MuiChip-root-InputChip {
      background: ${theme.palette.secondary.main}5;
      color: ${theme.palette.secondary.main};
    }
    `}
`;

export const InfoLabel = styled.div<{ theme?: Theme }>`
  ${({ theme }: any) => `
  color: ${theme.palette.common.gray[8]};
  font-size: 10px;
  font-weight: 500;
`}
`;
