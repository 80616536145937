import React, { useEffect, useState } from "react";
import { CalculateButton, CalculateInfoInput, InfoInput, InputContainer, InputIcon, WindowTitle, Windows } from "./styles";
import { COLORS, ICONS } from "../../constants";
import { useNavigate } from "react-router-dom";
import { Input, MapInput } from "../../components/ui";
import { DMake } from "./data";
import ReportAPI from "../../api/report";
import ClipLoader from "react-spinners/ClipLoader";
import { useDebounce } from "../../hooks";
import { OpeenStreetMap } from "../../api/report/types";

const Calculator = () => {
  const navigate = useNavigate();
  const [makeOptions, setMakeOptions] = useState<any[]>([]);
  const [modelOptions, setModelOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [location, setLocation] = useState<any>(null)
  const [allModelOptions, setAllModelOptions] = useState<any>([])
  const [makeModelData, setMakeModelData] = useState<any>(null)
  const [yearOptions, setYearOptions] = useState<any[]>([]);
  const [mapOptions, setMapOptions] = useState<any[]>([]);

  const [inputInfo, setInputInfo] = useState({
    make: DMake[0],
    model: DMake[0],
    year: "",
  });

  const handleChangeMake = (make: any) => {
    setInputInfo((data: any) => ({
      ...data,
      make: make
    }))
  }

  const handleChangeModel = (event: any) => {
    let makeselect: any = null;

    if (makeModelData) {
      for (let make in makeModelData) {
        for (let model in makeModelData[make]) {
          if (model === event.value) {
            makeselect = {
              label: make,
              value: make
            };
          }
        }
      }
    }

    setInputInfo((data: any) => ({
      ...data,
      model: event,
      make: makeselect
    }))
  }

  useEffect(() => {
    let inputField = document.querySelector('div.autocomplete-input-wrapper>input');
    inputField?.addEventListener('keydown', (e: any) => {
      if (e.key === 'Enter' && e.keyCode === 13 && e.code === 'Enter') {
        e.stopPropagation();
        e.preventDefault();
      } else {
      }
    })
  }, []);

  const getCarOptions = async (location: any) => {
    setLoading(true);
    try {
      const body = {
        lat: location.lat,
        lng: location.lng
      }
      const res = await ReportAPI.getMakeModelYearList(body);
      const makeoptions = res?.make?.map((make: any) => {
        return {
          label: make,
          value: make,
        }
      })

      const modeloptions = res?.model?.map((model: any) => {
        return {
          label: model,
          value: model,
        }
      })

      setMakeOptions(makeoptions);
      setModelOptions(modeloptions);
      setAllModelOptions(modeloptions);

      setMakeModelData(res.data);

    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location?.value?.lat?.toString())
      getCarOptions(location.value)
  }, [location])

  useEffect(() => {
    const selectMake = inputInfo?.make?.value
    let matcedData = [];
    if (makeModelData) {
      if (!selectMake) {
        setModelOptions([...allModelOptions]);
      } else {
        for (let dataKey in makeModelData) {
          if (selectMake === dataKey) {
            matcedData.push(makeModelData[dataKey])
          }
        }


        let modeloptions: any[] = [];
        matcedData.forEach((model) => {
          for (let key in model) {
            modeloptions = [...modeloptions, {
              label: key,
              value: key,
            }]
          }
        })

        setModelOptions(modeloptions);

        let yearArray: any = []
        matcedData?.forEach((item: any) => {
          for (let key in item) {
            if (typeof item[key] === 'object') {
              yearArray.push(...item[key])
            }
          }
        })

        //@ts-ignore
        const uniqueArray = [...new Set(yearArray)]

        let sortedArray = uniqueArray.sort((a: any, b: any) => a - b)
        const yearoptions = sortedArray?.map((year: any) => {
          return {
            label: year?.toString(),
            value: year?.toString(),
          }
        })

        setYearOptions(yearoptions);
      }
    }

    //eslint-disable-next-line
  }, [inputInfo.make])

  const getGeocoding = (address = '') => {
    if (!address) {
      setMapOptions([])
      return;
    };

    const openStreetMapUrl = 'https://nominatim.openstreetmap.org'
    const url = `${openStreetMapUrl}/search?format=json&q=${address}`;

    fetch(url)
      .then((response) => response.json())
      .then((data: OpeenStreetMap[]) => {
        let options = data.filter((location, index, array) => array.findIndex((innerLocation) => innerLocation.display_name === location.display_name) === index);
        options = options.filter((item: any) => item.display_name.includes("United States"));
        if (options.length > 0) {
          const moptions = options.map((item: any) => {
            return {
              label: item.display_name,
              // value: item.display_name,
              value: {
                lat: item.lat,
                lng: item.lon,
                city: item.name
              }
            }
          })
          setMapOptions(moptions)
        }
      })
      .catch(() => setMapOptions([]));
  }

  const getLocationOptions = async (searchTerm: string = '') => {
    getGeocoding(searchTerm);
  };

  const debouncedJobs = useDebounce(getLocationOptions, 500);

  const handleCalculate = () => {
    if (
      !location.value.city ||
      !inputInfo.make.value ||
      !inputInfo.model.value
    ) {

    } else {
      //@ts-ignore
      let min = inputInfo.year ? new Date(inputInfo.year?.value).getFullYear() - 1 : null;
      //@ts-ignore
      let max = inputInfo.year ? new Date(inputInfo.year?.value).getFullYear() + 1 : null;
      const data = {
        city: location.value.city,
        lat: location.value.lat,
        lng: location.value.lng,
        make: inputInfo.make.value,
        model: inputInfo.model.value,
        year: [min, max],
      }

      localStorage.setItem("landingPage", JSON.stringify(data));
    }

    navigate("/signup");
  };

  return (
    <Windows className="windows">
      <WindowTitle className="window-title">
        How Much Could Your Car Earn?
      </WindowTitle>
      <InfoInput className="infoInputAuto">
        <MapInput
          type="select"
          label=""
          onSearch={debouncedJobs}
          placeholder="Select Location"
          options={mapOptions}
          value={location}
          onValue={(location) => setLocation(location)}
        />
      </InfoInput>
      <InfoInput className="info-input">
        <InputContainer className="input-container">
          <InputIcon className="input-icon">
            {loading ? (
              <ClipLoader color={COLORS.blue} loading={loading} size={20} />
            ) : (
              <img src={ICONS.maker} alt="" />
            )}
          </InputIcon>
          <Input
            type="select"
            label=""
            placeholder="Make"
            options={makeOptions}
            value={inputInfo.make}
            onValue={(make) => handleChangeMake(make)}
          />
        </InputContainer>
      </InfoInput>
      <InfoInput>
        <InputContainer>
          <InputIcon>
            {loading ? (
              <ClipLoader color={COLORS.blue} loading={loading} size={20} />
            ) : (
              <img src={ICONS.model} alt="" />
            )}
          </InputIcon>
          <Input
            type="select"
            label=""
            placeholder="Model"
            options={modelOptions}
            value={inputInfo.model}
            onValue={(model) => handleChangeModel(model)}
          />
        </InputContainer>
      </InfoInput>
      <InfoInput>
        <InputContainer>
          <InputIcon>
            {loading ? (
              <ClipLoader color={COLORS.blue} loading={loading} size={20} />
            ) : (
              <img src={ICONS.year} alt="" />
            )}
          </InputIcon>
          <Input
            type="select"
            label=""
            placeholder="Year"
            options={yearOptions}
            value={inputInfo.year}
            onValue={(year) => setInputInfo((data: any) => ({ ...data, year }))}
          />
        </InputContainer>
      </InfoInput>

      <CalculateInfoInput className="calculateinfo-input">
        <CalculateButton
          className="calculate-button"
          onClick={handleCalculate}
          disabled={false}
        >
          Calculate Earning Potential
        </CalculateButton>
      </CalculateInfoInput>
    </Windows>
  )
}

export default Calculator;