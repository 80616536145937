export const COLORS = {
    primary: "#1098F7",
    secondary: "#15E596",

    white: "white",
    black: "#000000",
    dark: "#0D111E",
    gray: "#E3E3E3",
    red: "#F35A56",
    green: "#15E596",
    blue: "#1098F7",
    header: '#f5f5f5',
    light_blue: "#1098f738",
    light_yello: "#E5B900",
    light_red: "#FFBBD0",
    white_smoke:"#EFEFEF",
    light_gray: "#E6E6E6",
    light_green: "#15e59633",
    transparent: "#ffffff00",
    black_pearl : "#151521",
    dove_gray:"#787878b3",
}; 
export const SIZES = {
    // font sizes
    h1: 136.688,
    h2: 91.125,
    h3: 60.75,
    h4: 40.5,
    h5: 27,
    h6: 24,
    h7: 22,
    h8:20,
    h9:18,
    body1: 16.5,
    body4: 14,
    body2: 12,
    body3: 8,
};


export const MSIZES = {
    // font sizes
    h1: 26,
    h2: 18,
    h3: 16,
    h4: 15,
    h5: 13,
    body1: 15,
    body2: 14,
    body3: 13,
};

export const FONT_FAMILY = {
    regular: "NunitoSans-Regular",
    medium: "Poppins-Medium",
    semibold: "Poppins-SemiBold",
    bold: "Poppins-Bold",
    dm_medium: "DM Sans-Medium",
}

const appTheme = { COLORS, SIZES, FONT_FAMILY, MSIZES };

export default appTheme;
