import { TSidebarItem } from './types';
import { ICONS } from '../../../constants';

export const DSidebarItems: Array<TSidebarItem> = [
  {
    id: 1,
    type: 'nested',
    icon: ICONS.report_icon,
    label: 'My Reports',
    items: [
     
    ],
  },
  {
    id: 2,
    type: 'route',
    icon: ICONS.account_icon,
    label: 'My Account',
    location: '/account',
  },
 
];

export const DNSidebarItems: Array<TSidebarItem> = [
  {
    id: 1,
    type: 'route',
    icon: ICONS.report_icon,
    label: 'My Reports',
    location: '/report/0',
  },
  {
    id: 2,
    type: 'route',
    icon: ICONS.account_icon,
    label: 'My Account',
    location: '/account',
  },
 
];
