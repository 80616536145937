import axiosInstance from "../../axios/axiosInstance";
import { API, STRIPE_PUBLIC_KEY } from "../../utils/constant";
import { getToken } from "../../utils/helper";

const SubscriptionAPI = {
  makePaymentRequest: async (productId: string, userEmail: string) => {
    const headers = { "Content-Type": "application/json"}
    const { data } = await axiosInstance.get(`${API}/strapi-stripe/getRedirectUrl/${productId}/${userEmail}`, {
      headers: headers
    });
    return data;
  },

  getAllPlans: async () => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/plans`, {
      headers: headers
    });
    return data;
  },

  getApiKey: async () => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/api-key/mine`, {
      headers: headers
    });
    return data;
  },
  
  getAllProducts: async () => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${STRIPE_PUBLIC_KEY}` }
    const { data } = await axiosInstance.get(`${API}/strapi-stripe/getProduct/0/5/name/asc`, {
      headers: headers
    });
    return data;
  },

  retrieveCheckoutSession: async (checkoutSessionId: string) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/api-key/purchase/${checkoutSessionId}`, {
      headers: headers
    });
    return data;
  },

  deleteApiKey: async (id: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.delete(`${API}/api/api-keys/${id}`, {
      headers: headers
    });
    return data;
  },
  changeToFreePlan: async (id: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`${API}/api/api-key/change-to-free/${id}`, {
      headers: headers
    });
    return data;
  },
};

export default SubscriptionAPI;
