import React, { useEffect, useState } from "react";
import Dashboard from "./describes";
import ManyCar from "./manyCar";
import MoreInfo from "./moreInfo";
import WhatLooking from "./whatLooking";
import Container from "../../../layout/container";
import { useAuth } from "../../../context/AppContext";
import AuthorizationAPI from "../../../api/authorization";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const { authStep, setAuthStep, currentUser, setCurrentUser, handleCreateNewReport } = useAuth();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleStepInfo = (num: any) => {
    setAuthStep(num);
  };

  const handleUpdateUserInfo = async (userinfo: any) => {
    try {
      const body = {
        ...userinfo,
        authstep: authStep + 1,
      };
      await AuthorizationAPI.upateUser(currentUser.id, body);
    } catch (error) { }
  };

  const handleSubmit = async (userinfo: any) => {
    let landingCarReportInfo: any = localStorage.getItem("landingPage");
    if (landingCarReportInfo) {
      landingCarReportInfo = JSON.parse(landingCarReportInfo);
      if (!landingCarReportInfo.city) {
        landingCarReportInfo = null;
      }
    } else {
      landingCarReportInfo = null;
    }

    setSubmitting(true);
    localStorage.setItem("landingPage", "");
    
    try {
      if (landingCarReportInfo) {
        const report = await handleCreateNewReport(landingCarReportInfo);
        if (report) {
          const body = {
            ...userinfo,
            authstep: authStep + 1
          }
          const user = await AuthorizationAPI.upateUser(currentUser.id, body);
          setCurrentUser(user);

          navigate(`/reports/${report?.report_id}`, { replace: true });
        } else {
          const body = {
            ...userinfo,
            authstep: authStep + 1
          }
          const user = await AuthorizationAPI.upateUser(currentUser.id, body);
          setCurrentUser(user);

          navigate("/reports/0", { replace: true });
        }
      } else {
        const body = {
          ...userinfo,
          authstep: authStep + 1
        }
        const user = await AuthorizationAPI.upateUser(currentUser.id, body);
        setCurrentUser(user);

        navigate("/reports/0", { replace: true });
      }
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setAuthStep(currentUser.authstep);
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <Container>
      {authStep === 0 && (
        <Dashboard
          handleStepInfo={handleStepInfo}
          handleUpdateUser={handleUpdateUserInfo}
        />
      )}
      {authStep === 1 && (
        <ManyCar
          handleStepInfo={handleStepInfo}
          handleUpdateUser={handleUpdateUserInfo}
        />
      )}
      {authStep === 2 && (
        <MoreInfo
          handleStepInfo={handleStepInfo}
          handleUpdateUser={handleUpdateUserInfo}
        />
      )}
      {authStep === 3 && (
        <WhatLooking
          handleStepInfo={handleStepInfo}
          handleUpdateUser={handleSubmit}
          loading={submitting}
        />
      )}
    </Container>
  );
};

export default Register;
