import axiosInstance from '../../axios/axiosInstance';
import { getToken } from '../../utils/helper';
import {
  TLoginParams,
  TLoginResponse,
  TConfirmResetPassword,
  TResendEmailConfirmation,
  TRegisterParams,
  TResetPassword,
} from './types';

const AuthorizationAPI = {
  login: async (body: TLoginParams) => {
    const { data } = await axiosInstance.post(`api/auth/local`, body);
    return data as TLoginResponse;
  },

  googleSignInCallBack: async (search: any) => {
    const { data } = await axiosInstance.get(`api/auth/google/callback?${search}`);
    return data as any;
  },

  googleSignIn: async () => {
    const { data } = await axiosInstance.get(`api/strapi-google-auth/init`);
    return data as any;
  },

  register: async (body: TRegisterParams) => {
    const { data } = await axiosInstance.post(`api/auth/local/register`, body);
    // return data as TRegisterParams;
    return data;
  },

  logout: async () => {
    await axiosInstance.post(`api/auth/logout`);
  },

  emailConfirmation: async (confirmation: string) => {
    await axiosInstance.get(`/api/auth/email-confirmation`, {
      params: {
        confirmation,
      },
    });
  },

  resendEmailConfirmation: async (
    body: TResendEmailConfirmation,
  ) => {
    await axiosInstance.post(`api/auth/send-email-confirmation`, body);
  },

  forgotPassword: async (body: TResetPassword) => {
    await axiosInstance.post(`api/auth/forgot-password`, body);
  },

  confirmResetPassword: async (body: TConfirmResetPassword) => {
    await axiosInstance.post(`api/auth/reset-password`, body);
  },

  me: async () => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.get(`api/users/me`, {
      headers: headers
    });
    return data;
  },

  upateUser: async (id: any, body: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.put(`api/users/${id}`, body, {
      headers: headers
    });
    return data;
  },

  changePassword: async (body: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.post(`api/auth/change-password`, body, {
      headers: headers
    });
    return data;
  },
  saveInfos: async (id: any, body: any) => {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` }
    const { data } = await axiosInstance.put(`api/users/${id}`, body, {
      headers: headers
    });
    return data;
  },

};

export default AuthorizationAPI;
