import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { FONT_FAMILY, SIZES } from "../../constants";

export const PolicyContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    padding:10px;
  `}
`;
export const PolicyHeaderWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    padding:20px 50px;
    margin:130px 50px 10px 50px;
    display:flex;
    flex-direction:column;
    text-align: center;
    gap:20px;
    ${theme.breakpoints.down("lg")}{
      align-items:center;
    }
    ${theme.breakpoints.down("md")}{
    }
    ${theme.breakpoints.down("sm")}{
      margin:60px 0 10px 0;
    }
  `}
`;
export const PolicyHeader = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.h4}px;
    font-family:${FONT_FAMILY.bold};
    ${theme.breakpoints.down("lg")}{
      text-align:center;
    }
    ${theme.breakpoints.down("sm")}{
      font-size:${SIZES.h6}px;
    }
  `}
`;
export const PolicyHeaderUpdated = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-family:${FONT_FAMILY.semibold};
    font-size:${SIZES.body1}px;
    ${theme.breakpoints.down("sm")}{
      font-size:${SIZES.body2}px;
    }
  `}
`;

export const PolicyContainerBody = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      padding:70px 85px;
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
      ${theme.breakpoints.down("sm")}{
        padding:20px 15px;
      }
  `}
`;

export const PolicyContainerBodyInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    max-width:1000px;
  `}
`;
export const PolicyContainerBodyInnerDesc = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      ${theme.breakpoints.down("sm")}{
      font-size:${SIZES.body4}px;
      }
  `}
`;
export const PolicyContainerBodyItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display:flex;
    flex-direction:column;
    margin-top:30px;
    gap:20px;
    font-size:${SIZES.body4}px;
  `}
`;
export const PolicyContainerBodyItemTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-family:${FONT_FAMILY.semibold};
      font-size:${SIZES.h7}px;
      ${theme.breakpoints.down("sm")}{
        font-size:${SIZES.body1}px;
      }
  `}
`;
export const PolicyContainerBodyItemSubContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display:flex;
    flex-direction:column;
    gap:10px;
    font-size:${SIZES.body4}px;
  `}
`;
export const PolicyContainerBodyItemSubContentTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-family:${FONT_FAMILY.medium};
      font-size:${SIZES.h9}px;
      ${theme.breakpoints.down("sm")}{
        font-size:${SIZES.body2}px;
      }
  `}
`;
export const PolicyContainerBodyItemSubContentDesc = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
  `}
`;
export const PolicyContainerBodyItemUl = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    margin-left:50px;
  `}
  `;
