import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { FONT_FAMILY, SIZES } from "../../constants";

export const TermsContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    padding:10px;
  `}
`;
export const TermsHeaderWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    padding:20px;
    margin-top:130px;
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
    flex-direction:column;
    gap:40px;
    ${theme.breakpoints.down("sm")}{
      margin-top:60px;
      gap:10px;
    }
  `}
`;
export const TermsHeader = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.h4}px;
    font-family:${FONT_FAMILY.bold};
    ${theme.breakpoints.down("sm")}{
      font-size:${SIZES.h7}px;
    }
  `}
`;
export const TermsHeaderUpdated = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-family:${FONT_FAMILY.semibold};
    font-size:${SIZES.body1}px;
    ${theme.breakpoints.down("sm")}{
      font-size:${SIZES.body2}px;
    }
  `}
`;

export const TermsContainerBody = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    padding:70px 85px;
    ${theme.breakpoints.down("lg")}{
      padding:15px;
    }
  `}
`;

export const TermsContainerBodyInner = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    padding:30px 20px;
  `}
`;


export const TermsItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display:flex;
    flex-direction:column;
    gap:20px;
    margin-bottom:30px;
  `}
`;


export const TermsItemTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    font-size:${SIZES.h7}px;
    font-family:${FONT_FAMILY.semibold};
    ${theme.breakpoints.down("sm")}{
      font-size:${SIZES.body1}px;
    }
  `}
`;


export const TermsItemContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
      font-size:${SIZES.body4}px;
    }
  `}
`;


