export const DJobsFilters = () => ({
  dateFrom: null,
  dateTo: null,
  jobtitle: [],
});

export const DTopTenHead = [
  {
    reference: 'no',
    label: 'No',
    visible: true,
  },
  {
    reference: 'model',
    label: 'Vehicle',
    visible: true,
  },
  {
    reference: 'monthly_roi',
    label: 'Monthly ROI',
    visible: true,
  },
  {
    reference: 'expec_month_rev',
    label: 'Exp. Mon. Revenue',
    visible: true,
  },
  {
    reference: 'msrp',
    label: 'MSRP',
    visible: true,
  },
  {
    reference: 'placement',
    label: 'Placement',
    visible: true,
  }
];

export const DTempCars = [
  {
    "expec_month_rev": 1185.71,
    "id": 2430111,
    "latitude": 40.74114167374636,
    "longitude": -73.99359861524337,
    "monthly_roi": 4.97,
    "msrp": 23840.0,
    "make": "Toyota",
    "model": "Highlander",
    "placement": "top",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 840.8,
    "id": 2409219,
    "latitude": 40.72771889866187,
    "longitude": -74.06291707835987,
    "monthly_roi": 3.21,
    "make": "Toyota",
    "model": "Highlander",
    "msrp": 26170.0,
    "placement": "top",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 87.86,
    "id": 2390538,
    "latitude": 40.719206666808645,
    "longitude": -73.86424471725363,
    "make": "Toyota",
    "model": "Highlander",
    "monthly_roi": 0.32,
    "msrp": 27485.0,
    "placement": "top",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 0.0,
    "id": 2087767,
    "latitude": 40.7866069611174,
    "longitude": -73.9418121511016,
    "monthly_roi": 0.0,
    "msrp": 27385.0,
    "make": "Toyota",
    "model": "Highlander",
    "placement": "top",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 1185.71,
    "id": 2430111,
    "latitude": 40.74114167374636,
    "longitude": -73.99359861524337,
    "monthly_roi": 4.97,
    "msrp": 23840.0,
    "make": "Toyota",
    "model": "Highlander",
    "placement": "bottom",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 840.8,
    "id": 2409219,
    "latitude": 40.72771889866187,
    "longitude": -74.06291707835987,
    "monthly_roi": 3.21,
    "msrp": 26170.0,
    "make": "Toyota",
    "model": "Highlander",
    "placement": "bottom",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 87.86,
    "id": 2390538,
    "latitude": 40.719206666808645,
    "longitude": -73.86424471725363,
    "monthly_roi": 0.32,
    "msrp": 27485.0,
    "make": "Toyota",
    "model": "Highlander",
    "placement": "bottom",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 0.0,
    "id": 2087767,
    "latitude": 40.7866069611174,
    "longitude": -73.9418121511016,
    "monthly_roi": 0.0,
    "make": "Toyota",
    "model": "Highlander",
    "msrp": 27385.0,
    "placement": "bottom",
    "vehicle_make_model": "Toyota Camry"
  }
]

export const DTempTotalCars = [
  {
    "expec_month_rev": 1185.71,
    "id": 2430111,
    "latitude": 40.74114167374636,
    "longitude": -73.99359861524337,
    "monthly_roi": 4.97,
    "msrp": 23840.0,
    "placement": "top",
    "make": "Toyota",
    "model": "Highlander",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 840.8,
    "id": 2409219,
    "latitude": 40.72771889866187,
    "longitude": -74.06291707835987,
    "monthly_roi": 3.21,
    "msrp": 26170.0,
    "placement": "top",
    "make": "Toyota",
    "model": "Highlander",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 87.86,
    "id": 2390538,
    "latitude": 40.719206666808645,
    "longitude": -73.86424471725363,
    "monthly_roi": 0.32,
    "msrp": 27485.0,
    "placement": "top",
    "make": "Toyota",
    "model": "Highlander",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 0.0,
    "id": 2087767,
    "latitude": 40.7866069611174,
    "longitude": -73.9418121511016,
    "monthly_roi": 0.0,
    "msrp": 27385.0,
    "placement": "top",
    "make": "Toyota",
    "model": "Highlander",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 1185.71,
    "id": 2430111,
    "latitude": 40.74114167374636,
    "longitude": -73.99359861524337,
    "monthly_roi": 4.97,
    "msrp": 23840.0,
    "make": "Toyota",
    "model": "Highlander",
    "placement": "bottom",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 840.8,
    "id": 2409219,
    "latitude": 40.72771889866187,
    "longitude": -74.06291707835987,
    "monthly_roi": 3.21,
    "msrp": 26170.0,
    "make": "Toyota",
    "model": "Highlander",
    "placement": "bottom",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 87.86,
    "id": 2390538,
    "latitude": 40.719206666808645,
    "longitude": -73.86424471725363,
    "monthly_roi": 0.32,
    "msrp": 27485.0,
    "placement": "bottom",
    "make": "Toyota",
    "model": "Highlander",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 0.0,
    "id": 2087767,
    "latitude": 40.7866069611174,
    "longitude": -73.9418121511016,
    "monthly_roi": 0.0,
    "make": "Toyota",
    "model": "Highlander",
    "msrp": 27385.0,
    "placement": "bottom",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 1185.71,
    "id": 2430111,
    "latitude": 40.74114167374636,
    "longitude": -73.99359861524337,
    "monthly_roi": 4.97,
    "msrp": 23840.0,
    "placement": "top",
    "make": "Toyota",
    "model": "Highlander",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 840.8,
    "id": 2409219,
    "latitude": 40.72771889866187,
    "longitude": -74.06291707835987,
    "monthly_roi": 3.21,
    "msrp": 26170.0,
    "make": "Toyota",
    "model": "Highlander",
    "placement": "top",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 87.86,
    "id": 2390538,
    "latitude": 40.719206666808645,
    "longitude": -73.86424471725363,
    "monthly_roi": 0.32,
    "msrp": 27485.0,
    "placement": "top",
    "make": "Toyota",
    "model": "Highlander",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 0.0,
    "id": 2087767,
    "latitude": 40.7866069611174,
    "longitude": -73.9418121511016,
    "monthly_roi": 0.0,
    "msrp": 27385.0,
    "make": "Toyota",
    "model": "Highlander",
    "placement": "top",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 1185.71,
    "id": 2430111,
    "latitude": 40.74114167374636,
    "longitude": -73.99359861524337,
    "monthly_roi": 4.97,
    "make": "Toyota",
    "model": "Highlander",
    "msrp": 23840.0,
    "placement": "bottom",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 840.8,
    "id": 2409219,
    "latitude": 40.72771889866187,
    "longitude": -74.06291707835987,
    "monthly_roi": 3.21,
    "make": "Toyota",
    "model": "Highlander",
    "msrp": 26170.0,
    "placement": "bottom",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 87.86,
    "id": 2390538,
    "latitude": 40.719206666808645,
    "longitude": -73.86424471725363,
    "monthly_roi": 0.32,
    "msrp": 27485.0,
    "make": "Toyota",
    "model": "Highlander",
    "placement": "bottom",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 0.0,
    "id": 2087767,
    "latitude": 40.7866069611174,
    "longitude": -73.9418121511016,
    "monthly_roi": 0.0,
    "msrp": 27385.0,
    "make": "Toyota",
    "model": "Highlander",
    "placement": "bottom",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 1185.71,
    "id": 2430111,
    "latitude": 40.74114167374636,
    "longitude": -73.99359861524337,
    "monthly_roi": 4.97,
    "msrp": 23840.0,
    "make": "Toyota",
    "model": "Highlander",
    "placement": "top",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 840.8,
    "id": 2409219,
    "latitude": 40.72771889866187,
    "longitude": -74.06291707835987,
    "monthly_roi": 3.21,
    "msrp": 26170.0,
    "placement": "top",
    "make": "Toyota",
    "model": "Highlander",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 87.86,
    "id": 2390538,
    "latitude": 40.719206666808645,
    "longitude": -73.86424471725363,
    "monthly_roi": 0.32,
    "msrp": 27485.0,
    "make": "Toyota",
    "model": "Highlander",
    "placement": "top",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 0.0,
    "id": 2087767,
    "latitude": 40.7866069611174,
    "longitude": -73.9418121511016,
    "monthly_roi": 0.0,
    "make": "Toyota",
    "model": "Highlander",
    "msrp": 27385.0,
    "placement": "top",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 1185.71,
    "id": 2430111,
    "latitude": 40.74114167374636,
    "longitude": -73.99359861524337,
    "monthly_roi": 4.97,
    "make": "Toyota",
    "model": "Highlander",
    "msrp": 23840.0,
    "placement": "bottom",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 840.8,
    "id": 2409219,
    "latitude": 40.72771889866187,
    "longitude": -74.06291707835987,
    "monthly_roi": 3.21,
    "msrp": 26170.0,
    "make": "Toyota",
    "model": "Highlander",
    "placement": "bottom",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 87.86,
    "id": 2390538,
    "latitude": 40.719206666808645,
    "longitude": -73.86424471725363,
    "monthly_roi": 0.32,
    "msrp": 27485.0,
    "placement": "bottom",
    "make": "Toyota",
    "model": "Highlander",
    "vehicle_make_model": "Toyota Camry"
  },
  {
    "expec_month_rev": 0.0,
    "id": 2087767,
    "latitude": 40.7866069611174,
    "longitude": -73.9418121511016,
    "monthly_roi": 0.0,
    "make": "Toyota",
    "model": "Highlander",
    "msrp": 27385.0,
    "placement": "bottom",
    "vehicle_make_model": "Toyota Camry"
  }
]