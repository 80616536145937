import React, { useState } from "react";
import {
  BackBtnInner,
  MoreInfoContainer,
  MoreInfoContent,
  MoreInfoTiTle,
  MoreInfoRightInput,
  MoreInfoUpWindow,
  InputInfo,
  MoreInfoBackground,
  MoreInfoImg,
} from "./styles";
import { ICONS, IMAGES } from "../../../../constants";
import BackBtnModule from "../../components/bottomButton/backBtnModule";
import BottomButton from "../../components/bottomButton";
import { NextBtnContainer, NextBtns } from "../describes/styles";
import { InputNameInfo } from "../../signUp/styles";

const MoreInfo = (props: any) => {
  const { handleStepInfo, handleUpdateUser } = props;
  const [bool, setBool] = useState<boolean>(false);

  const onMouseOver = () => {
    setBool(true);
  };
  const onMouseLeave = () => {
    setBool(false);
  };

  const [userinfo, setUserInfo] = useState<any>({
    phoneNumber: "",
    companyName: "",
  });

  const handleValidate = async (nextstep: number) => {
    handleStepInfo(nextstep);
    handleUpdateUser(userinfo);
  };

  const handleChange = (event: any) => {
    const field = event.target.name;
    const value = event.target.value;

    setUserInfo((info: any) => ({ ...info, [field]: value }));
  };


  const disabled = false

  return (
    <>
      <MoreInfoContainer className="MoreInfoContainer">
        <BackBtnModule handleStepInfo={handleStepInfo} currentstep={2} />

        <MoreInfoContent className="MoreInfoContent">
          <MoreInfoTiTle className="MoreInfoTiTle">
            Tell us a bit more about yourself.
          </MoreInfoTiTle>
          <MoreInfoRightInput className="MoreInfoRightInput">
            <MoreInfoUpWindow className="MoreInfoUpWindow">
              <InputNameInfo className="input-name-info">
                <InputInfo
                  className="phoneNumber"
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={userinfo.phoneNumber}
                  onChange={handleChange}
                ></InputInfo>
              </InputNameInfo>
              <InputNameInfo className="input-name-info">
                <InputInfo
                  className="companyName"
                  type="text"
                  name="companyName"
                  placeholder="Company Name"
                  value={userinfo.companyName}
                  onChange={handleChange}
                ></InputInfo>

              </InputNameInfo>
             
            </MoreInfoUpWindow>
          </MoreInfoRightInput>
        </MoreInfoContent>
        <MoreInfoBackground className="MoreInfoBackground">
          <MoreInfoImg src={IMAGES.moreinfo_bg}></MoreInfoImg>
        </MoreInfoBackground>
        <NextBtnContainer className="NextBtnContainer">
          <NextBtns
            className="NextBtn"
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onClick={() => handleValidate(3)}
            disabled={disabled}
          >
            Next
            <BackBtnInner
              src={
                bool && !disabled ? ICONS.new_next_arrow_blue : ICONS.new_next_arrow_black
              }
            ></BackBtnInner>
          </NextBtns>
        </NextBtnContainer>
      </MoreInfoContainer>
      <BottomButton
        handleStepInfo={handleValidate}
        currentstep={2}
        disabled={disabled}
      />
    </>
  );
};

export default MoreInfo;
