import React from 'react';
import { useMenu } from '../../../../hooks';
import {
  DeleteIcon,
  EditIcon,
  VerticalDotsIcon,
} from '../../../../components/svg';
import {
  ApplicationStatusActionsMain,
  ApplicationStatusActionsMenu,
  ISpan,
} from './styles';

const ChatMenuActions = (props: any) => {
  const { setEdit, handleDelete } = props;
  const [menu, open, setOpen, buttonRef, position] = useMenu(false);

  const handleMenu = () => {
    setOpen(!open);
  };

  const handleEdit = async () => {
    setEdit(true)
  };

  const ApplicationStatusActions = [
    {
      icon: <EditIcon />,
      label: 'Rename',
      action: () => {
        handleEdit();
        handleMenu();
      },
    },
    {
      icon: <DeleteIcon />,
      label: 'Delete',
      action: () => {
        handleDelete();
        handleMenu();
      },
    },
  ];

  return (
    <ApplicationStatusActionsMain className='text-hover-primary'>
      <ISpan ref={buttonRef}>
        <VerticalDotsIcon onClick={handleMenu} />
      </ISpan>
      {open && (
        <ApplicationStatusActionsMenu
          position={position}
          items={ApplicationStatusActions}
          ref={menu}
        />
      )}
    </ApplicationStatusActionsMain>
  );
};

export default ChatMenuActions;
