import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { Link } from 'react-router-dom';
import { FONT_FAMILY } from '../../../../../constants'

export const SidebarItemNestedDropDown = styled.div<{
  theme?: Theme;
  expanded: boolean;
}>`
  ${({ theme, expanded }) => `
        // padding-left: ${theme.spacing(7)};
        display: flex;
        flex-direction: column;
        align-items: flex-start;
         overflow: hidden;
        
        max-height: ${expanded ? 400 : 0}px;
         transform-origin: top;
        transform 0.4s ease-in-out;
        -webkit-transition: all .4s;
        -moz-transition: all .4s;
        transition: all .4s;
    `}
`;

export const SidebarItemNestedDropDownSubItem = styled(Link) <{ theme?: Theme }>`
  ${({ theme }) => `
        text-decoration: none;
        padding: ${theme.spacing(1)} 0;
        width: 100%;
    `}
`;

export const SidebarItemNestedDropDownSubItemLabel = styled.span<{
  theme?: Theme;
  active: boolean;
}>`
  ${({ theme, active }) => `
        font-size: 14px;
        font-family: ${FONT_FAMILY.regular};
        color: ${active
      ? theme.palette.primary.main
      : (theme.palette.common as any).gray[6]
    };
    `}
`;

export const SidebarItemNestedIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `   
    width: 20px;
    color: ${(theme.palette.common as any).gray[9]};
    svg{
        width: 100%;
        height: 100%;
    }
    `}
`;

export const SidebarItemNestedLabel = styled.span<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size: 14px;
        font-family: ${FONT_FAMILY.medium};
        color: ${(theme.palette.common as any).gray[9]};
    `}
`;

export const SidebarItemNestedContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display: flex;
          align-items: center;
    gap: ${theme.spacing(2.5)};
    border-radius: 10px 0 0 10px;
    cursor: pointer;
    `}
`;

export const SidebarItemNestedMain = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    // gap: ${theme.spacing(2.5)};
    border-radius: 10px 0 0 10px;
    justify-content: space-between;
    cursor: pointer;
`}
`;

export const SidebarItemNestedOuter = styled.div<{
  theme?: Theme;
  active: boolean;
}>`
  ${({ theme, active }: any) => `
    user-select: none;
    border-radius: 10px 0 0 10px;
    cursor: pointer;
    padding: ${theme.spacing(2.5)} ${theme.spacing(2.5)} ${theme.spacing(2.5)} ${theme.spacing(5)};
    display: block;
    text-decoration: none;
    transition:0.6s;
    ${active &&
    `
      background-color: ${theme.palette.common.background};
        ${SidebarItemNestedIcon}{
            color: ${theme.palette.secondary.main};
        }
        ${SidebarItemNestedLabel}{
            color: ${theme.palette.primary.main};
        }
    `
    }
    `}
`;

export const SidebarItemNestedExpandIcon = styled.div<{ expanded: boolean }>`
  ${({ expanded }) => `
    width: 14px;
    height: 14px;
    transform: ${expanded ? 'rotate(-90deg)' : 'rotate(0)'};
    transition: transform 500ms ease-in-out;
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
    `}
`;
