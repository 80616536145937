import React, { useEffect, useState } from "react";
import {
  OwnCarInfoItem,
  OwncarInfoItemSpan,
  PersonOwnCarInfoContainer,
  PersonOwnCarInfos,
  PersonOwnStatus,
  PersonOwnTitle,
} from "./styles";

import "./index.css";
import TurboMainContainer from "../../../../layout/turboMainContainer";
import PersonCarTable from "./personCarTable";
import { StarFilled } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import ReportAPI from "../../../../api/report";
import { nFormatter, sortByProperty } from "../../../../utils/helper";
import { ClipLoaderWrapper } from "../../../earningPotential/styles";
import ClipLoader from "react-spinners/ClipLoader";
import { COLORS } from "../../../../constants";
import { useAuth } from "../../../../context/AppContext";

const PersonOwnCarInfo = () => {
  const location = useLocation();
  const { allReports, setCurrentReport, openBigMapStatus, setDetailMap } = useAuth();
  const pathArray = location.pathname.split('/');
  const report_id = pathArray[2];
  const host_id = pathArray[3];
  const [hostInfo, setHostInfo] = useState<any>();
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>();

  const handleFunction = async () => {
    setLoadingReport(true);
    try {
      const res = await ReportAPI.getHostDetails(report_id, host_id);
      if (res) {
        const sort =  sortByProperty<any>(res.data, "monthly_roi", 'desc');
        const tempdata = sort.map((item: any, index: any) => ({
          ...item,
          no: index + 1
        }))
        setTableData(tempdata)
        setHostInfo(res.hostInfos[0]);
        setLoadingReport(false);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleFunction();
    localStorage.setItem("currentPage", '2');
    //eslint-disable-next-line
  }, [])


  const handlePage = () => {
    let report = allReports.find((item: any) => item.report_id === report_id);
    if (!report) {
      report = allReports[0];

      setCurrentReport(report);
      setDetailMap(false)
    } else {
      setCurrentReport(report);
      setDetailMap(false)
    }
  };

  useEffect(() => {
    // setMapCars([]);
    if (allReports && allReports !== undefined) {
      if (allReports.length > 0) {
        handlePage();
      }
    }
    // eslint-disable-next-line
  }, [report_id, allReports]);

  useEffect(() => {
    if (!openBigMapStatus) {
      const scrollPosition = localStorage.getItem('scrollPosition');
      if (scrollPosition) {
        window.scrollTo({ top: parseFloat(scrollPosition) });
        localStorage.removeItem('scrollPosition');
      }
    }
  }, [openBigMapStatus])

  return (
    <TurboMainContainer>
      <PersonOwnCarInfoContainer className="PersonOwnCarInfoContainer">
        <PersonOwnTitle className="PersonOwnTitle">
          {hostInfo?.name}’s vehicles
        </PersonOwnTitle>

        <PersonOwnStatus className="PersonOwnStatus">
          {hostInfo?.rating?.stars} <StarFilled />
          <OwncarInfoItemSpan> {hostInfo?.total_trips} trips</OwncarInfoItemSpan>
        </PersonOwnStatus>

        <PersonOwnCarInfos className="PersonOwnCarInfos">
          <OwnCarInfoItem className="OwnCarInfoItem">
            Total Cars: <OwncarInfoItemSpan>{hostInfo?.vehicles?.length}</OwncarInfoItemSpan>
          </OwnCarInfoItem>
          <OwnCarInfoItem className="OwnCarInfoItem">
            Total MSRP: <OwncarInfoItemSpan>${nFormatter(hostInfo?.total_MSRP, 1)}</OwncarInfoItemSpan>
          </OwnCarInfoItem>
          <OwnCarInfoItem className="OwnCarInfoItem">
            Total Mon. Revenue: <OwncarInfoItemSpan>${nFormatter(hostInfo?.expec_month_rev, 1)}</OwncarInfoItemSpan>
          </OwnCarInfoItem>
          <OwnCarInfoItem className="OwnCarInfoItem">
            Annual ROI:<OwncarInfoItemSpan> {hostInfo?.annual_roi}%</OwncarInfoItemSpan>
          </OwnCarInfoItem>
        </PersonOwnCarInfos>

        <PersonCarTable data={tableData} />
      </PersonOwnCarInfoContainer>
      {loadingReport && (
        <ClipLoaderWrapper className="ClipLoaderWrapper">
          <ClipLoader
            color={COLORS.black}
            loading={loadingReport}
            size={50}
          />
        </ClipLoaderWrapper>
      )}
    </TurboMainContainer>
  );
};

export default PersonOwnCarInfo;
