// import "animate.css/animate.min.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import './App.css';
import React, { Suspense, useEffect } from 'react';
import ReactGA from "react-ga4";
import { clarity } from 'react-microsoft-clarity';
import { Provider } from 'react-redux'
import store, { persistor } from './redux/store'
import './index.css';
import Theme from './theme';
import { AppcontextProvider, AuthInit } from "./context/AppContext";
import { ThemeProvider } from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import Routers from './routing/Routes';
import { LayoutSplashScreen } from './components/core/MetronicSplashScreen';
import { LayoutProvider } from './components/core/LayoutProvider';
import { MEASUREMENT_ID, MSFT_ID } from './utils/constant';

function App() {

  useEffect(() => {
    if (MEASUREMENT_ID) {
      ReactGA.initialize(MEASUREMENT_ID);
    }

    if (MSFT_ID) {
      clarity.init(MSFT_ID);
      clarity.consent();
    }
  }, [])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={Theme}>
            <LayoutProvider>
              <AppcontextProvider>
                <AuthInit>
                  <ToastContainer />
                  <Routers />
                </AuthInit>
              </AppcontextProvider>
            </LayoutProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}

export default App;
