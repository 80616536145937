export const DJobsFilters = () => ({
    dateFrom: null,
    dateTo: null,
    jobtitle: [],
});

export const DTopTenHead = [
    {
        reference: 'no',
        label: 'No',
        visible: true,
    },
    {
        reference: 'vehicle_make_model',
        label: 'Vehicle',
        visible: true,
    },
    {
        reference: 'monthly_roi',
        label: 'Monthly ROI',
        visible: true,
    },
    {
        reference: 'expec_month_rev',
        label: 'Exp. Mon. Revenue',
        visible: true,
    },
    {
        reference: 'msrp',
        label: 'MSRP',
        visible: true,
    },
];

export const DTempCars = [
    {
        key: "1",
        vehicle: "Mitsubishi Mirage",
        montyly_roi: "3.11%",
        exp_monthly_revenue: "$453",
        msrp: "$3423",
    },
    {
        key: "2",
        vehicle: "Bingo",
        montyly_roi: "12%",
        exp_monthly_revenue: "$212",
        msrp: "$14223",
    },
    {
        key: "3",
        vehicle: "Lexus",
        montyly_roi: "11%",
        exp_monthly_revenue: "$543",
        msrp: "$6765",
    },
    {
        key: "4",
        vehicle: "Santos",
        montyly_roi: "31.1%",
        exp_monthly_revenue: "$111",
        msrp: "$8878",
    },
    {
        key: "5",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
    {
        key: "6",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
    {
        key: "7",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
    {
        key: "8",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
    {
        key: "9",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
    {
        key: "10",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
    {
        key: "11",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
    {
        key: "7",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
    {
        key: "8",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
    {
        key: "9",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
    {
        key: "10",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
    {
        key: "11",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
    {
        key: "7",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
    {
        key: "8",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
    {
        key: "9",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
    {
        key: "10",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
    {
        key: "11",
        vehicle: "Vernado",
        montyly_roi: "21%",
        exp_monthly_revenue: "$231",
        msrp: "$234",
    },
];