import styled from "@emotion/styled";
import { Theme } from "@mui/material";

export const TopBottomTableContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        margin-top:20px;
        position:relative;
    `}
`;

