import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { FONT_FAMILY, SIZES } from "../../../constants";

export const SubScriptionContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    max-width:100%;
    height:100%;
    display:flex;
    flex-direction:column;

    ${theme.breakpoints.down("sm")} {
  `}
`;
export const SubScriptionHeader = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:32px;
      font-family:'Poppins-Medium';
      text-align:center;
      margin-bottom:30px;
      ${theme.breakpoints.down("sm")} {
        margin-bottom:40px;
        font-size:${SIZES.h7}px;
    }
  `}
`;
export const SubScriptionContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        display:flex;
        flex-direction:column;
        flex:1;
        max-height: calc(100dvh - 211px);
        ${theme.breakpoints.down("sm")} {
        max-height: calc(100dvh - 150px);
      }
    `}
`;
export const SubScriptionContentImageSection = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        box-sizing: border-box;
        display: flex;
        flex-flow: wrap;
        width: 100%;
        position:sticky;
        background-color:white;
        top:0;
    `}
`;
export const SubScriptionContentImageSectionSecond = styled.div<{
  theme?: Theme;
}>`
  ${({ theme }) => `
        box-sizing: border-box;
        display: flex;
        flex-flow: wrap;
        width: 100%;
    `}
`;
export const SubScriptionContentSecondWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

    `}
`;
export const SubScriptionContentButton = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        box-sizing: border-box;
        display: flex;
        flex-flow: wrap;
        width: 100%;
        position: sticky;
        top: 167px;

        ${theme.breakpoints.down("md")} {
          top: 165px;
         }
        ${theme.breakpoints.down("sm")} {
          top: 135.25px;
         }
    `}
`;
export const ImageHiddenSection = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:20%;
        background-color:white;
        ${theme.breakpoints.down("md")} {
         width:0;
        }
    `}
`;
export const ImageRestSection = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        flex:1;
        display:flex;
        ${theme.breakpoints.down("md")} {
          width:0;
         }
    `}
`;
export const ButtonRestSection = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        flex:1;
        display:flex;
        
    `}
`;
export const ImageRestSectionDes = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        flex:1;
        display:flex;
        ${theme.breakpoints.down("md")} {
          display:none;
        }
    `}
`;
export const ImageSectionItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:30px;
        display:flex;
        flex-direction:column;
        border:1px solid #e6e6e6;
        width:100%;
        background-color:white;
        ${theme.breakpoints.down("md")} {
          padding:19px;
        }
        ${theme.breakpoints.down("sm")} {
          padding:25px 5px 0 7px;
        }
    `}
`;
export const ImageSectionDescItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:30px;
        display:flex;
        flex-direction:column;
        border-width:0 1px 0 1px;
        border-color:#e6e6e6;
        width:100%;
        background-color:white;
        ${theme.breakpoints.down("sm")} {
          padding:25px 5px 0 7px;
        }
    `}
`;
export const ButtonSectionItem = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        border-width:0 1px 1px 1px;
        border-color:#e6e6e6;
        width:100%;
        padding:10px;
        background-color:white;

        ${theme.breakpoints.down("sm")} {
          padding:8px;
        }
    `}
`;
export const ItemTilte = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.h8}px;
        font-family:${FONT_FAMILY.medium};
        display:flex;
        align-items:center;
        gap:5px;

        ${theme.breakpoints.down("sm")} {
          font-size:${SIZES.body2}px;
          display:flex;
          flex-direction:column;
        }
    `}
`;
export const ItemTitleIcon = styled.img<{ theme?: Theme }>`
  ${({ theme }) => `
        width:31px;
    `}
`;
export const ItemTilteExplore = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size:${SIZES.body4}px;
        font-family:${FONT_FAMILY.regular};
    `}
`;
export const ItemPrice = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:32px;
      font-family:${FONT_FAMILY.bold};
      display:flex;
      
      ${theme.breakpoints.down("sm")} {
        font-size:${SIZES.body4}px;
        font-family:${FONT_FAMILY.medium};
      }
    `}
`;
export const ItemPriceSpan = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `  
        font-size:14px;
        font-family:${FONT_FAMILY.medium};
        color:#8E8E8E;
        display:flex;
        align-items:center;
    `}
`;
export const ItemPriceSpans = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `  
        font-size:26px;
        font-family:${FONT_FAMILY.bold};
        display:flex;
        align-items:center;
        padding-top:7px;
        ${theme.breakpoints.down("sm")} {
          font-size:13px;
          font-family:${FONT_FAMILY.medium};
          
        }
    `}
`;
export const ItemPeriod = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
      font-size:14px;
      font-family:${FONT_FAMILY.medium};
      color:#8E8E8E;
      ${theme.breakpoints.down("sm")} {
        font-size:10px;
        font-family:${FONT_FAMILY.regular};
      }
    `}
`;
export const SubScribeButtonWrapper = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
        padding:8px 0;
        background-color:black;
        color:white;
        border:1px solid black;
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius:36px;
        &:hover {
          background-color:white !important;
          color:black !important;
          border:1px solid black !important;
        }

        ${theme.breakpoints.down("sm")} {
            padding:0 4px;
        }
    `}
`;
