import React, { useEffect, useMemo, useState } from "react";
import Table, { Tsorter } from "../../../../../components/table";
import { DTempCars, DTopTenHead } from "./data";
import { TTableRenderItemObject } from "../../../../../components/table/types";
import { TSingleCar } from "../../../../../api/report/types";
import ReportAPI from "../../../../../api/report";
import { TopBottomTableContainer, TopBottomTableLockTitle } from "./styles";
import { COLORS } from "../../../../../constants";
import Lock from "./lock";
import { useAuth } from "../../../../../context/AppContext";
import { ClipLoaderWrapper } from "../../../../earningPotential/styles";
import ClipLoader from "react-spinners/ClipLoader";
import { nFormatter, sortByProperty } from "../../../../../utils/helper";

const TopBottomTenTable = (props: any) => {
  const { currentPage } = props;
  const { currentReport, setMapCars,  setMapCenter, setDetailMap, setSelectedCar } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const { apiKey } = useAuth();
  const [totalData, setTotalData] = useState<any[]>(DTempCars);
  const [sorter, setSorter] = useState<Tsorter>("no");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);

  const handleGetTopBottomTen = async () => {
    setLoading(true);
    try {
      const res = await ReportAPI.getTopBottomTen(currentReport.report_id);
      if (res) {
        const tempData = res.map((item: any, index: any) => ({
          ...item,
          no: index + 1
        }))
        setTotalData(tempData);
        setLoading(false);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    if (currentReport) {
      handleGetTopBottomTen();
    }
    //eslint-disable-next-line
  }, [currentReport]);

  useEffect(() => {
    if (currentPage.toString() === "1" && totalData) {
      const carArray: any = [];
      totalData.forEach((item: any) => {
        if (item.placement === 'top') {
          item?.vehicles?.forEach((element: any) => {
            element = { ...element, no: item.no, placement: 'top' }
            carArray.push(element)
          });
        } else {
          item?.vehicles?.forEach((element: any) => {
            element = { ...element, no: item.no, placement: 'bottom' }
            carArray.push(element)
          });
        }
      })
      setMapCars(carArray)
    }

    // eslint-disable-next-line
  }, [currentPage, totalData])

  useEffect(() => {
    setMapCars([])
    //eslint-disable-next-line
  }, [])

  const [currentCarIndex, setCurrentCarIndex] = useState<any>(0);
  const [currentCarNo, setCurrentCarNo] = useState<any>(null);

  const handleClickVehicle = (car: any) => {
    let newCarIndex = currentCarIndex;
    if (car?.vehicles.length > 1) {
      if (car.no === currentCarNo) {
        if(currentCarIndex === car?.vehicles.length -1) {
          newCarIndex = -1;
        }
        newCarIndex++;
        if (currentCarIndex >= car?.vehicles.length) {
          newCarIndex = 0;
        }
      } else {
        newCarIndex = 0;
      }
    } else {
      newCarIndex = 0;
    }

    setCurrentCarNo(car.no);

    let element = car?.vehicles[newCarIndex];
    element['no'] = car.no
    setCurrentCarIndex(newCarIndex)
    let cars: any[] = [];
    cars = [{ lat: element.latitude, lng: element.longitude }]
    setMapCenter(() => cars)
    setSelectedCar(element);
    setDetailMap(true);
  }

  const renderItem = ({ headItem, row }: TTableRenderItemObject) => {
    const car: any = row.data as TSingleCar;
    if (headItem.reference === "no") {
      return car.no;
    }
    if (headItem.reference === "model") {
      return (
        //eslint-disable-next-line
        <a onClick={() => handleClickVehicle(car)}>
          {`${car.make} ${car.model} ${car.year}`}
        </a>
      )
    }
    else if (headItem.reference === "placement") {
      return (
        <div
          style={{
            padding: 2,
            width: "fit-content",
            minWidth: "60px",
            borderRadius: "5px",
            textAlign: "center",
            background: `${car.placement === "top" ? COLORS.light_green : COLORS.light_blue
              }`,
            color: `${car.placement === "top" ? COLORS.green : COLORS.blue}`,
          }}
        >
          {car.placement}
        </div>
      );
    }
    else if (headItem.reference === "monthly_roi") {
      return `${car.monthly_roi}%`;
    }
    else if (headItem.reference === "expec_month_rev") {
      return `$${car.expec_month_rev < 1000 ? nFormatter(car.expec_month_rev, 0) : nFormatter(car.expec_month_rev, 1)}`
    }
    else {
      return `$${nFormatter(car[headItem.reference], 1)}`;
    }

  };

  const data = useMemo<any[]>(() => {
    return sortByProperty<any>(totalData, sorter, sortOrder ?? "asc");
  }, [sorter, sortOrder, totalData]);

  return (
    <>
      {!apiKey || !apiKey?.plan_type ? (
        <Lock />
      ) : (
        <>
          {!loading ? (
            <TopBottomTableContainer className="TopBottomTableContainer" >
              <TopBottomTableLockTitle className="TopBottomTableLockTitle">
                Top 10 And Bottom 10
              </TopBottomTableLockTitle>
              <Table
                head={DTopTenHead}
                items={data}
                renderItem={renderItem}
                sorter={sorter}
                setSorter={setSorter}
                setSortOrder={setSortOrder}
              />
            </TopBottomTableContainer>
          ) : (
            <TopBottomTableContainer className="TopBottomTableContainer">
              <Table
                head={DTopTenHead}
                items={data}
                renderItem={renderItem}
                sorter={sorter}
                setSorter={setSorter}
                setSortOrder={setSortOrder}
              />
              {loading && (
                <ClipLoaderWrapper className="ClipLoaderWrapper">
                  <ClipLoader
                    color={COLORS.black}
                    loading={loading}
                    size={50}
                  />
                </ClipLoaderWrapper>
              )}
            </TopBottomTableContainer>
          )}
        </>
      )}
    </>
  );
};

export default TopBottomTenTable;
