export const faqs:{title:string,description:string}[] = [
    {
      title: "How much money can I make renting my car on Turo?",
      description:
        "The best way to figure out your earnings is to look at how much other cars in your area are making. Our Turo earnings calculator helps you do this. Just enter details like your car's make, model, and year, and see what similar cars nearby are earning. Don't forget to think about extra costs like insurance and maintenance to get a clear idea of your possible earnings.",
    },
    {
      title: "How can I estimate the income for my car on Turo?",
      description:
        "Got a specific car in mind? Put in its details in our Turo earnings calculator, including things like its type and age. Adjust for any costs you know, like cleaning or insurance, to see what you could earn by listing it. We use real data from similar cars around you for the most accurate guess.",
    },
    {
      title: "Is renting out my car on Turo a good investment?",
      description:
        "Renting out your car can bring in extra cash and it's a smart way to generate passive income or use a car that's not often driven. But it's important to pick the right car to rent out. Before you start, use a trusted calculator to check how much you might make from your car.",
    },
    {
      title: "How does our Turo earnings calculator work?",
      description:
        "We've worked hard to make our calculator really good at predicting how much you'll earn. It looks at lots of details and compares your car to others like it. Try it out for free and see what you could earn with your car on Turo!",
    },
  ];

  export const DMake = [
    {
        label: '',
        value: ''
    }
];
  