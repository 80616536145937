import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { COLORS, FONT_FAMILY, SIZES } from "../../../../constants";

export const ScatterChartContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        position:relative;
        border:1px solid ${COLORS.gray};
        border-radius:10px;
        padding:2px 5px 0 5px;
        display:flex;
        flex-direction:column;
        gap:10px;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const ScatterChartContainerTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        
        font-size:${SIZES.body1};
        font-family:${FONT_FAMILY.bold};

        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const ScatterChartContainerLockBody = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
          padding-top:20px;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const ScatterChartContainerLockTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        
          font-family:${FONT_FAMILY.medium};
          font-size:${SIZES.body1}px;
          padding: 0 10px;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const ScatterChartContainerLockTitle1 = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        
          font-family:${FONT_FAMILY.regular};
          font-size:${SIZES.body4}px;
          padding-left:10px;

        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
