// ** Initial State
const initialState = {
  isRTL: false,
}

const layoutReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'HANDLE_RTL':
      return { ...state, isRTL: action.value }
    default:
      return state
  }
}

export default layoutReducer
