import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { Input } from "antd";
import { COLORS } from "../../../../constants";

export const ContactModalContainer = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        flex-direction:column;
        gap:16px;
        margin-top:40px;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const ContactModalFullNameWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        display:flex;
        gap:10px;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const ContactModalInputWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        width:100%;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const ContactModalInput = styled(Input)<{ theme?: Theme }>`
  ${({ theme }) => `
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        font-weight: 400;
        width: 100%;
        outline: none;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        padding: 8px 12px;
        box-shadow: rgb(255, 255, 255) 0px 0px 0px 
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const ContactModalCompanyInput = styled(Input)<{ theme?: Theme }>`
  ${({ theme }) => `

        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const ContactModalTextAreaWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `

        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const ContactModalButtonWrapper = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `  
        width:100%;
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;
export const ContactModalButton = styled.button<{ theme?: Theme }>`
  ${({ theme }) => `
        background-color:${COLORS.black};
        color:white;
        border:1px solid ${COLORS.black};
        border-radius:20px;
        padding: 10px 20px;
        width: 100%;
        font-size: 14px;
        &:hover {
          background-color:white;
          color:black;
        }
        
        ${theme.breakpoints.down("md")} {
        }
        ${theme.breakpoints.down("sm")} {
        }

  `}
`;

export const RequiredValidate = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    color:${COLORS.red};
    font-size:13px;
   ${theme.breakpoints.down("md")} {
  }

  `}
`;